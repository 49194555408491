import { Box, Skeleton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { FC, useCallback } from 'react';
import { useQuery } from 'react-query';

import { api } from '../../../../services/api';
import { selectFromQuery } from '../../../../utils';
import CreateAuthorForm from '../Create/form';
import gridColumns from './grid';
import { GridBoxSx, SkeletonBtnSx } from './styles';

const Authors: FC = () => {
  const { data: authors, isLoading: isAuthorsLoading } = useQuery({
    queryFn: () => api.author.authorsGet(),
    queryKey: ['authors'],
    select: useCallback(selectFromQuery, []),
  });

  return (
    <Box>
      <Box sx={GridBoxSx}>
        {isAuthorsLoading ? (
          <>
            <Box sx={SkeletonBtnSx}>
              <Skeleton variant="rounded" width={300} height={40} animation="wave" sx={{ mr: '15px' }} />
              <Skeleton variant="rounded" width={190} height={40} animation="wave" />
            </Box>
            <Skeleton variant="rounded" width="100%" height={50} animation="wave" sx={{ mt: 1 }} />
            {Array.from({ length: 10 }, (_, index) => (
              <Skeleton key={index} variant="rounded" width="100%" height={40} animation="wave" sx={{ mt: 1 }} />
            ))}
          </>
        ) : (
          <Box>
            <CreateAuthorForm />
            {authors && (
              <Box sx={GridBoxSx}>
                <DataGrid
                  disableVirtualization
                  columns={gridColumns}
                  disableColumnSelector
                  pageSize={10}
                  rows={authors}
                  rowsPerPageOptions={[10]}
                  autoHeight
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Authors;
