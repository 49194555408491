import React, { createContext } from 'react';

import { IUserData } from '../../services/api';

const CurrentUserContext = createContext<{
  currentUser: IUserData | null;
  setCurrentUser: React.Dispatch<React.SetStateAction<IUserData | null>>;
}>({ currentUser: null, setCurrentUser: () => null });

export default CurrentUserContext;
