import { ReactNode, useContext, useEffect, useState } from 'react';

import currentUserContext from '../../contexts/CurrentUserContext';
import { IUser } from '../../services/api';

interface IAuthGuardProps {
  children?: ReactNode | ReactNode[];
  roles: number[];
}

const isAuthorised = (user: IUser | null, roles: number[] = []): boolean => {
  let res = true;

  if (!roles.length) return !!user;

  if (user) {
    if (!roles.find((r) => r === user.role.id)) {
      res = false;
    }
  } else res = false;

  return res;
};

export const AuthGuard = ({ children, roles }: IAuthGuardProps) => {
  const { currentUser } = useContext(currentUserContext);
  const [isAuth, setIsAuth] = useState<boolean>(false);

  useEffect(() => {
    setIsAuth(isAuthorised(currentUser, roles));
  }, [currentUser, roles]);

  return isAuth ? <>{children}</> : null;
};
