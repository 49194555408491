import { PublicClientApplication } from '@azure/msal-browser';
import axios from 'axios';
import { useCallback, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { msalConfig } from '../config/authConfig';
import { SetFeedbackType } from '../contexts/FeedbackContext';
import { api, IOffice, IUser, IUserData } from '../services/api';
import { handleErrorMsg, selectFromQuery } from '../utils';

export const useSubscriptionsCheck = (
  currentUser: IUser | null,
  setCurrentUser: React.Dispatch<React.SetStateAction<IUserData | null>>,
  setFeedback: SetFeedbackType,
) => {
  const queryClient = useQueryClient();

  const updateUserOfficeMutation = useMutation(
    (variables: { office: IOffice }) =>
      api.user.usersIdPatch({
        id: Number(currentUser?.id),
        usersIdGetRequest: { office_id: Number(variables.office.id) },
      }),
    {
      onSuccess: async (data) => {
        setCurrentUser(data.data.data);
        await queryClient.invalidateQueries();
      },
      onError: (e) => handleErrorMsg(e, setFeedback),
    },
  );

  const { data: offices } = useQuery({
    queryFn: () => api.office.officesGet(),
    queryKey: ['offices'],
    select: useCallback(selectFromQuery, []),
    enabled: !!currentUser,
  });

  const { data: subscriptions } = useQuery({
    enabled: !!currentUser,
    queryFn: () =>
      api.subscription.subscriptionsGet({
        params: { user_id: currentUser?.id },
      }),
    select: useCallback(selectFromQuery, [currentUser]),
  });

  const postSubMutation = useMutation((notification: string) =>
    api.subscription.subscriptionsPost({
      subscriptionsGetRequest: {
        notification_name: notification,
        status: true,
      },
    }),
  );

  useEffect(() => {
    const postDefaultSubs = async () => {
      if (currentUser) {
        try {
          await Promise.all([postSubMutation.mutateAsync('new_book'), postSubMutation.mutateAsync('new_kindle')]);

          const username = currentUser.name.split(' ')[0];
          setFeedback({
            status: 'success',
            message: `Welcome ${username}, you can check your subscriptions in the settings on the profile menu`,
            welcome: true,
          });
        } catch {
          /* empty */
        }
      }
    };

    const updateOffice = async () => {
      if (currentUser) {
        const msalInstance = new PublicClientApplication(msalConfig);
        const activeAccount = msalInstance.getActiveAccount();
        const accounts = msalInstance.getAllAccounts();

        const graphToken = await msalInstance.acquireTokenSilent({
          scopes: ['User.Read'],
          account: activeAccount || accounts[0],
        });

        const resp = await axios.get('https://graph.microsoft.com/v1.0/me?$select=city', {
          headers: {
            Authorization: `Bearer ${graphToken.accessToken}`,
          },
        });

        const city = resp.data.city;
        const office = offices?.find((of) => of.city === city);
        if (office && office.id !== currentUser.office.id) updateUserOfficeMutation.mutate({ office });
      }
    };

    if (!!subscriptions && !subscriptions.length) {
      postDefaultSubs()
        .then(() => queryClient.invalidateQueries(['subscriptions']))
        .catch((e) => console.log(e));

      if (offices)
        updateOffice()
          .then(() => queryClient.invalidateQueries())
          .catch((e) => console.log(e));
    }
  }, [currentUser, subscriptions, offices]);

  return subscriptions;
};
