import * as Yup from 'yup';

export const schema = Yup.object({
  name: Yup.string()
    .trim()
    .max(30, 'Office name should have a maximum of 30 characters')
    .required('Office name is required'),
  city: Yup.string().trim().max(30, 'City should have a maximum of 30 characters').required('City is required'),
  country: Yup.string().required('Country is required'),
  max_books: Yup.number().integer('Must be an integer').min(0, 'Min 0').max(10, 'Max 10').required('Required'),
  max_term_books: Yup.number().integer('Must be an integer').min(0, 'Min 0').max(365, 'Max 365').required('Required'),
  max_kindles: Yup.number().integer('Must be an integer').min(0, 'Min 0').required('Required'),
  max_term_kindles: Yup.number().integer('Must be an integer').min(0, 'Min 0').max(365, 'Max 365').required('Required'),
});
