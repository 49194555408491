import { Box, Container, Link, Typography } from '@mui/material';
import * as React from 'react';
import { FC } from 'react';

const Copyright: FC = () => {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.amdaris.com">
        Amdaris
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
};

const Footer: FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: (theme) =>
          theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
      }}
    >
      <Container maxWidth="sm">
        <Copyright />
      </Container>
    </Box>
  );
};

export default Footer;
