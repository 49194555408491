import { Box, Button, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound: FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '80vh',
      }}
    >
      <Typography variant="h1" style={{ color: 'black' }}>
        404
      </Typography>
      <Typography variant="h4" style={{ color: 'black', marginTop: '10px' }}>
        Page not found
      </Typography>
      <Button variant="contained" style={{ marginTop: '30px' }} onClick={() => navigate('/')}>
        Back Home
      </Button>
    </Box>
  );
};

export default NotFound;
