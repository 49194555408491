import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Grid, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import * as Yup from 'yup';

import feedbackContext from '../../../../../contexts/FeedbackContext';
import { api } from '../../../../../services/api';
import { handleErrorMsg } from '../../../../../utils';
import { AddFormSubmitBtnSx, AddFormTextFieldSx } from '../../styles';

interface IFormValues {
  description: string;
}

const initialValues: IFormValues = {
  description: '',
};

interface IFormProps {
  handleOpen: () => void;
}

const schema = Yup.object({
  description: Yup.string().max(50, 'Description should be less than 50 chars').required('Description is required'),
});

const AddTagForm = ({ handleOpen }: IFormProps) => {
  const queryClient = useQueryClient();
  const { setFeedback } = useContext(feedbackContext);

  const postMutation = useMutation(
    (values: IFormValues) => {
      return api.tag.tagsPost({ tagsGetRequest: values });
    },
    {
      onSuccess: () => {
        setFeedback({
          status: 'success',
          message: 'The tag was successfully created',
        });
        return queryClient.invalidateQueries(['tags']);
      },
      onError: (e) => {
        handleErrorMsg(e, setFeedback);
      },
    },
  );

  const handleSubmitForm = async (values: IFormValues) => {
    await postMutation.mutateAsync(values);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    resetForm();
    handleOpen();
  };

  const { errors, handleBlur, handleChange, handleSubmit, resetForm, isValid, values, touched } = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmitForm,
    validationSchema: schema,
  });

  return (
    <Box component="form" autoComplete="off" onSubmit={handleSubmit}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          {' '}
          <TextField
            sx={AddFormTextFieldSx}
            InputProps={{ sx: { height: '40px' } }}
            size="small"
            error={touched.description && Boolean(errors.description)}
            helperText={touched.description && errors.description}
            id="description"
            name="description"
            label="Description"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.description}
            spellCheck
          />
        </Grid>
        <Grid item container direction="column" justifyContent="flex-end" alignItems="flex-end" spacing={2}>
          <Grid item>
            {' '}
            <LoadingButton
              sx={AddFormSubmitBtnSx}
              disabled={!isValid}
              loading={postMutation.isLoading}
              type="submit"
              variant="contained"
            >
              Submit
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddTagForm;
