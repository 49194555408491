import { DescriptionOutlined, TabletAndroidOutlined } from '@mui/icons-material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Grid, LinearProgress, Link, Stack, Tooltip } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { AxiosResponse } from 'axios';
import { addDays, format } from 'date-fns';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { AuthGuard } from '../../../components/AuthorizationGuard';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import FeedbackContext from '../../../contexts/FeedbackContext';
import { ConfirmBorrowDialog } from '../../../dialogs/confirmationDialog';
import { api, IBookDetails, IBookRegister, IWish } from '../../../services/api';
import {
  BookRegisterGet200Response,
  BooksGet201Response,
  KindlesGet200Response,
  WishlistsGet200Response,
} from '../../../services/api/openapi';
import BookCopiesStatus from '../../../shared/BookCopiesStatus';
import BookCover from '../../../shared/BookCover';
import { copyObject, selectFromQuery } from '../../../utils';
import DeleteBook from '../Delete';
import EditBook from '../Edit';
import {
  actionButtonDisabledSx,
  actionButtonSx,
  authorsBoxSx,
  authorsListSx,
  authorsSx,
  availableTextSx,
  boldTextSx,
  categoryChipSx,
  chipsContainerSx,
  copiesBoxItemSx,
  copiesBoxSx,
  descriptionBoxSx,
  descriptionSx,
  descriptionTitleSx,
  infoBoxSx,
  infoStackSx,
  italicTextSx,
  librarianActionsBoxSx,
  numberOfPagesBoxSx,
  paperSx,
  progressBoxSx,
  publishedYearBoxSx,
  tagChipSx,
  titleSx,
  typeChipSx,
  wishlistButtonSx,
} from './styles';

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  width: '276px',
  height: '411px',
  borderRadius: '3px',
  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.3)',
});

export interface IBookActionDetails {
  availability: boolean;
  borrowedByCurrentUser: boolean;
  editions: IBookDetails[];
  wishes: number[];
}

const initialAction: IBookActionDetails = {
  availability: false,
  borrowedByCurrentUser: false,
  editions: [],
  wishes: [],
};

export const initialBookActions: IBookActions = {
  ebook: initialAction,
  kindle: initialAction,
  paper: initialAction,
};

export interface IBookActions {
  ebook: IBookActionDetails;
  kindle: IBookActionDetails;
  paper: IBookActionDetails;
}

export const filterBookActions = (
  bookDetails: IBookDetails[],
  booksRegisters: IBookRegister[],
  wishlist: IWish[],
): IBookActions => {
  const actions: IBookActions = copyObject(initialBookActions);
  const availabilityStatus = { available: true, borrowed: false };

  bookDetails.forEach((bd) => {
    const copyBd = copyObject(bd);

    switch (copyBd.type) {
      case 'e-book':
        actions.ebook.editions.push(copyBd);
        if (!actions.ebook.availability) actions.ebook.availability = true;

        if (wishlist.length) {
          const ebookWish = wishlist.find((w) => w.book_edition.id === copyBd.id);
          if (ebookWish) {
            actions.ebook.wishes.push(ebookWish.id);
          }
        }

        if (booksRegisters.find((br) => br.book_edition?.id === bd.id)) {
          actions.ebook.borrowedByCurrentUser = true;
        }
        break;
      case 'paper':
        actions.paper.editions.push(copyBd);

        if (!actions.paper.availability)
          actions.paper.availability = availabilityStatus[copyBd.status as 'available' | 'borrowed'];

        if (wishlist.length) {
          const paperWish = wishlist.find((w) => w.book_edition.id === copyBd.id);
          if (paperWish) {
            actions.paper.wishes.push(paperWish.id);
          }
        }

        if (booksRegisters.find((br) => br.book_edition?.id === bd.id)) {
          actions.paper.borrowedByCurrentUser = true;
        }

        break;
      case 'kindle':
        actions.kindle.editions.push(copyBd);

        if (!actions.kindle.availability)
          actions.kindle.availability = availabilityStatus[copyBd.status as 'available' | 'borrowed'];

        if (wishlist.length) {
          const kindleWish = wishlist.find((w) => w.book_edition.id === copyBd.id);
          if (kindleWish) {
            actions.kindle.wishes.push(kindleWish.id);
          }
        }

        if (booksRegisters.find((br) => br.book_edition?.id === bd.id)) {
          actions.kindle.borrowedByCurrentUser = true;
        }

        break;
    }
  });

  const sortFnc = (a: IBookDetails, b: IBookDetails) => {
    if (a.status === b.status) return 0;

    if (a.status === 'available') {
      return -1;
    } else {
      return 1;
    }
  };

  // Put available editions on first positions
  actions.paper.editions.sort(sortFnc);
  actions.kindle.editions.sort(sortFnc);

  return actions;
};

const ShowBook: FC = () => {
  const [bookActions, setBookActions] = useState<IBookActions>(copyObject(initialBookActions));
  const [actionType, setActionType] = useState<IBookActionDetails>(initialBookActions.paper);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { setFeedback } = useContext(FeedbackContext);
  const { currentUser } = useContext(CurrentUserContext);

  const { data: book, isLoading: isLoadingBook } = useQuery({
    queryFn: () => {
      return api.book.booksIdGet({ id: Number(id) });
    },
    queryKey: ['book', Number(id)],
    select: useCallback((data: AxiosResponse<BooksGet201Response, any>) => {
      return data.data.data;
    }, []),
  });

  const { data: booksRegisters, isLoading: isLoadingBookRegisters } = useQuery({
    queryFn: () => {
      return api.bookRegister.bookRegisterGet();
    },
    queryKey: ['booksRegisters'],
    select: useCallback((data: AxiosResponse<BookRegisterGet200Response, any>) => {
      return data.data.data;
    }, []),
  });

  const { data: wishlist, isLoading: isLoadingWishlist } = useQuery({
    queryFn: () => {
      return api.wishlist.wishlistsGet();
    },
    queryKey: 'wishlist',
    select: useCallback((data: AxiosResponse<WishlistsGet200Response, any>) => {
      return data.data.data;
    }, []),
  });

  const { data: kindlesData, isLoading: isKindlesLoading } = useQuery({
    queryKey: 'kindles',
    queryFn: () => api.kindle.kindlesGet(),
    select: useCallback(
      (data: AxiosResponse<KindlesGet200Response, any>) => {
        return data.data.data.filter((k) => k.office?.id === currentUser?.office?.id);
      },
      [currentUser],
    ),
  });

  const addInWishlistMutation = useMutation(
    (bookEditionIds: number[]) => {
      return api.wishlist.wishlistsMultiplePost(
        {
          wishlistsMultiplePostRequest: {
            book_edition_ids: bookEditionIds,
          },
        },
        { headers: { 'Content-Type': 'multipart/form-data' } },
      );
    },
    {
      onSuccess: () => queryClient.invalidateQueries('wishlist'),
    },
  );

  const borrowBookMutation = useMutation(
    (bookEditionId: number) => {
      return api.bookRegister.bookRegisterPost({
        bookRegisterGetRequest: { book_edition_id: bookEditionId },
      });
    },
    {
      onSuccess: async () => {
        navigate('/books/borrowed');

        await Promise.all([
          queryClient.invalidateQueries('booksRegister'),
          queryClient.invalidateQueries('booksRegistersOffice'),
          queryClient.invalidateQueries('wishlist'),
          queryClient.invalidateQueries(['book', Number(id)]),
        ]);
      },
      onError: (e) => {
        if ((e as any).response.status === 403) {
          const errData = (e as any).response.data;

          setFeedback({
            status: 'error',
            message: errData.message,
          });
        }
      },
    },
  );

  const { data: settings } = useQuery({
    queryFn: () => api.setting.settingsGet(),
    queryKey: 'settings',
    select: useCallback(selectFromQuery, []),
  });

  const handleBorrowBookClick = (bookEditionId: number, bookTitle: string, coverUrl?: string) => {
    const maxTermBooks = settings?.filter((s) => s.office_id.id == currentUser?.office.id)[0].max_term_books;
    const returnDate = maxTermBooks ? format(addDays(new Date(), maxTermBooks), 'MMMM d, yyyy') : '';
    ConfirmBorrowDialog(
      'book',
      bookTitle,
      () => borrowBookMutation.mutate(bookEditionId),
      [
        <Box key={1} component="span" sx={{ fontWeight: 600 }}>
          Please keep in mind the following rules:
        </Box>,
        '1. Please take care of the borrowed book and keep it safe.',
        `2. Please return the book by ${returnDate}.`,
        '3. Please do not forget to use a bookmark to mark the place where you stopped, instead of folding the page. This will keep the book in good condition and prevent damage.',
      ],
      coverUrl,
    );
  };

  const removeFromWishlistMutation = useMutation(
    (bookEditionIds: number[]) => {
      return api.wishlist.wishlistsDeleteMultipleDelete({
        bookEditionIds: bookEditionIds,
      });
    },
    {
      onSuccess: () => queryClient.invalidateQueries('wishlist'),
    },
  );

  useEffect(() => {
    if (book && booksRegisters && wishlist) {
      const filteredActions = filterBookActions(book.details, booksRegisters, wishlist);
      setBookActions(filteredActions);

      if (filteredActions.paper.editions.length) {
        setActionType(filteredActions.paper);
      } else if (filteredActions.ebook.editions.length) {
        setActionType(filteredActions.ebook);
      } else if (filteredActions.kindle.editions.length) {
        setActionType(filteredActions.kindle);
      }
    }
  }, [book, booksRegisters, wishlist]);

  const toggleWishAction = async (action: IBookActionDetails) => {
    if (action.editions.length) {
      if (!action.wishes.length) {
        await addInWishlistMutation.mutateAsync(action.editions.map((e) => e.id));
      } else {
        await removeFromWishlistMutation.mutateAsync(action.editions.map((e) => e.id));
      }
    }
  };

  return (
    <Box data-testid="book_details_page">
      {isLoadingBook || isLoadingBookRegisters || isLoadingWishlist ? (
        <Box sx={progressBoxSx}>
          <LinearProgress />
        </Box>
      ) : (
        book !== undefined && (
          <Paper sx={paperSx} elevation={0}>
            <Box sx={{ marginRight: '25px' }}>
              <Stack direction="row">
                <BookCover
                  bookId={book.id}
                  title={book.title}
                  cover={book.cover}
                  isAvailable={book.details.some((bd) => bd.status === 'available')}
                  width="276px"
                  height="411px"
                />
                <Grid sx={infoBoxSx} container>
                  <Grid item xs container direction="column">
                    <Grid item>
                      <Tooltip title={book.title} placement="top-start">
                        <Box data-testid="showBookTitle" sx={titleSx}>
                          {book.title}
                        </Box>
                      </Tooltip>
                    </Grid>
                    <Grid item xs>
                      <Stack sx={infoStackSx}>
                        <Box>
                          <Box sx={authorsBoxSx}>
                            <Box component="span" sx={authorsSx}>
                              Authors:
                            </Box>
                            <Tooltip title={book.authors.map(({ name }) => name).join(', ')} placement="bottom-start">
                              <Box component="span" sx={authorsListSx}>
                                {book.authors.map(({ name }) => name).join(', ')}
                              </Box>
                            </Tooltip>
                          </Box>
                          <Box sx={publishedYearBoxSx}>
                            <Box sx={boldTextSx}>Published year:</Box>
                            <Box sx={italicTextSx}>{book.published_at} </Box>
                          </Box>
                          {book.details.some((be) => be.number_of_pages !== null) && (
                            <Box sx={numberOfPagesBoxSx}>
                              <Box sx={boldTextSx}>Number of pages:</Box>
                              <Box sx={italicTextSx} data-testid="numberOfPages">
                                {(() => {
                                  const paperEditions = book.details.filter((be) => be.type === 'paper');
                                  const kindleEditions = book.details.filter((be) => be.type === 'kindle');
                                  const ebookEditions = book.details.filter((be) => be.type === 'e-book');

                                  if (paperEditions.length > 0) {
                                    return paperEditions[paperEditions.length - 1].number_of_pages;
                                  } else if (kindleEditions.length > 0) {
                                    return kindleEditions[kindleEditions.length - 1].number_of_pages;
                                  } else if (ebookEditions.length > 0) {
                                    return ebookEditions[ebookEditions.length - 1].number_of_pages;
                                  }
                                })()}
                              </Box>
                            </Box>
                          )}
                        </Box>
                        <Box>
                          <Box sx={chipsContainerSx}>
                            <Box sx={boldTextSx}>Category:</Box>
                            <Box sx={categoryChipSx} key={book.category.id}>
                              {book.category.description}
                            </Box>
                          </Box>
                          <Box sx={chipsContainerSx}>
                            <Box sx={boldTextSx}>Tags:</Box>
                            {book.tags.map((tag) => (
                              <Box sx={tagChipSx} key={tag.id}>
                                {tag.description}
                              </Box>
                            ))}
                          </Box>
                          <Box sx={chipsContainerSx}>
                            <Box sx={boldTextSx}>Types:</Box>
                            {book.details.some((be) => be.type === 'paper') && <Box sx={typeChipSx}>Paper</Box>}
                            {book.details.some((be) => be.type === 'kindle') && <Box sx={typeChipSx}>Kindle</Box>}
                            {book.details.some((be) => be.type === 'e-book') && <Box sx={typeChipSx}>E-Book</Box>}
                          </Box>
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
                <Box sx={{ marginLeft: '25px' }}>
                  <LoadingButton
                    sx={wishlistButtonSx}
                    color="error"
                    disabled={!actionType.editions.length}
                    loading={
                      addInWishlistMutation.isLoading || isLoadingWishlist || removeFromWishlistMutation.isLoading
                    }
                    loadingPosition="center"
                    onClick={async () => toggleWishAction(actionType)}
                    startIcon={
                      actionType.editions.length && actionType.wishes.length ? (
                        <Tooltip title="Remove from wishlist" arrow>
                          <FavoriteIcon sx={wishlistButtonSx} />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Add to wishlist" arrow>
                          <FavoriteBorderIcon sx={wishlistButtonSx} />
                        </Tooltip>
                      )
                    }
                    data-testid="wishlist_book_btn"
                  />
                </Box>
              </Stack>
              <Box sx={descriptionBoxSx}>
                <Box sx={descriptionTitleSx}>Description</Box>
                <Box sx={descriptionSx}>{book.description}</Box>
              </Box>
              <Grid sx={copiesBoxSx} container justifyContent="space-evenly">
                <Grid sx={copiesBoxItemSx} item xs={3}>
                  <Stack direction="column" alignItems="center">
                    <MenuBookIcon sx={{ height: '38px', width: '38px' }} />
                    <Button
                      sx={
                        bookActions.paper.editions.length === 0 ||
                        bookActions.paper.editions.every((edition) => edition.status !== 'available')
                          ? actionButtonDisabledSx
                          : actionButtonSx
                      }
                      disabled={
                        bookActions.paper.editions.length === 0 ||
                        bookActions.paper.editions.every((edition) => edition.status !== 'available')
                      }
                      onClick={() => handleBorrowBookClick(bookActions.paper.editions[0].id, book?.title, book?.cover)}
                      data-testid="borrow_book_btn"
                    >
                      {
                        bookActions.paper.editions.length === 0
                          ? /* eslint-disable @typescript-eslint/indent */
                            ' Borrow Book'
                          : bookActions.paper.editions.every((edition) => edition.status !== 'available')
                          ? 'Already borrowed'
                          : 'Borrow Book' /* eslint-enable @typescript-eslint/indent */
                      }
                    </Button>
                    {book.details.filter((be) => be.type === 'paper').length > 0 && (
                      <>
                        <Box sx={availableTextSx}>Available copies:</Box>
                        <BookCopiesStatus
                          availableCopies={
                            book.details.filter((be) => be.type === 'paper' && be.status === 'available').length
                          }
                          totalCopies={book.details.filter((be) => be.type === 'paper').length}
                        />
                      </>
                    )}
                  </Stack>
                </Grid>
                <Grid sx={copiesBoxItemSx} item xs={3}>
                  <Stack direction="column" alignItems="center">
                    <TabletAndroidOutlined sx={{ height: '38px', width: '38px' }} />
                    <Button
                      sx={
                        !bookActions.kindle.editions.length ||
                        !kindlesData?.length ||
                        kindlesData.filter((k) => k.status === 'available').length === 0
                          ? actionButtonDisabledSx
                          : actionButtonSx
                      }
                      disabled={
                        !bookActions.kindle.editions.length ||
                        !kindlesData?.length ||
                        kindlesData.filter((k) => k.status === 'available').length === 0
                      }
                      onClick={() => navigate('/books?tab=1')}
                      data-testid="borrow_kindle_btn"
                    >
                      {bookActions.kindle.borrowedByCurrentUser ? 'Already borrowed' : 'Borrow Kindle'}
                    </Button>
                    {book.details.filter((be) => be.type === 'kindle').length > 0 && (
                      <>
                        <Grid item>
                          <Box sx={availableTextSx}>Available kindle devices:</Box>
                        </Grid>
                        <Grid item>
                          <BookCopiesStatus
                            availableCopies={kindlesData?.filter((be) => be.status === 'available').length}
                            totalCopies={kindlesData?.length}
                          />
                        </Grid>
                      </>
                    )}
                  </Stack>
                </Grid>
                <Grid sx={copiesBoxItemSx} item xs={3}>
                  <Stack direction="column" alignItems="center">
                    <DescriptionOutlined sx={{ height: '38px', width: '38px' }} />
                    <Button
                      sx={!bookActions.ebook.editions.length ? actionButtonDisabledSx : actionButtonSx}
                      component={Link}
                      disabled={!bookActions.ebook.editions.length}
                      download
                      href={bookActions.ebook.editions.length ? bookActions.ebook.editions[0].file_url : undefined}
                      target="_blank"
                      data-testid="download_ebook_btn"
                    >
                      Download E-Book
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
              <AuthGuard roles={[1, 2]}>
                <Box justifyContent="end" sx={librarianActionsBoxSx}>
                  <EditBook book={book} />
                  <DeleteBook book={book} />
                </Box>
              </AuthGuard>
            </Box>
          </Paper>
        )
      )}
    </Box>
  );
};

export default ShowBook;
