import Axios from 'axios';

interface IGoogleBookItemVolumeInfo {
  authors?: Array<any>;
  categories?: string[];
  description: string;
  imageLinks?: { thumbnail: string };
  industryIdentifiers: { identifier: string; type: 'ISBN_10' | 'ISBN_13' }[];
  language: string;
  publishedDate: string;
  title: string;
  pageCount: number;
}

interface IGoogleBookItem {
  volumeInfo: IGoogleBookItemVolumeInfo;
}

interface IGoogleBook {
  items: Array<IGoogleBookItem>;
  kind: string;
  totalItems: number;
}

export default class GoogleBooks {
  async getGoogleBook(isbn: string): Promise<IGoogleBook | undefined> {
    return Axios.get(`https://www.googleapis.com/books/v1/volumes?q=isbn:${isbn}`).then(
      (response) => response.data ?? undefined,
    );
  }
}
