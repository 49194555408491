import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { AxiosResponse } from 'axios';
import { useFormik } from 'formik';
import { FC, useCallback, useContext, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import FeedbackContext from '../../../../contexts/FeedbackContext';
import { api, IOfficePost, ISettingsPost } from '../../../../services/api';
import { DropdownsGet200Response } from '../../../../services/api/openapi';
import { handleErrorMsg } from '../../../../utils';
import { Inputs } from '../../styles';
import {
  OfficeDialogCloseBtnSx,
  OfficeDialogSaveBtnSx,
  OfficeDialogSaveDisabledBtnSx,
  OfficeDialogTitleSx,
} from '../styles';
import { IFormValues } from '../types';
import { schema } from '../validation';
import { AddOfficeBtnSx } from './styles';

const CreateOffice: FC = () => {
  const { setFeedback } = useContext(FeedbackContext);
  const queryClient = useQueryClient();
  const [open, setOpen] = useState<boolean>(false);

  const formInitialValues: IFormValues = {
    name: '',
    city: '',
    country: 'UK',
    max_books: 3,
    max_term_books: 30,
    max_kindles: 1,
    max_term_kindles: 90,
  };

  const saveSettingsMutation = useMutation(
    (variables: { settingsData: ISettingsPost }) => {
      return api.setting.settingsPost({
        settingsGetRequest: variables.settingsData,
      });
    },
    {
      onSuccess: () => {
        setOpen(false);
        return queryClient.invalidateQueries(['settings']);
      },
      onError: (e) => handleErrorMsg(e, setFeedback),
    },
  );

  const saveOfficeMutation = useMutation(
    (variables: { officeData: IOfficePost; settingsData: ISettingsPost }) => {
      return api.office.officesPost({
        officesGetRequest: {
          name: variables.officeData.name,
          city: variables.officeData.city,
          country: variables.officeData.country,
        },
      });
    },
    {
      onSuccess: (response, variables) => {
        saveSettingsMutation.mutate({
          settingsData: {
            ...variables.settingsData,
            office_id: response.data.data.id,
          },
        });
        setOpen(false);
        return queryClient.invalidateQueries(['offices']);
      },
      onError: (e) => handleErrorMsg(e, setFeedback),
    },
  );

  const { handleSubmit, handleChange, handleBlur, values, resetForm, setFieldValue, errors, isValid, touched } =
    useFormik({
      initialValues: formInitialValues,
      enableReinitialize: true,
      validationSchema: schema,
      onSubmit: () => {
        const officeData = {
          name: values.name,
          city: values.city,
          country: values.country,
        };
        const settingsData = {
          max_books: values.max_books,
          max_term_books: values.max_term_books,
          max_term_kindles: values.max_term_kindles,
        };
        saveOfficeMutation.mutate({ officeData, settingsData });
      },
    });

  const handleOpenDialog = () => {
    resetForm();
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const { data: countriesData, isLoading: isLoadingCountries } = useQuery({
    queryKey: ['countries'],
    queryFn: () => api.dropdown.dropdownsGet({ name: ['office_country'] }),
    select: useCallback((data: AxiosResponse<DropdownsGet200Response, any>) => {
      const countries = Object.entries(data.data.data[0].values) as [string, string][];
      return countries.map((c) => c[1]);
    }, []),
  });

  return (
    <>
      <Button onClick={handleOpenDialog} sx={AddOfficeBtnSx}>
        Add office
      </Button>
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle sx={OfficeDialogTitleSx}>Add office</DialogTitle>
        <Box component="form" onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container columns={{ xs: 12, md: 12 }} spacing={1}>
              <Grid item xs={12}>
                <TextField
                  spellCheck
                  sx={[Inputs]}
                  id="name"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  spellCheck
                  sx={[Inputs]}
                  id="city"
                  label="City"
                  variant="outlined"
                  fullWidth
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!(touched.city && errors.city)}
                  helperText={touched.city && errors.city}
                />
              </Grid>

              <Grid item xs={12}>
                {isLoadingCountries ? (
                  <Box>Loading...</Box>
                ) : (
                  <Autocomplete
                    sx={[Inputs]}
                    disableClearable
                    fullWidth
                    id="country"
                    value={values.country}
                    onChange={(event, value) => setFieldValue('country', value)}
                    options={countriesData ?? []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Country"
                        fullWidth
                        onBlur={handleBlur}
                        error={!!(touched.country && errors.country)}
                        helperText={touched.country && errors.country}
                      />
                    )}
                  />
                )}
              </Grid>

              <Grid item xs={3}>
                <TextField
                  sx={[Inputs]}
                  id="max_books"
                  type="number"
                  label="Max books"
                  variant="outlined"
                  value={values.max_books}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!(touched.max_books && errors.max_books)}
                  helperText={touched.max_books && errors.max_books}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  sx={[Inputs]}
                  id="max_term_books"
                  type="number"
                  label="Max term books"
                  variant="outlined"
                  value={values.max_term_books}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!(touched.max_term_books && errors.max_term_books)}
                  helperText={touched.max_term_books && errors.max_term_books}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  sx={[Inputs]}
                  disabled
                  id="max_kindles"
                  type="number"
                  label="Max kindles"
                  variant="outlined"
                  value={values.max_kindles}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!(touched.max_kindles && errors.max_kindles)}
                  helperText={touched.max_kindles && errors.max_kindles}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  sx={{ m: '6px 0px 6px 2px' }}
                  id="max_term_kindles"
                  type="number"
                  label="Max term kindles"
                  variant="outlined"
                  value={values.max_term_kindles}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!(touched.max_term_kindles && errors.max_term_kindles)}
                  helperText={touched.max_term_kindles && errors.max_term_kindles}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ mb: 1 }}>
            <Button sx={OfficeDialogCloseBtnSx} onClick={handleCloseDialog}>
              Close
            </Button>
            <Button
              type="submit"
              disabled={!isValid}
              sx={!isValid ? OfficeDialogSaveDisabledBtnSx : OfficeDialogSaveBtnSx}
            >
              Save
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default CreateOffice;
