import { SxProps } from '@mui/material';

export const dashboardCoverSx: SxProps = {
  backgroundSize: 'cover',
  backgroundImage: 'url(LibraryBooks8.jpg)',
  marginTop: '-35px',
  width: '100%',
  height: '313px',
  backgroundPositionY: '70%',
  position: 'relative',
  flexShrink: 0,
};

export const keyboardArrowSx: SxProps = {
  width: '45px',
  height: '45px',
  borderRadius: '50%',
  color: '#444',
  // marginLeft: '0.5rem',
  // marginRight: '0.5rem',
  padding: '0.1rem',
  fontSize: '0.9rem',
  border: '2px solid rgba(150, 150, 150, 1)',
  '&:disabled': {
    color: 'rgba(200, 200, 200, 0.5)',
    border: '2px solid rgba(200, 200, 200, 0.5)',
  },
  marginTop: '-85px',
};

export const keyboardArrowSignSx: SxProps = {
  width: '45px',
  height: '45px',
};

export const circularProgressSx: SxProps = {
  color: '#E0E0E0',
  marginTop: '15px',
};

export const myBooksBtnSx: SxProps = {
  width: '170px',
  height: '40px',
  flexShrink: 0,
  borderRadius: '10px',
  background: 'linear-gradient(180deg, #EC147A 0%, #EC147A 100%)',
  color: '#FFF',
  textTransform: 'none',
  fontSize: '14px',
  fontWeight: 600,
  marginRight: '20px',
  transition: 'background 0.3s ease-in-out',
  '&:hover': {
    background: 'linear-gradient(180deg, rgb(215 14 109) 0%, rgb(215 14 109) 100%)',
  },
};

export const dashboardGreetingsSx: SxProps = {
  color: '#ffffff',
  fontSize: '28px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  marginTop: '50px',
};

export const dashboardDateSx: SxProps = {
  color: '#ffffff',
  fontSize: '14px',
  fontStyle: 'italic',
  fontFamily: 'Roboto',
  fontWeight: 400,
  lineHeight: 'normal',
  marginTop: '20px',
};

export const dashboardCitySx: SxProps = {
  color: '#ffffff',
  fontSize: '15px',
  fontStyle: 'normal',
  fontFamily: 'Roboto',
  fontWeight: 500,
  lineHeight: 'normal',
  marginTop: '10px',
  marginBottom: '35px',
};

export const booksSectionTitleSx: SxProps = {
  color: '#000',
  fontFamily: 'Roboto',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  mt: '50px',
  ml: '45px',
};

export const readingNowTitleSx: SxProps = {
  color: '#000',
  fontSize: '20px',
  fontWeight: 400,
  marginTop: '30px',
  marginBottom: '25px',
  marginLeft: '5px',
  lineHeight: 'normal',
};

export const mainContentSx: SxProps = {
  maxWidth: '1440px',
};

export const mainCoverOverlaySx: SxProps = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
};

export const loadingSkeletonBoxSx: SxProps = {
  height: '369px',
  paddingTop: '25px',
  paddingBottom: '25px',
};

export const topMostReadBooksPaperSx: SxProps = {
  maxWidth: '1440px',
  width: '93%',
  border: '1px solid #E0E0E0',
  borderRadius: '10px',
  backgroundColor: '#EEE',
  flexGrow: 1,
  padding: '50px 25px 50px 25px',
};

export const topMostReadBooksTitleSx: SxProps = {
  color: '#000',
  textAlign: 'center',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: 'normal',
};

export const topMostReadBooksAuthorsSx: SxProps = {
  color: '#000',
  textAlign: 'center',
  fontSize: '11px',
  fontWeight: 300,
  lineHeight: 'normal',
};

export const scrollBoxSx: SxProps = {
  height: '20px',
  scrollMargin: '20px',
};

export const paginationBoxSx: SxProps = {
  marginTop: '40px',
  display: 'flex',
  justifyContent: 'center',
};

export const dividerBoxSx: SxProps = {
  width: '95%',
  borderBottom: '1px solid #E0E0E0',
};
