import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import React, { SetStateAction } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { api, IAuthor } from '../../../../../services/api';
import {
  AddAuthorsDialogAgreeBtnSx,
  AddAuthorsDialogContentSx,
  AddAuthorsDialogDisagreeBtnSx,
  AddAuthorsDialogListSx,
  AddAuthorsDialogTitleSx,
} from '../../styles';

interface IAddConfirmDialog {
  openClosedState: [boolean, (value: SetStateAction<boolean>) => void];
  selectedAuthorsState: [IAuthor[], (value: SetStateAction<IAuthor[]>) => void];
  toAddAuthorsState: [string[], (value: SetStateAction<string[]>) => void];
}

const AddAuthorsDialog = ({ openClosedState, selectedAuthorsState, toAddAuthorsState }: IAddConfirmDialog) => {
  const [authorsSelected, setAuthorsSelected] = selectedAuthorsState;
  const [authorsToAdd, setAuthorsToAdd] = toAddAuthorsState;
  const [open, setOpen] = openClosedState;
  const queryClient = useQueryClient();

  const postMutation = useMutation((name: string) => {
    return api.author.authorsPost({
      authorsGetRequest: { name },
    });
  });

  const postNewAuthors = async (authorsNames: string[]) => {
    try {
      const res = await Promise.all(
        authorsNames.map((n) => {
          return postMutation.mutateAsync(n);
        }),
      );

      await queryClient.invalidateQueries(['authors']);
      setAuthorsSelected([...authorsSelected, ...res.map((r) => r.data.data)]);
    } catch {
      /* empty */
    }
  };

  const handleClose = () => {
    setAuthorsToAdd([]);
    setOpen(false);
  };

  const handleAgree = async () => {
    await postNewAuthors(authorsToAdd);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title" sx={AddAuthorsDialogTitleSx}>
        Found new authors, do you agree to add them to the database?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {authorsToAdd.length === 1 ? (
            <>
              <Typography component="div" sx={AddAuthorsDialogContentSx}>
                Click agree to add the following author to the database:{' '}
              </Typography>
              <Typography component="ul">
                <Typography component="li" sx={AddAuthorsDialogListSx}>
                  {authorsToAdd[0]}
                </Typography>
              </Typography>
            </>
          ) : (
            <>
              <Typography component="div" sx={AddAuthorsDialogContentSx}>
                Click agree to add the following authors to the database:
              </Typography>
              <Typography component="ul">
                {authorsToAdd.map((a) => (
                  <Typography component="li" key={a} sx={AddAuthorsDialogListSx}>
                    {a}
                  </Typography>
                ))}
              </Typography>
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ mb: 1, mr: 2 }}>
        <Button onClick={handleClose} sx={AddAuthorsDialogDisagreeBtnSx}>
          Disagree
        </Button>
        <Button onClick={handleAgree} autoFocus variant="contained" sx={AddAuthorsDialogAgreeBtnSx}>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddAuthorsDialog;
