/* eslint-disable @typescript-eslint/indent */
import React, { forwardRef, HTMLAttributes } from 'react';

// eslint-disable-next-line react/display-name
const AutocompleteLi = forwardRef<
  HTMLLIElement,
  { option: string; isLastOption?: boolean } & HTMLAttributes<HTMLLIElement>
>(({ option, isLastOption, ...props }, ref) => {
  const liProps = isLastOption ? { ...props, ref: ref as React.RefObject<HTMLLIElement> } : props;
  return <li {...liProps}>{option}</li>;
});

export default AutocompleteLi;
