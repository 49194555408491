import { Box, Tab, Tabs } from '@mui/material';
import React, { FC, ReactNode, SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import CurrentUserContext from '../../contexts/CurrentUserContext';
import OfficeBorrowedBooksTab from './OfficeBorrowedBooksTab';
import OfficeSettingsTab from './OfficeSettingsTab';
import OfficeSuggestedBooksTab from './OfficeSuggestedBooksTab/index';
import ReportsTab from './ReportsTab';
import { officeTextSx, tabsSx, tabTextSx } from './styles';

interface ITabPanelProps {
  children?: ReactNode;
  value: number;
  index: number;
}

const TabsCount = 4;

const TabPanel = (props: ITabPanelProps) => {
  const { children, value, index } = props;
  return <div hidden={value !== index}>{value === index && <Box>{children}</Box>}</div>;
};

const OfficePage: FC = () => {
  const { currentUser } = useContext(CurrentUserContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState<number>(0);

  useEffect(() => {
    const queryValue = Number.parseInt(searchParams.get('tab') ?? '0');
    if (isNaN(queryValue) || queryValue < 0 || queryValue >= TabsCount) setSearchParams({ tab: '0' });
    else setValue(queryValue);
  }, [searchParams]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setSearchParams({ tab: newValue.toString() });
  };

  return (
    <Box>
      <Box sx={officeTextSx}>
        Office {currentUser?.office?.name}, {currentUser?.office?.city}
      </Box>
      <Box>
        <Tabs indicatorColor="secondary" sx={tabsSx} value={value} onChange={handleChange}>
          <Tab label={<Box sx={tabTextSx}>Borrowed books</Box>} />
          <Tab label={<Box sx={tabTextSx}>Office settings</Box>} />
          <Tab label={<Box sx={tabTextSx}>Suggested books</Box>} />
          <Tab label={<Box sx={tabTextSx}>Reports</Box>} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <OfficeBorrowedBooksTab />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <OfficeSettingsTab />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <OfficeSuggestedBooksTab />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <ReportsTab />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default OfficePage;
