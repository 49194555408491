import * as React from 'react';

import { IBook } from '../../services/api';

export enum CardType {
  BORROWED,
  WISHLIST,
  BOOKITEM,
}

export interface BookCardProps {
  book: IBook;
  returnBefore?: Date;
  numberOfPages?: number;
  isPendingReturnConfirmation?: boolean;
  returnBtn?: React.ReactElement;
  handleHeartClick?: () => void;
  cardType: CardType;
  totalKindles?: number;
  availableKindles?: number;
}

export interface BorrowedKindleProps {
  inventoryNum: number;
  returnBefore: Date;
  returnBtn: React.ReactElement;
  isPendingReturnConfirmation?: boolean;
}

export enum DueDateState {
  FAR,
  CLOSE,
  PASSED,
}
