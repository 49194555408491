import { Box, Tab, Tabs } from '@mui/material';
import React, { FC, ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { tabsSx, tabTextSx } from '../Office/styles';
import FeedbackTab from './FeedbackTab';
import OfficesTab from './OfficesTab';
import UsersTab from './UsersTab';

interface ITabPanelProps {
  children?: ReactNode;
  value: number;
  index: number;
}

const TabsCount = 3;

const TabPanel = (props: ITabPanelProps) => {
  const { children, value, index } = props;
  return <div hidden={value !== index}>{value === index && <Box>{children}</Box>}</div>;
};

const AdminPage: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState<number>(0);

  useEffect(() => {
    const queryValue = Number.parseInt(searchParams.get('tab') ?? '0');
    if (isNaN(queryValue) || queryValue < 0 || queryValue >= TabsCount) setSearchParams({ tab: '0' });
    else setValue(queryValue);
  }, [searchParams]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setSearchParams({ tab: newValue.toString() });
  };

  return (
    <Box>
      <Tabs indicatorColor="secondary" sx={tabsSx} value={value} onChange={handleChange}>
        <Tab label={<Box sx={tabTextSx}>Users</Box>} />
        <Tab label={<Box sx={tabTextSx}>Offices</Box>} />
        <Tab label={<Box sx={tabTextSx}>Feedback</Box>} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <UsersTab />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <OfficesTab />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <FeedbackTab />
      </TabPanel>
    </Box>
  );
};

export default AdminPage;
