import { SxProps } from '@mui/material';
import { CSSProperties } from 'react';

import { libraryTheme } from '../../../LibraryTheme';

export const FormPaperSx: SxProps = {
  border: '1px solid #E3E3E3',
  borderRadius: '5px',
  flexGrow: 1,
  padding: '50px 141px 50px 50px',
  backgroundColor: '#fff',
};

export const FormBoxSx: SxProps = {
  '& .MuiTextField-root': { mb: 1, mt: 1, width: '100ch' },
};

export const FullWCss: CSSProperties = {
  width: '100%',
};

export const BookCoverPlaceholderSx: SxProps = {
  height: '411px',
  lineHeight: '411px',
  borderRadius: '3px',
  border: '1px solid #E3E3E3',
  background: 'lightgray',
  textAlign: 'center',
};

export const BookCoverSx: SxProps = {
  height: '411px',
  borderRadius: '3px',
  backgroundColor: 'lightgray',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  border: '1px solid #bebebe',
  filter: 'contrast(110%) brightness(110%)',
};

export const FormBtnSx: SxProps = {
  width: '170px',
  height: '40px',
  borderRadius: '5px',
  color: '#FFF',
  background: 'linear-gradient(180deg, #EC147A 0%, #EC147A 100%)',
  fontSize: '14px',
  fontWeight: 700,
  transition: 'background 0.3s ease-in-out',
  '&:hover': {
    background: 'linear-gradient(180deg, rgb(215 14 109) 0%, rgb(215 14 109) 100%)',
  },
};

export const FormLabelSx: SxProps = {
  color: '#000',
  fontSize: '14px',
  fontWeight: 500,
};

export const SecondFormPaperSx: SxProps = {
  border: '1px solid #E3E3E3',
  borderRadius: '5px',
  backgroundColor: '#fff',
  padding: '35px 35px 60px 35px',
  mt: '50px',
  width: '346px',
};

export const AddFormTextFieldSx: SxProps = {
  height: '40px',
  width: '341px',
  mt: 1,
  mb: 2.5,
};

export const AddFormSubmitBtnSx: SxProps = {
  height: '40px',
  width: '124px',
  backgroundColor: libraryTheme.palette.secondary.main,
  whiteSpace: 'normal',
  color: '#FFF',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
};

export const AddAuthorsDialogTitleSx: SxProps = {
  color: '#000',
  fontSize: '18px',
  fontWeight: 600,
  fontStyle: 'normal',
  lineHeight: 'normal',
  textAlign: 'center',
  mt: 1,
  mb: 1,
};

export const AddAuthorsDialogContentSx: SxProps = {
  color: '#000',
  fontSize: '14px',
  fontWeight: 400,
  fontStyle: 'normal',
  lineHeight: 'normal',
  mb: 2,
};
export const AddAuthorsDialogListSx: SxProps = {
  color: '#000',
  fontSize: '15px',
  fontWeight: 500,
  fontStyle: 'italic',
  lineHeight: 'normal',
};

export const AddAuthorsDialogDisagreeBtnSx: SxProps = {
  border: '1px #464646 solid',
  borderRadius: '5px',
  width: '124px',
  height: '40px',
  color: '#000',
  fontSize: '14px',
  fontWeight: 700,
  fontStyle: 'normal',
  lineHeight: 'normal',
  mr: 1,
};

export const AddAuthorsDialogAgreeBtnSx: SxProps = {
  width: '124px',
  height: '40px',
  color: '#FFF',
  fontSize: '14px',
  fontWeight: 700,
  fontStyle: 'normal',
  lineHeight: 'normal',
  backgroundColor: libraryTheme.palette.secondary.main,
  borderRadius: '5px',
};
