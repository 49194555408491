import { SxProps } from '@mui/material';

export const PopupTitleSx: SxProps = {
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  textAlign: 'center',
  mt: 1,
};

export const PopupContentSx: SxProps = {
  color: '#000',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  mt: 1,
  mb: 1,
};
