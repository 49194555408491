import moment from 'moment';
import * as Yup from 'yup';

const FILE_SIZE = 19922944;
const SUPPORTED_FORMATS = [
  'application/pdf',
  'application/epub+zip',
  'application/x-mobipocket-ebook',
  'application/fb2+xml',
];

export const categorySchema = Yup.object({
  description: Yup.string()
    .matches(
      /^[a-zA-Z\s.'-]+$/,
      'Category should contains letters, spaces and next special characters like' + " - . and '",
    )
    .max(50, 'Category description should contain maximum 50 characters')
    .required('Description is required'),
});

export const bookSchema = Yup.object({
  isbn: Yup.string()
    .matches(
      /^\d{10}$|^\d{9}X$|\d{13}$/,
      'ISBN should contain 10 characters (first nine should be digits and' +
        " the last one can be digit or 'X') or 13 digits",
    )
    .max(13, 'ISBN should not contain more than 13 digits')
    .required('Enter ISBN10 or ISBN13'),
  isbn_2: Yup.string()
    .when('isbn', {
      is: (value: string | undefined) => {
        if (value) return value.length === 13;
        return false;
      },
      then: Yup.string().matches(
        /^\d{9}X$|^\d{10}$/,
        'ISBN should contain 10 characters (first nine should be digits and' + " the last one can be digit or 'X')",
      ),
    })
    .when('isbn', {
      is: (value: string | undefined) => {
        if (value) return value.length === 10;
        return false;
      },
      then: Yup.string().matches(/^\d{13}$/, 'ISBN13 should contain 13 digits'),
    }),
  title: Yup.string().max(200, 'Title should contain maximum 200 characters').required('Title is required'),
  author_ids: Yup.array().min(1, 'Please add at least one author'),
  lang: Yup.string().max(2, 'Language should contain 2 characters').required('Please select book language'),
  category_id: Yup.number().required('Please select a category for the book'),
  tag_ids: Yup.array().min(1, 'Please add at least one tag').max(5, 'Only 5 tags can be added'),
  cover: Yup.string().max(2048, 'Author name should contain maximum 50 characters').required('Cover is required'),
  published_at: Yup.number()
    .required('Published year is required')
    .typeError('Published year must be a 4-digit number')
    .min(1970, 'Published year starts from 1970')
    .max(moment().year(), 'Published year cannot be in the future'),
  number_of_pages: Yup.number()
    .nullable()
    .integer('Number of pages must be an integer')
    .typeError('Number of pages must be an integer'),
  description: Yup.string()
    .max(4000, 'Description should contain maximum 4000 characters')
    .required('Description is required'),
  type: Yup.array().required('Type is required'),
  inventory_num: Yup.string().when('type', {
    is: (value: string[]) => value?.includes('paper'),
    then: Yup.string().required('Inventory number is required'),
    otherwise: Yup.string().notRequired(),
  }),
  file_url: Yup.mixed().when('type', {
    is: (value: string[]) => value?.includes('e-book'),
    then: Yup.mixed()
      .required('File is required')
      .test('fileSize', 'File size is too large', function (value) {
        if (value) {
          return value.size <= FILE_SIZE;
        }
        return false;
      })
      .test('fileType', 'Unsupported file format. Supported formats are pdf,epub,mobi,fb2', function (value) {
        if (value) {
          return SUPPORTED_FORMATS.includes(value.type);
        }
        return false;
      }),
    otherwise: Yup.mixed().notRequired(),
  }),
});

export const tagSchema = Yup.object({
  description: Yup.string()
    .max(50, 'Tag description should contain than 50 characters')
    .required('Description is required'),
});
