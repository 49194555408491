import { ModeEdit } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import FeedbackContext from '../../../contexts/FeedbackContext';
import { libraryTheme } from '../../../LibraryTheme';
import { api, ISuggestedBook } from '../../../services/api';
import { handleErrorMsg, selectFromQuery } from '../../../utils';
import { DeleteButtonSx, EditDialogContentSx, EditDialogTextFieldSx, EditDialogTitleSx } from '../../Admin/styles';
import { openEditIconSx } from '../../Book/Edit/styles';
import { dataGridBoxSx, multiLineSx, statusTextSx, TypeChipSx } from '../styles';

const STATUSES = ['suggested', 'bought'];

const OfficeSuggestedBooksTab: FC = () => {
  const { setFeedback } = useContext(FeedbackContext);
  const queryClient = useQueryClient();
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [editSuggestedBook, setEditSuggestedBook] = useState<ISuggestedBook | undefined>(undefined);
  const [deleteBook, setDeleteBook] = useState<ISuggestedBook | undefined>(undefined);

  const { data: suggestedBooksData, isLoading: isSuggestedBooksLoading } = useQuery({
    queryFn: () =>
      api.suggestedBooks.suggestedBooksGet({
        params: {
          sort: 'desc',
        },
      }),
    queryKey: ['suggestedBooks'],
    select: useCallback(selectFromQuery, []),
  });

  const editStatusMutation = useMutation(
    (variables: { suggestedBook: ISuggestedBook }) =>
      api.suggestedBooks.suggestedBooksIdPatch({
        id: variables.suggestedBook.id,
        suggestedBooksGetRequest: {
          status: variables.suggestedBook.status,
        },
      }),
    {
      onSuccess: () => {
        const inv = queryClient.invalidateQueries(['suggestedBooks']);
        setEditOpen(false);
        return inv;
      },
      onError: (e) => handleErrorMsg(e, setFeedback),
    },
  );

  const deleteSuggestedBookMutation = useMutation(
    (suggestedBook: ISuggestedBook) =>
      api.suggestedBooks.suggestedBooksIdDelete({
        id: suggestedBook.id,
      }),
    {
      onSuccess: () => {
        setFeedback({
          status: 'success',
          message: 'Selected suggested book was deleted successfully',
        });
        setDeleteOpen(false);
        return queryClient.invalidateQueries(['suggestedBooks']);
      },
      onError: (e) => handleErrorMsg(e, setFeedback),
    },
  );

  const suggestedBooksInfo = useMemo(() => {
    if (!suggestedBooksData) return [];
    return suggestedBooksData.map((sb) => {
      return {
        id: sb.id,
        title: sb.title,
        author: sb.author,
        published_at: sb.published_at,
        type: sb.type,
        office: sb.user?.office.city,
        user: sb.user?.name,
        status: sb.status,
      };
    });
  }, [suggestedBooksData]);

  const handleOpenEdit = (e: any) => {
    setEditSuggestedBook(e);
    setEditOpen(true);
  };

  const handleOpenDelete = (e: any) => {
    setDeleteOpen(true);
    setDeleteBook(e);
  };

  const handleCloseEdit = () => setEditOpen(false);

  const handleCloseDelete = () => setDeleteOpen(false);

  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: 'Title',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      minWidth: 200,
      flex: 1,
      renderCell: (row) => {
        return <Box sx={{ ...multiLineSx, fontWeight: 400 }}>{row.value}</Box>;
      },
    },
    {
      field: 'author',
      headerName: 'Author',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      width: 170,
      renderCell: (row) => {
        return <Box sx={{ ...multiLineSx, fontStyle: 'italic', fontWeight: 300 }}>{row.value}</Box>;
      },
    },
    {
      field: 'published_at',
      headerName: 'Year',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      width: 100,
      renderCell: (params) => {
        const publishedAt = params.value;
        return <Box sx={{ fontStyle: 'italic', fontWeight: 300 }}>{publishedAt ? publishedAt : '-'}</Box>;
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      width: 100,
      renderCell: (row) => {
        return row.value ? <Chip sx={TypeChipSx} label={row.value} size="small" color="warning" key={row.id} /> : '-';
      },
    },
    {
      field: 'office',
      headerName: 'Office',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      width: 120,
      renderCell: (row) => {
        return <Box sx={{ fontWeight: 300 }}>{row.value}</Box>;
      },
    },
    {
      field: 'user',
      headerName: 'User',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      width: 150,
      renderCell: (row) => {
        return <Box sx={{ fontWeight: 300 }}>{row.value}</Box>;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      width: 120,
      renderCell: (row) => {
        return (
          <Box
            sx={{
              ...statusTextSx,
              color: row.value === 'suggested' ? libraryTheme.palette.warning.main : libraryTheme.palette.success.main,
            }}
            key={row.id}
          >
            {row.value}
          </Box>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Edit',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 100,
      renderCell: (row) => (
        <Tooltip title="Edit">
          <IconButton sx={openEditIconSx} onClick={() => handleOpenEdit(row.row)} aria-label="update" color="primary">
            <ModeEdit sx={openEditIconSx} />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: 'delete',
      headerName: 'Delete',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      width: 100,
      renderCell: (row) => {
        return (
          <Tooltip title="Delete">
            <IconButton
              sx={openEditIconSx}
              aria-label="delete"
              color="primary"
              onClick={() => handleOpenDelete(row.row)}
            >
              <DeleteIcon sx={openEditIconSx} />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <Box>
      {isSuggestedBooksLoading ? (
        <>
          {' '}
          <Skeleton variant="rounded" width="100%" height={70} animation="wave" sx={{ mt: 1 }} />
          {Array.from({ length: 4 }, (_, index) => (
            <Skeleton key={index} variant="rounded" width="100%" height={80} animation="wave" sx={{ mt: 0.5 }} />
          ))}
        </>
      ) : (
        <>
          <Box sx={dataGridBoxSx}>
            <DataGrid
              disableVirtualization
              headerHeight={70}
              rowHeight={70}
              rows={suggestedBooksInfo}
              columns={columns}
              loading={isSuggestedBooksLoading}
              pageSize={10}
              rowsPerPageOptions={[10]}
              disableColumnSelector
              autoHeight
            />
          </Box>
          {/* Edit Dialog */}
          <Dialog open={editOpen} onClose={handleCloseEdit}>
            <DialogTitle sx={EditDialogTitleSx}>Edit status for book</DialogTitle>
            <DialogContent>
              <Typography sx={EditDialogContentSx}>
                Edit status for book &quot;{editSuggestedBook?.title}&quot;
              </Typography>
              <FormControl fullWidth>
                <Autocomplete
                  id="statuses"
                  disableClearable
                  options={STATUSES}
                  value={editSuggestedBook?.status}
                  isOptionEqualToValue={(option, value) => option === value}
                  getOptionLabel={(option) => option}
                  onChange={(event, value) => {
                    setEditSuggestedBook((prev) => {
                      return { ...prev!, status: value };
                    });
                  }}
                  renderInput={(params) => <TextField {...params} variant="outlined" sx={EditDialogTextFieldSx} />}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <LoadingButton
                loading={editStatusMutation.isLoading}
                onClick={() =>
                  editStatusMutation.mutate({
                    suggestedBook: editSuggestedBook!,
                  })
                }
                sx={{ ...DeleteButtonSx, mb: 1, mr: 2 }}
              >
                Submit
              </LoadingButton>
            </DialogActions>
          </Dialog>
          {/* Delete Dialog */}
          <Dialog open={deleteOpen} onClose={handleCloseDelete}>
            <DialogTitle sx={EditDialogTitleSx}>Delete suggested book</DialogTitle>
            <DialogContent>
              <Typography sx={EditDialogContentSx}>
                Delete suggested book: <b>&quot;{deleteBook?.title}&quot; </b>
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => deleteSuggestedBookMutation.mutate(deleteBook!)}
                sx={{ ...DeleteButtonSx, mb: 1, mr: 2 }}
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
};

export default OfficeSuggestedBooksTab;
