import { ListItem, ListItemText, Switch } from '@mui/material';
import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import feedbackContext from '../../../contexts/FeedbackContext';
import { api, ISubscription } from '../../../services/api';

interface IDefaultSub {
  name: string;
  description: string;
  placeholder: string;
}

interface ISubsListItemProps {
  sub: ISubscription;
}

const defaultSubs: IDefaultSub[] = [
  {
    description: 'You will be notified via email when new books will be added in your office',
    name: 'new_book',
    placeholder: 'New books',
  },
  {
    description: 'You will be notified via email when new kindle devices will be added in your office',
    name: 'new_kindle',
    placeholder: 'New kindles',
  },
];

const SubsListItem = ({ sub }: ISubsListItemProps) => {
  const [defaultSub, setDefaultSub] = useState<IDefaultSub | null>();
  const { setFeedback } = useContext(feedbackContext);

  const updateMutation = useMutation(
    (status: boolean) => {
      return api.subscription.subscriptionsIdPatch({
        id: sub.id,
        subscriptionsIdPatchRequest: { status },
      });
    },
    {
      onSuccess: (_, variables) => {
        setFeedback({
          status: 'success',
          message: `You successfully  ${variables ? 'added' : 'removed'} the email subscription for ${
            defaultSub?.placeholder
          }`,
        });
      },
    },
  );

  useEffect(() => {
    setDefaultSub(defaultSubs.find((el) => el.name === sub.notification_name) ?? null);
  }, [sub]);

  const handleToggleSub = (e: SyntheticEvent, status: boolean) => updateMutation.mutate(status);

  return (
    <ListItem
      secondaryAction={
        <Switch defaultChecked={sub.status} disabled={updateMutation.isLoading} onChange={handleToggleSub} />
      }
    >
      {defaultSub ? (
        <ListItemText primary={defaultSub.placeholder} secondary={defaultSub.description} />
      ) : (
        <ListItemText primary={sub.notification_name} />
      )}
    </ListItem>
  );
};

export default SubsListItem;
