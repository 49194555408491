import { SxProps } from '@mui/material';

import { libraryTheme } from '../../LibraryTheme';

export const FormBoxSx: SxProps = {
  display: 'flex',
  alignItems: 'center',
  mb: '45px',
};

export const FormTextFieldSx: SxProps = {
  flex: 1,
  height: '40px',
  marginRight: '15px',
};

export const AddNewBtnSx: SxProps = {
  height: '40px',
  width: '170px',
  color: '#FFF',
  background: 'linear-gradient(180deg, #EC147A 0%, #EC147A 100%)',
  whiteSpace: 'normal',
  transition: 'background 0.3s ease-in-out',
  '&:hover': {
    background: 'linear-gradient(180deg, rgb(215 14 109) 0%, rgb(215 14 109) 100%)',
  },
};

export const AddNewDisabledBtnSx: SxProps = {
  height: '40px',
  width: '170px',
  color: '#FFF',
  background: 'lightgrey',
  whiteSpace: 'normal',
};

export const EditDialogTitleSx: SxProps = {
  color: '#000',
  fontSize: '18px',
  fontWeight: 600,
};

export const EditTextFieldSx: SxProps = {
  width: '341px',
};

export const EditSubmitBtnSx: SxProps = {
  width: '124px',
  height: '40px',
  color: '#FFF',
  fontSize: '14px',
  fontWeight: 700,
  mt: 1,
  borderRadius: '5px',
  background: 'linear-gradient(180deg, #EC147A 0%, #EC147A 100%)',
  transition: 'background 0.3s ease-in-out',
  '&:hover': {
    background: 'linear-gradient(180deg, rgb(215 14 109) 0%, rgb(215 14 109) 100%)',
  },
};

export const EditSubmitDisabledBtnSx: SxProps = {
  width: '124px',
  height: '40px',
  color: '#FFF',
  fontSize: '14px',
  fontWeight: 700,
  mt: 1,
  borderRadius: '5px',
  background: 'lightgrey',
};
