import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { AxiosResponse } from 'axios';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { FC, SyntheticEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import SuggestABookIcon from '../../../assets/images/SuggestABook.png';
import feedbackContext from '../../../contexts/FeedbackContext';
import { api } from '../../../services/api';
import { DropdownsGet200Response } from '../../../services/api/openapi';
import { handleErrorMsg } from '../../../utils';
import AutocompleteLi from '../../Book/Create/components/AutocompleteLi/index';
import { FullWCss } from '../../Book/Create/styles';
import {
  cancelBtnSx,
  submitBtnSx,
  suggestABookBtnSx,
  suggestABookCardMediaSx,
  suggestABookDialogTitleSx,
} from './styles';

interface IFormValues {
  title?: string;
  author?: string;
  published_at?: number;
  type?: string;
}

const initialValues: IFormValues = {
  title: '',
  author: '',
  published_at: undefined,
  type: undefined,
};

const schema = Yup.object({
  title: Yup.string().max(200, 'Title should be less or equal to 200 chars').required('Title is required'),
  author: Yup.string()
    .matches(/^[a-zA-Z\s.'-]+$/, 'Author name should only contain letters, spaces, dashes, dots and apostrophes')
    .max(50, 'Author name should be less than 50 chars')
    .required('Name is required'),
  published_at: Yup.number()
    .nullable()
    .min(1970, 'Published year starts from 1970')
    .max(moment().year(), 'Published year cannot be in the future')
    .typeError('Published year must be a 4-digit number'),
  type: Yup.string().nullable(),
});

const SuggestBookForm: FC = () => {
  const { setFeedback } = useContext(feedbackContext);
  const [publishedAt, setPublishedAt] = useState<number | null>(null);
  const [bookTypeSelected, setBookTypeSelected] = useState<string>('');
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenCreate = () => {
    setOpen(true);
  };

  const postMutation = useMutation(
    (values: IFormValues) => {
      return api.suggestedBooks.suggestedBooksPost({
        suggestedBooksGetRequest: {
          title: values.title,
          author: values.author,
          published_at: values.published_at,
          type: values.type,
        },
      });
    },
    {
      onSuccess: () => {
        setFeedback({
          status: 'success',
          message: 'The book was successfully added to the waiting list. Thank you! 😊',
        });
        navigate('/wishlist');
      },
      onError: (e) => {
        handleErrorMsg(e, setFeedback);
      },
    },
  );

  const { data: bookTypes } = useQuery({
    queryFn: () => api.dropdown.dropdownsGet({ name: ['book_type'] }),
    queryKey: ['types'],
    select: useCallback((data: AxiosResponse<DropdownsGet200Response, any>) => {
      const dropdowns = data.data.data;

      if (dropdowns.length) return Object.entries(dropdowns[0].values) as [string, string][];

      return [];
    }, []),
  });

  const handleTypeChange = (e: SyntheticEvent, value: [string, string]) => {
    setBookTypeSelected(value[1]);
  };

  const handleSubmitForm = async (values: IFormValues) => {
    if (!values.published_at) {
      values.published_at = undefined;
    }
    if (!values.type) {
      values.type = undefined;
    }
    await postMutation.mutateAsync(values);
  };

  const handlePublishedDateChange = (value: moment.Moment | null) => {
    if (value) {
      setPublishedAt(value.year());
    } else setPublishedAt(null);
  };

  const { resetForm, errors, handleBlur, handleChange, handleSubmit, isValid, setFieldValue, values, touched } =
    useFormik({
      initialValues: initialValues,
      onSubmit: handleSubmitForm,
      validationSchema: schema,
    });

  const handleCancel = () => {
    resetForm(); // Reset form values and clear error messages
    setPublishedAt(null);
    setBookTypeSelected('');
    setOpen(false); // Close the dialog
  };

  useEffect(() => {
    setFieldValue('published_at', publishedAt ?? null);
  }, [publishedAt]);

  useEffect(() => {
    setFieldValue('type', bookTypeSelected ?? null);
  }, [bookTypeSelected]);

  return (
    <React.Fragment>
      <Button onClick={handleOpenCreate} sx={suggestABookBtnSx}>
        Suggest a book
      </Button>
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle sx={suggestABookDialogTitleSx}>
          Suggest a book
          <CardMedia component="img" image={SuggestABookIcon} alt="suggest-a-book" sx={suggestABookCardMediaSx} />
        </DialogTitle>
        <Box
          autoComplete="off"
          component="form"
          onSubmit={handleSubmit}
          sx={{ '& .MuiTextField-root': { mb: 1, mt: 1, width: '100%' } }}
        >
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  id="title"
                  name="title"
                  label="Enter the title of the book"
                  value={values.title}
                  onChange={handleChange}
                  error={touched.title && Boolean(errors.title)}
                  helperText={touched.title && errors.title}
                  onBlur={handleBlur}
                  spellCheck
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="author"
                  name="author"
                  label="Type the name of the author"
                  value={values.author}
                  onChange={handleChange}
                  error={touched.author && Boolean(errors.author)}
                  helperText={touched.author && errors.author}
                  onBlur={handleBlur}
                  spellCheck
                />
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterMoment} style={FullWCss}>
                  <DatePicker
                    label="Select the published year"
                    maxDate={moment()}
                    minDate={moment('1970-01-01')}
                    onChange={handlePublishedDateChange}
                    openTo="year"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="published_at"
                        style={FullWCss}
                        error={!!(touched.published_at && errors.published_at)}
                        helperText={touched.published_at && errors.published_at}
                        onBlur={handleBlur}
                      />
                    )}
                    value={moment(publishedAt, 'YYYY')}
                    views={['year']}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  disableClearable
                  getOptionLabel={(option) => option[0]}
                  id="type"
                  isOptionEqualToValue={(option, value) => option[0] === value[0]}
                  options={bookTypes ?? []}
                  onChange={handleTypeChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select a type"
                      style={FullWCss}
                      error={touched.type && Boolean(errors.type)}
                      helperText={touched.type && errors.type}
                      onBlur={handleBlur}
                    />
                  )}
                  renderOption={(props, option) => <AutocompleteLi key={option[1]} option={option[0]} {...props} />}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ mb: '20px' }}>
              <Grid item>
                <Button sx={cancelBtnSx} onClick={handleCancel}>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <LoadingButton disabled={!isValid} loading={postMutation.isLoading} type="submit" sx={submitBtnSx}>
                  Submit
                </LoadingButton>
              </Grid>
            </Grid>
          </DialogActions>
        </Box>
      </Dialog>
    </React.Fragment>
  );
};

export default SuggestBookForm;
