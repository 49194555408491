import { SxProps } from '@mui/material';
import { CSSProperties } from 'react';

import { libraryTheme } from '../../LibraryTheme';
export const Inputs: CSSProperties = {
  margin: '6px 2px',
};

export const Actions: CSSProperties = {
  margin: '2px 3px',
};

export const ButtonsBoxSx: SxProps = {
  padding: '5px 0',
};

export const GridBoxSx: SxProps = {
  minHeight: 600,
  maxWidth: 1000,
};
export const FeedbackGridBoxSx: SxProps = {
  minHeight: 600,
  maxWidth: 1150,
};

export const LibrarianGridBox: SxProps = {
  minHeight: 200,
  maxWidth: '100%',
};

export const CancelButtonSx: SxProps = {
  border: '1px #464646 solid',
  borderRadius: '5px',
  width: '124px',
  height: '40px',
  color: '#000',
  fontSize: '14px',
  fontWeight: 700,
  fontStyle: 'normal',
  lineHeight: 'normal',
};

export const ActionIconSx: SxProps = {
  width: '30px',
  height: '30px',
  color: '#000',
};

export const DeleteButtonSx: SxProps = {
  width: '124px',
  height: '40px',
  color: '#FFF',
  fontSize: '14px',
  fontWeight: 700,
  background: 'linear-gradient(180deg, #EC147A 0%, #EC147A 100%)',
  borderRadius: '5px',
  transition: 'background 0.3s ease-in-out',
  '&:hover': {
    background: 'linear-gradient(180deg, rgb(215 14 109) 0%, rgb(215 14 109) 100%)',
  },
};

export const DeleteDialogTitleSx: SxProps = {
  color: '#000',
  fontSize: '16px',
  fontWeight: 600,
  fontStyle: 'normal',
  lineHeight: 'normal',
  mt: 1,
};

export const EditDialogTitleSx: SxProps = {
  color: '#000',
  fontSize: '18px',
  fontWeight: 600,
  fontStyle: 'normal',
  lineHeight: 'normal',
  textAlign: 'center',
  mb: 1,
  mt: 1,
};

export const EditDialogContentSx: SxProps = {
  color: '#000',
  fontSize: '14px',
  fontWeight: 400,
  fontStyle: 'normal',
  lineHeight: 'normal',
  mb: 1,
};

export const EditDialogTextFieldSx: SxProps = {
  width: '341px',
  height: '40px',
  borderRadius: '5px',
  background: '#FFF',
  mb: 1,
};

export const SelectedFeedbackTitleSx: SxProps = {
  textAlign: 'center',
  color: '#000',
  fontSize: '18px',
  fontWeight: 600,
  textTransform: 'capitalize',
};

export const SelectedFeedbackContentSx: SxProps = {
  textAlign: 'center',
  color: '#000',
  fontSize: '24px',
  fontWeight: 600,
};

export const SelectedFeedbackCloseBtnSx: SxProps = {
  width: '124px',
  height: '40px',
  borderRadius: '5px',
  background: 'linear-gradient(180deg, #EC147A 0%, #EC147A 100%)',
  color: '#FFF',
  fontSize: '14px',
  fontWeight: 700,
  mb: 1,
  mr: 1,
  transition: 'background 0.3s ease-in-out',
  '&:hover': {
    background: 'linear-gradient(180deg, rgb(215 14 109) 0%, rgb(215 14 109) 100%)',
  },
};
