import { SxProps } from '@mui/material';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const SearchBarContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 22em;
  height: 3em;
  background-color: #fff;
  border-radius: 4px;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.14);
  //box-shadow: 0px 2px 12px 3px rgba(0, 0, 0, 0.14);
`;

export const SearchInputContainer = styled.div`
  width: 100%;
  min-height: 3.2em;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0px 15px;
`;

export const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  font-size: 16px;
  color: #12112e;
  font-weight: 500;
  border-radius: 6px;
  background-color: transparent;
  &:focus {
    outline: none;
    &::placeholder {
      opacity: 0;
    }
  }
  &::placeholder {
    color: #bebebe;
    transition: all 250ms ease-in-out;
  }
`;

export const SearchIcon = styled.span`
  color: #bebebe;
  font-size: 23px;
  margin-right: 8px;
  margin-top: 6px;
  vertical-align: middle;
`;

export const CloseIcon = styled(motion.span)`
  color: #bebebe;
  font-size: 23px;
  vertical-align: middle;
  margin-top: 8px;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  &:hover {
    color: #dfdfdf;
  }
`;

export const LineSeparator = styled.span`
  display: flex;
  min-width: 100%;
  min-height: 2px;
  background-color: #d8d8d878;
`;

export const SearchContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1em;
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: #888888 #f5f5f5;
  scroll-margin-right: 10px;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background: #b0b0b0;
    border-radius: 4px;
    transition: background 0.3s ease;

    &:hover {
      background: #6bbbab;
    }
  }
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WarningMessage = styled.span`
  color: #a1a1a1;
  font-size: 14px;
  display: flex;
  align-self: center;
  justify-self: center;
`;

export const containerVariants = {
  expanded: {
    height: '20em',
    top: '136px',
  },
  collapsed: {
    height: '3em',
    top: '0px',
  },
};

export const containerTransition = {
  type: 'spring',
  damping: 22,
  stiffness: 150,
};

export const searchIconBoxSx: SxProps = {
  verticalAlign: 'center',
  marginTop: '6px',
  display: { xs: 'none', sm: 'block' },
};
