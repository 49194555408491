import { SxProps } from '@mui/material';

import { libraryTheme } from '../../../LibraryTheme';

export const openDeleteIconSx: SxProps = {
  width: '30px',
  height: '30px',
};

export const dialogTitleSx: SxProps = {
  fontSize: '18px',
  fontWeight: 600,
};

export const textSx: SxProps = {
  fontSize: '16px',
  fontWeight: 400,
  marginBottom: '15px',
};

export const editionsBoxSx: SxProps = {
  width: '550px',
  border: '1px solid #e3e3e3',
  borderRadius: '5px',
  padding: '25px',
};

export const cancelButtonSx: SxProps = {
  color: '#000',
  borderColor: libraryTheme.palette.neutral.main,
  border: 1,
  height: '40px',
  width: '120px',
  fontWeight: 700,
  mr: '5px',
};

export const deleteButtonSx: SxProps = {
  height: '40px',
  width: '120px',
  fontWeight: 700,
  color: '#FFF',
  background: 'linear-gradient(180deg, #EC147A 0%, #EC147A 100%)',
  transition: 'background 0.3s ease-in-out',
  '&:hover': {
    background: 'linear-gradient(180deg, rgb(215 14 109) 0%, rgb(215 14 109) 100%)',
  },
  mr: 2,
};

export const deleteButtonDisabledSx: SxProps = {
  height: '40px',
  width: '120px',
  fontWeight: 700,
  color: '#FFF',
  background: 'lightgrey',
  mr: 2,
};

export const iconSx: SxProps = {
  color: libraryTheme.palette.neutral.main,
  position: 'relative',
  top: '3px',
  width: '24px',
  height: '24px',
};

export const xImageSx: SxProps = {
  color: '#F00',
  position: 'relative',
  left: '-15px',
  top: '10px',
};
