import InfoIcon from '@mui/icons-material/Info';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  Box,
  ButtonBase,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Stack,
  TextField,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { AxiosError, AxiosResponse } from 'axios';
import { AxiosRequestConfig } from 'axios';
import { useFormik } from 'formik';
import moment from 'moment/moment';
import React, { ChangeEvent, SyntheticEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { MoonLoader } from 'react-spinners';

import { LoadingWrapper } from '../../../components/MainLayout/SearchBar/styles';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import feedbackContext from '../../../contexts/FeedbackContext';
import { api, IAuthor, IBook, IBookCategory, IBookEdition } from '../../../services/api';
import {
  BookApiBooksPostRequest,
  BookEditionApiBookEditionsPostRequest,
  BookNamesGet200Response,
  BookNamesGet200ResponseDataInner,
  BooksGet201Response,
  BooksGetRequestTypeEnum,
  DropdownsGet200Response,
} from '../../../services/api/openapi';
import { handleErrorMsg, selectFromQuery } from '../../../utils';
import AddAuthorsDialog from './components/AddAuthorDialog';
import AddCategoryDialog from './components/AddCategoryDialog';
import AddTagDialog from './components/AddTagDialog';
import AddTypeSwitch from './components/AddTypeSwitch';
import AuthorQuickAddForm from './components/AuthorQuickAddForm';
import AutocompleteLi from './components/AutocompleteLi';
import {
  BookCoverPlaceholderSx,
  FormBoxSx,
  FormBtnSx,
  FormLabelSx,
  FormPaperSx,
  FullWCss,
  SecondFormPaperSx,
} from './styles';
import { IFormValues, ITagDropdown, ITypesDropdown } from './types';
import { bookEditionPostFactory, bookPostFactory } from './utils';
import { bookSchema } from './validations';

const initialValues: IFormValues = {
  author_ids: [],
  category_id: '',
  cover: '',
  description: '',
  file_url: undefined,
  inventory_num: undefined,
  isbn: '',
  isbn_2: '',
  lang: 'en',
  published_at: 1,
  tag_ids: [],
  title: '',
  type: '',
  number_of_pages: 0,
};

const initialTypes: ITypesDropdown = {
  'e-book': false,
  kindle: false,
  paper: false,
};

interface BookCoverComponentProps {
  coverUrl: string;
}

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  width: '276px',
  height: '411px',
  borderRadius: '3px',
  filter: 'contrast(110%) brightness(110%)',
  border: '2px #d4d4d4 solid',
});

const BookCoverComponent = ({ coverUrl }: BookCoverComponentProps) => {
  if (!coverUrl) {
    // If coverUrl is not provided, render a gray box
    return <Box sx={BookCoverPlaceholderSx}>Cover will appear here</Box>;
  }

  // If coverUrl is provided, render the actual cover image
  return (
    <ButtonBase sx={{ width: 276, height: 411 }}>
      <Img alt="Book Cover" src={coverUrl} />
    </ButtonBase>
  );
};

const CreateBook = () => {
  const navigate = useNavigate();

  const { setFeedback } = useContext(feedbackContext);

  const [authorsAddDialog, setAuthorsAddDialog] = React.useState(false);
  const [authorsToAdd, setAuthorsToAdd] = useState<string[]>([]);
  const [authorsSelected, setAuthorsSelected] = useState<IAuthor[]>([]);
  const [bookLangSelected, setBookLangSelected] = useState<[string, string]>(['English', 'en']);
  const [bookTypeSelected, setBookTypeSelected] = useState<ITypesDropdown>(initialTypes);
  const [categorySelected, setCategorySelected] = useState<IBookCategory | null>(null);
  const [disabledRefBook, setDisabledRefBook] = useState<boolean>(true);
  const [publishedAt, setPublishedAt] = useState<number | null>(null);
  const [refBook, setRefBook] = useState<IBook | null>(null);
  const [tagsSelected, setTagsSelected] = useState<ITagDropdown[]>([]);
  const { currentUser } = useContext(CurrentUserContext);
  const queryClient = useQueryClient();

  const { data: authors } = useQuery({
    queryFn: () => api.author.authorsGet(),
    queryKey: ['authors'],
    select: useCallback(selectFromQuery, []),
  });

  let timer: NodeJS.Timeout;

  const observer = useRef<IntersectionObserver>();
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [books, setBooks] = useState<BookNamesGet200Response>({ data: [] });
  const [selectedBook, setSelectedBook] = useState<BookNamesGet200ResponseDataInner | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const { data: bookData, isLoading: isLoadingBook } = useQuery({
    queryFn: () => {
      return api.book.booksIdGet({ id: Number(selectedBook?.id) });
    },
    queryKey: ['book', Number(selectedBook?.id)],
    select: useCallback((data: AxiosResponse<BooksGet201Response, any>) => {
      return data.data.data;
    }, []),
    enabled: !!selectedBook,
  });

  const lastElement = (targetNode: HTMLLIElement) => {
    if (isLoading) {
      return;
    }

    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setCurrentPage((prev) => prev + 1);
      }
    });

    if (targetNode) {
      observer.current?.observe(targetNode);
    }
  };

  useEffect(() => {
    setBooks({ data: [] });
  }, [searchQuery]);

  useEffect(() => {
    if (bookData && !isLoadingBook) {
      setRefBook(bookData);
    }
  }, [bookData, isLoadingBook]);

  useEffect(() => {
    let mounted = true;
    setLoading(true);

    const getBooks = async () => {
      const filteredBooks = await api.book.bookNamesGet({
        params: {
          per_page: 10,
          page: currentPage,
          composite: searchQuery,
        },
      });
      if (mounted) {
        const res = filteredBooks.data.data;
        setHasMore(filteredBooks.data.data.length > 0);
        setBooks((prevBooksData) => {
          const mergedData = [
            ...prevBooksData.data, // Spread prevBooksData.data instead of prevBooksData
            ...res.filter((book) => {
              return !prevBooksData.data.some((prevBook) => prevBook.id === book.id);
            }),
          ];
          return { data: mergedData };
        });
      }
      setLoading(false);
    };

    getBooks();
    return () => {
      mounted = false;
    };
  }, [currentPage, searchQuery]);

  const handleChangeSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      setSearchQuery(e.target.value);
      setCurrentPage(1);
    }, 300);
  };

  const { data: bookLanguages } = useQuery({
    queryFn: () => api.dropdown.dropdownsGet({ name: ['book_lang'] }),
    queryKey: ['languages'],
    select: useCallback((data: AxiosResponse<DropdownsGet200Response, any>) => {
      const dropdowns = data.data.data;

      if (dropdowns.length) return Object.entries(dropdowns[0].values) as [string, string][];

      return [];
    }, []),
  });

  const { data: bookCategories } = useQuery({
    queryFn: () => api.category.categoriesGet(),
    queryKey: ['categories'],
    select: useCallback(selectFromQuery, []),
  });

  const { data: bookTags } = useQuery({
    queryFn: () => api.tag.tagsGet(),
    queryKey: ['tags'],
    select: useCallback(selectFromQuery, []),
  });

  const { data: bookTypes, isLoading: bookTypesLoading } = useQuery({
    queryFn: () => api.dropdown.dropdownsGet({ name: ['book_type'] }),
    queryKey: ['types'],
    select: useCallback((data: AxiosResponse<DropdownsGet200Response, any>) => {
      const dropdowns = data.data.data;

      if (dropdowns.length) return Object.entries(dropdowns[0].values) as [string, BooksGetRequestTypeEnum][];

      return [];
    }, []),
  });

  const bookPostMutation = useMutation((data: BookApiBooksPostRequest) => {
    if (!data.booksGetRequest.type.includes('e-book')) {
      data.booksGetRequest.file_url = undefined;
    }

    const options: AxiosRequestConfig = data.booksGetRequest.type.includes('e-book')
      ? { headers: { 'Content-Type': 'multipart/form-data' } }
      : {};

    return api.book.booksPost(data, options);
  });

  const verifyISBNInDB = useMutation((isbn: string) => {
    const config: AxiosRequestConfig = { params: { isbn } };

    if (isbn.length === 13) {
      config.params.isbn = undefined;
      config.params.isbn_13 = isbn;
    }

    return api.bookEdition.bookEditionsGet(config);
  });

  const bookEditionPostMutation = useMutation(
    (data: BookEditionApiBookEditionsPostRequest) => {
      if (!data.bookEditionsGetRequest.type.includes('e-book')) data.bookEditionsGetRequest.file_url = undefined;
      const options: AxiosRequestConfig = data.bookEditionsGetRequest.type.includes('e-book')
        ? { headers: { 'Content-Type': 'multipart/form-data' } }
        : {};

      return api.bookEdition.bookEditionsPost(data, options);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(['paginatedBooks', currentUser?.office.id]);
      },
      onError: (e) => {
        const error = e as AxiosError<{ message: string }>;

        if (error.response)
          setFeedback({
            status: 'error',
            message: error.response.data.message,
          });
      },
    },
  );

  const clearRefBookData = (keepISBN = false) => {
    /* eslint-disable @typescript-eslint/no-use-before-define */
    const isbn = values.isbn;
    resetForm();
    setAuthorsSelected([]);
    setCategorySelected(null);
    setPublishedAt(1);
    setTagsSelected([]);

    if (keepISBN) setFieldValue('isbn', isbn);
    /* eslint-enable @typescript-eslint/no-use-before-define */
  };

  const fetchGoogleBookMutation = useMutation(
    () => {
      // Clear all form data except the isbn
      clearRefBookData(true);

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return api.googleBooks.getGoogleBook(values.isbn);
    },
    {
      onSuccess: (res) => {
        const foundAuthors: IAuthor[] = [];
        const authorsToAddFromGoogle: string[] = [];

        if (res) {
          if (res.totalItems === 0) {
            setFeedback({
              status: 'error',
              message: 'No match found for ISBN provided',
            });
          }
          const googleBook = res.items[0].volumeInfo;

          // Set both ISBNs
          // eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/no-use-before-define
          const isbn_2 = googleBook.industryIdentifiers.find((i) => i.identifier.length !== values.isbn.length);
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          if (isbn_2) setFieldValue('isbn_2', isbn_2.identifier);

          // Find exists authors

          googleBook.authors?.forEach((a) => {
            const found = authors?.find((el) => el.name === a);

            if (found) foundAuthors.push(found);
            else authorsToAddFromGoogle.push(a);
          });

          setAuthorsSelected(foundAuthors);

          // Find exists language
          const foundLang = bookLanguages?.find((el) => el[1] === googleBook.language);
          if (foundLang) setBookLangSelected(foundLang);

          // Find exists category
          googleBook.categories?.forEach((c) => {
            const found = bookCategories?.find((el) => el.description === c.replaceAll('&', 'and'));

            if (found) {
              setCategorySelected(found);
            }
          });

          // Set form data
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const publishedAt = moment(googleBook.publishedDate).year();
          setPublishedAt(publishedAt);

          if (authorsToAddFromGoogle) setAuthorsToAdd(authorsToAddFromGoogle);
          /* eslint-disable @typescript-eslint/no-use-before-define */
          if (googleBook.imageLinks) {
            setFieldValue('cover', googleBook.imageLinks.thumbnail.replace('http://', 'https://'));
          }

          setFieldValue('description', googleBook.description);
          setFieldValue('lang', googleBook.language);
          setFieldValue('title', googleBook.title);
          setFieldValue('number_of_pages', googleBook.pageCount);
          /* eslint-enable @typescript-eslint/no-use-before-define */
          setFeedback({
            status: 'info',
            message: 'The book fetched from Google Books',
          });
        }
      },
    },
  );

  const handleBookSearch = async () => {
    /* eslint-disable @typescript-eslint/no-use-before-define */
    const response = await verifyISBNInDB.mutateAsync(values.isbn);

    if (response.data.data.length) {
      setRefBook(response.data.data[0].book);

      fillRefBookByBookEdition(response.data.data[0]);

      setFeedback({
        status: 'info',
        message: 'The book already exists in the database!',
      });
      return;
    }
    if (values.isbn) {
      fetchGoogleBookMutation.mutate();

      setRefBook(null);
    }
    /* eslint-enable @typescript-eslint/no-use-before-define */
  };

  const handleSubmitForm = async (values: IFormValues) => {
    // Fill data for book and book edition post
    const bookPostData = bookPostFactory(values);
    const bookEditionPostData = bookEditionPostFactory(values);

    try {
      if (!refBook) {
        const resPostedBook = await bookPostMutation.mutateAsync(bookPostData);
        if (resPostedBook.status === 201) {
          const addedBook = resPostedBook.data.data;

          bookEditionPostData.bookEditionsGetRequest.book_id = addedBook.id;
          if (resPostedBook.status === 201) {
            setFeedback({
              status: 'success',
              message: 'The book has been added successfully!\nThe email was sent to all the subscribers!',
            });
            navigate('/books');
          }
        }
      } else {
        // In case the book already exists ignore book post
        bookEditionPostData.bookEditionsGetRequest.book_id = (refBook as IBook).id;
        // eslint-disable-next-line no-useless-catch
        try {
          const resPostedBEdition = await bookEditionPostMutation.mutateAsync(bookEditionPostData);

          if (resPostedBEdition.status === 201) {
            setFeedback({
              status: 'success',
              message: 'The book has been added successfully!\nThe email was sent to all the subscribers!',
            });
            navigate('/books');
          }
        } catch (e) {
          throw e;
        }
      }
    } catch (e) {
      handleErrorMsg(e, setFeedback);
    }
  };

  const fillRefBookByBook = (book: IBook) => {
    if (bookTypes) {
      const updatedTypes: ITypesDropdown = { 'e-book': false, kindle: false, paper: false };

      // for setting selected types based on existing ones
      // bookTypes.forEach((type) => {
      //   return (updatedTypes[type[1]] = book.types.includes(type[1]));
      // });

      updatedTypes.paper = book.types.includes('paper');
      setBookTypeSelected(updatedTypes);
    }

    setAuthorsSelected(book.authors);
    setCategorySelected(book.category);
    setPublishedAt(book.published_at);
    setTagsSelected(book.tags);

    /* eslint-disable @typescript-eslint/no-use-before-define */
    setFieldValue('isbn', book.details[0].isbn_13);
    setFieldValue('isbn_2', book.details[0].isbn);
    setFieldValue('number_of_pages', book.details[0].number_of_pages ?? 0);
    setFieldValue('cover', book.cover);
    setFieldValue('description', book.description);
    setFieldValue('lang', book.lang);
    setFieldValue('published_at', book.published_at);
    setFieldValue('title', book.title);
    /* eslint-enable @typescript-eslint/no-use-before-define */
  };

  const { errors, handleBlur, handleChange, handleSubmit, resetForm, setFieldValue, touched, values } = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: handleSubmitForm,
    validationSchema: bookSchema,
  });

  const fillRefBookByBookEdition = (data: IBookEdition) => {
    const { book } = data;
    fillRefBookByBook(book);

    setFieldValue(
      'isbn_2',
      [data.isbn ?? '', data.isbn_13 ?? ''].find((i) => i.length !== values.isbn.length),
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    data.number_of_pages === null
      ? setFieldValue('number_of_pages', 0)
      : setFieldValue('number_of_pages', data.number_of_pages);
  };

  useEffect(() => {
    if (authorsToAdd.length) {
      setAuthorsAddDialog(true);
    }
  }, [authorsToAdd]);

  useEffect(() => {
    setFieldValue(
      'author_ids',
      authorsSelected.map((el) => el.id),
    );
  }, [authorsSelected]);

  useEffect(() => {
    setFieldValue('lang', bookLangSelected[1]);
  }, [bookLangSelected]);

  useEffect(() => {
    if (bookTypes) {
      const selectedBookTypes = Object.entries(bookTypeSelected)
        .filter(([_, value]) => value === true)
        .map((type) => {
          return type[0];
        });

      setFieldValue('type', selectedBookTypes);
    }
  }, [bookTypeSelected]);

  useEffect(() => {
    if (categorySelected) {
      setFieldValue('category_id', categorySelected.id);
    }
  }, [categorySelected]);

  useEffect(() => {
    if (disabledRefBook) setRefBook(null);
  }, [disabledRefBook]);

  useEffect(() => {
    setFieldValue('published_at', publishedAt ?? 1);
  }, [publishedAt]);

  useEffect(() => {
    if (refBook) {
      fillRefBookByBook(refBook);
    } else clearRefBookData();
  }, [refBook]);

  useEffect(() => {
    setFieldValue(
      'tag_ids',
      tagsSelected.map((el) => el.id),
    );
  }, [tagsSelected]);

  const handleAuthorsChange = (e: SyntheticEvent, value: IAuthor[]) => setAuthorsSelected(value);

  const handleCategoryChange = (e: SyntheticEvent, value: IBookCategory) => setCategorySelected(value);

  const handleFileUrlChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = e.target as HTMLInputElement;

    if (target !== null && target.files !== null) {
      setFieldValue('file_url', target.files[0]);
    }
  };

  const handleLangChange = (e: SyntheticEvent, value: [string, string]) => setBookLangSelected(value);

  const handlePublishedDateChange = (value: moment.Moment | null) => {
    if (value) {
      setPublishedAt(value.year());
    } else setPublishedAt(null);
  };

  const handleRefBookChange = (e: SyntheticEvent, value: BookNamesGet200ResponseDataInner | null) => {
    setSelectedBook(value);
  };

  const handleTagsChange = (e: SyntheticEvent, value: ITagDropdown[]) => setTagsSelected(value);

  const handleTypeChange = (e: SyntheticEvent, typeName: string, value: boolean) => {
    setBookTypeSelected((prev) => ({ ...prev, [typeName]: value }));
  };

  const handleIsbnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    const filteredValue = value.replace(/[^a-zA-Z0-9]/g, '');
    setFieldValue(id, filteredValue);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AddAuthorsDialog
        openClosedState={[authorsAddDialog, setAuthorsAddDialog]}
        selectedAuthorsState={[authorsSelected, setAuthorsSelected]}
        toAddAuthorsState={[authorsToAdd, setAuthorsToAdd]}
      />
      <Paper elevation={0} sx={FormPaperSx}>
        <Box autoComplete="off" component="form" onSubmit={handleSubmit} sx={FormBoxSx}>
          <Grid container columnSpacing={{ xs: 12, sm: 12, md: 18 }} columns={{ xs: 12, sm: 12, md: 12 }}>
            <Grid item md={7.5}>
              <Grid container columns={{ xs: 12, sm: 12, md: 12 }} spacing={1}>
                <Grid item xs={12}>
                  <Stack direction="column" justifyContent="center" spacing={0}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          data-testid="book-ref-checkbox"
                          onChange={(e, checked) => {
                            setDisabledRefBook(!checked);
                          }}
                        />
                      }
                      label={<Box sx={FormLabelSx}>Use reference to an existing book</Box>}
                      labelPlacement="end"
                    />
                    <Autocomplete
                      data-testid="book-ref-autocomplete"
                      loading={isLoading}
                      disabled={disabledRefBook}
                      getOptionLabel={(option: BookNamesGet200ResponseDataInner) => option.name}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={handleRefBookChange}
                      options={books.data ?? []}
                      filterOptions={(options) => options}
                      renderInput={(params) => (
                        <TextField
                          label="Book"
                          style={{ width: '100%' }}
                          onChange={handleChangeSearchQuery}
                          {...params}
                        />
                      )}
                      renderOption={(props, option, item) => {
                        const isLastOption = item.index === books.data.length - 1;
                        const ref = isLastOption ? lastElement : null;

                        return (
                          <AutocompleteLi
                            key={option.id}
                            option={option.name}
                            isLastOption={isLastOption}
                            ref={ref}
                            {...props}
                          />
                        );
                      }}
                      value={selectedBook}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack direction="column" justifyContent="center" spacing={0}>
                    <Box sx={FormLabelSx}>Search for books by ISBN</Box>
                    <TextField
                      error={!!(touched.isbn && errors.isbn)}
                      helperText={touched.isbn && errors.isbn}
                      id="isbn"
                      label="ISBN10"
                      onBlur={handleBlur}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleIsbnChange(e);
                      }}
                      style={FullWCss}
                      disabled={!!refBook}
                      value={values.isbn ?? ''}
                    />
                    <TextField
                      error={!!(touched.isbn_2 && errors.isbn_2)}
                      helperText={touched.isbn_2 && errors.isbn_2}
                      label="ISBN13"
                      id="isbn_2"
                      onBlur={handleBlur}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleIsbnChange(e);
                      }}
                      style={FullWCss}
                      value={values.isbn_2 ?? ''}
                      disabled={!!refBook}
                    />
                  </Stack>
                </Grid>

                {!refBook && (
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end">
                      <LoadingButton
                        data-testid="isbn-search-button"
                        loading={fetchGoogleBookMutation.isLoading || verifyISBNInDB.isLoading}
                        onClick={handleBookSearch}
                        sx={FormBtnSx}
                      >
                        Search
                      </LoadingButton>
                    </Box>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Stack direction="column" justifyContent="center" spacing={0}>
                    <Box sx={FormLabelSx}>Book details</Box>
                    <TextField
                      disabled={!!refBook}
                      error={!!(touched.title && errors.title)}
                      helperText={touched.title && errors.title}
                      id="title"
                      label="Title"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      style={FullWCss}
                      value={values.title}
                      spellCheck
                    />
                    <Stack alignItems="center" direction="row" spacing={1}>
                      <Autocomplete
                        disabled={!!refBook}
                        getOptionLabel={(option) => option.name}
                        id="author_ids"
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        limitTags={3}
                        multiple
                        onChange={handleAuthorsChange}
                        options={authors ?? []}
                        renderInput={(params) => (
                          <TextField
                            error={!!(touched.author_ids && errors.author_ids)}
                            helperText={touched.author_ids && errors.author_ids}
                            label="Authors"
                            onBlur={handleBlur}
                            style={{ width: '100%' }}
                            {...params}
                          />
                        )}
                        renderOption={(props, option) => (
                          <AutocompleteLi key={option.id} option={option.name} {...props} />
                        )}
                        sx={{ flexGrow: 1 }}
                        value={authorsSelected}
                      />
                      {!refBook && <AuthorQuickAddForm />}
                    </Stack>
                    <TextField
                      disabled={!!refBook}
                      error={!!(touched.cover && errors.cover)}
                      helperText={touched.cover && errors.cover}
                      id="cover"
                      label="Cover url"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      style={FullWCss}
                      value={values.cover}
                    />
                    <TextField
                      disabled={!!refBook}
                      error={!!(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      id="description"
                      label="Description"
                      maxRows={5}
                      minRows={5}
                      multiline
                      onBlur={handleBlur}
                      onChange={handleChange}
                      style={FullWCss}
                      value={values.description}
                      spellCheck
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack direction="column" justifyContent="center" spacing={0}>
                    <Box sx={FormLabelSx}>Book classification</Box>
                    <Stack direction="row">
                      <Autocomplete
                        disabled={!!refBook}
                        getOptionLabel={(option) => option.description}
                        id="tag_ids"
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        limitTags={3}
                        multiple
                        onChange={handleTagsChange}
                        options={bookTags ?? []}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Tags"
                            style={FullWCss}
                            error={touched.tag_ids && Boolean(errors.tag_ids)}
                            helperText={touched.tag_ids && errors.tag_ids}
                            onBlur={handleBlur}
                          />
                        )}
                        renderOption={(props, option) => (
                          <AutocompleteLi key={option.id} option={option.description} {...props} />
                        )}
                        sx={{ flexGrow: 1 }}
                        value={tagsSelected}
                      />
                      <Box
                        style={{
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'center',
                          marginLeft: '2px',
                        }}
                      >
                        {!refBook && <AddTagDialog />}
                      </Box>
                    </Stack>
                    <Stack direction="column" justifyContent="center" spacing={0}>
                      <Box sx={FormLabelSx}>Book Type</Box>

                      {!bookTypesLoading && bookTypes ? (
                        bookTypes.map((booktype) => {
                          return (
                            <AddTypeSwitch
                              key={booktype[0]}
                              name={booktype[1]}
                              type={booktype[1]}
                              handleTypeChange={handleTypeChange}
                              exists={refBook?.types?.includes(booktype[1])}
                              disabled={booktype[1] !== 'paper' && refBook?.types?.includes(booktype[1])}
                            />
                          );
                        })
                      ) : (
                        <LoadingWrapper>
                          <MoonLoader loading color="#000" size={20} />
                        </LoadingWrapper>
                      )}

                      {!!errors.type && (
                        <FormHelperText error={!!(touched.type && errors.type)}>
                          {touched.type && errors.type}
                        </FormHelperText>
                      )}

                      {bookTypeSelected.paper && (
                        <Box display="flex" alignItems="center">
                          <TextField
                            error={!!(touched.inventory_num && errors.inventory_num)}
                            helperText={touched.inventory_num && errors.inventory_num}
                            id="inventory_num"
                            label="Paper Book Inventory number"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            style={FullWCss}
                          />
                          <Tooltip
                            title="A Paper Book inventory number is a unique identifier assigned to each book in a library's collection. The inventory number is manually written inside the book. This number allows the Librarian to track, manage, and organize its inventory efficiently."
                            placement="right"
                          >
                            <Box sx={{ ml: 1 }}>
                              <InfoIcon />
                            </Box>
                          </Tooltip>
                        </Box>
                      )}

                      {bookTypeSelected['e-book'] && (
                        <>
                          <Box sx={FormLabelSx}>E-book file</Box>

                          <TextField
                            error={touched.file_url && Boolean(errors.file_url)}
                            helperText={touched.file_url && errors.file_url}
                            id="file_url"
                            onBlur={handleBlur}
                            onChange={handleFileUrlChange}
                            style={FullWCss}
                            type="file"
                          />
                        </>
                      )}
                    </Stack>
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Stack direction="row" spacing={2}>
                      <LoadingButton
                        loading={bookPostMutation.isLoading || bookEditionPostMutation.isLoading}
                        type="submit"
                        sx={FormBtnSx}
                      >
                        Submit
                      </LoadingButton>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4.5}>
              <Paper elevation={0} sx={SecondFormPaperSx}>
                <Grid container columns={{ xs: 12, sm: 12, md: 12 }} spacing={2}>
                  <Grid item xs={12}>
                    <BookCoverComponent coverUrl={values.cover} />
                  </Grid>

                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterMoment} style={FullWCss}>
                      <DatePicker
                        disabled={!!refBook}
                        label="Published year"
                        maxDate={moment()}
                        minDate={moment('1970-01-01')}
                        onChange={handlePublishedDateChange}
                        openTo="year"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="published_at"
                            style={FullWCss}
                            error={!!(touched.published_at && errors.published_at)}
                            helperText={touched.published_at && errors.published_at}
                            onBlur={handleBlur}
                          />
                        )}
                        value={moment(publishedAt, 'YYYY')}
                        views={['year']}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      error={!!(touched.number_of_pages && errors.number_of_pages)}
                      helperText={touched.number_of_pages && errors.number_of_pages}
                      id="number_of_pages"
                      label="Number of pages"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        const newValue = e.target.value === '' ? 0 : parseInt(e.target.value, 10) || 0;
                        handleChange({
                          target: {
                            name: 'number_of_pages',
                            value: newValue,
                          },
                        });
                      }}
                      style={FullWCss}
                      inputProps={{ min: 0, max: 10000 }}
                      value={values.number_of_pages}
                      disabled={!!refBook}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    {refBook && categorySelected ? (
                      <TextField disabled placeholder={categorySelected.description} style={FullWCss} />
                    ) : (
                      <Stack direction="row">
                        <Autocomplete
                          disableClearable
                          disabled={!!refBook}
                          getOptionLabel={(option) => option.description}
                          id="category_id"
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={handleCategoryChange}
                          options={bookCategories ?? []}
                          renderInput={(params) => (
                            <TextField
                              error={!!(touched.category_id && errors.category_id)}
                              helperText={touched.category_id && errors.category_id}
                              label="Category"
                              onBlur={handleBlur}
                              style={FullWCss}
                              {...params}
                            />
                          )}
                          renderOption={(props, option) => (
                            <AutocompleteLi key={option.id} option={option.description} {...props} />
                          )}
                          sx={{ flexGrow: 1 }}
                        />
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            marginLeft: '2px',
                          }}
                        >
                          <AddCategoryDialog />
                        </Box>
                      </Stack>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      disableClearable
                      getOptionLabel={(option) => option[0]}
                      id="lang"
                      isOptionEqualToValue={(option, value) => option[0] === value[0]}
                      onChange={handleLangChange}
                      options={bookLanguages ?? []}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!(touched.lang && errors.lang)}
                          helperText={touched.lang && errors.lang}
                          label="Language"
                          onBlur={handleBlur}
                          style={FullWCss}
                        />
                      )}
                      renderOption={(props, option) => <AutocompleteLi key={option[1]} option={option[0]} {...props} />}
                      value={bookLangSelected}
                      disabled={!!refBook}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default CreateBook;
