import DeleteIcon from '@mui/icons-material/Delete';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import { Box, Chip, IconButton, LinearProgress, Skeleton, Stack, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { AxiosResponse } from 'axios';
import React, { FC, useCallback, useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import CurrentUserContext from '../../contexts/CurrentUserContext';
import FeedbackContext from '../../contexts/FeedbackContext';
import { DeleteItem } from '../../dialogs/confirmationDialog';
import { libraryTheme } from '../../LibraryTheme';
import { api, IBook, IBookEdition, IWish } from '../../services/api';
import { copyObject, handleErrorMsg, selectFromQuery } from '../../utils';
import { actionIconSx } from '../Kindle/Index/styles';
import { multiLineSx, statusTextSx, TypeChipSx } from '../Office/styles';
import { itemSx, noItemsBoxSx, sectionTextSx } from './styles';
import WishlistItem from './WishlistItem';

interface IGroupedWishlist {
  book: IBook;
  bookEditions: IBookEdition[];
  bookEditionType: string;
}

const groupWishlistByBookEType = (wishlist: IWish[]) => {
  const grouped: IGroupedWishlist[] = [];

  wishlist.forEach((el) => {
    const found = grouped.find(
      (g) => g.book.id === el.book_edition.book.id && g.bookEditionType === el.book_edition.type,
    );

    if (found) {
      found.bookEditions.push(copyObject(el.book_edition));
    } else {
      grouped.push({
        book: copyObject(el.book_edition.book),
        bookEditions: [copyObject(el.book_edition)],
        bookEditionType: el.book_edition.type,
      });
    }
  });

  return grouped;
};

const Wishlist: FC = () => {
  const { currentUser } = useContext(CurrentUserContext);
  const queryClient = useQueryClient();
  const { setFeedback } = useContext(FeedbackContext);

  const { data: wishlist, isFetching } = useQuery({
    queryKey: ['wishlist'],
    queryFn: () => api.wishlist.wishlistsGet(),
    select: useCallback((res: AxiosResponse<{ data: IWish[] }>) => {
      return groupWishlistByBookEType(res.data.data);
    }, []),
  });

  const { data: suggestedBooksList, isLoading: isSuggestedBooksLoading } = useQuery({
    queryFn: () =>
      api.suggestedBooks.suggestedBooksGet({
        params: {
          user_id: currentUser?.id,
          sort: 'desc',
        },
      }),
    queryKey: ['suggestedBooksByUser'],
    select: useCallback(selectFromQuery, []),
    enabled: !!currentUser?.id,
  });

  const suggestedBooksDeleteMutation = useMutation(
    (suggestedBookId: number) => api.suggestedBooks.suggestedBooksIdDelete({ id: suggestedBookId }),
    {
      onSuccess: () => {
        setFeedback({
          status: 'success',
          message: 'Suggested book was deleted successfully',
        });
        return queryClient.invalidateQueries('suggestedBooksByUser');
      },
      onError: (error) => {
        handleErrorMsg(error, setFeedback);
      },
    },
  );

  const handleClickOpen = (rowId: any) => () => {
    DeleteItem(() => suggestedBooksDeleteMutation.mutate(rowId));
  };

  const suggestedBooksData = suggestedBooksList?.map((sb) => {
    return {
      id: sb.id,
      title: sb.title,
      author: sb.author,
      published_at: sb.published_at,
      type: sb.type,
      status: sb.status,
    };
  });

  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: 'Title',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      minWidth: 200,
      flex: 0.7,
      renderCell: (row) => {
        return <Box sx={multiLineSx}>{row.value}</Box>;
      },
    },
    {
      field: 'author',
      headerName: 'Author',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      minWidth: 180,
      flex: 0.3,
      renderCell: (row) => {
        return <Box sx={{ ...multiLineSx, fontWeight: 300 }}>{row.value}</Box>;
      },
    },
    {
      field: 'published_at',
      headerName: 'Published year',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      width: 120,
      renderCell: (params) => {
        const publishedAt = params.value;
        return <Box sx={{ fontStyle: 'italic', fontWeight: 300 }}>{publishedAt ? publishedAt : '-'}</Box>;
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      width: 120,
      renderCell: (row) => {
        return row.value ? <Chip sx={TypeChipSx} label={row.value} size="small" color="warning" key={row.id} /> : '-';
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      width: 130,
      renderCell: (row) => {
        return (
          <Box
            sx={{
              ...statusTextSx,
              color: row.value === 'suggested' ? libraryTheme.palette.warning.main : libraryTheme.palette.success.main,
            }}
            key={row.id}
          >
            {row.value}
          </Box>
        );
      },
    },
    {
      field: 'Actions',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      width: 90,
      renderCell: (row) => {
        return (
          <Stack direction="row" className="cellAction">
            <Tooltip title="Delete">
              <IconButton
                data-testid="delete-suggested-book-btn"
                aria-label="delete"
                color="primary"
                onClick={handleClickOpen(row.row.id)}
              >
                <DeleteIcon sx={actionIconSx} />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  return (
    <Box>
      <Box sx={sectionTextSx}>
        <VolunteerActivismIcon sx={{ width: '20px', height: '20px', marginRight: '10px' }} /> Wishlist
      </Box>
      {wishlist?.length === 0 && (
        <Box sx={noItemsBoxSx}>Your wishlist is empty. Please add a book which you want to borrow.</Box>
      )}

      <Grid container>
        {
          isFetching && !wishlist
            ? /* eslint-disable @typescript-eslint/indent */
              Array.from({ length: 3 }).map((_, index) => (
                <Grid item key={index} xs={12}>
                  <Skeleton
                    data-testid="bookSkeleton"
                    variant="rounded"
                    width="100%"
                    height={300}
                    animation="wave"
                    sx={{ mb: '15px' }}
                  />
                </Grid>
              ))
            : wishlist?.map((wish, index) => (
                <Grid item sx={itemSx} key={index} xs={12}>
                  <WishlistItem item={wish} />
                </Grid>
              )) /* eslint-enable @typescript-eslint/indent */
        }
      </Grid>

      <Box sx={{ ...sectionTextSx, marginTop: '50px' }}>
        <Diversity1Icon sx={{ width: '20px', height: '20px', marginRight: '10px' }} /> My suggested books
      </Box>
      {suggestedBooksList && suggestedBooksList.length === 0 && (
        <Box sx={noItemsBoxSx}>You have no suggested books.</Box>
      )}
      {isSuggestedBooksLoading ? (
        <Box sx={{ width: '100%' }}>
          <LinearProgress color="secondary" />
        </Box>
      ) : (
        suggestedBooksData &&
        suggestedBooksData.length > 0 && (
          <Box sx={{ minHeight: 200 }}>
            <DataGrid
              disableVirtualization
              headerHeight={70}
              rowHeight={70}
              rows={suggestedBooksData}
              columns={columns}
              loading={isSuggestedBooksLoading}
              pageSize={5}
              rowsPerPageOptions={[5]}
              disableColumnSelector
              autoHeight
            />
          </Box>
        )
      )}
    </Box>
  );
};

export default Wishlist;
