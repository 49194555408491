import { Box, Skeleton } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { FC, useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '../../../services/api';
import { selectFromQuery } from '../../../utils';
import { SkeletonBtnSx } from '../../Create/Authors/Index/styles';
import { GridBoxSx } from '../styles';
import CreateOffice from './Create';
import DeleteOffice from './Delete';
import EditOffice from './Edit';

const OfficesTab: FC = () => {
  const { data: officesData, isLoading: isLoadingOffices } = useQuery({
    queryKey: ['offices'],
    queryFn: () => api.office.officesGet(),
    select: useCallback(selectFromQuery, []),
  });

  const { data: settingsData, isLoading: isLoadingSettings } = useQuery({
    queryKey: ['settings'],
    queryFn: () => api.setting.settingsGet(),
    select: useCallback(selectFromQuery, []),
  });

  const officesRows = useMemo(() => {
    if (!officesData) return [];
    if (!settingsData) return officesData;
    return officesData.map((of) => {
      const sett = settingsData.find((s) => s.office_id?.id === of.id);
      if (!sett) return of;
      return {
        ...of,
        settings: {
          id: sett.id,
          max_books: sett.max_books,
          max_term_books: sett.max_term_books,
          max_kindles: sett.max_kindles,
          max_term_kindles: sett.max_term_kindles,
        },
      };
    });
  }, [officesData, settingsData]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      minWidth: 140,
      flex: 0.6,
    },
    {
      field: 'city',
      headerName: 'City',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      minWidth: 120,
      flex: 0.2,
    },
    {
      field: 'country',
      headerName: 'Country',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      minWidth: 120,
      flex: 0.2,
    },
    {
      field: 'settings',
      headerName: 'Settings',
      headerAlign: 'center',
      align: 'left',
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 200,
      renderCell: (row) => (
        <Box>
          <Box>
            Max books: {row.formattedValue?.max_books ?? 'not set'}
            <br />
            Max term books: {row.formattedValue?.max_term_books ?? 'not set'}
            <br />
            Max kindles: {row.formattedValue?.max_kindles ?? 'not set'}
            <br />
            Max term kindles: {row.formattedValue?.max_term_kindles ?? 'not set'}
          </Box>
        </Box>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 130,
      renderCell: (row) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <EditOffice office={row.row} />
            <DeleteOffice office={row.row} />
          </div>
        );
      },
    },
  ];

  return (
    <Box>
      <Box sx={GridBoxSx}>
        {isLoadingOffices || isLoadingSettings ? (
          <Box>
            <Box sx={SkeletonBtnSx}>
              <Skeleton variant="rounded" width={170} height={40} animation="wave" />
            </Box>
            <Skeleton variant="rounded" width="100%" height={50} animation="wave" sx={{ mt: 1 }} />
            {Array.from({ length: 4 }, (_, index) => (
              <Skeleton key={index} variant="rounded" width="100%" height={100} animation="wave" sx={{ mt: 0.5 }} />
            ))}
          </Box>
        ) : (
          <Box>
            <CreateOffice />
            <Box sx={GridBoxSx}>
              <DataGrid
                disableVirtualization
                rowHeight={100}
                columns={columns}
                rows={officesRows}
                loading={isLoadingOffices || isLoadingSettings}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableColumnSelector
                autoHeight
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default OfficesTab;
