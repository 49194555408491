import { Box } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useClickOutside } from 'react-click-outside-hook';
import { IoClose, IoSearch } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import MoonLoader from 'react-spinners/MoonLoader';

import CurrentUserContext from '../../../contexts/CurrentUserContext';
import { api, IBook } from '../../../services/api';
import Book from './Books/index';
import {
  CloseIcon,
  containerTransition,
  containerVariants,
  LineSeparator,
  LoadingWrapper,
  SearchBarContainer,
  SearchContent,
  SearchIcon,
  searchIconBoxSx,
  SearchInput,
  SearchInputContainer,
  WarningMessage,
} from './styles';

const SearchBar: FC = () => {
  let timer: NodeJS.Timeout;
  const navigate = useNavigate();

  const { currentUser } = useContext(CurrentUserContext);
  const [parentRef, isClickedOutside] = useClickOutside();
  const [isExpanded, setExpanded] = useState<boolean>(false);

  const observer = useRef<IntersectionObserver>();
  const inputRef = useRef<HTMLInputElement>(null);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [booksData, setBooksData] = useState<IBook[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const noBooks = !booksData || booksData.length === 0;

  const lastElement = (targetNode: HTMLDivElement) => {
    if (isLoading) {
      return;
    }

    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setCurrentPage((prev) => prev + 1);
      }
    });

    if (targetNode) {
      observer.current?.observe(targetNode);
    }
  };

  useEffect(() => {
    setBooksData([]);
  }, [searchQuery]);

  useEffect(() => {
    if (!searchQuery || searchQuery.trim() === '') {
      return;
    }

    setLoading(true);

    const getSearchBooks = async () => {
      const filteredBooks = await api.book.booksGet({
        params: {
          composite: searchQuery,
          per_page: 10,
          office_id: currentUser?.office.id,
          page: currentPage,
        },
      });

      setHasMore(filteredBooks.data.data.length > 0);
      setBooksData((prevBooksData) => [
        ...prevBooksData,
        ...filteredBooks.data.data.filter((book) => {
          return !prevBooksData.some((prevBook) => prevBook.id === book.id);
        }),
      ]);
      setLoading(false);
    };

    getSearchBooks();
  }, [searchQuery, currentPage, currentUser?.office.id]);

  const handleBookClick = (bookId: number) => {
    navigate(`/books/${bookId}`);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      setSearchQuery(e.target.value);
      setCurrentPage(1);
    }, 300);
  };

  const clearSearch = () => {
    setBooksData([]);
    setLoading(false);
    setSearchQuery('');
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const expandContainer = () => {
    clearSearch();
    setExpanded(true);
  };

  const collapseContainer = useCallback(() => {
    clearSearch();
    setExpanded(false);
  }, []);

  useEffect(() => {
    if (isClickedOutside) {
      collapseContainer();
    }
  }, [isClickedOutside, collapseContainer]);

  return (
    <SearchBarContainer
      animate={isExpanded ? 'expanded' : 'collapsed'}
      variants={containerVariants}
      transition={containerTransition}
      ref={parentRef}
    >
      <SearchInputContainer>
        <Box sx={searchIconBoxSx}>
          <SearchIcon>
            <IoSearch />
          </SearchIcon>
        </Box>
        <SearchInput
          placeholder="Search for Books"
          onFocus={expandContainer}
          ref={inputRef}
          onChange={(e) => handleChange(e)}
        />
        <AnimatePresence>
          {isExpanded && (
            <CloseIcon
              key="close-icon"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              onClick={collapseContainer}
            >
              <IoClose />
            </CloseIcon>
          )}
        </AnimatePresence>
      </SearchInputContainer>
      {isExpanded && <LineSeparator />}
      {isExpanded && (
        <SearchContent>
          {isLoading && noBooks && (
            <LoadingWrapper>
              <MoonLoader loading color="#000" size={20} />
            </LoadingWrapper>
          )}
          {!isLoading && !searchQuery && noBooks && (
            <LoadingWrapper>
              <WarningMessage>Start typing to Search</WarningMessage>
            </LoadingWrapper>
          )}
          {!isLoading && searchQuery && noBooks && (
            <LoadingWrapper>
              <WarningMessage>No Books found!</WarningMessage>
            </LoadingWrapper>
          )}
          {!noBooks && (
            <>
              {booksData.map((book, index) => {
                if (booksData.length === index + 1) {
                  return (
                    <Book
                      forwardRef={lastElement}
                      key={book.id}
                      title={book.title}
                      cover={book.cover}
                      onClick={() => handleBookClick(book.id)}
                    />
                  );
                } else {
                  return (
                    <Book
                      key={book.id}
                      title={book.title}
                      cover={book.cover}
                      onClick={() => handleBookClick(book.id)}
                    />
                  );
                }
              })}
              {isLoading && (
                <LoadingWrapper>
                  <MoonLoader loading color="#000" size={20} />
                </LoadingWrapper>
              )}
            </>
          )}
        </SearchContent>
      )}
    </SearchBarContainer>
  );
};

export default SearchBar;
