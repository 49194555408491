import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';

import Popup from '../../../../../components/Popup';
import AddCategoryForm from './form';

const AddCategoryDialog = () => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => setOpen((prev) => !prev);

  return (
    <>
      <IconButton data-testid="category-open-form" onClick={handleOpen}>
        <AddIcon />
      </IconButton>

      <Popup
        content="Enter the desired name of the new book's category"
        title="Add new book category"
        openState={[open, setOpen]}
      >
        <AddCategoryForm handleOpen={handleOpen} />
      </Popup>
    </>
  );
};

export default AddCategoryDialog;
