import { DescriptionOutlined, TabletAndroidOutlined } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Tooltip,
} from '@mui/material';
import React, { FC, useContext, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import CurrentUserContext from '../../../contexts/CurrentUserContext';
import FeedbackContext from '../../../contexts/FeedbackContext';
import { api, IBook } from '../../../services/api';
import {
  cancelButtonSx,
  deleteButtonDisabledSx,
  deleteButtonSx,
  dialogTitleSx,
  editionsBoxSx,
  iconSx,
  openDeleteIconSx,
  textSx,
  xImageSx,
} from './styles';

interface Props {
  book: IBook;
}

const DeleteBook: FC<Props> = ({ book }) => {
  const navigate = useNavigate();
  const { setFeedback } = useContext(FeedbackContext);
  const queryClient = useQueryClient();
  const [open, setOpen] = useState<boolean>(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);

  const [checkedBookEditions, setCheckedBookEditions] = useState<(typeof book.details)[0][]>([]);
  const { currentUser } = useContext(CurrentUserContext);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenDelete = () => {
    setCheckedBookEditions([]);
    setOpen(true);
  };

  const deleteBookMutation = useMutation(
    (variables: { bookEditionId: number }) =>
      api.bookEdition.bookEditionsIdDelete({
        id: variables.bookEditionId,
      }),
    {
      onSuccess: async () => {
        setFeedback({
          status: 'success',
          message: 'Book Edition was deleted!',
        });
        await Promise.all([
          queryClient.invalidateQueries(['book', book.id]),
          queryClient.invalidateQueries(['paginatedBooks', currentUser?.office.id]),
        ]);
        navigate('/books');
      },

      onError: (e) => {
        setFeedback({
          status: 'error',
          message: (e as any).response.data.message,
        });
      },
    },
  );

  const handleDeleteBookEditions = () => {
    setOpenConfirmationDialog(false);
    checkedBookEditions.forEach((ed) => deleteBookMutation.mutate({ bookEditionId: ed.id }));
    setOpen(false);
  };

  const handleCheckboxChange = (bookEditionId: number, checked: boolean) => {
    if (checked) {
      const bookEdition = book.details.find((ed) => ed.id === bookEditionId)!;
      setCheckedBookEditions((prev) => [...prev, bookEdition]);
    } else setCheckedBookEditions((prev) => prev.filter((be) => be.id !== bookEditionId));
  };

  return (
    <>
      <Tooltip title="Delete Book">
        <IconButton
          sx={{ ...openDeleteIconSx, marginLeft: '10px' }}
          aria-label="delete"
          color="primary"
          onClick={handleOpenDelete}
        >
          <DeleteIcon sx={openDeleteIconSx} />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={dialogTitleSx} align="center">
          Delete book edition
        </DialogTitle>
        <DialogContent>
          <Box sx={textSx}>Select the types of books that you want to delete.</Box>
          <Box sx={editionsBoxSx}>
            <FormControl>
              {book.details
                .filter((ed) => ed.type === 'e-book')
                .map((ed) => (
                  <FormControlLabel
                    key={ed.id}
                    label="E-Book"
                    control={
                      <Checkbox
                        id={ed.id.toString()}
                        defaultChecked={false}
                        onChange={(e) => handleCheckboxChange(Number.parseInt(e.target.id), e.target.checked)}
                      />
                    }
                  />
                ))}
              {book.details
                .filter((ed) => ed.type === 'kindle')
                .map((ed) => (
                  <FormControlLabel
                    key={ed.id}
                    label={'Book type: Kindle' + (ed.status === 'borrowed' ? ' - is borrowed' : '')}
                    control={
                      <Checkbox
                        id={ed.id.toString()}
                        disabled={ed.status === 'borrowed'}
                        defaultChecked={false}
                        onChange={(e) => handleCheckboxChange(Number.parseInt(e.target.id), e.target.checked)}
                      />
                    }
                  />
                ))}
              {
                /* eslint-disable @typescript-eslint/indent */ book.details
                  .filter((ed) => ed.type === 'paper')
                  .map((ed) => (
                    <FormControlLabel
                      key={ed.id}
                      label={
                        <Box>
                          Book type: Paper{' '}
                          <Box component="span" sx={{ fontStyle: 'italic' }}>
                            {' '}
                            (inventory number: {ed.inventory_num})
                          </Box>
                          {ed.status === 'borrowed'
                            ? ' - is borrowed'
                            : ed.status === 'pending return confirmation'
                            ? ' - is in pending return'
                            : ''}
                        </Box>
                      }
                      control={
                        <Checkbox
                          id={ed.id.toString()}
                          disabled={ed.status === 'borrowed' || ed.status === 'pending return confirmation'}
                          defaultChecked={false}
                          onChange={(e) => handleCheckboxChange(Number.parseInt(e.target.id), e.target.checked)}
                        />
                      }
                    />
                  ))
                /* eslint-enable @typescript-eslint/indent */
              }
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button sx={cancelButtonSx} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            sx={checkedBookEditions.length === 0 ? deleteButtonDisabledSx : deleteButtonSx}
            disabled={checkedBookEditions.length === 0}
            onClick={() => setOpenConfirmationDialog(true)}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openConfirmationDialog}>
        <DialogTitle sx={dialogTitleSx}>Are you sure you want to delete the following book copies?</DialogTitle>
        <DialogContent>
          <Box sx={editionsBoxSx}>
            {checkedBookEditions.map((ed) => (
              <Box sx={{ marginBottom: '10px' }} key={ed.id}>
                <Box component="span" sx={{ marginTop: '10px' }}>
                  {ed.type === 'e-book' ? (
                    <DescriptionOutlined sx={iconSx} />
                  ) : ed.type === 'kindle' ? (
                    <TabletAndroidOutlined sx={iconSx} />
                  ) : (
                    <MenuBookIcon sx={iconSx} />
                  )}
                  <CloseIcon sx={xImageSx} />
                </Box>
                <Box component="span">
                  {ed.type === 'e-book' ? 'E-Book' : 'Book type: ' + ed.type.charAt(0).toUpperCase() + ed.type.slice(1)}
                  {ed.type === 'paper' && (
                    <Box component="span" sx={{ fontStyle: 'italic' }}>
                      {' '}
                      (inventory number: {ed.inventory_num})
                    </Box>
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button sx={cancelButtonSx} onClick={() => setOpenConfirmationDialog(false)}>
            Cancel
          </Button>
          <Button onClick={() => handleDeleteBookEditions()} sx={deleteButtonSx}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteBook;
