import { Box, Button, Grid, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { FC, useCallback, useContext, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import CurrentUserContext from '../../../contexts/CurrentUserContext';
import FeedbackContext from '../../../contexts/FeedbackContext/index';
import { ConfirmOfficeSettings } from '../../../dialogs/confirmationDialog';
import { api, ISettingsPost } from '../../../services/api';
import { handleErrorMsg, selectFromQuery } from '../../../utils';
import {
  fieldSx,
  saveSettingsButtonSx,
  saveSettingsDisabledButtonSx,
  settingsGridSx,
  settingsTextFieldSx,
  settingsTextSx,
} from '../styles';
import { schema } from './validation';

const OfficeSettingsTab: FC = () => {
  const { currentUser } = useContext(CurrentUserContext);
  const { setFeedback } = useContext(FeedbackContext);
  const queryClient = useQueryClient();

  const editSettingsMutation = useMutation(
    (variables: { settingsId: number; settingsData: ISettingsPost }) => {
      return api.setting.settingsIdPatch({
        id: variables.settingsId,
        settingsGetRequest: variables.settingsData,
      });
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['settings']),
      onError: (e) => handleErrorMsg(e, setFeedback),
    },
  );

  const { data: settingsData, isLoading: isLoadingSettings } = useQuery({
    queryKey: ['settings'],
    queryFn: () => api.setting.settingsGet(),
    select: useCallback(selectFromQuery, []),
  });

  const settings = useMemo(() => {
    if (!currentUser || !settingsData) return undefined;
    const sett = settingsData.find((s) => s.office_id?.id === currentUser.office.id);
    if (!sett) return undefined;
    return {
      id: sett.id,
      office_id: currentUser.office.id,
      max_books: sett.max_books,
      max_term_books: sett.max_term_books,
      max_kindles: sett.max_kindles,
      max_term_kindles: sett.max_term_kindles,
    };
  }, [currentUser, settingsData]);

  const formInitialValues: ISettingsPost = {
    max_books: settings?.max_books,
    max_term_books: settings?.max_term_books,
    max_term_kindles: settings?.max_term_kindles,
  };

  const { handleSubmit, handleChange, handleBlur, values, errors, isValid } = useFormik({
    initialValues: formInitialValues,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: () => {
      if (!settings) return;
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const settingsData = {
        max_books: values.max_books,
        max_term_books: values.max_term_books,
        max_term_kindles: values.max_term_kindles,
      };
      editSettingsMutation.mutate({
        settingsId: settings.id,
        settingsData,
      });
    },
  });

  const settingsDataChanged = useMemo(() => {
    return (
      settings?.max_books !== values.max_books ||
      settings?.max_term_books !== values.max_term_books ||
      settings?.max_term_kindles !== values.max_term_kindles
    );
  }, [values.max_books, values.max_term_books, values.max_term_kindles, settings]);

  return (
    <Box>
      {!isLoadingSettings && (
        <Grid sx={settingsGridSx} container alignItems="flex-start">
          {!settings ? (
            <Box>Error getting settings for this office</Box>
          ) : (
            <Grid item sx={{ width: '550px' }}>
              <Stack sx={fieldSx} direction="row">
                <Box sx={settingsTextSx}>Max books</Box>
                <TextField
                  sx={settingsTextFieldSx}
                  id="max_books"
                  type="number"
                  size="small"
                  value={values.max_books}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.max_books}
                  helperText={errors.max_books}
                />
              </Stack>

              <Stack sx={fieldSx} direction="row">
                <Box sx={settingsTextSx}>Max term books</Box>
                <TextField
                  sx={settingsTextFieldSx}
                  id="max_term_books"
                  type="number"
                  size="small"
                  value={values.max_term_books}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.max_term_books}
                  helperText={errors.max_term_books}
                />
              </Stack>

              <Stack sx={fieldSx} direction="row">
                <Box sx={settingsTextSx}>Max kindles</Box>
                <TextField
                  sx={settingsTextFieldSx}
                  disabled
                  id="max_kindles"
                  type="number"
                  size="small"
                  value={settings.max_kindles}
                  onBlur={handleBlur}
                />
              </Stack>

              <Stack sx={fieldSx} direction="row">
                <Box sx={settingsTextSx}>Max term kindles</Box>
                <TextField
                  sx={settingsTextFieldSx}
                  id="max_term_kindles"
                  type="number"
                  size="small"
                  value={values.max_term_kindles}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.max_term_kindles}
                  helperText={errors.max_term_kindles}
                />
              </Stack>

              <Stack direction="row" justifyContent="flex-end">
                <Button
                  disabled={!settingsDataChanged || !isValid}
                  sx={!settingsDataChanged || !isValid ? saveSettingsDisabledButtonSx : saveSettingsButtonSx}
                  onClick={() => ConfirmOfficeSettings(handleSubmit)}
                >
                  Save
                </Button>
              </Stack>
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );
};

export default OfficeSettingsTab;
