import { Avatar, Box } from '@mui/material';
import { FC } from 'react';

import { circleBoxSx, userNameTypographySx } from '../pages/Dashboard/ReadingNowBooks/FlagComponent/styles';

interface UserPictureProps {
  userPictureUrl?: string;
  userName?: string;
  circleBoxBackground?: string;
}

const UserPicture: FC<UserPictureProps> = ({ userPictureUrl, userName, circleBoxBackground }) => {
  return (
    <>
      {userPictureUrl ? (
        <Avatar src={userPictureUrl} sx={circleBoxSx} />
      ) : (
        <Avatar
          sx={{
            ...circleBoxSx,
            background: circleBoxBackground,
          }}
        >
          <Box sx={userNameTypographySx}>
            {userName
              ?.split(' ')
              .slice(0, 2)
              .map((name: string) => name[0])
              .join('')
              .toUpperCase()}
          </Box>
        </Avatar>
      )}
    </>
  );
};

export default UserPicture;
