import { SxProps } from '@mui/material';

export const MainLayoutSx: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
};

export const AppTitleSx: SxProps = {
  flexGrow: 1,
  textAlign: 'left',
  fontSize: '40px',
  fontWeight: '50',
  fontFamily: 'LeagueGothic',
  color: 'black',
  ml: '20px',
};

export const AppTitleHideSx: SxProps = {
  display: { xs: 'none', md: 'inline' },
};

export const ToolbarSx: SxProps = {
  width: '100%',
  backgroundColor: 'white',
  height: '60px',
  paddingLeft: '0px',
  pl: '0px!important', // keep right padding when drawer closed
};

export const IconButtonSx: SxProps = {
  height: '100%',
  width: '72px',
  borderRadius: 0,
  backgroundColor: '#041c32',
  pr: '8px!important',
  '&:hover': {
    backgroundColor: '#041c32',
  },
};

export const DrawerSx: SxProps = {
  backgroundColor: '#041c32',
  color: 'red',
  paddingTop: '64px',
};
