import { PublicClientApplication } from '@azure/msal-browser';
import { AxiosError } from 'axios';
import { AxiosResponse } from 'axios';
import axios from 'axios';

import { msalConfig } from './config/authConfig';
import { SetFeedbackType } from './contexts/FeedbackContext';

export const handleErrorMsg = (e: unknown, setFeedback: SetFeedbackType) => {
  const error = e as AxiosError<{ errors: string[] }>;

  if (error.response && error.response.status === 422) {
    let errMsg = '';
    const errors = error.response.data.errors;
    if (errors) (Object.values(error.response.data.errors) as string[]).forEach((er) => (errMsg += `${er}\n`));
    else errMsg = (e as any).response.data.message;

    setFeedback({ status: 'error', message: errMsg });
  }
  if (error.response && error.response.status === 403) {
    const err = e as any;
    if (err.response.data.message) setFeedback({ status: 'error', message: err.response.data.message });
    else if (Array.isArray(err.response.data)) setFeedback({ status: 'error', message: err.response.data.join('\n') });
  }

  if (error.response) {
    let errMsg = '';
    const errors = error.response.data.errors;
    if (errors) (Object.values(error.response.data.errors) as string[]).forEach((er) => (errMsg += `${er}\n`));
    else errMsg = (e as any).response.data.message;

    setFeedback({ status: 'error', message: errMsg });
  }
};

export const selectFromQuery = <T>(data: AxiosResponse<{ data: T }>) => {
  return data.data.data;
};

export const getProfilePicturesAccessToken = async () => {
  const msalInstance = new PublicClientApplication(msalConfig);
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();

  const graphToken = await msalInstance.acquireTokenSilent({
    scopes: ['User.Read.All'],
    account: activeAccount || accounts[0],
  });

  return graphToken.accessToken;
};

export const getProfilePictureUrl = async (email: string, accessToken: string) => {
  const resp = await axios
    .get(`https://graph.microsoft.com/v1.0/users/${email}/photo/$value`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      responseType: 'blob',
    })
    .catch((error) => {
      if (error.response.status !== 404) console.error(error);
    });
  if (!resp) return undefined;
  return URL.createObjectURL(resp.data);
};

export const copyObject = <T>(o: T): T => {
  return JSON.parse(JSON.stringify(o));
};

export const ENVIRONMENT = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  TEST: window.NODE_ENV === 'test',
};
