import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import { Box, Button, IconButton, Skeleton, Stack, TextField, Tooltip } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { AxiosResponse } from 'axios';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as Yup from 'yup';

import KindleImage from '../../../assets/images/KindleImage.jpg';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import feedbackContext from '../../../contexts/FeedbackContext';
import { ConfirmReturnDialog, DeleteItem } from '../../../dialogs/confirmationDialog';
import { libraryTheme } from '../../../LibraryTheme';
import { api } from '../../../services/api';
import { KindlesBorrowedGet200Response } from '../../../services/api/openapi';
import { ENVIRONMENT, handleErrorMsg } from '../../../utils';
import { SkeletonBtnSx } from '../../Create/Authors/Index/styles';
import UpdateKindle from '../Update';
import {
  actionIconSx,
  buttonDisabledSx,
  buttonSx,
  createBoxSx,
  dataGridBoxSx,
  dateTextSx,
  imageSx,
  invNumberTextFieldSx,
  manageKindlesTitleSx,
  statusTextSx,
} from './styles';

const Kindles: FC = () => {
  const { setFeedback } = useContext(feedbackContext);
  const queryClient = useQueryClient();
  const { currentUser } = useContext(CurrentUserContext);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);

  const { data: kindlesData, isFetching: isKindlesFetching } = useQuery({
    queryKey: ['kindles', currentPage],
    queryFn: () =>
      api.kindle.kindlesBorrowedGet({
        params: {
          borrowed_office_id: currentUser?.office.id,
          'sort[status]': 'desc',
          per_page: 10,
          page: currentPage + 1,
        },
      }),
    // select: useCallback((data: AxiosResponse<KindlesBorrowedGet200Response, any>) => {
    //   return data.data.data;
    // }, []),
    enabled: !!currentUser?.office.id,
  });

  const [rowCountState, setRowCountState] = useState(kindlesData?.data.meta.total || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      kindlesData?.data.meta.total !== undefined ? kindlesData?.data.meta.total : prevRowCountState,
    );
  }, [kindlesData?.data.meta.total, rowCountState]);

  const deleteKindleMutation = useMutation((kindleId: number) => api.kindle.kindlesIdDelete({ id: kindleId }), {
    onSuccess: () => {
      return queryClient.invalidateQueries('kindles');
    },
    onError: (error) => {
      handleErrorMsg(error, setFeedback);
    },
  });

  const handleClickOpen = (rowId: any, status: string) => () => {
    if (status === 'available') {
      DeleteItem(() => deleteKindleMutation.mutate(rowId));
    } else {
      setFeedback({
        status: 'error',
        message: "Kindle with status 'Borrowed' cannot be deleted",
      });
    }
  };

  const confirmReturnedMutation = useMutation(
    (variables: { kindleRegisterId: number }) =>
      api.kindleRegister.kindleRegisterConfirmIdPatch({
        id: variables.kindleRegisterId,
      }),
    {
      onSuccess: () => {
        return Promise.all([
          queryClient.invalidateQueries(['kindleRegisterAll']),
          queryClient.invalidateQueries(['kindles']),
          queryClient.invalidateQueries(['kindleRegister']),
        ]);
      },
      onError: (e) => handleErrorMsg(e, setFeedback),
    },
  );

  const handleConfirmClick = (kindleRegisterId: number) => {
    ConfirmReturnDialog('kindle', () => confirmReturnedMutation.mutate({ kindleRegisterId: kindleRegisterId }));
  };

  const columns: GridColDef[] = [
    {
      field: 'image',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      width: 70,
      hideable: false,
      renderCell: () => <Box component="img" src={KindleImage} sx={imageSx} />,
    },
    {
      field: 'inventory_num',
      headerName: 'Inventory number',
      align: 'center',
      headerAlign: 'center',
      minWidth: 130,
      flex: 0.25,
      hideable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      align: 'center',
      headerAlign: 'center',
      minWidth: 200,
      flex: 0.3,
      hideable: false,
      renderCell: (row) => {
        return (
          <Box
            sx={{
              ...statusTextSx,
              color: row.value === 'available' ? libraryTheme.palette.success.main : libraryTheme.palette.warning.main,
            }}
          >
            {row.value}
          </Box>
        );
      },
    },
    {
      field: 'user_name',
      headerName: 'Borrowed by',
      align: 'center',
      headerAlign: 'center',
      minWidth: 140,
      flex: 0.45,
      hideable: false,
      renderCell: (params) => {
        const borrowedBy = params.value;
        return <Box sx={{ fontWeight: 300 }}>{borrowedBy ? borrowedBy : '-'}</Box>;
      },
    },
    {
      field: 'borrowed_at',
      headerName: 'Borrowed at',
      align: 'center',
      headerAlign: 'center',
      width: 100,
      hideable: false,
      valueFormatter: (params) => {
        if (!params.value) {
          return '-';
        }
        const date = new Date(params.value);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      },
      renderCell: (row) => {
        return <Box sx={{ ...dateTextSx, fontWeight: 300 }}>{row.formattedValue}</Box>;
      },
    },
    {
      field: 'return_before',
      headerName: 'Return date',
      align: 'center',
      headerAlign: 'center',
      width: 100,
      hideable: false,
      valueFormatter: (params) => {
        if (!params.value) {
          return '-';
        }
        const date = new Date(params.value);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      },
      renderCell: (row) => {
        const past = moment(row.value) < moment();
        return <Box sx={{ ...dateTextSx, fontWeight: 300, color: past ? 'red' : undefined }}>{row.formattedValue}</Box>;
      },
    },
    {
      field: 'Actions',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      width: 90,
      renderCell: (row) => {
        return (
          <Stack direction="row" className="cellAction">
            <UpdateKindle kindle={{ id: row.row.id, inventory_num: row.row.inventory_num }} />
            <Tooltip title="Delete">
              <IconButton
                data-testid="delete-kindle-btn"
                aria-label="delete"
                color="primary"
                onClick={handleClickOpen(row.row.id, row.row.status)}
              >
                <DeleteIcon sx={actionIconSx} />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      field: 'confirm_returned',
      headerName: 'Returned',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      width: 175,
      renderCell: (row) => (
        <Button
          sx={row.row.status !== 'pending return confirmation' ? buttonDisabledSx : buttonSx}
          disabled={row.row.status !== 'pending return confirmation'}
          onClick={() => handleConfirmClick(row.row.kindle_register_id)}
          size="small"
        >
          Confirm
        </Button>
      ),
    },
  ];

  // old fe sorting
  // const sortedKindles = kindlesData
  //   ?.sort((a, b) => {
  //     if (a.status === 'borrowed' && b.status !== 'borrowed') {
  //       return -1;
  //     } else if (
  //       a.status === 'pending return confirmation' &&
  //       b.status !== 'borrowed' &&
  //       b.status !== 'pending return confirmation'
  //     ) {
  //       return -1;
  //     } else if ((a.status === 'available' || a.status === 'pending return confirmation') && b.status === 'borrowed') {
  //       return 1;
  //     } else if (a.status === 'available' && b.status === 'pending return confirmation') {
  //       return 1;
  //     } else {
  //       return 0;
  //     }
  //   })
  //   .map((item) => {
  //     return {
  //       id: item.id,
  //       register_id: item.kindle_register_id,
  //       inventory_num: item.inventory_num,
  //       status: item.status,
  //       user_name: item.user_name,
  //       borrowed_at: item.borrowed_at,
  //       return_before: item.return_before,
  //     };
  //   });

  const createKindle = useMutation(
    (inventoryNum: number) =>
      api.kindle.kindlesPost({
        kindlesGetRequest: {
          inventory_num: inventoryNum,
        },
      }),
    {
      onSuccess: () => {
        setFeedback({
          status: 'success',
          message: 'The Kindle was successfully created\nThe email was sent to all the subscribers!',
        });
        return queryClient.invalidateQueries('kindles');
      },
      onError: (error) => {
        handleErrorMsg(error, setFeedback);
      },
    },
  );

  const schema = Yup.object({
    inventory_num: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .max(20, 'Kindle inventory number should be less than 20 digits')
      .required('Inventory number is required'),
  });

  const { errors, isValid, touched, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: {
      inventory_num: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      createKindle.mutate(Number(values.inventory_num));
    },
  });

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <Box>
      <Box sx={manageKindlesTitleSx}>Manage Kindles</Box>
      {isKindlesFetching && !kindlesData?.data.data.length ? (
        <Box data-testid="kindles_skeleton">
          <Box sx={SkeletonBtnSx}>
            <Skeleton variant="rounded" width={400} height={40} animation="wave" sx={{ mr: '15px' }} />
            <Skeleton variant="rounded" width={170} height={40} animation="wave" />
          </Box>
          <Skeleton variant="rounded" width="100%" height={60} animation="wave" sx={{ mt: 1 }} />
          {Array.from({ length: 10 }, (_, index) => (
            <Skeleton
              key={`kindle-skeleton-${index}`}
              variant="rounded"
              width="100%"
              height={50}
              animation="wave"
              sx={{ mt: 0.5 }}
            />
          ))}
        </Box>
      ) : (
        <Box>
          <Box component="form" sx={createBoxSx} autoComplete="off" onSubmit={handleSubmit}>
            <TextField
              sx={invNumberTextFieldSx}
              InputProps={{ sx: { height: '40px' } }}
              size="small"
              error={touched.inventory_num && Boolean(errors.inventory_num)}
              id="inventory_num"
              name="inventory_num"
              label="Inventory number"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.inventory_num && errors.inventory_num}
              inputProps={{ onKeyPress: handleKeyPress }}
            />
            <LoadingButton
              sx={!isValid ? buttonDisabledSx : buttonSx}
              disabled={!isValid}
              loading={createKindle.isLoading}
              type="submit"
            >
              Create Kindle
            </LoadingButton>
          </Box>

          <Box sx={dataGridBoxSx}>
            {kindlesData?.data.data && (
              <DataGrid
                disableVirtualization
                rows={kindlesData.data.data}
                columns={columns}
                loading={isKindlesFetching}
                pageSize={pageSize}
                rowsPerPageOptions={[10]}
                disableColumnSelector
                headerHeight={70}
                rowHeight={70}
                autoHeight
                rowCount={rowCountState}
                pagination
                page={currentPage}
                paginationMode="server"
                onPageChange={(newPage) => setCurrentPage(newPage)}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Kindles;
