import { SxProps } from '@mui/material';

import { libraryTheme } from '../../../LibraryTheme';

export const openEditIconSx: SxProps = {
  width: '30px',
  height: '30px',
};

export const editModalTitleSx: SxProps = {
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: 'normal',
};

export const tabsSx: SxProps = {
  marginBottom: '20px',
  borderBottom: 1,
  borderColor: '#e0e0e0',
  height: '30px',
  minHeight: '30px',
};

export const tabSx: SxProps = {
  height: '30px',
  minHeight: '30px',
  padding: '10px 10px',
};

export const tabTextSx: SxProps = {
  color: '#000000 !important',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: 'normal',
  textTransform: 'none',
};

export const editBookItem: SxProps = {
  margin: '12px 0px',
};

export const tagChipSx: SxProps = {
  color: '#FFF',
  backgroundColor: libraryTheme.palette.primary.main,
  '& svg': {
    width: '20px',
    height: '20px',
    backgroundColor: '#FFF',
    borderRadius: '15px',
  },
};

export const cancelButtonSx: SxProps = {
  color: '#000000',
  fontSize: '14px',
  fontWeight: 700,
  height: '40px',
  borderColor: libraryTheme.palette.neutral.main,
  border: 1,
  width: '104px',
  marginRight: '5px',
};

export const deleteButtonSx: SxProps = {
  color: '#000000',
  fontSize: '14px',
  fontWeight: 700,
  height: '40px',
  borderColor: libraryTheme.palette.neutral.main,
  border: 1,
  marginRight: '5px',
};

export const saveButtonSx: SxProps = {
  color: '#FFF',
  fontSize: '14px',
  fontWeight: 700,
  background: 'linear-gradient(180deg, #EC147A 0%, #EC147A 100%)',
  height: '40px',
  width: '104px',
  transition: 'background 0.3s ease-in-out',
  '&:hover': {
    background: 'linear-gradient(180deg, rgb(215 14 109) 0%, rgb(215 14 109) 100%)',
  },
};

export const editionTextSx: SxProps = {
  color: '#000',
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: 'normal',
  marginBottom: '15px',
};

export const paperEditionBoxSx: SxProps = {
  height: '185px',
  border: '1px solid #e3e3e3',
  borderRadius: '5px',
  padding: '25px',
};

export const kindleEditionBoxSx: SxProps = {
  height: '140px',
  border: '1px solid #e3e3e3',
  borderRadius: '5px',
  padding: '25px',
};

export const switchEdiitonBoxSx: SxProps = {
  border: '1px solid #e3e3e3',
  borderRadius: '5px',
  padding: '8.5px 14px',
};

export const ebookEditionBoxSx: SxProps = {
  height: '220px',
  border: '1px solid #e3e3e3',
  borderRadius: '5px',
  padding: '25px',
  position: 'relative',
};

export const chooseFileButtonSx: SxProps = {
  background: 'linear-gradient(180deg, #EC147A 0%, #EC147A 100%)',
  color: '#FFF',
  height: '40px',
  fontSize: '14px',
  fontWeight: 600,
  transition: 'background 0.3s ease-in-out',
  '&:hover': {
    background: 'linear-gradient(180deg, rgb(215 14 109) 0%, rgb(215 14 109) 100%)',
  },
};

export const editUrlButtonSx: SxProps = {
  color: '#FFF',
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: 'normal',
  background: 'linear-gradient(180deg, #EC147A 0%, #EC147A 100%)',
  height: '40px',
  transition: 'background 0.3s ease-in-out',
  '&:hover': {
    background: 'linear-gradient(180deg, rgb(215 14 109) 0%, rgb(215 14 109) 100%)',
  },
};
