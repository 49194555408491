import { SxProps } from '@mui/material';

export const paginationSx: SxProps = {
  width: 1,
  display: 'flex',
  justifyContent: 'center',
};

export const bottomBarSx: SxProps = {
  marginTop: 4,
};

export const itemSx: SxProps = {
  marginBottom: '15px',
};

export const noBooksBoxSx: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  mt: 10,
};

export const filterListSx: SxProps = {
  overflow: 'auto',
  maxHeight: '250px',
};

export const filterOptionTextSx: SxProps = {
  maxWidth: '174px',
  wordWrap: 'break-word',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: '#000',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
};

export const bookTitleSx: SxProps = {
  color: '#000',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
};

export const accordionMainBoxSx: SxProps = {
  mr: '7px',
};

export const accordionTextFieldSx: SxProps = {
  mb: '15px',
};

export const mainTitleSx: SxProps = {
  color: '#000',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 400,
  fontHeight: 'normal',
  mb: '25px',
};

export const tabSx: SxProps = {
  textTransform: 'none',
  color: '#000',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
  fontHeight: 'normal',
};

export const mainTabsSx: SxProps = {
  borderBottom: 1,
  borderColor: '#e0e0e0',
  height: '47px',
  mb: '25px',
};
