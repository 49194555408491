import EditIcon from '@mui/icons-material/Edit';
import { Box, Dialog, DialogTitle, IconButton, Tab, Tabs, Tooltip } from '@mui/material';
import { FC, ReactNode, SyntheticEvent, useMemo, useState } from 'react';

import { IBook } from '../../../services/api';
import AddBookEdition from './AddBookEdition';
import EditBookDetails from './EditBookDetails';
import EditBookEdition from './EditBookEdition';
import { editModalTitleSx, openEditIconSx, tabsSx, tabSx, tabTextSx } from './styles';

interface Props {
  book: IBook;
}

interface ITabPanelProps {
  children?: ReactNode;
  value: number;
  index: number;
}

const TabPanel = (props: ITabPanelProps) => {
  const { children, value, index } = props;
  return <Box hidden={value !== index}>{value === index && <Box>{children}</Box>}</Box>;
};

const EditBook: FC<Props> = ({ book }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [tabNumber, setTabNumber] = useState<number>(0);

  const hasPaperOrEbookEditions = useMemo(() => {
    return book.details.some((be) => be.type === 'paper' || be.type === 'e-book');
  }, [book.details]);

  const handleOpenEdit = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleChange = (event: SyntheticEvent, newValue: number) => setTabNumber(newValue);

  return (
    <>
      <Tooltip title="Edit Book">
        <IconButton sx={openEditIconSx} aria-label="edit" color="primary" onClick={handleOpenEdit}>
          <EditIcon sx={openEditIconSx} />
        </IconButton>
      </Tooltip>
      <Dialog fullWidth sx={{ minWidth: '600px' }} maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle sx={editModalTitleSx} alignSelf="center">
          Edit book
        </DialogTitle>

        <Box sx={{ margin: '0px 25px' }}>
          <Tabs indicatorColor="secondary" sx={tabsSx} value={tabNumber} onChange={handleChange}>
            <Tab sx={tabSx} label={<Box sx={tabTextSx}>Book details</Box>} />
            {hasPaperOrEbookEditions && <Tab sx={tabSx} label={<Box sx={tabTextSx}>Edit Book edition</Box>} />}
            <Tab sx={tabSx} label={<Box sx={tabTextSx}>Add Book edition</Box>} />
          </Tabs>
          <TabPanel value={tabNumber} index={0}>
            <EditBookDetails book={book} setOpen={setOpen} />
          </TabPanel>
          {hasPaperOrEbookEditions && (
            <TabPanel value={tabNumber} index={1}>
              <EditBookEdition book={book} setOpen={setOpen} />
            </TabPanel>
          )}
          <TabPanel value={tabNumber} index={hasPaperOrEbookEditions ? 2 : 1}>
            <AddBookEdition book={book} setOpen={setOpen} />
          </TabPanel>
        </Box>
      </Dialog>
    </>
  );
};

export default EditBook;
