import { ExpandMore } from '@mui/icons-material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Container,
  Grid,
  IconButton,
  LinearProgress,
  List,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useFormik } from 'formik';
import React, {
  FC,
  HTMLAttributes,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import currentUserContext from '../../contexts/CurrentUserContext';
import feedbackContext from '../../contexts/FeedbackContext';
import { api, IOffice } from '../../services/api';
import UserPicture from '../../shared/UserPicture';
import { getProfilePicturesAccessToken, getProfilePictureUrl, handleErrorMsg, selectFromQuery } from '../../utils';
import { LibrarianGridBox } from '../Admin/styles';
import { openEditIconSx } from '../Book/Edit/styles';
import EmailDialog from './EmailDialog/EmailDialog';
import { AccordionSx, LibrarianNameSx, UpdateButtonSx, UpdateDisabledButtonSx, UserBoxSx } from './styles';
import SubsListItem from './SubsListItem';
import officeSchema from './validations';

interface IOfficeFormValues {
  officeId?: number;
}

const initialOfficeFormValues: IOfficeFormValues = {};

export interface ISelectedLibrarian {
  name: string;
  url?: string;
  email: string;
}

const AutocompleteLi = ({
  option,
  ...props
}: {
  option: IOffice;
} & HTMLAttributes<HTMLLIElement>) => <li {...props}>{option.name + ', ' + option.city}</li>;

const UserSettings: FC = () => {
  const { currentUser, setCurrentUser } = useContext(currentUserContext);
  const [selectedOffice, setSelectedOffice] = useState<IOffice | undefined>(currentUser?.office);
  const queryClient = useQueryClient();
  const { setFeedback } = useContext(feedbackContext);

  const [open, setOpen] = useState(false);
  const [selectedLibrarianData, setSelectedLibrarianData] = useState<ISelectedLibrarian>({ name: '', email: '' });

  const { data: officeLibrariansData, isLoading: isLoadingLibrarians } = useQuery({
    queryKey: ['officeLibrarians', currentUser],
    queryFn: async () => {
      const response = await api.user.usersLibrarianContactsGet();
      return response.data.data;
    },
    retry: false,
  });

  const { data: subscriptions, isLoading: isLoadingSubscriptions } = useQuery({
    enabled: !!currentUser,
    queryFn: () =>
      api.subscription.subscriptionsGet({
        params: { user_id: currentUser?.id },
      }),
    queryKey: ['subscriptions'],
    select: useCallback(selectFromQuery, [currentUser]),
  });

  const {
    data: offices,
    isFetching: isFetchingOffices,
    isLoading: isLoadingOffices,
  } = useQuery({
    queryFn: () => api.office.officesGet(),
    queryKey: ['offices'],
    select: useCallback(selectFromQuery, []),
  });

  const updateUserOfficeMutation = useMutation(
    () =>
      api.user.usersIdPatch({
        id: Number(currentUser?.id),
        usersIdGetRequest: { office_id: Number(selectedOffice?.id) },
      }),
    {
      onSuccess: async (data) => {
        setCurrentUser(data.data.data);
        setSelectedOffice(data.data.data.office);
        await Promise.all([
          queryClient.refetchQueries(['paginatedBooks', currentUser?.office.id]),
          queryClient.refetchQueries(['books', currentUser?.office.id]),
          queryClient.refetchQueries('kindles'),
        ]);
      },
      onError: (e) => handleErrorMsg(e, setFeedback),
    },
  );

  useEffect(() => {
    if (currentUser) setSelectedOffice(currentUser.office);
  }, [currentUser]);

  const handleSubmitForm = async () => {
    await updateUserOfficeMutation.mutateAsync();
    setFeedback({
      message: `The office was successfully updated to ${selectedOffice?.name}, ${selectedOffice?.city}!`,
    });
  };

  const { errors, handleBlur, handleSubmit, setFieldValue, touched } = useFormik({
    enableReinitialize: true,
    initialValues: initialOfficeFormValues,
    onSubmit: handleSubmitForm,
    validationSchema: officeSchema,
  });

  useEffect(() => {
    if (selectedOffice) setFieldValue('officeId', selectedOffice.id);
    else setFieldValue('officeId', 0);
  }, [selectedOffice, setFieldValue]);

  const loadingPage = isLoadingOffices || isFetchingOffices || isLoadingSubscriptions || isLoadingSubscriptions;

  const handleOfficeChange = (_e: SyntheticEvent, value: IOffice) => {
    setSelectedOffice(value);
  };

  const [profilePictures, setProfilePictures] = useState<[string, string][]>([]);

  const getPictureUrl = (email: string | undefined) => {
    if (!email) return undefined;
    const item = profilePictures.find((p) => p[0] === email);
    if (item) return item[1];
    return undefined;
  };

  useEffect(() => {
    getProfilePicturesAccessToken().then((accessToken) => {
      const emails = officeLibrariansData?.map((librarian) => librarian.email);
      emails?.forEach(async (email) => {
        if (email) {
          const url = await getProfilePictureUrl(email, accessToken);
          if (url) setProfilePictures((prev) => [...prev, [email, url]]);
        }
      });
    });
  }, [officeLibrariansData]);
  const librarianRows = useMemo(() => {
    if (!officeLibrariansData) return [];
    return officeLibrariansData?.map((el) => {
      return {
        id: el.id,
        lib_name: el.name,
        lib_role: el.role?.name,
        lib_email: el.email,
      };
    });
  }, [officeLibrariansData]);

  const handleClickOpen = (name: string, email: string, url?: string) => {
    setSelectedLibrarianData({ name, url, email });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'user',
      headerName: 'Name',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      hideable: false,
      width: 300,
      renderCell: (row) => {
        return (
          <Box sx={UserBoxSx}>
            <UserPicture userPictureUrl={getPictureUrl(row.row.lib_email)} userName={row.row.lib_name} />
            <Box sx={LibrarianNameSx}>{row.row.lib_name}</Box>
          </Box>
        );
      },
    },
    {
      field: 'role',
      headerName: 'Role',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      sortable: false,
      width: 250,
      renderCell: (row) => row.row.lib_role,
    },

    {
      field: 'email',
      headerName: 'Email',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      sortable: false,
      width: 300,
      renderCell: (row) => row.row.lib_email,
    },
    {
      field: 'contact',
      headerName: 'Contact',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 250,
      renderCell: (row) => (
        <Tooltip title="Send Message">
          <IconButton
            aria-label="edit"
            onClick={() => {
              handleClickOpen(row.row.lib_name, row.row.lib_email, getPictureUrl(row.row.lib_email));
            }}
          >
            <EmailOutlinedIcon sx={{ ...openEditIconSx, color: '#000' }} />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return loadingPage ? (
    <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
  ) : (
    <Box>
      <Typography variant="h5" gutterBottom>
        User settings
      </Typography>
      <Container>
        <Typography variant="h6" gutterBottom>
          Office {currentUser?.office.name}, {currentUser?.office.city}
        </Typography>
        <Accordion defaultExpanded={true} sx={AccordionSx}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panelSubscriptions-content"
            id="panelSubscriptions-header"
          >
            <Typography color="text.primary">Subscriptions</Typography>
          </AccordionSummary>
          <AccordionDetails id="panelSubscriptions-content">
            {subscriptions && (
              <List>
                {subscriptions.map((sub) => (
                  <SubsListItem key={sub.id} sub={sub} />
                ))}
              </List>
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded={true} sx={AccordionSx}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panelChangeOffice-content"
            id="panelChangeOffice-header"
          >
            <Typography color="text.primary">Change office</Typography>
          </AccordionSummary>
          <AccordionDetails id="panelChangeOffice-content">
            <Grid sx={{ pb: 4 }}>
              <Box
                autoComplete="off"
                component="form"
                onSubmit={handleSubmit}
                sx={{
                  '& .MuiTextField-root': { mb: 1, mt: 1, width: '100ch' },
                }}
              >
                <Grid container columns={{ xs: 12, md: 12 }}>
                  <Grid item xs={12}>
                    <Autocomplete
                      disableClearable
                      getOptionLabel={(option) => option.name + ', ' + option.city}
                      id="office_id"
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={handleOfficeChange}
                      options={offices ?? []}
                      value={selectedOffice}
                      renderOption={(props, o) => <AutocompleteLi key={o.id} option={o} {...props} />}
                      renderInput={(params) => (
                        <TextField
                          error={!!(touched.officeId && errors.officeId)}
                          helperText={touched.officeId && errors.officeId}
                          label="Office"
                          onBlur={handleBlur}
                          style={{ width: '100%' }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction="row" spacing={2}>
                      <LoadingButton
                        disabled={currentUser?.office.id === selectedOffice?.id}
                        loading={updateUserOfficeMutation.isLoading}
                        type="submit"
                        sx={currentUser?.office.id === selectedOffice?.id ? UpdateDisabledButtonSx : UpdateButtonSx}
                      >
                        Update
                      </LoadingButton>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded={true} sx={AccordionSx}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panelLibrarians-content"
            id="panelLibrarians-header"
          >
            <Typography>Librarians in your office</Typography>
          </AccordionSummary>
          <AccordionDetails id="panelLibrarians-content">
            {isLoadingLibrarians ? (
              <>
                <Skeleton variant="rounded" width="100%" height={50} animation="wave" sx={{ mt: 1 }} />
                {Array.from({ length: 10 }, (_, index) => (
                  <Skeleton key={index} variant="rounded" width="100%" height={40} animation="wave" sx={{ mt: 0.5 }} />
                ))}
              </>
            ) : officeLibrariansData && officeLibrariansData.length > 0 ? (
              <Box sx={LibrarianGridBox}>
                <DataGrid
                  disableVirtualization
                  columns={columns}
                  rows={librarianRows}
                  loading={isLoadingLibrarians}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  disableColumnSelector
                  onCellClick={() => {}}
                  autoHeight
                  disableColumnFilter
                  disableSelectionOnClick
                />
              </Box>
            ) : (
              <Typography>No Librarians</Typography>
            )}
          </AccordionDetails>
        </Accordion>
        <EmailDialog
          name={selectedLibrarianData.name}
          url={selectedLibrarianData.url}
          email={selectedLibrarianData.email}
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
        />
      </Container>
    </Box>
  );
};

export default UserSettings;
