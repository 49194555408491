import { Autocomplete, Box, Grid, Skeleton, TextField, Tooltip } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { AxiosResponse } from 'axios';
import moment, { Moment } from 'moment';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { api } from '../../../services/api';
import {
  ReportsAverageBorrowingPeriodGet200Response,
  ReportsFrequencyGet200Response,
  ReportsReadersGet200Response,
} from '../../../services/api/openapi';
import { addPaddingSx, reportsDataGridBoxSx } from '../styles';
import {
  BorrowingTypes,
  columnsAverageBorrowingPeriod,
  columnsAverageBorrowingPeriodKindle,
  columnsFrequency,
  columnsFrequencyKindle,
  columnsReaders,
  columnsReadersKindle,
  ReportTypes,
  TypesDescriptions,
} from './utils';

const ReportsTab: FC = () => {
  const [reportType, setReportType] = useState<string | null>(null);
  const [fromDate, setFromDate] = useState<Moment>(moment());
  const [toDate, setToDate] = useState<Moment>(moment());
  const [type, setType] = useState<string | null>(null);

  const { data: frequencyData, isLoading: frequencyIsLoading } = useQuery({
    queryKey: ['frequencyReport', fromDate, toDate, type],
    queryFn: () =>
      api.report.reportsFrequencyGet({
        params: {
          start_date: fromDate.format('YYYY-MM-DD'),
          end_date: toDate.format('YYYY-MM-DD'),
          type: type?.toLowerCase(),
        },
      }),
    select: useCallback((data: AxiosResponse<ReportsFrequencyGet200Response>) => {
      return data.data.data.map((entry, index) => ({ id: index, ...entry }));
    }, []),
    enabled: reportType === ReportTypes[0] && !!type,
  });

  const { data: averageBorrowingPeriodData, isLoading: averageBorrowingPeriodIsLoading } = useQuery({
    queryKey: ['averageBorrowingPeriodReport', fromDate, toDate, type],
    queryFn: () =>
      api.report.reportsAverageBorrowingPeriodGet({
        params: {
          start_date: fromDate.format('YYYY-MM-DD'),
          end_date: toDate.format('YYYY-MM-DD'),
          type: type?.toLowerCase(),
        },
      }),
    select: useCallback((data: AxiosResponse<ReportsAverageBorrowingPeriodGet200Response>) => {
      return data.data.data.map((entry, index) => ({ id: index, ...entry }));
    }, []),
    enabled: reportType === ReportTypes[1] && !!type,
  });

  const { data: readersData, isLoading: readersIsLoading } = useQuery({
    queryKey: ['readersReport', fromDate, toDate, type],
    queryFn: () =>
      api.report.reportsReadersGet({
        params: {
          start_date: fromDate.format('YYYY-MM-DD'),
          end_date: toDate.format('YYYY-MM-DD'),
          type: type?.toLowerCase(),
        },
      }),
    select: useCallback((data: AxiosResponse<ReportsReadersGet200Response>) => {
      return data.data.data.map((entry, index) => ({ id: index, ...entry }));
    }, []),
    enabled: reportType === ReportTypes[2] && !!type,
  });

  const reportRows = useMemo(() => {
    switch (reportType) {
      case ReportTypes[0]:
        return frequencyData;
      case ReportTypes[1]:
        return averageBorrowingPeriodData;
      case ReportTypes[2]:
        return readersData;
      default:
        return [];
    }
  }, [reportType, frequencyData, averageBorrowingPeriodData, readersData]);

  const fileName = useMemo(() => {
    const from = fromDate.format('DD/MM/YYYY');
    const to = toDate.format('DD/MM/YYYY');
    return reportType ? `${reportType} ${from} - ${to}` : `${from} - ${to}`;
  }, [reportType, fromDate, toDate]);

  const CustomToolbar: FC = () => (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{ fileName, allColumns: true, getRowsToExport: () => reportRows?.map((r) => r.id) }}
        printOptions={{ disableToolbarButton: true }}
      />
    </GridToolbarContainer>
  );

  const reportColumns = useMemo(() => {
    switch (reportType) {
      case ReportTypes[0]:
        return type === 'kindle' ? columnsFrequencyKindle : columnsFrequency;
      case ReportTypes[1]:
        return type === 'kindle' ? columnsAverageBorrowingPeriodKindle : columnsAverageBorrowingPeriod;
      case ReportTypes[2]:
        return type === 'kindle' ? columnsReadersKindle : columnsReaders;
      default:
        return [];
    }
  }, [reportType, type]);

  const isValidReportData = useMemo(() => {
    return !!(
      reportType &&
      ReportTypes.includes(reportType) &&
      toDate.diff(fromDate, 'days') <= 364 &&
      fromDate <= toDate &&
      fromDate <= moment() &&
      toDate <= moment()
    );
  }, [reportType, fromDate, toDate]);

  return (
    <Box>
      <Grid container columns={{ xs: 12, md: 12 }}>
        <Grid sx={addPaddingSx} item xs={3}>
          <Autocomplete
            id="report_type"
            value={reportType}
            onChange={(event, value) => setReportType(value)}
            options={ReportTypes}
            renderInput={(params) => <TextField {...params} variant="outlined" label="Report type" />}
            renderOption={(props, option) => (
              <Tooltip title={TypesDescriptions.get(option)} placement="right" key={option}>
                <li {...props}>{option}</li>
              </Tooltip>
            )}
          />
        </Grid>

        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Grid sx={addPaddingSx} item xs={3}>
            <DatePicker
              inputFormat="DD/MM/YYYY"
              label="From"
              minDate={moment('2023-01-01')}
              maxDate={moment()}
              onChange={(newValue) => newValue && setFromDate(newValue)}
              value={fromDate}
              renderInput={(params) => <TextField {...params} variant="outlined" fullWidth id="from_date" />}
            />
          </Grid>

          <Grid sx={addPaddingSx} item xs={3}>
            <DatePicker
              inputFormat="DD/MM/YYYY"
              label="To"
              minDate={moment(fromDate)}
              maxDate={
                moment().isAfter(moment(fromDate).add(364, 'days')) ? moment(fromDate).add(364, 'days') : moment()
              }
              onChange={(newValue) => newValue && setToDate(newValue)}
              value={toDate}
              renderInput={(params) => <TextField {...params} variant="outlined" fullWidth id="to_date" />}
            />
          </Grid>

          <Grid sx={addPaddingSx} item xs={3}>
            <Autocomplete
              id="type"
              value={type ? type.charAt(0).toUpperCase() + type.slice(1) : null}
              onChange={(event, value) => setType(value ? value.toLowerCase() : null)}
              options={BorrowingTypes}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Type" />}
            />
          </Grid>
        </LocalizationProvider>
      </Grid>

      {frequencyIsLoading || averageBorrowingPeriodIsLoading || readersIsLoading ? (
        <>
          <Skeleton variant="rounded" width="100%" height={65} animation="wave" sx={{ mt: 1 }} />
          {Array.from({ length: 4 }, (_, index) => (
            <Skeleton key={index} variant="rounded" width="100%" height={100} animation="wave" sx={{ mt: 0.5 }} />
          ))}
        </>
      ) : (
        <Box sx={reportsDataGridBoxSx}>
          {isValidReportData && (
            <DataGrid
              disableVirtualization
              components={{ Toolbar: CustomToolbar }}
              getRowId={(row) => row.id}
              headerHeight={40}
              rowHeight={100}
              rows={reportRows ?? []}
              columns={reportColumns ?? []}
              pageSize={10}
              rowsPerPageOptions={[10]}
              disableColumnSelector
              autoHeight
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default ReportsTab;
