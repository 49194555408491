import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import React, { useContext, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import FeedbackContext from '../../../contexts/FeedbackContext';
import { api } from '../../../services/api';
import { handleErrorMsg } from '../../../utils';
import {
  CancelButtonSx,
  FeedBackDialogActionsSx,
  FeedBackDialogBoxSx,
  FeedBackDialogContentSx,
  FeedBackDialogIconSx,
  FeedBackDialogTextFieldSx,
  FeedBackDialogTitleSx,
  SubmitButtonSx,
} from './styles';

const FeedbackDialog = () => {
  const [open, setOpen] = useState<boolean>(false);
  const { setFeedback } = useContext(FeedbackContext);
  const [feedbackText, setFeedbackText] = useState<string>('');
  const queryClient = useQueryClient();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFeedbackChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFeedbackText(event.target.value);
  };

  const sendFeedbackMutation = useMutation(
    (feedback: string) =>
      api.feedback.feedbacksPost({
        feedbacksGetRequest: {
          message: feedback,
        },
      }),
    {
      onSuccess: () => {
        setFeedback({
          status: 'success',
          message: 'Thank you for your feedback!',
        });
        queryClient.invalidateQueries('feedback');
      },
      onError: (e) => handleErrorMsg(e, setFeedback),
    },
  );

  const handleSubmit = async () => {
    if (feedbackText.trim() !== '') {
      sendFeedbackMutation.mutateAsync(feedbackText);

      setFeedbackText('');
      setOpen(false);
    } else {
      setFeedback({
        status: 'error',
        message: 'Feedback cannot be empty',
      });
    }
  };

  return (
    <React.Fragment>
      <Box sx={FeedBackDialogBoxSx}>
        <Tooltip title="Give feedback">
          <IconButton disableFocusRipple disableRipple onClick={handleClickOpen}>
            <FeedbackOutlinedIcon sx={FeedBackDialogIconSx} />
          </IconButton>
        </Tooltip>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={FeedBackDialogTitleSx}>Help us improve</DialogTitle>
        <DialogContent>
          <DialogContentText sx={FeedBackDialogContentSx}>
            Leave your feedback and suggestions regarding the application here!
          </DialogContentText>
          <TextField
            sx={FeedBackDialogTextFieldSx}
            placeholder="Enter your feedback here..."
            multiline
            rows={5}
            value={feedbackText}
            inputProps={{ maxLength: 5000 }}
            onChange={handleFeedbackChange}
            spellCheck
          />

          <DialogActions sx={FeedBackDialogActionsSx}>
            <Button sx={CancelButtonSx} onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton
              sx={SubmitButtonSx}
              onClick={handleSubmit}
              loading={sendFeedbackMutation.isLoading}
              type="submit"
            >
              Submit
            </LoadingButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default FeedbackDialog;
