import { SxProps } from '@mui/material';

export const manageKindlesTitleSx: SxProps = {
  fontSize: '20px',
  fontWeight: 400,
};

export const createBoxSx: SxProps = {
  height: '65px',
  marginTop: '25px',
};

export const invNumberTextFieldSx: SxProps = {
  width: '400px',
  marginRight: '15px',
};

export const imageSx: SxProps = {
  width: '50px',
  height: '50px',
};

export const statusTextSx: SxProps = {
  fontWeight: 500,
  // textTransform: 'capitalize',
};

export const dateTextSx: SxProps = {
  fontStyle: 'italic',
};

export const actionIconSx: SxProps = {
  height: '30px',
  width: '30px',
  color: '#000',
};

export const buttonSx: SxProps = {
  height: '40px',
  width: '170px',
  color: '#FFF',
  background: 'linear-gradient(180deg, #EC147A 0%, #EC147A 100%)',
  whiteSpace: 'normal',
  transition: 'background 0.3s ease-in-out',
  '&:hover': {
    background: 'linear-gradient(180deg, rgb(215 14 109) 0%, rgb(215 14 109) 100%)',
  },
};

export const buttonDisabledSx: SxProps = {
  height: '40px',
  width: '170px',
  color: '#FFF',
  background: 'lightgrey',
  whiteSpace: 'normal',
};

export const loadingBoxSx: SxProps = {
  width: '100%',
};

export const dataGridBoxSx: SxProps = {
  width: '100%',
  marginTop: 1,
};
