import { Delete } from '@mui/icons-material';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React, { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import feedbackContext from '../../../../contexts/FeedbackContext';
import { DeleteItem } from '../../../../dialogs/confirmationDialog';
import { api } from '../../../../services/api';
import { handleErrorMsg } from '../../../../utils';
import UpdateAuthor from '../Update';

interface IActionsColumnProps {
  values: GridRenderCellParams<any, any, any>;
}

const ActionsColumn = ({ values }: IActionsColumnProps) => {
  const queryClient = useQueryClient();
  const { setFeedback } = useContext(feedbackContext);

  const deleteMutation = useMutation(() => api.author.authorsIdDelete({ id: values.row.id }), {
    onError: (e) => handleErrorMsg(e, setFeedback),
    onSuccess: () => queryClient.invalidateQueries(['authors']),
  });

  const handleDelete = () => DeleteItem(() => deleteMutation.mutate());

  return (
    <Stack direction="row">
      <UpdateAuthor author={{ id: Number(values.row.id), name: values.row.name }} />
      <Tooltip title="Delete">
        <IconButton data-testid="delete-button" aria-label="delete" color="primary" onClick={() => handleDelete()}>
          <Delete sx={{ color: '#000' }} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

const gridColumns: GridColDef[] = [
  {
    field: 'name',
    headerAlign: 'center',
    align: 'center',
    headerName: 'Author',
    hideable: false,
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'Actions',
    headerAlign: 'center',
    align: 'center',
    disableColumnMenu: true,
    filterable: false,
    hideable: false,
    sortable: false,
    width: 120,
    renderCell: (values) => <ActionsColumn values={values} />,
  },
];

export default gridColumns;
