import { useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { RemoveFromWishlistDialog } from '../../../dialogs/confirmationDialog';
import { api, IBook, IBookEdition } from '../../../services/api';
import BookCard from '../../BorrowedBooks/BookCard';
import { CardType } from '../../BorrowedBooks/types';

interface IGroupedWishlist {
  book: IBook;
  bookEditions: IBookEdition[];
  bookEditionType: string;
}

interface IWishlistItemProps {
  item: IGroupedWishlist;
}

export const WishlistItem = ({ item }: IWishlistItemProps) => {
  const queryClient = useQueryClient();

  const removeMutation = useMutation(
    (bookEditionIds: number[]) =>
      api.wishlist.wishlistsDeleteMultipleDelete({
        bookEditionIds,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries('wishlist'),
    },
  );
  const handleOpen = () => {
    RemoveFromWishlistDialog(
      `${item.book.title}`,
      () => removeMutation.mutate(item.bookEditions.map((e) => e.id)),
      item.book.cover,
    );
  };

  const numberOfPages = useMemo(() => {
    const paperEdition = item.book.details.find((be) => be.type === 'paper');
    if (paperEdition) return paperEdition.number_of_pages;

    const kindleEdition = item.book.details.find((be) => be.type === 'kindle');
    if (kindleEdition) return kindleEdition.number_of_pages;

    const ebookEdition = item.book.details.find((be) => be.type === 'e-book');
    if (ebookEdition) return ebookEdition.number_of_pages;

    return undefined;
  }, [item.book.details]);

  return (
    <BookCard
      book={item.book}
      numberOfPages={numberOfPages}
      handleHeartClick={handleOpen}
      cardType={CardType.WISHLIST}
    />
  );
};

export default WishlistItem;
