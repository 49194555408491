import './styles.css';

import { Box, CircularProgress, Container, Typography } from '@mui/material';
import { AxiosResponse } from 'axios';
import React, { FC, useCallback, useContext } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import CurrentUserContext from '../../../contexts/CurrentUserContext';
import { api } from '../../../services/api';
import { BookRegisterGet200Response, KindleRegisterGet200Response } from '../../../services/api/openapi';
import { circularProgressSx } from '../styles/styles';
import { titleSx } from './styles';

const BorrowedBooks: FC = () => {
  const { currentUser } = useContext(CurrentUserContext);

  const { data: booksRegister, isLoading: isBookRegisterLoading } = useQuery({
    queryKey: 'booksRegister',
    queryFn: () =>
      api.bookRegister.bookRegisterGet({
        params: { user_id: currentUser?.id, active: true },
      }),
    select: useCallback(
      (data: AxiosResponse<BookRegisterGet200Response>) => {
        return data.data.data;
      },
      [currentUser],
    ),
  });

  const { data: kindleRegister } = useQuery({
    queryKey: 'kindleRegister',
    queryFn: () =>
      api.kindleRegister.kindleRegisterGet({
        params: { user_id: currentUser?.id, active: true },
      }),
    select: useCallback(
      (data: AxiosResponse<KindleRegisterGet200Response>) => {
        return data.data.data;
      },
      [currentUser],
    ),
  });

  return (
    <React.Fragment>
      {booksRegister && !isBookRegisterLoading ? (
        booksRegister?.length > 0 && kindleRegister?.length === 0 ? (
          <Box>
            <Typography sx={titleSx}>
              You have{' '}
              <Link to="/books/borrowed" className="link">
                {booksRegister.length} {booksRegister.length === 1 ? 'borrowed book.' : 'borrowed books.'}
              </Link>
            </Typography>
          </Box>
        ) : booksRegister && booksRegister?.length > 0 && kindleRegister?.length ? (
          <Box>
            <Typography sx={titleSx}>
              You have{' '}
              <Link to="/books/borrowed" className="link">
                {booksRegister.length} {booksRegister.length === 1 ? 'borrowed book.' : 'borrowed books.'}
              </Link>
            </Typography>
            <Typography sx={{ ...titleSx, mt: '5px' }}>
              You have{' '}
              <Link to="/books/borrowed" className="link">
                {kindleRegister?.length} {kindleRegister?.length === 1 ? 'borrowed kindle.' : 'borrowed kindles.'}
              </Link>
            </Typography>
          </Box>
        ) : booksRegister?.length === 0 && kindleRegister?.length ? (
          <Box>
            <Typography sx={titleSx}>
              You have{' '}
              <Link to="/books/borrowed" className="link">
                {kindleRegister?.length} {kindleRegister?.length === 1 ? 'borrowed kindle.' : 'borrowed kindles.'}
              </Link>
            </Typography>
          </Box>
        ) : (
          <Box>
            <Typography sx={titleSx}>You don&apos;t have any borrowed books or kindles!</Typography>
          </Box>
        )
      ) : (
        <Container>
          <CircularProgress sx={circularProgressSx} />
        </Container>
      )}
    </React.Fragment>
  );
};

export default BorrowedBooks;
