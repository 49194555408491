import { Box, Stack, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { FC, useMemo } from 'react';

import BookCover from '../../../shared/BookCover';
import FlagComponent from './FlagComponent/FlagComponent';
import {
  descriptionBoxSx,
  descriptionSx,
  descriptionTitleSx,
  grayTrapezoidSx,
  readingNowAuthorsBoxSx,
  readingNowAuthorsLabelSx,
  readingNowAuthorsSx,
  readingNowCategoryChipSx,
  readingNowCoverBoxSx,
  readingNowFlagBoxSx,
  readingNowNumOfPagesBoxSx,
  readingNowNumOfPagesLabelSx,
  readingNowNumOfPagesSx,
  readingNowPublishedYearBoxSx,
  readingNowPublishedYearLabelSx,
  readingNowPublishedYearSx,
  readingNowReaderSx,
  readingNowTagChipSx,
  readingNowTitleSx,
  readingNowTrapezoidSx,
  readingNowTypeChipSx,
} from './styles';

interface BookProps {
  cover?: string;
  userPictureUrl?: string;
  userName?: string;
  title?: string;
  authors?: string;
  return_at?: string;
  return_confirmed_at?: string;
  bookId?: number;
  publishedYear?: number;
  numberOfPages?: number;
  category?: string;
  tag?: string;
  type?: string;
  isAvailable?: boolean;
  description?: string;
  isFirst?: boolean;
  isLast?: boolean;
}

const ReadingNowBook: FC<BookProps> = ({
  cover,
  userPictureUrl,
  userName,
  title,
  authors,
  return_at,
  return_confirmed_at,
  bookId,
  publishedYear,
  numberOfPages,
  category,
  tag,
  type,
  isAvailable,
  description,
  isFirst,
  isLast,
}) => {
  const getRandomColor = useMemo(() => {
    const hue = Math.floor(Math.random() * 360); // Random hue between 0 and 360
    const saturation = Math.floor(Math.random() * 50) + 50; // Random saturation between 50 and 100
    const lightness = Math.floor(Math.random() * 30) + 35; // Random lightness between 35 and 65

    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  }, []);

  return (
    <Grid container>
      <Grid
        wrap="nowrap"
        container
        item
        xs={6}
        sx={{
          ...readingNowTrapezoidSx,
          borderTopLeftRadius: isFirst ? '7px' : undefined,
          borderBottomLeftRadius: isLast ? '7px' : undefined,
        }}
      >
        <Box sx={readingNowCoverBoxSx}>
          <BookCover
            bookId={bookId}
            title={title}
            cover={cover}
            isAvailable={isAvailable!}
            width="118px"
            height="177px"
            small
            alignLeft
          />
          <Box sx={readingNowFlagBoxSx}>
            <FlagComponent
              userPictureUrl={userPictureUrl}
              userName={userName}
              status={return_at === null || return_confirmed_at === null ? 'reading' : 'read'}
              flagBgColor={return_at === null || return_confirmed_at === null ? '#041C32' : '#320404'}
              flagBoxHeight={'191px'}
              bottomFlagBoxTop={'38.5px'}
              circleBoxBackground={getRandomColor}
            />
          </Box>
        </Box>
        <Box>
          <Stack sx={{ height: '100%', justifyContent: 'space-between' }}>
            <Box>
              <Box component="span" sx={readingNowReaderSx}>{`${userName?.split(' ')[0]} `}</Box>
              <Box component="span" sx={{ ...readingNowReaderSx, fontStyle: 'italic', fontWeight: '300' }}>
                {return_at === null || return_confirmed_at === null ? 'is ' : 'has '}
              </Box>
              <Box component="span" sx={{ ...readingNowReaderSx, fontStyle: 'italic' }}>
                {return_at === null || return_confirmed_at === null ? 'reading' : 'read'}
              </Box>

              <Tooltip title={title} placement="bottom-start">
                <Box sx={readingNowTitleSx}>{title}</Box>
              </Tooltip>

              <Box sx={readingNowAuthorsBoxSx}>
                <Box component="span" sx={readingNowAuthorsLabelSx}>
                  Authors:{' '}
                </Box>
                <Box component="span" sx={readingNowAuthorsSx}>
                  {authors}
                </Box>
              </Box>

              <Box sx={readingNowPublishedYearBoxSx}>
                <Box component="span" sx={readingNowPublishedYearLabelSx}>
                  Published year:{' '}
                </Box>
                <Box component="span" sx={readingNowPublishedYearSx}>
                  {publishedYear}
                </Box>
              </Box>

              {numberOfPages && (
                <Box sx={readingNowNumOfPagesBoxSx}>
                  <Box component="span" sx={readingNowNumOfPagesLabelSx}>
                    Number of pages:{' '}
                  </Box>
                  <Box component="span" sx={readingNowNumOfPagesSx}>
                    {numberOfPages}
                  </Box>
                </Box>
              )}
            </Box>

            <Grid container direction="row">
              <Grid item sx={readingNowCategoryChipSx}>
                {category}
              </Grid>
              <Grid item sx={readingNowTagChipSx}>
                {tag}
              </Grid>
              <Grid item sx={readingNowTypeChipSx}>
                {type}
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={1} sx={grayTrapezoidSx} />
      <Grid item xs={5} sx={descriptionBoxSx}>
        <Box sx={descriptionTitleSx}>Description</Box>
        <Box sx={descriptionSx}>{description && description}</Box>
      </Grid>
    </Grid>
  );
};

export default ReadingNowBook;
