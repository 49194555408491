import { FC } from 'react';
import styled from 'styled-components';

const BookContainer = styled.div`
  width: 100%;
  max-height: 6em;
  display: flex;
  border-bottom: 2px solid #d8d8d852;
  padding: 6px 0;
  align-items: center;
`;

const Thumbnail = styled.div`
  width: auto;
  height: 100%;
  display: flex;
  flex: 0.5;
  img {
    //width: auto;
    width: 100%;
    height: 100%;
    filter: contrast(110%);
    boxshadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
    objectfit: 'cover';
  }
`;

const Name = styled.h3`
  font-size: 12px;
  color: #000;
  margin-left: 10px;
  flex: 2;
  display: flex;
  line-height: 1.2;
`;

interface Props {
  cover: string;
  title: string;

  onClick: () => void;
  forwardRef?: React.Ref<HTMLDivElement>;
}
const Book: FC<Props> = ({ cover, title, onClick, forwardRef }) => {
  return (
    <BookContainer ref={forwardRef} onClick={onClick} style={{ cursor: 'pointer' }}>
      <Thumbnail>
        <img src={cover} alt={title} />
      </Thumbnail>
      <Name>{title}</Name>
    </BookContainer>
  );
};

export default Book;
