import { ModeEdit } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import React, { FC, useState } from 'react';

import { EditDialogTitleSx } from '../../styles';
import UpdateTagForm from './form';

interface Props {
  tag: { id: number; description: string };
}

const UpdateTag: FC<Props> = ({ tag }) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenEdit = () => {
    setOpen(true);
  };

  const handleCloseEdit = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Tooltip title="Edit">
        <IconButton data-testid="edit-button" onClick={handleOpenEdit} aria-label="update" color="primary">
          <ModeEdit sx={{ color: '#000' }} />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleCloseEdit}>
        <DialogTitle sx={EditDialogTitleSx} textAlign="center">
          Edit tag
        </DialogTitle>
        <DialogContent>{tag && <UpdateTagForm tag={tag} handleCloseEdit={handleCloseEdit} />}</DialogContent>
      </Dialog>
    </Box>
  );
};

export default UpdateTag;
