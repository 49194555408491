import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import React, { FC } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import largeLogo from '../../assets/images/logo.png';
import AccountMenu from './AccountMenu';
import AppBar from './AppBar';
import Drawer from './Drawer';
import Feedback from './Feedback';
import Footer from './Footer';
import SearchBar from './SearchBar';
import ListRouter from './SideNavigation';
import { AppTitleHideSx, AppTitleSx, DrawerSx, IconButtonSx, MainLayoutSx, ToolbarSx } from './styles';

const MainLayout: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Box sx={MainLayoutSx}>
      <Box sx={{ display: 'flex' }}>
        <AppBar position="fixed" open={false} sx={{ height: '60px' }}>
          <Toolbar sx={ToolbarSx}>
            <IconButton onClick={() => navigate('/')} sx={IconButtonSx}>
              <img src={largeLogo} style={{ height: '70%' }} alt="" />
            </IconButton>
            <Box sx={{ ...AppTitleSx, flexGrow: 1, paddingRight: 1 }}>
              <Box
                sx={{
                  fontFamily: 'A-Symbol-Underlined',
                  display: 'inline',
                }}
              >
                A
              </Box>
              <Box sx={AppTitleHideSx}>mdaris library</Box>
            </Box>
            <Feedback />
            <SearchBar />
            <AccountMenu />
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          open={false}
          PaperProps={{
            sx: DrawerSx,
          }}
        >
          <ListRouter />
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}
        >
          <Toolbar />
          <Container
            sx={{ mt: 4, mb: 4 }}
            maxWidth={location.pathname === '/' ? false : 'xl'}
            disableGutters={location.pathname === '/'}
          >
            <Outlet />
          </Container>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
