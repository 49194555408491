import { SxProps } from '@mui/material';

import { libraryTheme } from '../../../LibraryTheme';

export const progressBoxSx: SxProps = { width: '100%' };

export const paperSx: SxProps = {
  minWidth: '800px',
  border: '1px solid #E0E0E0',
  borderRadius: '10px',
  flexGrow: 1,
  padding: '50px 25px 50px 50px',
  backgroundColor: '#fff',
};

export const infoBoxSx: SxProps = {
  paddingTop: '10px',
  paddingLeft: '10px',
  paddingBottom: '10px',
};

export const infoStackSx: SxProps = {
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
};

export const titleSx: SxProps = {
  color: '#000',
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: 'normal',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: '3',
  WebkitBoxOrient: 'vertical',
};

export const wishlistButtonSx: SxProps = {
  minWidth: '25px',
  minHeight: '25px',
  maxWidth: '25px',
  maxHeight: '25px',
};

export const authorsBoxSx: SxProps = {
  mt: '15px',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
};

const authorsTextSx: SxProps = {
  color: '#000',
  fontSize: '15px',
  lineHeight: 'normal',
};

export const authorsSx: SxProps = {
  ...authorsTextSx,
  fontWeight: 500,
  mr: 1,
};

export const authorsListSx: SxProps = {
  ...authorsTextSx,
  fontWeight: 300,
};

const commonTextSx: SxProps = {
  color: '#000',
  fontSize: '14px',
  lineHeight: 'normal',
  fontStyle: 'italic',
};

export const boldTextSx: SxProps = {
  ...commonTextSx,
  fontWeight: 500,
  mr: 1,
};

export const italicTextSx: SxProps = {
  ...commonTextSx,
  fontWeight: 300,
};

export const publishedYearBoxSx: SxProps = {
  display: 'flex',
  alignItems: 'center',
  mt: '25px',
};

export const numberOfPagesBoxSx: SxProps = {
  display: 'flex',
  alignItems: 'center',
  mt: '15px',
};

export const chipsContainerSx: SxProps = {
  display: 'flex',
  alignItems: 'center',
  paddingTop: '20px',
};

const chipSx: SxProps = {
  height: '30px',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  borderRadius: '40px',
  padding: '7px 25px',
  marginLeft: '15px',
  textTransform: 'capitalize',
  color: 'white',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};

export const categoryChipSx: SxProps = {
  ...chipSx,
  backgroundColor: libraryTheme.palette.fourth.main,
};

export const tagChipSx: SxProps = {
  ...chipSx,
  backgroundColor: libraryTheme.palette.primary.main,
};

export const typeChipSx: SxProps = {
  ...chipSx,
  backgroundColor: libraryTheme.palette.third.main,
};

export const descriptionBoxSx: SxProps = {
  paddingTop: '50px',
};

export const descriptionTitleSx: SxProps = {
  color: '#000000',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: 'normal',
};

export const descriptionSx: SxProps = {
  color: '#000000',
  fontSize: '14px',
  fontWeight: 300,
  lineHeight: 'normal',
  paddingTop: '25px',
};

export const copiesBoxSx: SxProps = {
  marginTop: '50px',
};

export const copiesBoxItemSx: SxProps = {
  minWidth: '190px',
};

export const actionButtonSx: SxProps = {
  height: '40px',
  width: '185px',
  marginTop: '15px',
  padding: '12px 5px',
  fontSize: '14px',
  fontWeight: 500,
  color: '#FFF',
  textTransform: 'uppercase',
  background: 'linear-gradient(180deg, #EC147A 0%, #EC147A 100%)',
  transition: 'background 0.3s ease-in-out',
  '&:hover': {
    background: 'linear-gradient(180deg, rgb(215 14 109) 0%, rgb(215 14 109) 100%)',
  },
};

export const actionButtonDisabledSx: SxProps = {
  height: '40px',
  width: '185px',
  marginTop: '15px',
  padding: '12px 5px',
  fontSize: '14px',
  fontWeight: 500,
  textTransform: 'uppercase',
  background: 'lightgrey',
};

export const availableTextSx: SxProps = {
  color: '#000',
  marginTop: '10px',
  marginBottom: '10px',
  fontSize: '13px',
  fontStyle: 'italic',
  fontWeight: 400,
  lineHeight: 'normal',
};

export const copiesTextSx: SxProps = {
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: 'normal',
};

export const librarianActionsBoxSx: SxProps = {
  display: 'flex',
  alignItems: 'flex-end',
};
