import { ModeEdit } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Stack, TextField, Tooltip } from '@mui/material';
import { useFormik } from 'formik';
import React, { FC, useContext, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import * as Yup from 'yup';

import feedbackContext from '../../../contexts/FeedbackContext';
import { api } from '../../../services/api';
import { handleErrorMsg } from '../../../utils';
import { actionIconSx } from '../Index/styles';
import {
  updateKindleSubmitBtnSx,
  updateKindleSubmitDisabledBtnSx,
  updateKindleTextFieldSx,
  updateKindleTitleSx,
} from './styles';

interface Props {
  kindle: {
    id: string | number;
    inventory_num: string;
  };
}

const UpdateKindle: FC<Props> = ({ kindle }) => {
  const { setFeedback } = useContext(feedbackContext);
  const queryClient = useQueryClient();
  const [open, setOpen] = useState<boolean>(false);

  const schema = Yup.object({
    inventory_num: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .max(20, 'Kindle inventory number should be less then 20 chars')
      .required('Inventory number is required'),
  });

  const updateKindle = useMutation(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (kindle: { id: number; inventory_num: number }) =>
      api.kindle.kindlesIdPatch({
        id: kindle.id,
        kindlesGetRequest: { inventory_num: kindle.inventory_num },
      }),
    {
      onSuccess: () => {
        setFeedback({
          status: 'success',
          message: 'The Kindle was successfully edited',
        });
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        handleCloseEdit();
        return queryClient.invalidateQueries('kindles');
      },
      onError: (error) => {
        handleErrorMsg(error, setFeedback);
      },
    },
  );

  const { resetForm, handleSubmit, handleChange, handleBlur, touched, values, errors, isValid } = useFormik({
    initialValues: {
      id: Number(kindle.id) ?? 0,
      inventory_num: kindle.inventory_num ?? '',
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: () => {
      const kindleFormData = {
        id: values.id,
        inventory_num: Number(values.inventory_num),
      };
      updateKindle.mutate(kindleFormData);
    },
  });

  const handleOpenEdit = () => {
    setOpen(true);
  };

  const handleCloseEdit = () => {
    resetForm();
    setOpen(false);
  };

  return (
    <Box>
      <Tooltip title="Edit">
        <IconButton data-testid="edit-kindle-btn" onClick={handleOpenEdit} aria-label="update" color="primary">
          <ModeEdit sx={actionIconSx} />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleCloseEdit}>
        <DialogTitle sx={updateKindleTitleSx} textAlign="center">
          Edit Kindle
        </DialogTitle>
        <DialogContent>
          <Box
            autoComplete="off"
            component="form"
            onSubmit={handleSubmit}
            sx={{ '& .MuiTextField-root': { mb: 1, mt: 1 } }}
          >
            <TextField
              sx={updateKindleTextFieldSx}
              error={touched.inventory_num && Boolean(errors.inventory_num)}
              id="inventory_num"
              label="Inventory number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.inventory_num}
              helperText={touched.inventory_num && errors.inventory_num}
            />

            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <LoadingButton
                sx={
                  kindle.inventory_num === values.inventory_num || !isValid
                    ? updateKindleSubmitDisabledBtnSx
                    : updateKindleSubmitBtnSx
                }
                loading={updateKindle.isLoading}
                disabled={kindle.inventory_num === values.inventory_num || !isValid}
                type="submit"
              >
                Submit
              </LoadingButton>
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default UpdateKindle;
