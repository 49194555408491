import { SxProps } from '@mui/material';

import { libraryTheme } from '../../LibraryTheme';

export const itemSx: SxProps = {
  marginBottom: '15px',
};

export const cardBoxSx: SxProps = {
  border: '1px solid #E0E0E0',
  borderRadius: '10px',
  padding: '25px',
};

export const bookImageSx: SxProps = {
  width: '182px',
  height: '270px',
  borderRadius: '3px',
  cursor: 'pointer',
  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.3)',
};

export const kindleImageSx: SxProps = {
  width: '124px',
  height: '124px',
};

export const borrowedBooksTitleSx: SxProps = {
  fontSize: '18px',
  fontWeight: 500,
  marginBottom: '25px',
};

export const borrowedKindlesTitleSx: SxProps = {
  fontSize: '18px',
  fontWeight: 500,
  marginTop: '50px',
  marginBottom: '25px',
};

export const infoBoxSx: SxProps = {
  paddingTop: '5px',
  paddingLeft: '15px',
  paddingBottom: '5px',
};

export const infoStackSx: SxProps = {
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
};

export const titleSx: SxProps = {
  color: '#000',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: 'normal',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: '3',
  WebkitBoxOrient: 'vertical',
};

export const authorsBoxSx: SxProps = {
  marginTop: '10px',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
};

const authorsTextSx: SxProps = {
  color: '#000',
  fontSize: '12px',
  lineHeight: 'normal',
};

export const authorsSx: SxProps = {
  ...authorsTextSx,
  fontWeight: 500,
  marginRight: 1,
};

export const authorsListSx: SxProps = {
  ...authorsTextSx,
  fontWeight: 300,
};

const commonTextSx: SxProps = {
  color: '#000',
  fontSize: '11px',
  lineHeight: 'normal',
  fontStyle: 'italic',
};

export const boldTextSx: SxProps = {
  ...commonTextSx,
  fontWeight: 500,
  marginRight: 1,
};

export const italicTextSx: SxProps = {
  ...commonTextSx,
  fontWeight: 300,
};

export const publishedYearBoxSx: SxProps = {
  display: 'flex',
  alignItems: 'center',
  marginTop: '10px',
};

export const numberOfPagesBoxSx: SxProps = {
  display: 'flex',
  alignItems: 'center',
  marginTop: '10px',
};

export const chipsContainerSx: SxProps = {
  display: 'flex',
  alignItems: 'center',
  paddingTop: '10px',
};

const chipSx: SxProps = {
  height: '30px',
  fontSize: '11px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  borderRadius: '40px',
  padding: '8px 25px',
  marginLeft: '15px',
  textTransform: 'capitalize',
  color: 'white',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};

export const categoryChipSx: SxProps = {
  ...chipSx,
  backgroundColor: libraryTheme.palette.fourth.main,
};

export const tagChipSx: SxProps = {
  ...chipSx,
  backgroundColor: libraryTheme.palette.primary.main,
};

export const typeChipSx: SxProps = {
  ...chipSx,
  backgroundColor: libraryTheme.palette.third.main,
};

export const pendingConfirmationTextSx: SxProps = {
  fontSize: '11px',
  fontWeight: 500,
  fontStyle: 'italic',
  textAlign: 'center',
  marginBottom: '10px',
};

export const returnButtonSx: SxProps = {
  background: 'linear-gradient(180deg, #EC147A 0%, #EC147A 100%)',
  height: '40px',
  width: '170px',
  fontSize: '14px',
  color: '#FFF',
  fontWeight: 700,
  padding: '13px 58px',
  marginBottom: '10px',
  transition: 'background 0.3s ease-in-out',
  '&:hover': {
    background: 'linear-gradient(180deg, rgb(215 14 109) 0%, rgb(215 14 109) 100%)',
  },
};

export const returnButtonDisabledSx: SxProps = {
  background: 'lightgrey',
  height: '40px',
  width: '170px',
  fontSize: '14px',
  color: '#FFF',
  fontWeight: 700,
  padding: '13px 58px',
  marginBottom: '10px',
};

export const returnBeforeDateSx: SxProps = {
  fontSize: '11px',
  fontWeight: 500,
  fontStyle: 'italic',
  textAlign: 'center',
  marginBottom: '10px',
};

export const daysLeftSx: SxProps = {
  fontSize: '12px',
  fontWeight: 500,
  textAlign: 'center',
};

export const rulesSx: SxProps = {
  color: '#2d2d2d',
  fontSize: '12px',
  fontWeight: 400,
  fontStyle: 'italic',
};

export const noBorrowedBoxSx: SxProps = {
  height: '200px',
  lineHeight: '200px',
  border: '1px solid #E0E0E0',
  borderRadius: '10px',
  textAlign: 'center',
};

export const availableCopiesMainBoxSx: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
};
export const paperCopiesContentBoxSx: SxProps = {
  display: 'flex',
  alignItems: 'center',
};

export const kindleEditionsContentBoxSx: SxProps = {
  display: 'flex',
  alignItems: 'center',
  marginTop: '10px',
};

export const availableCopiesTitleSx: SxProps = {
  color: '#000',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'italic',
  fontWeight: 500,
  lineHeight: 'normal',
  mr: '6px',
};

export const paperCopiesContentSx: SxProps = {
  color: '#F00',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'italic',
  fontWeight: 500,
  lineHeight: 'normal',
};

export const kindleEditionsContentSx: SxProps = {
  color: '#CB7A00',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'italic',
  fontWeight: 500,
  lineHeight: 'normal',
};
