import './assets/styles/DataGrid.css';
import './assets/styles/SideMenu.css';

import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Box, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React, { FC, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import { MainLayout } from './components';
import Feedback from './components/Feedback';
import PrivateRoute from './components/PrivateRoute';
import CurrentUserContext from './contexts/CurrentUserContext';
import FeedbackContext, { IFeedback } from './contexts/FeedbackContext';
import { useSubscriptionsCheck } from './hooks/useSubscriptionsCheck';
import { libraryTheme } from './LibraryTheme';
import {
  AdminPage,
  BorrowedBooksPage,
  CreateBookPage,
  DashboardPage,
  ErrorPage,
  KindlesPage,
  LoginPage,
  NotFoundPage,
  OfficePage,
  ShowBookPage,
  UserSettingsPage,
  WishlistPage,
} from './pages';
import BooksAndKindles from './pages/Book/Index/BooksAndKindles';
import Create from './pages/Create';
import { api, IUserData } from './services/api';

const App: FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const [currentUser, setCurrentUser] = useState<IUserData | null>(null);
  const [feedback, setFeedback] = useState<IFeedback | null>(null);
  const { inProgress } = useMsal();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await api.user.usersMeGet();
        const userData = response.data.data;
        setCurrentUser(userData);
      } catch (error) {
        console.error(error);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isAuthenticated && fetchUserData();
  }, [isAuthenticated]);

  useSubscriptionsCheck(currentUser, setCurrentUser, setFeedback);

  return (
    <ThemeProvider theme={libraryTheme}>
      <CssBaseline />

      <CurrentUserContext.Provider value={{ currentUser, setCurrentUser }}>
        <FeedbackContext.Provider value={{ feedback, setFeedback }}>
          <Feedback />

          <Routes>
            {inProgress === InteractionStatus.None ? (
              isAuthenticated ? (
                <Route path="/" element={<MainLayout />}>
                  <Route index element={<DashboardPage />} />
                  <Route path="create" element={<Create />} />
                  <Route path="books">
                    <Route path=":id" element={<ShowBookPage />} />
                    <Route path="create" element={<PrivateRoute user={currentUser} authRoles={[1, 2]} />}>
                      <Route index element={<CreateBookPage />} />
                    </Route>
                    <Route path="borrowed" element={<BorrowedBooksPage />} />
                    <Route index element={<BooksAndKindles />} />
                  </Route>
                  <Route path="kindles" element={<PrivateRoute user={currentUser} authRoles={[1, 2]} />}>
                    <Route index element={<KindlesPage />} />
                  </Route>
                  <Route path="office" element={<PrivateRoute user={currentUser} authRoles={[1, 2]} />}>
                    <Route index element={<OfficePage />} />
                  </Route>
                  <Route path="wishlist" element={<WishlistPage />} />
                  <Route path="user">
                    <Route path="settings" element={<UserSettingsPage />} />
                  </Route>
                  <Route path="adminPage" element={<PrivateRoute user={currentUser} authRoles={[2]} />}>
                    <Route index element={<AdminPage />} />
                  </Route>
                  <Route path="error" element={<ErrorPage />} />
                  <Route path="*" element={<NotFoundPage />} />
                </Route>
              ) : (
                <Route path="*" element={<LoginPage />} />
              )
            ) : (
              <Route path="*" element={<Box />} />
            )}
          </Routes>
        </FeedbackContext.Provider>
      </CurrentUserContext.Provider>
    </ThemeProvider>
  );
};

export default App;
