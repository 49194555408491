import { PublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { Logout, Settings } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { msalConfig } from '../../../config/authConfig';
import currentUserContext from '../../../contexts/CurrentUserContext';
import { AvatarSx, UserAccountBoxSx } from './styles';

type User = {
  id?: number;
  fullName?: string;
  firstName?: string;
  lastName?: string;
  initials?: string;
  email?: string;
  role?: string;
  office?: {
    id: number;
    name: string;
    city: string;
    country: string;
  };
};

const AccountMenu: FC = () => {
  const { instance } = useMsal();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { currentUser } = useContext(currentUserContext);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('userToken');
    localStorage.removeItem('tokenExpirationDate');
    return instance.logoutPopup({
      postLogoutRedirectUri: '/login',
      mainWindowRedirectUri: '/', // redirects the top level app after logout
    });
  };

  const fullNameArr = currentUser?.name.split(' ', 2);
  const user: User = {
    id: currentUser?.id,
    fullName: currentUser?.name,
    lastName: fullNameArr && fullNameArr.length > 1 ? fullNameArr[1] : '',
    firstName: fullNameArr && fullNameArr.length > 0 ? fullNameArr[0] : '',
    initials: fullNameArr?.map((name: string) => name[0]).join(''),
    email: currentUser?.email,
    role: currentUser?.role.name,
    office: currentUser?.office,
  };
  localStorage.setItem('user', JSON.stringify(user));

  const getRandomBackground = useMemo(() => {
    const hue = Math.floor(Math.random() * 360); // Random hue between 0 and 360
    const saturation = Math.floor(Math.random() * 50) + 50; // Random saturation between 50 and 100
    const lightness = Math.floor(Math.random() * 30) + 35; // Random lightness between 35 and 65

    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  }, []);

  const [pic, setPic] = useState<string>('');

  useEffect(() => {
    const getProfilePicture = async () => {
      const msalInstance = new PublicClientApplication(msalConfig);
      const activeAccount = msalInstance.getActiveAccount();
      const accounts = msalInstance.getAllAccounts();

      const graphToken = await msalInstance.acquireTokenSilent({
        scopes: ['User.Read'],
        account: activeAccount || accounts[0],
      });

      const resp = await axios.get('https://graph.microsoft.com/v1.0/me/photo/$value', {
        headers: {
          Authorization: `Bearer ${graphToken.accessToken}`,
        },
        responseType: 'blob',
      });
      setPic(URL.createObjectURL(resp.data));
    };
    getProfilePicture();
  }, []);

  return (
    <>
      <Box sx={UserAccountBoxSx}>
        <Tooltip title="Account settings">
          <IconButton
            disableFocusRipple
            disableRipple
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            {pic || user.initials ? (
              pic ? (
                <Avatar src={pic} sx={AvatarSx} />
              ) : (
                <Avatar
                  sx={{
                    ...AvatarSx,
                    backgroundColor: getRandomBackground,
                  }}
                >
                  {user.initials}
                </Avatar>
              )
            ) : (
              <Skeleton variant="circular" width={40} height={40} animation="wave" sx={{ mr: 1 }} />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>{user.fullName}</MenuItem>
        <Typography variant="caption" paragraph={true} align="center">
          {user.role}
        </Typography>
        <Typography variant="caption" paragraph={true} align="center">
          {user.office?.name + ', ' + user.office?.city}
        </Typography>
        <Divider />
        <MenuItem component={Link} to="/user/settings">
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={() => handleLogout()}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default AccountMenu;
