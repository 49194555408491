import { SxProps } from '@mui/material';

import { libraryTheme } from '../../../LibraryTheme';

export const updateKindleTitleSx: SxProps = {
  color: '#000',
  fontSize: '18px',
  fontWeight: 600,
  textTransform: 'capitalize',
};

export const updateKindleTextFieldSx: SxProps = {
  width: '341px',
};

export const updateKindleSubmitBtnSx: SxProps = {
  width: '124px',
  height: '40px',
  color: '#FFF',
  fontSize: '14px',
  fontWeight: 700,
  mt: 1,
  borderRadius: '5px',
  background: 'linear-gradient(180deg, #EC147A 0%, #EC147A 100%)',
  transition: 'background 0.3s ease-in-out',
  '&:hover': {
    background: 'linear-gradient(180deg, rgb(215 14 109) 0%, rgb(215 14 109) 100%)',
  },
};

export const updateKindleSubmitDisabledBtnSx: SxProps = {
  width: '124px',
  height: '40px',
  color: '#FFF',
  fontSize: '14px',
  fontWeight: 700,
  mt: 1,
  borderRadius: '5px',
  background: 'lightgrey',
};
