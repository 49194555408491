import { SxProps } from '@mui/material';
import { Stack, Switch, Typography } from '@mui/material';
import { ReactNode, SyntheticEvent, useEffect, useState } from 'react';

interface ITypeProps {
  type: string;
  name: string;
  handleTypeChange: (e: SyntheticEvent, typeName: string, value: boolean) => void;
  exists?: boolean;
  sx?: SxProps;
  children?: ReactNode;
  disabled?: boolean;
}

const AddTypeSwitch = ({ type, name, handleTypeChange, exists, sx, children, disabled }: ITypeProps) => {
  const [status, setStatus] = useState(exists ?? false);

  useEffect(() => {
    if (exists !== undefined) {
      setStatus(exists);
    }
  }, [exists]);

  const handleToggleSwitch = (e: SyntheticEvent, switchStatus: boolean) => {
    setStatus(switchStatus);
    handleTypeChange(e, type, switchStatus);
  };

  return (
    <>
      <Stack direction="row" sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', ...sx }}>
        <Typography variant="body1" component="div">
          Add{' '}
          <Typography variant="body1" component="span" textTransform="capitalize">
            {name}{' '}
          </Typography>
          type book
        </Typography>
        <Switch disabled={disabled} checked={status} onChange={handleToggleSwitch} />
      </Stack>
      {children}
    </>
  );
};

export default AddTypeSwitch;
