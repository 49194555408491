import EditIcon from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { FC, useContext, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import FeedbackContext from '../../../contexts/FeedbackContext';
import { api, IUser } from '../../../services/api';
import { handleErrorMsg } from '../../../utils';
import { openEditIconSx } from '../../Book/Edit/styles';
import { DeleteButtonSx, EditDialogContentSx, EditDialogTextFieldSx, EditDialogTitleSx, GridBoxSx } from '../styles';

const ROLES = [
  { id: 3, name: 'Reader' },
  { id: 1, name: 'Librarian' },
  { id: 2, name: 'Super Admin' },
];
const READER_ROLE = ROLES[0].name;
const LIBRARIAN_ROLE = ROLES[1].name;
const SUPERADMIN_ROLE = ROLES[2].name;

const UsersTab: FC = () => {
  const { setFeedback } = useContext(FeedbackContext);
  const queryClient = useQueryClient();
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [editUser, setEditUser] = useState<IUser | undefined>(undefined);

  const editRoleMutation = useMutation(
    (variables: { user: IUser }) =>
      api.user.usersIdPatch({
        id: variables.user.id,
        usersIdGetRequest: {
          role_id: variables.user.role.id,
        },
      }),
    {
      onSuccess: () => {
        const inv = queryClient.invalidateQueries(['users']);
        setEditOpen(false);
        return inv;
      },
      onError: (e) => handleErrorMsg(e, setFeedback),
    },
  );

  const { data: usersData, isLoading: isLoadingUsers } = useQuery({
    queryKey: ['users'],
    queryFn: async () => {
      let allUsers: IUser[] = [];
      let currentPage = 1;
      let lastPage: number | undefined = 1;
      do {
        const response = await api.user.usersGet({
          params: { page: currentPage },
        });
        const responseUsers = response.data.data;
        allUsers = [...allUsers, ...responseUsers];
        const { links, meta } = response.data;
        currentPage = currentPage + 1;
        lastPage = meta.last_page;
        if (!links.next) break;
      } while (lastPage && currentPage <= lastPage);
      return allUsers;
    },
  });

  const handleOpenEdit = (e: any) => {
    setEditUser(e);
    setEditOpen(true);
  };

  const handleCloseEdit = () => setEditOpen(false);

  const usersRows = useMemo(() => {
    if (!usersData) return [];
    return usersData
      .filter((us) => !!us)
      .map((us) => {
        return {
          id: us.id,
          name: us.name,
          office: us.office,
          role: us.role,
        };
      });
  }, [usersData]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      minWidth: 200,
      flex: 0.6,
    },
    {
      field: 'office',
      headerName: 'Office',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      minWidth: 140,
      flex: 0.4,
      valueGetter: (params) => `${params.value.name}, ${params.value.city}`,
      valueFormatter: (params) => params.value,
    },
    {
      field: 'role',
      headerName: 'Role',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      width: 130,
      valueGetter: (params) => params.value.name,
      valueFormatter: (params) => params.value,
    },
    {
      field: 'actions',
      headerName: 'Edit Role',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 150,
      renderCell: (row) => (
        <Tooltip title="Edit Role">
          <IconButton aria-label="edit" onClick={() => handleOpenEdit(row.row)}>
            <EditIcon sx={{ ...openEditIconSx, color: '#000' }} />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <Box sx={GridBoxSx}>
        {isLoadingUsers ? (
          <>
            <Skeleton variant="rounded" width="100%" height={50} animation="wave" sx={{ mt: 1 }} />
            {Array.from({ length: 10 }, (_, index) => (
              <Skeleton key={index} variant="rounded" width="100%" height={36} animation="wave" sx={{ mt: 0.5 }} />
            ))}
          </>
        ) : (
          <DataGrid
            disableVirtualization
            columns={columns}
            rows={usersRows}
            loading={isLoadingUsers}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableColumnSelector
            autoHeight
          />
        )}
      </Box>
      <Dialog open={editOpen} onClose={handleCloseEdit}>
        <DialogTitle sx={EditDialogTitleSx}>Edit role for user</DialogTitle>
        <DialogContent>
          <Typography sx={EditDialogContentSx}> Edit the role for {editUser?.name}</Typography>
          <FormControl fullWidth>
            <Autocomplete
              id="roles"
              disableClearable
              options={ROLES}
              value={editUser?.role}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              onChange={(event, value) => {
                setEditUser((prev) => {
                  return { ...prev!, role: value };
                });
              }}
              renderInput={(params) => <TextField {...params} variant="outlined" sx={EditDialogTextFieldSx} />}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={editRoleMutation.isLoading}
            onClick={() => editRoleMutation.mutate({ user: editUser! })}
            sx={{ ...DeleteButtonSx, mb: 1, mr: 2 }}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UsersTab;
