import { Delete } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import React, { FC, useContext, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import FeedbackContext from '../../../../contexts/FeedbackContext';
import { api } from '../../../../services/api';
import { handleErrorMsg } from '../../../../utils';
import { ActionIconSx, ButtonsBoxSx, CancelButtonSx, DeleteButtonSx, DeleteDialogTitleSx } from '../../styles';
import { IOfficeSettings } from '../types';

interface Props {
  office: IOfficeSettings;
}

const DeleteOffice: FC<Props> = ({ office }) => {
  const { setFeedback } = useContext(FeedbackContext);
  const queryClient = useQueryClient();
  const [open, setOpen] = useState<boolean>(false);

  const deleteOfficeMutation = useMutation(
    (variables: { officeId: number }) => {
      return api.office.officesIdDelete({
        id: variables.officeId,
      });
    },
    {
      onSuccess: () =>
        Promise.all([queryClient.invalidateQueries(['settings']), queryClient.invalidateQueries(['offices'])]),
      onError: (e) => handleErrorMsg(e, setFeedback),
    },
  );

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    deleteOfficeMutation.mutate({ officeId: office.id });
    setOpen(false);
  };

  return (
    <Box>
      <Box sx={ButtonsBoxSx}>
        <Tooltip title="Delete">
          <IconButton data-testid="delete-button" aria-label="delete" onClick={handleOpenDialog}>
            <Delete sx={ActionIconSx} />
          </IconButton>
        </Tooltip>
      </Box>
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle sx={DeleteDialogTitleSx}>
          Are you sure you want to delete office {office.name}, {office.city}?
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions sx={{ mb: 1 }}>
          <Button sx={CancelButtonSx} onClick={handleCloseDialog}>
            Close
          </Button>
          <Button onClick={handleDelete} sx={DeleteButtonSx}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DeleteOffice;
