import { Box, Grid, Typography } from '@mui/material';
import React, { FC } from 'react';

import BookCover from '../../../shared/BookCover';
import { incomingBookAuthorsSx, incomingBookBoxSx, incomingBookTitleSx } from './styles';

interface IncomingBookProps {
  cover: string;
  title: string;
  author: string;
  bookId: number;
  isAvailable: boolean;
}

const IncomingBook: FC<IncomingBookProps> = ({ title, author, bookId, cover, isAvailable }) => {
  return (
    <Box sx={incomingBookBoxSx}>
      <Grid container direction="column" justifyContent="center" alignItems="center" spacing={1.3}>
        <Grid item>
          <BookCover
            bookId={bookId}
            title={title}
            cover={cover}
            isAvailable={isAvailable}
            width="168px"
            height="250px"
          />
        </Grid>
        <Grid item>
          <Typography sx={incomingBookTitleSx}>{title}</Typography>
        </Grid>
        <Grid item>
          <Typography sx={incomingBookAuthorsSx}>{author}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default IncomingBook;
