import {
  BlurLinear,
  Dashboard,
  LibraryAdd,
  LibraryBooks,
  ManageAccounts,
  Star,
  TabletAndroid,
} from '@mui/icons-material';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { Box, Divider, List, ListItemButton, Typography } from '@mui/material';
import * as React from 'react';
import { FC, useContext } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps, useLocation } from 'react-router-dom';

import currentUserContext from '../../../contexts/CurrentUserContext';

interface IListItemLink {
  icon?: React.ReactElement;
  primary: string;
  to: string;
}

const ListItemLink: FC<IListItemLink> = ({ icon, primary, to }) => {
  const location = useLocation();
  const renderLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'>>(function Link(itemProps, ref) {
        return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
      }),
    [to],
  );
  const modifiedPrimary =
    to === '/kindles' ? (
      <>
        {primary.split(' ')[0]}
        <br />
        {primary.split(' ').slice(1).join(' ')}
      </>
    ) : (
      primary
    );

  return (
    <li>
      <ListItemButton component={renderLink} sx={{ padding: 0 }}>
        <Box className={`menu-item ${location.pathname === to ? 'active-item' : 'non-active-item'}`}>
          {icon}
          <Typography color="#fff" sx={{ fontSize: '12px', pt: '2px' }}>
            {modifiedPrimary}
          </Typography>
        </Box>
      </ListItemButton>
    </li>
  );
};

const SideNavigation: FC = () => {
  const { currentUser } = useContext(currentUserContext);
  const role = currentUser?.role.name ?? '';

  return (
    <List sx={{ position: 'fixed', pt: 0, width: '72px' }} component="nav" aria-label="dashboard books navigation">
      <ListItemLink to="/" primary="Dashboard" icon={<Dashboard style={{ fill: 'white' }} />} />
      <ListItemLink to="/books/borrowed" primary="My books" icon={<MenuBookIcon style={{ fill: 'white' }} />} />
      <ListItemLink to="/books" primary="Books" icon={<LibraryBooks style={{ fill: 'white' }} />} />
      <ListItemLink to="/wishlist" primary="Wishlist" icon={<Star style={{ fill: 'white' }} />} />
      {(role === 'Librarian' || role === 'Super Admin') && (
        <>
          <Divider sx={{ my: 1, background: 'white' }} />
          <ListItemLink to="/create" primary="New book" icon={<LibraryAdd style={{ fill: 'white' }} />} />
          <ListItemLink to="/kindles" primary="Manage Kindles" icon={<TabletAndroid style={{ fill: 'white' }} />} />
          <ListItemLink to="/office" primary="Office" icon={<BlurLinear style={{ fill: 'white' }} />} />
        </>
      )}
      {role === 'Super Admin' && (
        <>
          <Divider sx={{ my: 1, background: 'white' }} />
          <ListItemLink to="/adminPage" primary="Admin" icon={<ManageAccounts style={{ fill: 'white' }} />} />
        </>
      )}
    </List>
  );
};

export default SideNavigation;
