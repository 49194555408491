import { SxProps } from '@mui/material';

export const noItemsBoxSx: SxProps = {
  height: '300px',
  lineHeight: '300px',
  border: '1px solid #E0E0E0',
  borderRadius: '10px',
  textAlign: 'center',
  fontSize: '16px',
  fontWeight: 400,
  fontStyle: 'italic',
};
