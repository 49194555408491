import { Box, Chip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import React from 'react';

import { typeChipSx } from '../../Book/Show/styles';
import { multiLineSx } from '../styles';

export const ReportTypes: string[] = ['Frequency', 'Average borrowing period', 'Readers'];
export const BorrowingTypes: string[] = ['Kindle', 'Paper'];

export const TypesDescriptions: Map<string, string> = new Map<string, string>();
TypesDescriptions.set('Frequency', 'How many times a book was borrowed in a given period of time');
TypesDescriptions.set(
  'Average borrowing period',
  'The average number of days a book was borrowed for in a given period of time',
);
TypesDescriptions.set('Readers', "Information about readers' activity in a given period of time");

const commonColumns: GridColDef[] = [
  {
    field: 'title',
    headerName: 'Title',
    headerAlign: 'center',
    align: 'center',
    hideable: false,
    minWidth: 250,
    flex: 0.7,
    renderCell: (row) => <Box sx={multiLineSx}>{row.value}</Box>,
  },
  {
    field: 'authors',
    headerName: 'Authors',
    headerAlign: 'center',
    align: 'center',
    hideable: false,
    minWidth: 200,
    flex: 0.3,
    valueFormatter: (params) => (params.value ? params.value.split(',').join(', ') : 'No authors'),
    renderCell: (row) => <Box sx={multiLineSx}>{row.formattedValue}</Box>,
  },
  {
    field: 'type',
    headerName: 'Type',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    width: 150,
    renderCell: (row) => <Chip sx={typeChipSx} label={row.value} size="small" color="warning" key={row.id} />,
  },
  {
    field: 'description',
    headerName: 'Category',
    headerAlign: 'center',
    align: 'center',
    hideable: false,
    width: 150,
    renderCell: (row) => <Box sx={multiLineSx}>{row.formattedValue}</Box>,
  },
  {
    field: 'tags',
    headerName: 'Tags',
    headerAlign: 'center',
    align: 'center',
    hideable: false,
    minWidth: 150,
    valueFormatter: (params) => (params.value ? params.value.split(',').join(', ') : 'No tags'),
    renderCell: (row) => <Box sx={multiLineSx}>{row.formattedValue}</Box>,
  },
];

export const columnsFrequencyKindle: GridColDef[] = [
  {
    field: 'inventory_num',
    headerName: 'Inventory Number',
    headerAlign: 'center',
    align: 'center',
    hideable: false,
    flex: 1,
  },
  {
    field: 'type',
    headerName: 'Type',
    headerAlign: 'center',
    align: 'center',
    hideable: false,
    flex: 1,
    renderCell: (row) => <Chip sx={typeChipSx} label={row.value} size="small" color="warning" key={row.id} />,
  },
  {
    field: 'kindle_registers_count',
    headerName: 'Kindle Registers Count',
    headerAlign: 'center',
    align: 'center',
    hideable: false,
    flex: 1.5,
  },
];

export const columnsAverageBorrowingPeriodKindle: GridColDef[] = [
  {
    field: 'inventory_num',
    headerName: 'Inventory Number',
    headerAlign: 'center',
    align: 'center',
    hideable: false,
    flex: 1,
  },
  {
    field: 'type',
    headerName: 'Type',
    headerAlign: 'center',
    align: 'center',
    hideable: false,
    flex: 1,
    renderCell: (row) => <Chip sx={typeChipSx} label={row.value} size="small" color="warning" key={row.id} />,
  },
  {
    field: 'average_borrowing_period',
    headerName: 'Average borrowing period',
    headerAlign: 'center',
    align: 'center',
    hideable: false,
    flex: 1.5,
    valueFormatter: (params) =>
      parseFloat(parseFloat(params.value).toFixed(2)) + (params.value === 1 ? ' day' : ' days'),
  },
];

export const columnsReadersKindle: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    headerAlign: 'center',
    align: 'center',
    hideable: false,
    flex: 1,
  },
  {
    field: 'total_number',
    headerName: 'Total Number',
    headerAlign: 'center',
    align: 'center',
    hideable: false,
    flex: 1,
  },
  {
    field: 'returned_kindles',
    headerName: 'Returned Kindles',
    headerAlign: 'center',
    align: 'center',
    hideable: false,
    flex: 1,
  },
  {
    field: 'not_returned_on_time',
    headerName: 'Not Returned on Time',
    headerAlign: 'center',
    align: 'center',
    hideable: false,
    flex: 1,
  },
];

export const columnsFrequency: GridColDef[] = [
  ...commonColumns,
  {
    field: 'book_registers_count',
    headerName: 'Borrow count',
    headerAlign: 'center',
    align: 'center',
    hideable: false,
    width: 120,
  },
];

export const columnsAverageBorrowingPeriod: GridColDef[] = [
  ...commonColumns,
  {
    field: 'average_borrowing_period',
    headerName: 'Average borrowing period',
    headerAlign: 'center',
    align: 'center',
    hideable: false,
    width: 180,
    valueFormatter: (params) =>
      parseFloat(parseFloat(params.value).toFixed(2)) + (params.value === 1 ? ' day' : ' days'),
  },
];

export const columnsReaders: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    headerAlign: 'center',
    align: 'center',
    hideable: false,
    minWidth: 250,
    flex: 0.4,
    renderCell: (row) => <Box sx={multiLineSx}>{row.value}</Box>,
  },
  {
    field: 'total_number',
    headerName: 'Total books',
    headerAlign: 'center',
    align: 'center',
    hideable: false,
    minWidth: 90,
    flex: 0.2,
  },
  {
    field: 'returned_books',
    headerName: 'Returned books',
    headerAlign: 'center',
    align: 'center',
    hideable: false,
    minWidth: 120,
    flex: 0.2,
  },
  {
    field: 'not_returned_on_time',
    headerName: 'Not returned on time',
    headerAlign: 'center',
    align: 'center',
    hideable: false,
    minWidth: 150,
    flex: 0.2,
  },
];
