import { SxProps } from '@mui/material';

import { libraryTheme } from '../../../LibraryTheme';

export const readingNowTrapezoidSx: SxProps = {
  p: '25px 0 25px 25px',
  backgroundColor: '#F6F6F6',
};

export const readingNowBooksPaperSx: SxProps = {
  border: '1px solid #E0E0E0',
  borderRadius: '10px',
  flex: 1,
};

export const readingNowCoverBoxSx: SxProps = {
  position: 'relative',
  mr: '15px',
};

export const readingNowFlagBoxSx: SxProps = {
  position: 'absolute',
  top: '5px',
  right: 0,
};

export const readingNowReaderSx: SxProps = {
  color: '#000',
  fontSize: '12px',
  fontWeight: '500',
};

export const readingNowTitleSx: SxProps = {
  color: '#000',
  fontSize: '14px',
  fontWeight: '500',
  mt: '10px',
  mb: '7px',
  lineHeight: 'normal',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
};

export const readingNowAuthorsBoxSx: SxProps = {
  pb: '10px',
};

export const readingNowAuthorsLabelSx: SxProps = {
  color: '#000',
  fontSize: '12px',
  fontWeight: '500',
  lineHeight: 'normal',
};

export const readingNowAuthorsSx: SxProps = {
  color: '#000',
  fontSize: '12px',
  fontWeight: '300',
  lineHeight: 'normal',
};

export const readingNowPublishedYearBoxSx: SxProps = {
  lineHeight: 'normal',
  color: '#000',
  fontSize: '12px',
  fontStyle: 'italic',
  pb: '5px',
};

export const readingNowPublishedYearLabelSx: SxProps = {
  fontWeight: '500',
};

export const readingNowPublishedYearSx: SxProps = {
  fontWeight: '300',
};

export const readingNowNumOfPagesBoxSx: SxProps = {
  lineHeight: 'normal',
  color: '#000',
  fontSize: '12px',
  fontStyle: 'italic',
  pb: '5px',
};

export const readingNowNumOfPagesLabelSx: SxProps = {
  fontWeight: '500',
};

export const readingNowNumOfPagesSx: SxProps = {
  fontWeight: '300',
};

const readingNowBooksChipSx: SxProps = {
  width: 'fit-content',
  height: '30px',
  fontSize: '13px',
  fontWeight: 500,
  borderRadius: '40px',
  p: '7px 20px 7px 21px',
  mr: '10px',
  textTransform: 'capitalize',
  color: 'white',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  lineHeight: 'normal',
};

export const readingNowCategoryChipSx: SxProps = {
  ...readingNowBooksChipSx,
  backgroundColor: libraryTheme.palette.fourth.main,
};

export const readingNowTagChipSx: SxProps = {
  ...readingNowBooksChipSx,
  backgroundColor: libraryTheme.palette.primary.main,
};

export const readingNowTypeChipSx: SxProps = {
  ...readingNowBooksChipSx,
  backgroundColor: libraryTheme.palette.third.main,
};

export const grayTrapezoidSx: SxProps = {
  borderTop: '260px solid #F6F6F6',
  borderRight: '50px solid transparent',
};

export const descriptionBoxSx: SxProps = {
  p: '38px 25px 38px 20px',
};

export const descriptionTitleSx: SxProps = {
  color: '#000000',
  fontSize: '14px',
  mt: '5px',
  fontWeight: 700,
  lineHeight: 'normal',
};

export const descriptionSx: SxProps = {
  color: '#000000',
  fontSize: '12px',
  fontWeight: 300,
  lineHeight: 'normal',
  pt: '10px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 8,
  WebkitBoxOrient: 'vertical',
};
