import { SxProps } from '@mui/material';

export const flagBoxSx: SxProps = {
  width: '26px',
  // height: '191px',
  position: 'relative',
  color: '#FFF',
  fontSize: '13px',
  textAlign: 'center',
  textTransform: 'none',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginRight: '5px',
};

export const circleBoxSx: SxProps = {
  width: '37px',
  height: '37px',
  border: '1px solid #FFF',
  marginTop: '-18px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const userNameTypographySx: SxProps = {
  textAlign: 'center',
  color: '#FFF',
  fontFamily: 'Roboto',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  paddingBottom: '2px',
};

export const readingStatusTypographySx: SxProps = {
  writingMode: 'vertical-rl',
  transform: 'rotate(-180deg)',
  fontStyle: 'italic',
  fontSize: '13px',
  fontWeight: 600,
  height: '50px',
  marginTop: '85px',
};

export const bottomFlagBoxSx: SxProps = {
  position: 'relative',
  // top: '38.5px',
  right: '13px',
  color: '#E0E0E0',
  background: 'red',
  textAlign: 'center',
  textTransform: 'uppercase',
  ':after': {
    content: '""',
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: 0,
    height: 0,
    borderBottom: '10px solid #e0e0e0',
    borderLeft: '13px solid transparent',
    borderRight: '13px solid transparent',
  },
};
