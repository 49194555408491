import { SxProps } from '@mui/material';

export const AvatarSx: SxProps = {
  width: 32,
  height: 32,
  margin: '5px',
};
const copyObject = <T>(o: T): T => {
  return JSON.parse(JSON.stringify(o));
};

export const UserBoxSx: SxProps = {
  height: '40px',
  paddingTop: '18px',
  display: 'flex',
  alignItems: 'center',
  alignText: 'center',
};

export const LibrarianNameSx: SxProps = {
  mb: '15px',
  ml: '10px',
};

export const AccordionSx: SxProps = {
  marginBottom: 2,
  marginTop: 2,
  border: '1px groove',
};
export const UpdateButtonSx: SxProps = {
  width: '124px',
  height: '40px',
  flexShrink: 0,
  borderRadius: '5px',
  background: 'linear-gradient(180deg, #EC147A 0%, #EC147A 100%)',
  color: '#FFF',
  fontSize: '14px',
  fontWeight: 700,
  textTransform: 'uppercase',
  transition: 'background 0.3s ease-in-out',
  '&:hover': {
    background: 'linear-gradient(180deg, rgb(215 14 109) 0%, rgb(215 14 109) 100%)',
  },
};

export const UpdateDisabledButtonSx: SxProps = {
  width: '124px',
  height: '40px',
  flexShrink: 0,
  borderRadius: '5px',
  background: 'lightgrey',
  color: '#FFF',
  fontSize: '14px',
  fontWeight: 700,
  textTransform: 'uppercase',
};
