import { LoadingButton } from '@mui/lab';
import { Box, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { useContext, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import feedbackContext from '../../../../contexts/FeedbackContext';
import { api } from '../../../../services/api';
import { handleErrorMsg } from '../../../../utils';
import { ITagDropdown } from '../../../Book/Create/types';
import { EditSubmitBtnSx, EditSubmitDisabledBtnSx, EditTextFieldSx } from '../../styles';
import schema from '../validations';

interface IFormValues {
  description: string;
}

interface IUpdateTagProps {
  tag: ITagDropdown;
  handleCloseEdit: () => void;
}

const initialValues: IFormValues = {
  description: '',
};

const UpdateTagForm = ({ tag, handleCloseEdit }: IUpdateTagProps) => {
  const queryClient = useQueryClient();
  const { setFeedback } = useContext(feedbackContext);

  useEffect(() => {
    initialValues.description = tag.description;
  }, [tag]);

  const updateMutation = useMutation(
    (values: IFormValues) => api.tag.tagsIdPatch({ id: tag.id, tagsGetRequest: values }),
    {
      onSuccess: () => {
        setFeedback({
          status: 'success',
          message: 'The tag was successfully edited',
        });
        handleCloseEdit();
        return Promise.all([
          queryClient.invalidateQueries(['tags']),
          queryClient.invalidateQueries(['tags', { id: tag.id }]),
        ]);
      },
      onError: (e) => {
        handleErrorMsg(e, setFeedback);
      },
    },
  );

  const handleSubmitForm = (values: IFormValues) => updateMutation.mutate(values);

  const { isValid, errors, handleBlur, handleChange, handleSubmit, values } = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: handleSubmitForm,
    validationSchema: schema,
  });

  return (
    <Box autoComplete="off" component="form" onSubmit={handleSubmit} sx={{ '& .MuiTextField-root': { mb: 1, mt: 1 } }}>
      <TextField
        sx={EditTextFieldSx}
        error={!!errors.description}
        helperText={errors.description}
        id="description"
        label="Description"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.description}
        spellCheck
      />
      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <LoadingButton
          sx={tag.description === values.description || !isValid ? EditSubmitDisabledBtnSx : EditSubmitBtnSx}
          disabled={tag.description === values.description || !isValid}
          loading={updateMutation.isLoading}
          type="submit"
        >
          Submit
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default UpdateTagForm;
