import * as Yup from 'yup';

const re = new RegExp(/^[\p{L}\s.'-]+$/u);

const schema = Yup.object({
  name: Yup.string()
    .matches(re, 'Invalid name format')
    .max(50, 'Author name should be less than 50 chars')
    .required('Author name is required'),
});

export default schema;
