import { Box, Button, DialogActions, TextField } from '@mui/material';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import React, { FC, SyntheticEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { MoonLoader } from 'react-spinners';

import { LoadingWrapper } from '../../../../components/MainLayout/SearchBar/styles';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';
import FeedbackContext from '../../../../contexts/FeedbackContext';
import { api, IBook } from '../../../../services/api';
import {
  BookEditionApiBookEditionsPostRequest,
  BooksGetRequestTypeEnum,
  DropdownsGet200Response,
} from '../../../../services/api/openapi';
import { handleErrorMsg } from '../../../../utils';
import AddTypeSwitch from '../../Create/components/AddTypeSwitch';
import { FullWCss } from '../../Create/styles';
import { ITypesDropdown } from '../../Create/types';
import { bookEditionPostFactory } from '../../Create/utils';
import { cancelButtonSx, saveButtonSx, switchEdiitonBoxSx } from '../styles';
import { FILE_SIZE, SUPPORTED_FORMATS } from '../validation';

const ALPHA_NUMERIC_REGEX = /[^0-9a-zA-Z]/;

const initialTypes: ITypesDropdown = {
  'e-book': false,
  kindle: false,
  paper: false,
};

interface Props {
  book: IBook;
  setOpen: (value: ((prevState: boolean) => boolean) | boolean) => void;
}

const AddBookEdition: FC<Props> = ({ book, setOpen }) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { setFeedback } = useContext(FeedbackContext);
  const queryClient = useQueryClient();

  const [inventoryNumberInput, setInventoryNumberInput] = useState<string | undefined>(undefined);

  const [ebookFile, setEbookFile] = useState<File>();
  const [hasSelectedFile, setHasSelectedFile] = useState<boolean>(false);
  const [bookTypeSelected, setBookTypeSelected] = useState<ITypesDropdown>(initialTypes);
  const [paperIsSelected, setPaperIsSelected] = useState<boolean>(false);
  const [ebookIsSelected, setEbookIsSelected] = useState<boolean>(false);
  const [existingTypes, setExistingTypes] = useState<ITypesDropdown>(initialTypes);

  const { data: bookTypes, isLoading: bookTypesLoading } = useQuery({
    queryFn: () => api.dropdown.dropdownsGet({ name: ['book_type'] }),
    queryKey: ['types'],
    select: useCallback((data: AxiosResponse<DropdownsGet200Response, any>) => {
      const dropdowns = data.data.data;

      if (dropdowns.length) return Object.entries(dropdowns[0].values) as [string, BooksGetRequestTypeEnum][];

      return [];
    }, []),
  });

  useEffect(() => {
    if (bookTypes) {
      const types: ITypesDropdown = { 'e-book': false, kindle: false, paper: false };
      bookTypes.forEach((type) => {
        return (types[type[1]] = book.types.includes(type[1]));
      });
      setExistingTypes(types);
    }
  }, [bookTypes]);

  const handleClose = () => setOpen(false);

  const bookEditionPostMutation = useMutation(
    (data: BookEditionApiBookEditionsPostRequest) => {
      if (!data.bookEditionsGetRequest.type.includes('e-book')) data.bookEditionsGetRequest.file_url = undefined;
      const options: AxiosRequestConfig =
        data.bookEditionsGetRequest.type.includes('e-book') || data.bookEditionsGetRequest.type === 'e-book'
          ? { headers: { 'Content-Type': 'multipart/form-data' } }
          : {};

      return api.bookEdition.bookEditionsPost(data, options);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(['paginatedBooks', 'book', book.id, currentUser?.office.id]);
      },
      onError: (e) => {
        const error = e as AxiosError<{ message: string }>;

        if (error.response)
          setFeedback({
            status: 'error',
            message: error.response.data.message,
          });
      },
    },
  );

  const handleSave = async () => {
    const selectedBookTypes = Object.entries(bookTypeSelected)
      .filter(([_, value]) => value === true)
      .map((type) => {
        return type[0];
      });

    const values = {
      author_ids: book.authors.map((a) => a.id),
      category_id: book.category.id,
      cover: book.cover,
      description: book.description,
      file_url: ebookFile as string | undefined,
      inventory_num: inventoryNumberInput,
      isbn: book.details[0].isbn_13,
      isbn_2: book.details[0].isbn,
      lang: book.lang,
      published_at: book.published_at,
      tag_ids: book.tags.map((t) => t.id),
      title: book.title,
      type: selectedBookTypes,
      numberOfPages: book.details[0].number_of_pages,
    };

    // handle submit new book edition
    const bookEditionPostData = bookEditionPostFactory(values);

    // In case the book already exists ignore book post
    bookEditionPostData.bookEditionsGetRequest.book_id = (book as IBook).id;
    // eslint-disable-next-line no-useless-catch
    try {
      const resPostedBEdition = await bookEditionPostMutation.mutateAsync(bookEditionPostData);

      if (resPostedBEdition.status === 201) {
        setFeedback({
          status: 'success',
          message: 'The book edition has been added successfully!',
        });
        handleClose();
        return await queryClient.invalidateQueries(['book', book.id]);
      }
    } catch (e) {
      handleErrorMsg(e, setFeedback);
    }
  };

  const isValidInvNumber = useMemo(() => {
    // add was selected here
    if (paperIsSelected && !inventoryNumberInput) return false;
    return inventoryNumberInput && inventoryNumberInput.length > 0 && inventoryNumberInput.length <= 20;
  }, [inventoryNumberInput]);

  const inventoryNumHelperText =
    paperIsSelected && !isValidInvNumber
      ? inventoryNumberInput
        ? 'Inventory number should have a maximum of 20 characters'
        : 'Inventory number cannot be empty'
      : null;

  const isValidEbookFile = useMemo(() => {
    return ebookFile && SUPPORTED_FORMATS.includes(ebookFile.type) && ebookFile.size <= FILE_SIZE;
  }, [ebookFile]);

  const handleTypeChange = (e: SyntheticEvent, typeName: string, value: boolean) => {
    setBookTypeSelected((prev) => ({ ...prev, [typeName]: value }));
  };

  const handlePaperBlur = () => {
    setPaperIsSelected(true);
  };

  const handleFileBlur = () => {
    setEbookIsSelected(true);
  };

  return (
    <Box>
      {!bookTypesLoading && bookTypes ? (
        bookTypes.map((booktype) => {
          return (
            <Box key={booktype[0]} sx={{ marginBottom: '25px' }}>
              {(booktype[1] === 'paper' || existingTypes[booktype[1]] === false) && (
                <AddTypeSwitch
                  name={booktype[1]}
                  type={booktype[1]}
                  handleTypeChange={handleTypeChange}
                  exists={false}
                  sx={switchEdiitonBoxSx}
                >
                  {booktype[1] === 'paper' && bookTypeSelected.paper && (
                    <Box sx={{ marginBottom: '25px', marginTop: '25px' }}>
                      <TextField
                        label="Add inventory number"
                        fullWidth
                        value={inventoryNumberInput}
                        onChange={(event) => {
                          if (!ALPHA_NUMERIC_REGEX.test(event.target.value))
                            setInventoryNumberInput(event.target.value);
                        }}
                        onBlur={handlePaperBlur}
                        size="small"
                        error={paperIsSelected && !isValidInvNumber}
                        helperText={inventoryNumHelperText}
                      />
                    </Box>
                  )}

                  {bookTypeSelected['e-book'] && booktype[1] === 'e-book' && (
                    <>
                      <Box>
                        <TextField
                          id="file_url"
                          style={{ ...FullWCss, marginTop: '25px' }}
                          onChange={(event) => {
                            const target = event.target as HTMLInputElement;
                            if (!!target.files && !!target.files[0]) {
                              setHasSelectedFile(true);
                              if (target && target.files) {
                                setEbookFile(target.files[0]);
                              }
                            }
                          }}
                          onBlur={handleFileBlur}
                          error={(ebookIsSelected && !isValidEbookFile) || (hasSelectedFile && !isValidEbookFile)}
                          helperText={
                            ebookIsSelected && !hasSelectedFile
                              ? 'Ebook file is required'
                              : hasSelectedFile &&
                                !isValidEbookFile &&
                                'File type should be pdf, fb2, epub or mobi, and size should be maximum 19 MB'
                          }
                          type="file"
                        />
                      </Box>
                    </>
                  )}
                </AddTypeSwitch>
              )}
            </Box>
          );
        })
      ) : (
        <LoadingWrapper>
          <MoonLoader loading color="#000" size={20} />
        </LoadingWrapper>
      )}

      <DialogActions sx={{ paddingRight: 0 }}>
        <Button sx={cancelButtonSx} onClick={handleClose}>
          Close
        </Button>
        <Button
          sx={saveButtonSx}
          disabled={
            !(
              bookTypeSelected.kindle ||
              (bookTypeSelected.paper && paperIsSelected && isValidInvNumber) ||
              (bookTypeSelected['e-book'] && hasSelectedFile && isValidEbookFile)
            )
          }
          variant="contained"
          onClick={handleSave}
        >
          Save
        </Button>
      </DialogActions>
    </Box>
  );
};

export default AddBookEdition;
