import { SxProps } from '@mui/material';

export const AddOfficeBtnSx: SxProps = {
  background: 'linear-gradient(180deg, #EC147A 0%, #EC147A 100%)',
  color: '#FFF',
  fontSize: '14px',
  fontWeight: 700,
  width: '170px',
  height: '40px',
  borderRadius: '5px',
  mb: '25px',
  transition: 'background 0.3s ease-in-out',
  '&:hover': {
    background: 'linear-gradient(180deg, rgb(215 14 109) 0%, rgb(215 14 109) 100%)',
  },
};
