import { Box } from '@mui/material';
import { FC } from 'react';

import { libraryTheme } from '../LibraryTheme';
import { copiesTextSx } from '../pages/Book/Show/styles';

interface Props {
  availableCopies?: number;
  totalCopies?: number;
}

const BookCopiesStatus: FC<Props> = ({ availableCopies, totalCopies }) => {
  return (
    <Box
      sx={{
        ...copiesTextSx,
        /* eslint-disable @typescript-eslint/indent */
        color:
          availableCopies === 0
            ? libraryTheme.palette.error.main
            : availableCopies === totalCopies
            ? libraryTheme.palette.success.main
            : '#B27600',
      }}
    >{`${availableCopies} Out of ${totalCopies}`}</Box>
  );
};

export default BookCopiesStatus;
