import { PublicClientApplication } from '@azure/msal-browser';
import axios from 'axios';

import { msalConfig } from '../../config/authConfig';

const acquireAccessToken = async (msalInstance: PublicClientApplication) => {
  const userToken: string | null = localStorage.getItem('userToken');
  const expiresOn: string | null = localStorage.getItem('tokenExpirationDate');
  if (!!userToken && !!expiresOn) if (new Date(expiresOn) > new Date()) return userToken;

  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    return '';
  }

  try {
    const authResult = await msalInstance.acquireTokenSilent({
      scopes: [process.env.REACT_APP_AZURE_SCOPES as string],
      account: activeAccount || accounts[0],
    });

    localStorage.setItem('userToken', authResult.accessToken);
    if (authResult.expiresOn) localStorage.setItem('tokenExpirationDate', authResult.expiresOn.toString());
    return authResult.accessToken;
  } catch (e: any) {
    if (e?.errorCode) {
      msalInstance.logoutRedirect({
        onRedirectNavigate(url) {
          return false;
        },
      });
      window.location.reload();
    }
  }
};

const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_AL_API_URL,
});
AxiosInstance.interceptors.request.use();

AxiosInstance.interceptors.request.use(async (config) => {
  const msalInstance = new PublicClientApplication(msalConfig);
  const token = await acquireAccessToken(msalInstance);
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

AxiosInstance.interceptors.response.use(undefined, (err) => {
  return Promise.reject(err);
});

export default AxiosInstance;
