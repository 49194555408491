import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { FC } from 'react';

import { BookRegisterTopGet200ResponseDataInnerBook } from '../../../services/api/openapi';
import BookCover from '../../../shared/BookCover';
import { incomingBookBoxSx } from '../IncomingBooks/styles';
import FlagComponent from '../ReadingNowBooks/FlagComponent/FlagComponent';
import { readingNowCoverBoxSx, readingNowFlagBoxSx } from '../ReadingNowBooks/styles';
import { topMostReadBooksAuthorsSx, topMostReadBooksTitleSx } from '../styles/styles';

interface TopMostReadBooksProps {
  index: number;
  book: BookRegisterTopGet200ResponseDataInnerBook;
}

const TopMostReadBooks: FC<TopMostReadBooksProps> = ({ index, book }) => {
  return (
    /* eslint-disable @typescript-eslint/indent */
    <Box
      sx={{
        ...incomingBookBoxSx,
        paddingTop:
          index === 1
            ? '31px'
            : index === 2
            ? '47px'
            : index === 3
            ? '53px'
            : index === 4
            ? '75px'
            : index === 5
            ? '86px'
            : '13px',
      }}
    >
      <Grid container direction="column" justifyContent="center" alignItems="center" spacing={1.3}>
        <Grid item>
          <Box sx={readingNowCoverBoxSx}>
            <BookCover
              bookId={book.id}
              title={book.title}
              cover={book.cover}
              isAvailable={book.details.some((be) => be.status === 'available')!}
              width={
                index === 0
                  ? '193px'
                  : index === 1
                  ? '168px'
                  : index === 2
                  ? '146px'
                  : index === 3
                  ? '126px'
                  : index === 4
                  ? '109px'
                  : '94px'
              }
              height={
                index === 0
                  ? '286.868px'
                  : index === 1
                  ? '250px'
                  : index === 2
                  ? '218.052px'
                  : index === 3
                  ? '189px'
                  : index === 4
                  ? '162.607px'
                  : '140.23px'
              }
              /* eslint-disable @typescript-eslint/indent */
              small
              alignLeft
            />
            <Box sx={readingNowFlagBoxSx}>
              <FlagComponent
                userName={`${index + 1}`}
                flagBgColor={index === 0 ? '#313204' : '#00332C'}
                circleBoxBackground={index === 0 ? '#907A2A' : '#7DD3C7'}
                flagBoxHeight={
                  index === 0
                    ? '297px'
                    : index === 1
                    ? '260px'
                    : index === 2
                    ? '228px'
                    : index === 3
                    ? '199px'
                    : index === 4
                    ? '173px'
                    : '150px'
                }
                bottomFlagBoxTop={
                  index === 0
                    ? '143.5px'
                    : index === 1
                    ? '106.5px'
                    : index === 2
                    ? '74.5px'
                    : index === 3
                    ? '45.5px'
                    : index === 4
                    ? '19.5px'
                    : '0.3px'
                }
              />
            </Box>
          </Box>
        </Grid>
        <Grid item>
          <Typography sx={topMostReadBooksTitleSx}>{book.title}</Typography>
        </Grid>
        <Grid item>
          <Typography sx={topMostReadBooksAuthorsSx}>
            {book.authors
              .slice(0, 2)
              .map((a) => a.name)
              .join(', ')}
          </Typography>
        </Grid>
      </Grid>
    </Box>
    /* eslint-disable @typescript-eslint/indent */
  );
};

export default TopMostReadBooks;
