import { SimplePaletteColorOptions } from '@mui/material';
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    third: SimplePaletteColorOptions;
    fourth: SimplePaletteColorOptions;
    neutral: SimplePaletteColorOptions;
  }

  interface PaletteOptions {
    third?: SimplePaletteColorOptions;
    fourth?: SimplePaletteColorOptions;
    neutral?: SimplePaletteColorOptions;
  }
}

export const libraryTheme = createTheme({
  palette: {
    primary: {
      main: '#071e4e',
    },
    secondary: {
      main: '#EC147A',
    },
    third: {
      main: '#662d91',
    },
    fourth: {
      main: '#386c72',
    },
    warning: {
      main: '#e4a11b',
    },
    info: {
      main: '#2B547E',
    },
    neutral: {
      main: '#464646',
    },
  },
});
