import { Box, SxProps } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  bookId?: number;
  title?: string;
  cover?: string;
  isAvailable: boolean;
  width: string;
  height: string;
  small?: boolean;
  alignLeft?: boolean;
}

const containerSx: SxProps = {
  position: 'relative',
  cursor: 'pointer',
};

const bookImageSx: SxProps = {
  borderRadius: '3px',
  cursor: 'pointer',
  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.3)',
};

const unavailableTextSx: SxProps = {
  width: '100%',
  color: '#FFF',
  fontSize: '16px',
  lineHeight: 'normal',
  fontWeight: 500,
  padding: '14px 2px',
  position: 'absolute',
  bottom: 0,
  background: '#C2ADAD',
};

const unavailableOverlaySx: SxProps = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: 'rgba(255,255,255,0.3)',
};

const BookCover: FC<Props> = ({ bookId, title, cover, isAvailable, width, height, small, alignLeft }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/books/${bookId}`);
  };

  return (
    <Box data-testid="bookCover" id="book-image" sx={containerSx} onClick={handleClick}>
      <Box component="img" src={cover} alt={title} sx={{ ...bookImageSx, width, height }}></Box>
      {!isAvailable && (
        <>
          <Box sx={unavailableOverlaySx} />
          <Box
            sx={{ ...unavailableTextSx, textAlign: alignLeft ? 'left' : 'center', fontSize: small ? '11px' : '16px' }}
          >
            Unavailable
          </Box>
        </>
      )}
    </Box>
  );
};

export default BookCover;
