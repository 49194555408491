import { Autocomplete, Box, Button, Chip, DialogActions, Grid, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { AxiosResponse } from 'axios';
import { useFormik } from 'formik';
import moment from 'moment/moment';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import FeedbackContext from '../../../../contexts/FeedbackContext';
import { api, IBook } from '../../../../services/api';
import {
  AuthorsGet200Response,
  BooksIdDeleteRequest,
  CategoriesGet200Response,
  DropdownsGet200Response,
  TagsGet200Response,
} from '../../../../services/api/openapi';
import { handleErrorMsg } from '../../../../utils';
import { cancelButtonSx, editBookItem, editUrlButtonSx, saveButtonSx, tagChipSx } from '../styles';
import { schema } from '../validation';

interface Props {
  book: IBook;
  setOpen: (value: ((prevState: boolean) => boolean) | boolean) => void;
}

const EditBookDetails: FC<Props> = ({ book, setOpen }) => {
  const { setFeedback } = useContext(FeedbackContext);
  const queryClient = useQueryClient();

  const [authorsSelected, setAuthorsSelected] = useState<{ id: number; name: string }[]>(book.authors);
  const [languageSelected, setLanguageSelected] = useState<[string, string]>(['', '']);
  const [categorySelected, setCategorySelected] = useState<{
    id: number;
    description: string;
  }>(book.category);
  const [tagsSelected, setTagsSelected] = useState<{ id: number; description: string }[]>(book.tags);

  const handleClose = () => setOpen(false);

  const formInitialValues = {
    title: book.title,
    author_id: book.authors.map((a) => a.id),
    cover: book.cover,
    lang: book.lang,
    isbn: book.details[0]?.isbn || book.details[0]?.isbn_13,
    published_at: book.published_at,
    numberOfPages: book.details[0].number_of_pages,
    description: book.description,
    category_id: book.category.id,
    tag_id: book.tags.map((t) => t.id),
  };

  const editBookDataMutation = useMutation(
    (variables: { newBookData: BooksIdDeleteRequest }) => {
      return api.book.booksIdPatch({
        id: book.id,
        booksIdDeleteRequest: variables.newBookData,
      });
    },
    {
      onSuccess: () => {
        setFeedback({ status: 'success', message: 'Book succesfully updated!' });
        handleClose();
        return queryClient.invalidateQueries(['book', book.id]);
      },
      onError: (e) => handleErrorMsg(e, setFeedback),
    },
  );

  const saveBookData = (newBookData: BooksIdDeleteRequest) => {
    editBookDataMutation.mutate({ newBookData });
  };

  const { handleSubmit, handleChange, handleBlur, values, setValues, setFieldValue, errors, isValid } = useFormik({
    initialValues: formInitialValues,
    enableReinitialize: true,
    validationSchema: schema,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    onSubmit: (values) => saveBookData(values),
  });

  const { data: bookLanguages, isFetching: isFetchingBookLangDD } = useQuery({
    queryKey: ['languages'],
    queryFn: () => api.dropdown.dropdownsGet({ name: ['book_lang'] }),
    select: useCallback((data: AxiosResponse<DropdownsGet200Response, any>) => {
      return Object.entries(data.data.data[0].values) as [string, string][];
    }, []),
    onSuccess: (data) => {
      const l = data?.find((lang) => lang[1] === book.lang);
      if (l !== undefined) setLanguageSelected(l);
    },
  });

  const { data: authors, isLoading: isLoadingAuthorDD } = useQuery({
    queryKey: ['authors'],
    queryFn: () => api.author.authorsGet(),
    select: useCallback((data: AxiosResponse<AuthorsGet200Response, any>) => {
      return data.data.data as { id: number; name: string }[];
    }, []),
  });

  const { data: bookCategories, isLoading: isLoadingCategoryDD } = useQuery({
    queryKey: ['categories'],
    queryFn: () => api.category.categoriesGet(),
    select: useCallback((data: AxiosResponse<CategoriesGet200Response, any>) => {
      return data.data.data as { id: number; description: string }[];
    }, []),
  });

  const { data: bookTags, isLoading: isLoadingTagDD } = useQuery({
    queryKey: ['tags'],
    queryFn: () => api.tag.tagsGet(),
    select: useCallback((data: AxiosResponse<TagsGet200Response>) => {
      return data.data.data as { id: number; description: string }[];
    }, []),
  });

  useEffect(() => {
    setValues(formInitialValues);
    setAuthorsSelected(book.authors);
    const l = bookLanguages?.find((lang) => lang[1] === book.lang);
    if (l !== undefined) setLanguageSelected(l);
    setCategorySelected(book.category);
    setTagsSelected(book.tags);
  }, []);

  const editUrl = (newCoverUrl: string) => {
    const newBookData = {
      author_id: book.authors.map((a) => a.id),
      category_id: book.category.id,
      cover: newCoverUrl,
      description: book.description,
      isbn: book.details[0]?.isbn || book.details[0]?.isbn_13,
      lang: book.lang,
      published_at: book.published_at,
      tag_id: book.tags.map((t) => t.id),
      title: book.title,
      numberOfPages: book.details[0].number_of_pages,
    };
    editBookDataMutation.mutate({ newBookData });
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Box sx={{ height: '75px' }}>
        <TextField
          sx={editBookItem}
          size="small"
          label="Title"
          placeholder="Enter the title of the book"
          fullWidth
          id="title"
          value={values.title}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.title}
          helperText={errors.title}
          spellCheck
        />
      </Box>

      <Box sx={{ height: '75px' }}>
        <TextField
          sx={editBookItem}
          size="small"
          label="isbn"
          placeholder="Enter the isbn of the book"
          fullWidth
          id="isbn"
          value={values.isbn}
          disabled
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.isbn}
          helperText={errors.isbn}
        />
      </Box>

      <Box sx={{ height: '75px' }}>
        {isLoadingAuthorDD ? (
          <Box>Loading...</Box>
        ) : (
          <Autocomplete
            size="small"
            multiple
            limitTags={3}
            id="author_id"
            value={authorsSelected}
            onChange={
              // eslint-disable-next-line @typescript-eslint/no-shadow
              (event, values) => {
                setAuthorsSelected(values);
                setFieldValue(
                  'author_id',
                  values.map((a) => a.id),
                );
              }
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={authors!}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={editBookItem}
                label="Authors"
                placeholder="Enter the name of the author"
                onBlur={handleBlur}
                error={!!errors.author_id}
                helperText={errors.author_id}
              />
            )}
          />
        )}
      </Box>

      <Box sx={{ height: '75px' }}>
        <Grid container>
          <Grid item xs={9} sx={{ paddingRight: '5px' }}>
            <TextField
              sx={editBookItem}
              size="small"
              label="Cover url"
              placeholder="Enter the URL of the cover"
              fullWidth
              id="cover"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.cover}
              error={!!errors.cover}
              helperText={errors.cover}
            />
          </Grid>
          <Grid item xs={3} sx={{ paddingLeft: '5px', marginTop: '12px' }}>
            <Button onClick={() => editUrl(values.cover)} fullWidth sx={editUrlButtonSx}>
              Edit url
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ height: '75px' }}>
        <Grid container>
          <Grid item xs={6} sx={{ paddingRight: '10px' }}>
            {isFetchingBookLangDD ? (
              <Box>Loading...</Box>
            ) : (
              <Autocomplete
                size="small"
                fullWidth
                disableClearable
                id="lang"
                value={languageSelected}
                isOptionEqualToValue={(option, value) => option[1] === value[1]}
                options={bookLanguages!}
                getOptionLabel={(option) => option[0]}
                renderOption={(props, option) => (
                  <li {...props} key={option[1]}>
                    {option[0]}
                  </li>
                )}
                onChange={(event, value) => {
                  setLanguageSelected(value);
                  setFieldValue('lang', value[1]);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={editBookItem}
                    label="Language"
                    onBlur={handleBlur}
                    error={!!errors.lang}
                    helperText={errors.lang}
                  />
                )}
              />
            )}
          </Grid>

          <Grid item xs={6} sx={{ paddingLeft: '10px' }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                openTo="year"
                label="Published year"
                views={['year']}
                minDate={moment('1970-01-01')}
                maxDate={moment()}
                value={moment(values.published_at, 'YYYY')}
                onChange={(newValue) => {
                  if (newValue) {
                    const year = newValue.year();
                    setFieldValue('published_at', year);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    fullWidth
                    sx={editBookItem}
                    id="published_at"
                    onBlur={handleBlur}
                    error={!!errors.published_at}
                    helperText={errors.published_at}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ height: '240px' }}>
        <TextField
          sx={editBookItem}
          size="small"
          label="Description"
          fullWidth
          id="description"
          multiline
          minRows={8}
          maxRows={8}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.description}
          error={!!errors.description}
          helperText={errors.description}
          spellCheck
        />
      </Box>

      <Box sx={{ height: '75px' }}>
        {isLoadingCategoryDD ? (
          <Box>Loading...</Box>
        ) : (
          <Autocomplete
            size="small"
            disableClearable
            fullWidth
            id="category_id"
            value={categorySelected}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={bookCategories!}
            getOptionLabel={(option) => option.description}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.description}
              </li>
            )}
            onChange={(event, value) => {
              setCategorySelected(value);
              if (value) setFieldValue('category_id', value.id);
              else setFieldValue('category_id', value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={editBookItem}
                label="Category"
                placeholder="Select the category"
                onBlur={handleBlur}
                error={!!errors.category_id}
                helperText={errors.category_id}
              />
            )}
          />
        )}
      </Box>

      <Box sx={{ height: '75px' }}>
        {isLoadingTagDD ? (
          <Box>Loading...</Box>
        ) : (
          <Autocomplete
            size="small"
            fullWidth
            multiple
            limitTags={3}
            id="tag_id"
            value={tagsSelected}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={bookTags!}
            getOptionLabel={(option) => option.description}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip sx={tagChipSx} label={option.description} {...getTagProps({ index })} key={index} />
              ))
            }
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.description}
              </li>
            )}
            onChange={(event, value) => {
              setTagsSelected(value);
              setFieldValue(
                'tag_id',
                value.map((t) => t.id),
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={editBookItem}
                label="Tags"
                placeholder="Select the tags"
                onBlur={handleBlur}
                error={!!errors.tag_id}
                helperText={errors.tag_id}
              />
            )}
          />
        )}
      </Box>
      <DialogActions sx={{ paddingRight: 0, mb: 1 }}>
        <Button sx={cancelButtonSx} onClick={handleClose}>
          Close
        </Button>
        <Button sx={saveButtonSx} type="submit" disabled={!isValid}>
          Save
        </Button>
      </DialogActions>
    </Box>
  );
};

export default EditBookDetails;
