/* tslint:disable */
/* eslint-disable */
/**
 * Amdaris Library API
 * Optional multiline or single-line description in [CommonMark](http://commonmark.org/help/) or HTML.
 *
 * The version of the OpenAPI document: 0.1.9
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface ActivityGet200Response
 */
export interface ActivityGet200Response {
  /**
   *
   * @type {Array<ActivityGet200ResponseDataInner>}
   * @memberof ActivityGet200Response
   */
  data: Array<ActivityGet200ResponseDataInner>;
}
/**
 *
 * @export
 * @interface ActivityGet200ResponseDataInner
 */
export interface ActivityGet200ResponseDataInner {
  /**
   *
   * @type {number}
   * @memberof ActivityGet200ResponseDataInner
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ActivityGet200ResponseDataInner
   */
  log_name: string;
  /**
   *
   * @type {string}
   * @memberof ActivityGet200ResponseDataInner
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof ActivityGet200ResponseDataInner
   */
  subject_type: string;
  /**
   *
   * @type {string}
   * @memberof ActivityGet200ResponseDataInner
   */
  event: string;
  /**
   *
   * @type {number}
   * @memberof ActivityGet200ResponseDataInner
   */
  subject_id: number;
  /**
   *
   * @type {string}
   * @memberof ActivityGet200ResponseDataInner
   */
  causer_type: string | null;
  /**
   *
   * @type {number}
   * @memberof ActivityGet200ResponseDataInner
   */
  causer_id: number | null;
  /**
   *
   * @type {ActivityGet200ResponseDataInnerProperties}
   * @memberof ActivityGet200ResponseDataInner
   */
  properties: ActivityGet200ResponseDataInnerProperties;
  /**
   *
   * @type {string}
   * @memberof ActivityGet200ResponseDataInner
   */
  batch_uuid: string | null;
  /**
   *
   * @type {string}
   * @memberof ActivityGet200ResponseDataInner
   */
  created_at: string;
  /**
   *
   * @type {string}
   * @memberof ActivityGet200ResponseDataInner
   */
  updated_at: string;
}
/**
 *
 * @export
 * @interface ActivityGet200ResponseDataInnerProperties
 */
export interface ActivityGet200ResponseDataInnerProperties {
  /**
   *
   * @type {ActivityGet200ResponseDataInnerPropertiesAttributes}
   * @memberof ActivityGet200ResponseDataInnerProperties
   */
  attributes: ActivityGet200ResponseDataInnerPropertiesAttributes;
}
/**
 *
 * @export
 * @interface ActivityGet200ResponseDataInnerPropertiesAttributes
 */
export interface ActivityGet200ResponseDataInnerPropertiesAttributes {
  /**
   *
   * @type {string}
   * @memberof ActivityGet200ResponseDataInnerPropertiesAttributes
   */
  description?: string;
}
/**
 *
 * @export
 * @interface AuthorsGet200Response
 */
export interface AuthorsGet200Response {
  /**
   *
   * @type {Array<AuthorsGet200ResponseDataInner>}
   * @memberof AuthorsGet200Response
   */
  data: Array<AuthorsGet200ResponseDataInner>;
}
/**
 *
 * @export
 * @interface AuthorsGet200ResponseDataInner
 */
export interface AuthorsGet200ResponseDataInner {
  /**
   *
   * @type {number}
   * @memberof AuthorsGet200ResponseDataInner
   */
  id: number;
  /**
   * Author first and last names
   * @type {string}
   * @memberof AuthorsGet200ResponseDataInner
   */
  name: string;
}
/**
 *
 * @export
 * @interface AuthorsGet201Response
 */
export interface AuthorsGet201Response {
  /**
   *
   * @type {AuthorsGet200ResponseDataInner}
   * @memberof AuthorsGet201Response
   */
  data: AuthorsGet200ResponseDataInner;
}
/**
 *
 * @export
 * @interface AuthorsGetRequest
 */
export interface AuthorsGetRequest {
  /**
   * Author full name
   * @type {string}
   * @memberof AuthorsGetRequest
   */
  name: string;
}
/**
 *
 * @export
 * @interface BookEditionsGet200Response
 */
export interface BookEditionsGet200Response {
  /**
   *
   * @type {Array<BookRegisterGet200ResponseDataInnerBookEdition>}
   * @memberof BookEditionsGet200Response
   */
  data: Array<BookRegisterGet200ResponseDataInnerBookEdition>;
}
/**
 *
 * @export
 * @interface BookEditionsGet201Response
 */
export interface BookEditionsGet201Response {
  /**
   *
   * @type {BookRegisterGet200ResponseDataInnerBookEdition}
   * @memberof BookEditionsGet201Response
   */
  data: BookRegisterGet200ResponseDataInnerBookEdition;
}
/**
 *
 * @export
 * @interface BookEditionsGetRequest
 */
export interface BookEditionsGetRequest {
  /**
   *
   * @type {string}
   * @memberof BookEditionsGetRequest
   */
  office_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof BookEditionsGetRequest
   */
  status?: string;
  /**
   * ISBN 10 chars
   * @type {string}
   * @memberof BookEditionsGetRequest
   */
  isbn?: string;
  /**
   * ISBN 13 digits
   * @type {string}
   * @memberof BookEditionsGetRequest
   */
  isbn_13?: string;
  /**
   *
   * @type {number}
   * @memberof BookEditionsGetRequest
   */
  book_id?: number;
  /**
   *
   * @type {string}
   * @memberof BookEditionsGetRequest
   */
  inventory_num?: string;
  /**
   *
   * @type {string}
   * @memberof BookEditionsGetRequest
   */
  type: string | string[];
  /**
   *
   * @type {string}
   * @memberof BookEditionsGetRequest
   */
  file_url?: string;
  /**
   *
   * @type {number}
   * @memberof BookEditionsGetRequest
   */
  number_of_pages?: number;
}
/**
 *
 * @export
 * @interface BookRegisterGet200Response
 */
export interface BookRegisterGet200Response {
  /**
   *
   * @type {Array<BookRegisterGet200ResponseDataInner>}
   * @memberof BookRegisterGet200Response
   */
  data: Array<BookRegisterGet200ResponseDataInner>;
  /**
   *
   * @type {BookRegisterGet200ResponseMeta}
   * @memberof BookRegisterGet200Response
   */
  meta: BookRegisterGet200ResponseMeta;
}
/**
 *
 * @export
 * @interface BookRegisterGet200ResponseMeta
 */
export interface BookRegisterGet200ResponseMeta {
  /**
   *
   * @type {number}
   * @memberof BookRegisterGet200ResponseMeta
   */
  total: number;
}
export interface BookRegisterGet200ResponseDataInner {
  /**
   *
   * @type {number}
   * @memberof BookRegisterGet200ResponseDataInner
   */
  id: number;
  /**
   *
   * @type {BooksGet200ResponseDataInnerCreatedBy}
   * @memberof BookRegisterGet200ResponseDataInner
   */
  user?: BooksGet200ResponseDataInnerCreatedBy;
  /**
   *
   * @type {BookRegisterGet200ResponseDataInnerBookEdition}
   * @memberof BookRegisterGet200ResponseDataInner
   */
  book_edition: BookRegisterGet200ResponseDataInnerBookEdition;
  /**
   *
   * @type {string}
   * @memberof BookRegisterGet200ResponseDataInner
   */
  borrowed_at: string;
  /**
   *
   * @type {string}
   * @memberof BookRegisterGet200ResponseDataInner
   */
  return_before: string;
  /**
   *
   * @type {string}
   * @memberof BookRegisterGet200ResponseDataInner
   */
  return_at?: string;
  /**
   *
   * @type {string}
   * @memberof BookRegisterGet200ResponseDataInner
   */
  return_confirmed_at?: string;
  /**
   *
   * @type {string}
   * @memberof BookRegisterGet200ResponseDataInner
   */
  created_at: string;
}
/**
 *
 * @export
 * @interface BookRegisterGet200ResponseDataInnerBookEdition
 */
export interface BookRegisterGet200ResponseDataInnerBookEdition {
  /**
   *
   * @type {number}
   * @memberof BookRegisterGet200ResponseDataInnerBookEdition
   */
  id: number;
  /**
   *
   * @type {BookRegisterGet200ResponseDataInnerBookEditionOffice}
   * @memberof BookRegisterGet200ResponseDataInnerBookEdition
   */
  office: BookRegisterGet200ResponseDataInnerBookEditionOffice | null;
  /**
   *
   * @type {BooksGet200ResponseDataInner}
   * @memberof BookRegisterGet200ResponseDataInnerBookEdition
   */
  book: BooksGet200ResponseDataInner;
  /**
   * ISBN 10 chars
   * @type {string}
   * @memberof BookRegisterGet200ResponseDataInnerBookEdition
   */
  isbn: string;
  /**
   * ISBN 13 digits
   * @type {string}
   * @memberof BookRegisterGet200ResponseDataInnerBookEdition
   */
  isbn_13: string;
  /**
   *
   * @type {string}
   * @memberof BookRegisterGet200ResponseDataInnerBookEdition
   */
  inventory_num: string;
  /**
   *
   * @type {string}
   * @memberof BookRegisterGet200ResponseDataInnerBookEdition
   */
  status: string;
  /**
   *
   * @type {string}
   * @memberof BookRegisterGet200ResponseDataInnerBookEdition
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof BookRegisterGet200ResponseDataInnerBookEdition
   */
  file_url?: string;
  /**
   *
   * @type {BooksGet200ResponseDataInnerCreatedBy}
   * @memberof BookRegisterGet200ResponseDataInnerBookEdition
   */
  created_by: BooksGet200ResponseDataInnerCreatedBy;
  /**
   *
   * @type {number}
   * @memberof BookRegisterGet200ResponseDataInnerBookEdition
   */
  number_of_pages?: number;
}
/**
 *
 * @export
 * @interface BookRegisterGet200ResponseDataInnerBookEditionOffice
 */
export interface BookRegisterGet200ResponseDataInnerBookEditionOffice {
  /**
   *
   * @type {number}
   * @memberof BookRegisterGet200ResponseDataInnerBookEditionOffice
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof BookRegisterGet200ResponseDataInnerBookEditionOffice
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof BookRegisterGet200ResponseDataInnerBookEditionOffice
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof BookRegisterGet200ResponseDataInnerBookEditionOffice
   */
  country: string;
}
/**
 *
 * @export
 * @interface BookRegisterGet201Response
 */
export interface BookRegisterGet201Response {
  /**
   *
   * @type {BookRegisterGet200ResponseDataInner}
   * @memberof BookRegisterGet201Response
   */
  data: BookRegisterGet200ResponseDataInner;
}
/**
 *
 * @export
 * @interface BookRegisterGetRequest
 */
export interface BookRegisterGetRequest {
  /**
   *
   * @type {number}
   * @memberof BookRegisterGetRequest
   */
  book_edition_id: number;
}
/**
 *
 * @export
 * @interface BookRegisterTopGet200Response
 */
export interface BookRegisterTopGet200Response {
  /**
   *
   * @type {Array<BookRegisterTopGet200ResponseDataInner>}
   * @memberof BookRegisterTopGet200Response
   */
  data: Array<BookRegisterTopGet200ResponseDataInner>;
}
/**
 *
 * @export
 * @interface BookRegisterTopGet200ResponseDataInner
 */
export interface BookRegisterTopGet200ResponseDataInner {
  /**
   *
   * @type {number}
   * @memberof BookRegisterTopGet200ResponseDataInner
   */
  borrowedCount: number;
  /**
   *
   * @type {BookRegisterTopGet200ResponseDataInnerBook}
   * @memberof BookRegisterTopGet200ResponseDataInner
   */
  book: BookRegisterTopGet200ResponseDataInnerBook;
}
/**
 *
 * @export
 * @interface BookRegisterTopGet200ResponseDataInnerBook
 */
export interface BookRegisterTopGet200ResponseDataInnerBook {
  /**
   *
   * @type {number}
   * @memberof BookRegisterTopGet200ResponseDataInnerBook
   */
  id: number;
  /**
   * Book title
   * @type {string}
   * @memberof BookRegisterTopGet200ResponseDataInnerBook
   */
  title: string;
  /**
   * Cover link
   * @type {string}
   * @memberof BookRegisterTopGet200ResponseDataInnerBook
   */
  cover: string;
  /**
   *
   * @type {string}
   * @memberof BookRegisterTopGet200ResponseDataInnerBook
   */
  lang: BookRegisterTopGet200ResponseDataInnerBookLangEnum;
  /**
   * Book description
   * @type {string}
   * @memberof BookRegisterTopGet200ResponseDataInnerBook
   */
  description: string;
  /**
   *
   * @type {Array<AuthorsGet200ResponseDataInner>}
   * @memberof BookRegisterTopGet200ResponseDataInnerBook
   */
  authors: Array<AuthorsGet200ResponseDataInner>;
  /**
   *
   * @type {Array<BooksGet200ResponseDataInnerDetailsInner>}
   * @memberof BookRegisterTopGet200ResponseDataInnerBook
   */
  details: Array<BooksGet200ResponseDataInnerDetailsInner>;
}

export const BookRegisterTopGet200ResponseDataInnerBookLangEnum = {
  En: 'en',
  Bg: 'bg',
  Ro: 'ro',
  Ru: 'ru',
} as const;

export type BookRegisterTopGet200ResponseDataInnerBookLangEnum =
  (typeof BookRegisterTopGet200ResponseDataInnerBookLangEnum)[keyof typeof BookRegisterTopGet200ResponseDataInnerBookLangEnum];

/**
 *
 * @export
 * @interface BooksFiltersGet200Response
 */
export interface BooksFiltersGet200Response {
  /**
   *
   * @type {BooksFiltersGet200ResponseData}
   * @memberof BooksFiltersGet200Response
   */
  data: BooksFiltersGet200ResponseData;
}
/**
 *
 * @export
 * @interface BooksFiltersGet200ResponseData
 */
export interface BooksFiltersGet200ResponseData {
  /**
   *
   * @type {Array<AuthorsGet200ResponseDataInner>}
   * @memberof BooksFiltersGet200ResponseData
   */
  authors: Array<AuthorsGet200ResponseDataInner>;
  /**
   *
   * @type {Array<BooksGet200ResponseDataInnerCategory>}
   * @memberof BooksFiltersGet200ResponseData
   */
  categories: Array<BooksGet200ResponseDataInnerCategory>;
  /**
   *
   * @type {Array<BooksGet200ResponseDataInnerTagsInner>}
   * @memberof BooksFiltersGet200ResponseData
   */
  tags: Array<BooksGet200ResponseDataInnerTagsInner>;
  /**
   *
   * @type {Array<BooksFiltersGet200ResponseDataBookLangsInner>}
   * @memberof BooksFiltersGet200ResponseData
   */
  book_langs: Array<BooksFiltersGet200ResponseDataBookLangsInner>;
  /**
   *
   * @type {string}
   * @memberof BooksFiltersGet200ResponseData
   */
  path?: string;
}
/**
 *
 * @export
 * @interface BooksFiltersGet200ResponseDataBookLangsInner
 */
export interface BooksFiltersGet200ResponseDataBookLangsInner {
  /**
   *
   * @type {string}
   * @memberof BooksFiltersGet200ResponseDataBookLangsInner
   */
  lang: string;
}
/**
 *
 * @export
 * @interface BooksGet200Response
 */
export interface BooksGet200Response {
  /**
   *
   * @type {Array<BooksGet200ResponseDataInner>}
   * @memberof BooksGet200Response
   */
  data: Array<BooksGet200ResponseDataInner>;
  /**
   *
   * @type {BooksGet200ResponseLinks}
   * @memberof BooksGet200Response
   */
  links: BooksGet200ResponseLinks;
  /**
   *
   * @type {BooksGet200ResponseMeta}
   * @memberof BooksGet200Response
   */
  meta: BooksGet200ResponseMeta;
}
/**
 *
 * @export
 * @interface BooksGet200ResponseDataInner
 */
export interface BooksGet200ResponseDataInner {
  /**
   *
   * @type {number}
   * @memberof BooksGet200ResponseDataInner
   */
  id: number;
  /**
   * Book title
   * @type {string}
   * @memberof BooksGet200ResponseDataInner
   */
  title: string;
  /**
   * Cover link
   * @type {string}
   * @memberof BooksGet200ResponseDataInner
   */
  cover: string;
  /**
   *
   * @type {Array<AuthorsGet200ResponseDataInner>}
   * @memberof BooksGet200ResponseDataInner
   */
  authors: Array<AuthorsGet200ResponseDataInner>;
  /**
   *
   * @type {string}
   * @memberof BooksGet200ResponseDataInner
   */
  lang: BooksGet200ResponseDataInnerLangEnum;
  /**
   *
   * @type {BooksGet200ResponseDataInnerCategory}
   * @memberof BooksGet200ResponseDataInner
   */
  category: BooksGet200ResponseDataInnerCategory;
  /**
   *
   * @type {Array<BooksGet200ResponseDataInnerTagsInner>}
   * @memberof BooksGet200ResponseDataInner
   */
  tags: Array<BooksGet200ResponseDataInnerTagsInner>;
  /**
   *
   * @type {Array<BooksGetRequestTypeEnum>}
   * @memberof BooksGet200ResponseDataInner
   */
  types: Array<BooksGetRequestTypeEnum>;
  /**
   * Year when book was published
   * @type {number}
   * @memberof BooksGet200ResponseDataInner
   */
  published_at: number;
  /**
   * Book description
   * @type {string}
   * @memberof BooksGet200ResponseDataInner
   */
  description: string;
  /**
   *
   * @type {BooksGet200ResponseDataInnerCreatedBy}
   * @memberof BooksGet200ResponseDataInner
   */
  created_by: BooksGet200ResponseDataInnerCreatedBy;
  /**
   *
   * @type {Array<BooksGet200ResponseDataInnerDetailsInner>}
   * @memberof BooksGet200ResponseDataInner
   */
  details: Array<BooksGet200ResponseDataInnerDetailsInner>;
}
/**
 *
 * @export
 * @interface BookNamesGet200Response
 */
export interface BookNamesGet200Response {
  /**
   *
   * @type {Array<BookNamesGet200ResponseDataInner>}
   * @memberof BookNamesGet200Response
   */
  data: Array<BookNamesGet200ResponseDataInner>;
}
/**
 *
 * @export
 * @interface BookNamesGet200ResponseDataInner
 */
export interface BookNamesGet200ResponseDataInner {
  /**
   *
   * @type {number}
   * @memberof BookNamesGet200ResponseDataInner
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof BookNamesGet200ResponseDataInner
   */
  name: string;
}

export const BooksGet200ResponseDataInnerLangEnum = {
  En: 'en',
  Bg: 'bg',
  Ro: 'ro',
  Ru: 'ru',
} as const;

export type BooksGet200ResponseDataInnerLangEnum =
  (typeof BooksGet200ResponseDataInnerLangEnum)[keyof typeof BooksGet200ResponseDataInnerLangEnum];

/**
 *
 * @export
 * @interface BooksGet200ResponseDataInnerCategory
 */
export interface BooksGet200ResponseDataInnerCategory {
  /**
   *
   * @type {number}
   * @memberof BooksGet200ResponseDataInnerCategory
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof BooksGet200ResponseDataInnerCategory
   */
  description: string;
}
/**
 *
 * @export
 * @interface BooksGet200ResponseDataInnerCreatedBy
 */
export interface BooksGet200ResponseDataInnerCreatedBy {
  /**
   *
   * @type {number}
   * @memberof BooksGet200ResponseDataInnerCreatedBy
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof BooksGet200ResponseDataInnerCreatedBy
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof BooksGet200ResponseDataInnerCreatedBy
   */
  email: string;
  /**
   *
   * @type {BooksGet200ResponseDataInnerCreatedByRole}
   * @memberof BooksGet200ResponseDataInnerCreatedBy
   */
  role: BooksGet200ResponseDataInnerCreatedByRole;
  /**
   *
   * @type {BooksGet200ResponseDataInnerCreatedByOffice}
   * @memberof BooksGet200ResponseDataInnerCreatedBy
   */
  office: BooksGet200ResponseDataInnerCreatedByOffice;
}
/**
 *
 * @export
 * @interface BooksGet200ResponseDataInnerCreatedByOffice
 */
export interface BooksGet200ResponseDataInnerCreatedByOffice {
  /**
   *
   * @type {number}
   * @memberof BooksGet200ResponseDataInnerCreatedByOffice
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof BooksGet200ResponseDataInnerCreatedByOffice
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof BooksGet200ResponseDataInnerCreatedByOffice
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof BooksGet200ResponseDataInnerCreatedByOffice
   */
  country: string;
}
/**
 *
 * @export
 * @interface BooksGet200ResponseDataInnerCreatedByRole
 */
export interface BooksGet200ResponseDataInnerCreatedByRole {
  /**
   *
   * @type {number}
   * @memberof BooksGet200ResponseDataInnerCreatedByRole
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof BooksGet200ResponseDataInnerCreatedByRole
   */
  name: string;
}
/**
 *
 * @export
 * @interface BooksGet200ResponseDataInnerDetailsInner
 */
export interface BooksGet200ResponseDataInnerDetailsInner {
  /**
   *
   * @type {number}
   * @memberof BooksGet200ResponseDataInnerDetailsInner
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof BooksGet200ResponseDataInnerDetailsInner
   */
  isbn: string;
  /**
   *
   * @type {number}
   * @memberof BooksGet200ResponseDataInnerDetailsInner
   */
  office_id: number | null;
  /**
   *
   * @type {string}
   * @memberof BooksGet200ResponseDataInnerDetailsInner
   */
  isbn_13: string;
  /**
   *
   * @type {string}
   * @memberof BooksGet200ResponseDataInnerDetailsInner
   */
  inventory_num: string;
  /**
   *
   * @type {string}
   * @memberof BooksGet200ResponseDataInnerDetailsInner
   */
  status: string;
  /**
   *
   * @type {string}
   * @memberof BooksGet200ResponseDataInnerDetailsInner
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof BooksGet200ResponseDataInnerDetailsInner
   */
  file_url?: string;
  /**
   *
   * @type {number}
   * @memberof BooksGet200ResponseDataInnerDetailsInner
   */
  number_of_pages?: number;
}
/**
 *
 * @export
 * @interface BooksGet200ResponseDataInnerTagsInner
 */
export interface BooksGet200ResponseDataInnerTagsInner {
  /**
   *
   * @type {number}
   * @memberof BooksGet200ResponseDataInnerTagsInner
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof BooksGet200ResponseDataInnerTagsInner
   */
  description: string;
}
/**
 *
 * @export
 * @interface BooksGet200ResponseLinks
 */
export interface BooksGet200ResponseLinks {
  /**
   *
   * @type {string}
   * @memberof BooksGet200ResponseLinks
   */
  first?: string;
  /**
   *
   * @type {string}
   * @memberof BooksGet200ResponseLinks
   */
  last?: string;
  /**
   *
   * @type {string}
   * @memberof BooksGet200ResponseLinks
   */
  prev?: string | null;
  /**
   *
   * @type {string}
   * @memberof BooksGet200ResponseLinks
   */
  next?: string | null;
}
/**
 *
 * @export
 * @interface BooksGet200ResponseMeta
 */
export interface BooksGet200ResponseMeta {
  /**
   *
   * @type {number}
   * @memberof BooksGet200ResponseMeta
   */
  current_page?: number;
  /**
   *
   * @type {number}
   * @memberof BooksGet200ResponseMeta
   */
  from?: number;
  /**
   *
   * @type {number}
   * @memberof BooksGet200ResponseMeta
   */
  last_page?: number;
  /**
   *
   * @type {Array<BooksGet200ResponseMetaLinksInner>}
   * @memberof BooksGet200ResponseMeta
   */
  links?: Array<BooksGet200ResponseMetaLinksInner>;
  /**
   *
   * @type {string}
   * @memberof BooksGet200ResponseMeta
   */
  path?: string;
  /**
   *
   * @type {number}
   * @memberof BooksGet200ResponseMeta
   */
  per_page?: number;
  /**
   *
   * @type {number}
   * @memberof BooksGet200ResponseMeta
   */
  to?: number;
  /**
   *
   * @type {number}
   * @memberof BooksGet200ResponseMeta
   */
  total?: number;
}
/**
 *
 * @export
 * @interface BooksGet200ResponseMetaLinksInner
 */
export interface BooksGet200ResponseMetaLinksInner {
  /**
   *
   * @type {string}
   * @memberof BooksGet200ResponseMetaLinksInner
   */
  url?: string | null;
  /**
   *
   * @type {string}
   * @memberof BooksGet200ResponseMetaLinksInner
   */
  label?: string;
  /**
   *
   * @type {boolean}
   * @memberof BooksGet200ResponseMetaLinksInner
   */
  active?: boolean;
}
/**
 *
 * @export
 * @interface BooksGet201Response
 */
export interface BooksGet201Response {
  /**
   *
   * @type {BooksGet200ResponseDataInner}
   * @memberof BooksGet201Response
   */
  data: BooksGet200ResponseDataInner;
}
/**
 *
 * @export
 * @interface BooksGetRequest
 */
export interface BooksGetRequest {
  /**
   * Book title
   * @type {string}
   * @memberof BooksGetRequest
   */
  title: string;
  /**
   * Cover file
   * @type {string}
   * @memberof BooksGetRequest
   */
  cover: string;
  /**
   *
   * @type {Array<number>}
   * @memberof BooksGetRequest
   */
  author_id: Array<number>;
  /**
   *
   * @type {string}
   * @memberof BooksGetRequest
   */
  lang: BooksGetRequestLangEnum;
  /**
   *
   * @type {number}
   * @memberof BooksGetRequest
   */
  category_id: number;
  /**
   *
   * @type {Array<number>}
   * @memberof BooksGetRequest
   */
  tag_id: Array<number>;
  /**
   * Year when book was published
   * @type {number}
   * @memberof BooksGetRequest
   */
  published_at: number;
  /**
   * Book description
   * @type {string}
   * @memberof BooksGetRequest
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof BooksGetRequest
   */
  type: BooksGetRequestTypeEnum;
  /**
   * ISBN 10 chars
   * @type {string}
   * @memberof BooksGetRequest
   */
  isbn: string;
  /**
   * ISBN 13 digits
   * @type {string}
   * @memberof BooksGetRequest
   */
  isbn_13: string;
  /**
   *
   * @type {string}
   * @memberof BooksGetRequest
   */
  inventory_num?: string;
  /**
   *
   * @type {string}
   * @memberof BooksGetRequest
   */
  file_url?: string;
  /**
   *
   * @type {number}
   * @memberof BooksGetRequest
   */
  number_of_pages?: number;
}

export const BooksGetRequestLangEnum = {
  En: 'en',
  Bg: 'bg',
  Ro: 'ro',
  Ru: 'ru',
} as const;

export type BooksGetRequestLangEnum = (typeof BooksGetRequestLangEnum)[keyof typeof BooksGetRequestLangEnum];
export const BooksGetRequestTypeEnum = {
  Paper: 'paper',
  Kindle: 'kindle',
  EBook: 'e-book',
} as const;

export type BooksGetRequestTypeEnum = (typeof BooksGetRequestTypeEnum)[keyof typeof BooksGetRequestTypeEnum];

/**
 *
 * @export
 * @interface BooksIdDeleteRequest
 */
export interface BooksIdDeleteRequest {
  /**
   * Book title
   * @type {string}
   * @memberof BooksIdDeleteRequest
   */
  title?: string;
  /**
   * Cover file
   * @type {string}
   * @memberof BooksIdDeleteRequest
   */
  cover?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof BooksIdDeleteRequest
   */
  author_id?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof BooksIdDeleteRequest
   */
  lang?: BooksIdDeleteRequestLangEnum;
  /**
   *
   * @type {number}
   * @memberof BooksIdDeleteRequest
   */
  category_id?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof BooksIdDeleteRequest
   */
  tag_id?: Array<number>;
  /**
   * Year when book was published
   * @type {number}
   * @memberof BooksIdDeleteRequest
   */
  published_at?: number;
  /**
   * Book description
   * @type {string}
   * @memberof BooksIdDeleteRequest
   */
  description?: string;
}

export const BooksIdDeleteRequestLangEnum = {
  En: 'en',
  Bg: 'bg',
  Ro: 'ro',
  Ru: 'ru',
} as const;

export type BooksIdDeleteRequestLangEnum =
  (typeof BooksIdDeleteRequestLangEnum)[keyof typeof BooksIdDeleteRequestLangEnum];

/**
 *
 * @export
 * @interface CategoriesGet200Response
 */
export interface CategoriesGet200Response {
  /**
   *
   * @type {Array<BooksGet200ResponseDataInnerCategory>}
   * @memberof CategoriesGet200Response
   */
  data: Array<BooksGet200ResponseDataInnerCategory>;
}
/**
 *
 * @export
 * @interface CategoriesGet201Response
 */
export interface CategoriesGet201Response {
  /**
   *
   * @type {BooksGet200ResponseDataInnerCategory}
   * @memberof CategoriesGet201Response
   */
  data: BooksGet200ResponseDataInnerCategory;
}
/**
 *
 * @export
 * @interface CategoriesGetRequest
 */
export interface CategoriesGetRequest {
  /**
   *
   * @type {string}
   * @memberof CategoriesGetRequest
   */
  description: string;
}
/**
 *
 * @export
 * @interface DropdownsGet200Response
 */
export interface DropdownsGet200Response {
  /**
   *
   * @type {Array<DropdownsGet200ResponseDataInner>}
   * @memberof DropdownsGet200Response
   */
  data: Array<DropdownsGet200ResponseDataInner>;
}
/**
 *
 * @export
 * @interface DropdownsGet200ResponseDataInner
 */
export interface DropdownsGet200ResponseDataInner {
  /**
   *
   * @type {string}
   * @memberof DropdownsGet200ResponseDataInner
   */
  name: string;
  /**
   *
   * @type {DropdownsGet200ResponseDataInnerValues}
   * @memberof DropdownsGet200ResponseDataInner
   */
  values: DropdownsGet200ResponseDataInnerValues;
}
/**
 *
 * @export
 * @interface DropdownsGet200ResponseDataInnerValues
 */
export interface DropdownsGet200ResponseDataInnerValues {
  /**
   *
   * @type {string}
   * @memberof DropdownsGet200ResponseDataInnerValues
   */
  English?: string;
  /**
   *
   * @type {string}
   * @memberof DropdownsGet200ResponseDataInnerValues
   */
  Bulgarian?: string;
  /**
   *
   * @type {string}
   * @memberof DropdownsGet200ResponseDataInnerValues
   */
  Romanian?: string;
  /**
   *
   * @type {string}
   * @memberof DropdownsGet200ResponseDataInnerValues
   */
  Russian?: string;
}
/**
 *
 * @export
 * @interface FeedbacksGet200Response
 */
export interface FeedbacksGet200Response {
  /**
   *
   * @type {Array<FeedbacksGet200ResponseDataInner>}
   * @memberof FeedbacksGet200Response
   */
  data: Array<FeedbacksGet200ResponseDataInner>;
}
/**
 *
 * @export
 * @interface FeedbacksGet200ResponseDataInner
 */
export interface FeedbacksGet200ResponseDataInner {
  /**
   *
   * @type {number}
   * @memberof FeedbacksGet200ResponseDataInner
   */
  id?: number;
  /**
   *
   * @type {BooksGet200ResponseDataInnerCreatedBy}
   * @memberof FeedbacksGet200ResponseDataInner
   */
  user?: BooksGet200ResponseDataInnerCreatedBy;
  /**
   *
   * @type {string}
   * @memberof FeedbacksGet200ResponseDataInner
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof FeedbacksGet200ResponseDataInner
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof FeedbacksGet200ResponseDataInner
   */
  created_at: string;
}
/**
 *
 * @export
 * @interface FeedbacksGet201Response
 */
export interface FeedbacksGet201Response {
  /**
   *
   * @type {FeedbacksGet200ResponseDataInner}
   * @memberof FeedbacksGet201Response
   */
  data: FeedbacksGet200ResponseDataInner;
}
/**
 *
 * @export
 * @interface FeedbacksGetRequest
 */
export interface FeedbacksGetRequest {
  /**
   *
   * @type {string}
   * @memberof FeedbacksGetRequest
   */
  message: string;
}
/**
 *
 * @export
 * @interface FeedbacksIdDeleteRequest
 */
export interface FeedbacksIdDeleteRequest {
  /**
   *
   * @type {string}
   * @memberof FeedbacksIdDeleteRequest
   */
  status: string;
}
/**
 *
 * @export
 * @interface EmailGetRequest
 */
export interface EmailGetRequest {
  /**
   *
   * @type {string}
   * @memberof EmailGetRequest
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof EmailGetRequest
   */
  librarian_email: string;
}
/**
 *
 * @export
 * @interface EmailGet201Response
 */
export interface EmailGet201Response {
  /**
   *
   * @type {EmailGetRequest}
   * @memberof EmailGet201Response
   */
  data: EmailGetRequest;
}
/**
 *
 * @export
 * @interface KindleRegisterGet200Response
 */
export interface KindleRegisterGet200Response {
  /**
   *
   * @type {Array<KindleRegisterGet200ResponseDataInner>}
   * @memberof KindleRegisterGet200Response
   */
  data: Array<KindleRegisterGet200ResponseDataInner>;
}
/**
 *
 * @export
 * @interface KindleRegisterGet200ResponseDataInner
 */
export interface KindleRegisterGet200ResponseDataInner {
  /**
   *
   * @type {number}
   * @memberof KindleRegisterGet200ResponseDataInner
   */
  id: number;
  /**
   *
   * @type {BooksGet200ResponseDataInnerCreatedBy}
   * @memberof KindleRegisterGet200ResponseDataInner
   */
  user: BooksGet200ResponseDataInnerCreatedBy;
  /**
   *
   * @type {KindlesGet200ResponseDataInner}
   * @memberof KindleRegisterGet200ResponseDataInner
   */
  kindle: KindlesGet200ResponseDataInner;
  /**
   *
   * @type {string}
   * @memberof KindleRegisterGet200ResponseDataInner
   */
  borrowed_at: string;
  /**
   *
   * @type {string}
   * @memberof KindleRegisterGet200ResponseDataInner
   */
  return_before: string;
  /**
   *
   * @type {string}
   * @memberof KindleRegisterGet200ResponseDataInner
   */
  return_at?: string;
  /**
   *
   * @type {string}
   * @memberof KindleRegisterGet200ResponseDataInner
   */
  return_confirmed_at?: string;
}
/**
 *
 * @export
 * @interface KindleRegisterGet201Response
 */
export interface KindleRegisterGet201Response {
  /**
   *
   * @type {KindleRegisterGet200ResponseDataInner}
   * @memberof KindleRegisterGet201Response
   */
  data: KindleRegisterGet200ResponseDataInner;
}
/**
 *
 * @export
 * @interface KindleRegisterGetRequest
 */
export interface KindleRegisterGetRequest {
  /**
   *
   * @type {number}
   * @memberof KindleRegisterGetRequest
   */
  kindle_id: number;
}
/**
 *
 * @export
 * @interface KindlesBorrowedGet200Response
 */
export interface KindlesBorrowedGet200Response {
  /**
   *
   * @type {Array<KindlesBorrowedGet200ResponseDataInner>}
   * @memberof KindlesBorrowedGet200Response
   */
  data: Array<KindlesBorrowedGet200ResponseDataInner>;
  /**
   *
   * @type {KindlesBorrowedGet200ResponseMeta}
   * @memberof KindlesBorrowedGet200Response
   */
  meta: KindlesBorrowedGet200ResponseMeta;
}
/**
 *
 * @export
 * @interface KindlesBorrowedGet200ResponseMeta
 */
export interface KindlesBorrowedGet200ResponseMeta {
  /**
   *
   * @type {number}
   * @memberof KindlesBorrowedGet200ResponseMeta
   */
  total: number;
}
/**
 *
 * @export
 * @interface KindlesBorrowedGet200ResponseDataInner
 */
export interface KindlesBorrowedGet200ResponseDataInner {
  /**
   *
   * @type {number}
   * @memberof KindlesBorrowedGet200ResponseDataInner
   */
  id: number;
  /**
   *
   * @type {BooksGet200ResponseDataInnerCreatedByOffice}
   * @memberof KindlesBorrowedGet200ResponseDataInner
   */
  office: BooksGet200ResponseDataInnerCreatedByOffice;
  /**
   *
   * @type {number}
   * @memberof KindlesBorrowedGet200ResponseDataInner
   */
  inventory_num: number;
  /**
   *
   * @type {string}
   * @memberof KindlesBorrowedGet200ResponseDataInner
   */
  status: string;
  /**
   *
   * @type {BooksGet200ResponseDataInnerCreatedBy}
   * @memberof KindlesBorrowedGet200ResponseDataInner
   */
  created_by: BooksGet200ResponseDataInnerCreatedBy;
  /**
   *
   * @type {string}
   * @memberof KindlesBorrowedGet200ResponseDataInner
   */
  user_name?: string;
  /**
   *
   * @type {number}
   * @memberof KindlesBorrowedGet200ResponseDataInner
   */
  user_id?: number;
  /**
   *
   * @type {string}
   * @memberof KindlesBorrowedGet200ResponseDataInner
   */
  borrowed_at?: string;
  /**
   *
   * @type {string}
   * @memberof KindlesBorrowedGet200ResponseDataInner
   */
  return_before?: string;
  /**
   *
   * @type {number}
   * @memberof KindlesBorrowedGet200ResponseDataInner
   */
  kindle_register_id?: number;
}
/**
 *
 * @export
 * @interface KindlesGet200Response
 */
export interface KindlesGet200Response {
  /**
   *
   * @type {Array<KindlesGet200ResponseDataInner>}
   * @memberof KindlesGet200Response
   */
  data: Array<KindlesGet200ResponseDataInner>;
}
/**
 *
 * @export
 * @interface KindlesGet200ResponseDataInner
 */
export interface KindlesGet200ResponseDataInner {
  /**
   *
   * @type {number}
   * @memberof KindlesGet200ResponseDataInner
   */
  id: number;
  /**
   *
   * @type {BooksGet200ResponseDataInnerCreatedByOffice}
   * @memberof KindlesGet200ResponseDataInner
   */
  office: BooksGet200ResponseDataInnerCreatedByOffice;
  /**
   *
   * @type {number}
   * @memberof KindlesGet200ResponseDataInner
   */
  inventory_num: number;
  /**
   *
   * @type {string}
   * @memberof KindlesGet200ResponseDataInner
   */
  status: string;
  /**
   *
   * @type {BooksGet200ResponseDataInnerCreatedBy}
   * @memberof KindlesGet200ResponseDataInner
   */
  created_by: BooksGet200ResponseDataInnerCreatedBy;
}
/**
 *
 * @export
 * @interface KindlesGet201Response
 */
export interface KindlesGet201Response {
  /**
   *
   * @type {KindlesGet200ResponseDataInner}
   * @memberof KindlesGet201Response
   */
  data: KindlesGet200ResponseDataInner;
}
/**
 *
 * @export
 * @interface KindlesGetRequest
 */
export interface KindlesGetRequest {
  /**
   *
   * @type {number}
   * @memberof KindlesGetRequest
   */
  inventory_num: number;
}
/**
 *
 * @export
 * @interface OfficesGet200Response
 */
export interface OfficesGet200Response {
  /**
   *
   * @type {Array<BooksGet200ResponseDataInnerCreatedByOffice>}
   * @memberof OfficesGet200Response
   */
  data: Array<BooksGet200ResponseDataInnerCreatedByOffice>;
}
/**
 *
 * @export
 * @interface OfficesGet201Response
 */
export interface OfficesGet201Response {
  /**
   *
   * @type {BooksGet200ResponseDataInnerCreatedByOffice}
   * @memberof OfficesGet201Response
   */
  data: BooksGet200ResponseDataInnerCreatedByOffice;
}
/**
 *
 * @export
 * @interface OfficesGetRequest
 */
export interface OfficesGetRequest {
  /**
   *
   * @type {string}
   * @memberof OfficesGetRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof OfficesGetRequest
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof OfficesGetRequest
   */
  country: string;
}
/**
 *
 * @export
 * @interface ReportsAverageBorrowingPeriodGet200Response
 */
export interface ReportsAverageBorrowingPeriodGet200Response {
  /**
   *
   * @type {Array<ReportsAverageBorrowingPeriodGet200ResponseDataInner>}
   * @memberof ReportsAverageBorrowingPeriodGet200Response
   */
  data: Array<ReportsAverageBorrowingPeriodGet200ResponseDataInner>;
}
/**
 *
 * @export
 * @interface ReportsAverageBorrowingPeriodGet200ResponseDataInner
 */
export interface ReportsAverageBorrowingPeriodGet200ResponseDataInner {
  /**
   *
   * @type {string}
   * @memberof ReportsAverageBorrowingPeriodGet200ResponseDataInner
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof ReportsAverageBorrowingPeriodGet200ResponseDataInner
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof ReportsAverageBorrowingPeriodGet200ResponseDataInner
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ReportsAverageBorrowingPeriodGet200ResponseDataInner
   */
  tags?: string;
  /**
   *
   * @type {string}
   * @memberof ReportsAverageBorrowingPeriodGet200ResponseDataInner
   */
  authors?: string;
  /**
   *
   * @type {number}
   * @memberof ReportsAverageBorrowingPeriodGet200ResponseDataInner
   */
  average_borrowing_period?: number;
}
/**
 *
 * @export
 * @interface ReportsFrequencyGet200Response
 */
export interface ReportsFrequencyGet200Response {
  /**
   *
   * @type {Array<ReportsFrequencyGet200ResponseDataInner>}
   * @memberof ReportsFrequencyGet200Response
   */
  data: Array<ReportsFrequencyGet200ResponseDataInner>;
}
/**
 *
 * @export
 * @interface ReportsFrequencyGet200ResponseDataInner
 */
export interface ReportsFrequencyGet200ResponseDataInner {
  /**
   *
   * @type {string}
   * @memberof ReportsFrequencyGet200ResponseDataInner
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof ReportsFrequencyGet200ResponseDataInner
   */
  authors?: string;
  /**
   *
   * @type {string}
   * @memberof ReportsFrequencyGet200ResponseDataInner
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof ReportsFrequencyGet200ResponseDataInner
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ReportsFrequencyGet200ResponseDataInner
   */
  tags?: string;
  /**
   *
   * @type {number}
   * @memberof ReportsFrequencyGet200ResponseDataInner
   */
  book_registers_count?: number;
}
/**
 *
 * @export
 * @interface ReportsReadersGet200Response
 */
export interface ReportsReadersGet200Response {
  /**
   *
   * @type {Array<ReportsReadersGet200ResponseDataInner>}
   * @memberof ReportsReadersGet200Response
   */
  data: Array<ReportsReadersGet200ResponseDataInner>;
}
/**
 *
 * @export
 * @interface ReportsReadersGet200ResponseDataInner
 */
export interface ReportsReadersGet200ResponseDataInner {
  /**
   *
   * @type {string}
   * @memberof ReportsReadersGet200ResponseDataInner
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof ReportsReadersGet200ResponseDataInner
   */
  total_number?: number;
  /**
   *
   * @type {number}
   * @memberof ReportsReadersGet200ResponseDataInner
   */
  returned_books?: number;
  /**
   *
   * @type {number}
   * @memberof ReportsReadersGet200ResponseDataInner
   */
  not_returned_on_time?: number;
}
/**
 *
 * @export
 * @interface SettingsGet200Response
 */
export interface SettingsGet200Response {
  /**
   *
   * @type {Array<SettingsGet200ResponseDataInner>}
   * @memberof SettingsGet200Response
   */
  data: Array<SettingsGet200ResponseDataInner>;
}
/**
 *
 * @export
 * @interface SettingsGet200ResponseDataInner
 */
export interface SettingsGet200ResponseDataInner {
  /**
   *
   * @type {number}
   * @memberof SettingsGet200ResponseDataInner
   */
  id: number;
  /**
   *
   * @type {BooksGet200ResponseDataInnerCreatedByOffice}
   * @memberof SettingsGet200ResponseDataInner
   */
  office_id: BooksGet200ResponseDataInnerCreatedByOffice;
  /**
   *
   * @type {number}
   * @memberof SettingsGet200ResponseDataInner
   */
  max_books: number;
  /**
   *
   * @type {number}
   * @memberof SettingsGet200ResponseDataInner
   */
  max_term_books: number;
  /**
   *
   * @type {number}
   * @memberof SettingsGet200ResponseDataInner
   */
  max_kindles: number;
  /**
   *
   * @type {number}
   * @memberof SettingsGet200ResponseDataInner
   */
  max_term_kindles: number;
}
/**
 *
 * @export
 * @interface SettingsGet201Response
 */
export interface SettingsGet201Response {
  /**
   *
   * @type {SettingsGet200ResponseDataInner}
   * @memberof SettingsGet201Response
   */
  data: SettingsGet200ResponseDataInner;
}
/**
 *
 * @export
 * @interface SettingsGetRequest
 */
export interface SettingsGetRequest {
  /**
   *
   * @type {number}
   * @memberof SettingsGetRequest
   */
  office_id?: number;
  /**
   *
   * @type {number}
   * @memberof SettingsGetRequest
   */
  max_books?: number;
  /**
   *
   * @type {number}
   * @memberof SettingsGetRequest
   */
  max_term_books?: number;
  /**
   *
   * @type {number}
   * @memberof SettingsGetRequest
   */
  max_term_kindles?: number;
}
/**
 *
 * @export
 * @interface SubscriptionsGet200Response
 */
export interface SubscriptionsGet200Response {
  /**
   *
   * @type {Array<SubscriptionsGet200ResponseDataInner>}
   * @memberof SubscriptionsGet200Response
   */
  data: Array<SubscriptionsGet200ResponseDataInner>;
}
/**
 *
 * @export
 * @interface SubscriptionsGet200ResponseDataInner
 */
export interface SubscriptionsGet200ResponseDataInner {
  /**
   *
   * @type {number}
   * @memberof SubscriptionsGet200ResponseDataInner
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof SubscriptionsGet200ResponseDataInner
   */
  user_id?: number;
  /**
   *
   * @type {string}
   * @memberof SubscriptionsGet200ResponseDataInner
   */
  notification_name?: string;
  /**
   *
   * @type {boolean}
   * @memberof SubscriptionsGet200ResponseDataInner
   */
  status?: boolean;
}
/**
 *
 * @export
 * @interface SubscriptionsGet201Response
 */
export interface SubscriptionsGet201Response {
  /**
   *
   * @type {SubscriptionsGet200ResponseDataInner}
   * @memberof SubscriptionsGet201Response
   */
  data: SubscriptionsGet200ResponseDataInner;
}
/**
 *
 * @export
 * @interface SubscriptionsGetRequest
 */
export interface SubscriptionsGetRequest {
  /**
   *
   * @type {string}
   * @memberof SubscriptionsGetRequest
   */
  notification_name: string;
  /**
   *
   * @type {boolean}
   * @memberof SubscriptionsGetRequest
   */
  status: boolean;
}
/**
 *
 * @export
 * @interface SubscriptionsIdPatchRequest
 */
export interface SubscriptionsIdPatchRequest {
  /**
   *
   * @type {boolean}
   * @memberof SubscriptionsIdPatchRequest
   */
  status: boolean;
}
/**
 *
 * @export
 * @interface SuggestedBooksGet200Response
 */
export interface SuggestedBooksGet200Response {
  /**
   *
   * @type {Array<SuggestedBooksGet200ResponseDataInner>}
   * @memberof SuggestedBooksGet200Response
   */
  data: Array<SuggestedBooksGet200ResponseDataInner>;
}
/**
 *
 * @export
 * @interface SuggestedBooksGet200ResponseDataInner
 */
export interface SuggestedBooksGet200ResponseDataInner {
  /**
   *
   * @type {number}
   * @memberof SuggestedBooksGet200ResponseDataInner
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof SuggestedBooksGet200ResponseDataInner
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof SuggestedBooksGet200ResponseDataInner
   */
  author: string;
  /**
   *
   * @type {number}
   * @memberof SuggestedBooksGet200ResponseDataInner
   */
  published_at?: number;
  /**
   *
   * @type {string}
   * @memberof SuggestedBooksGet200ResponseDataInner
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof SuggestedBooksGet200ResponseDataInner
   */
  status?: string;
  /**
   *
   * @type {BooksGet200ResponseDataInnerCreatedBy}
   * @memberof SuggestedBooksGet200ResponseDataInner
   */
  user?: BooksGet200ResponseDataInnerCreatedBy;
}
/**
 *
 * @export
 * @interface SuggestedBooksGet201Response
 */
export interface SuggestedBooksGet201Response {
  /**
   *
   * @type {SuggestedBooksGet200ResponseDataInner}
   * @memberof SuggestedBooksGet201Response
   */
  data: SuggestedBooksGet200ResponseDataInner;
}
/**
 *
 * @export
 * @interface SuggestedBooksGetRequest
 */
export interface SuggestedBooksGetRequest {
  /**
   *
   * @type {string}
   * @memberof SuggestedBooksGetRequest
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof SuggestedBooksGetRequest
   */
  author?: string;
  /**
   *
   * @type {number}
   * @memberof SuggestedBooksGetRequest
   */
  published_at?: number;
  /**
   *
   * @type {string}
   * @memberof SuggestedBooksGetRequest
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof SuggestedBooksGetRequest
   */
  status?: string;
}
/**
 *
 * @export
 * @interface TagsGet200Response
 */
export interface TagsGet200Response {
  /**
   *
   * @type {Array<BooksGet200ResponseDataInnerTagsInner>}
   * @memberof TagsGet200Response
   */
  data: Array<BooksGet200ResponseDataInnerTagsInner>;
}
/**
 *
 * @export
 * @interface TagsGet201Response
 */
export interface TagsGet201Response {
  /**
   *
   * @type {BooksGet200ResponseDataInnerTagsInner}
   * @memberof TagsGet201Response
   */
  data: BooksGet200ResponseDataInnerTagsInner;
}
/**
 *
 * @export
 * @interface TagsGetRequest
 */
export interface TagsGetRequest {
  /**
   *
   * @type {string}
   * @memberof TagsGetRequest
   */
  description: string;
}
/**
 *
 * @export
 * @interface UsersGet200Response
 */
export interface UsersGet200Response {
  /**
   *
   * @type {Array<BooksGet200ResponseDataInnerCreatedBy>}
   * @memberof UsersGet200Response
   */
  data: Array<BooksGet200ResponseDataInnerCreatedBy>;
  /**
   *
   * @type {BooksGet200ResponseLinks}
   * @memberof UsersGet200Response
   */
  links: BooksGet200ResponseLinks;
  /**
   *
   * @type {BooksGet200ResponseMeta}
   * @memberof UsersGet200Response
   */
  meta: BooksGet200ResponseMeta;
}
/**
 *
 * @export
 * @interface UsersIdGet200Response
 */
export interface UsersIdGet200Response {
  /**
   *
   * @type {BooksGet200ResponseDataInnerCreatedBy}
   * @memberof UsersIdGet200Response
   */
  data: BooksGet200ResponseDataInnerCreatedBy;
}
/**
 *
 * @export
 * @interface UsersIdGetRequest
 */
export interface UsersIdGetRequest {
  /**
   *
   * @type {number}
   * @memberof UsersIdGetRequest
   */
  role_id?: number;
  /**
   *
   * @type {number}
   * @memberof UsersIdGetRequest
   */
  office_id?: number;
}
/**
 *
 * @export
 * @interface UsersLibrarianContactsGet200Response
 */
export interface UsersLibrarianContactsGet200Response {
  /**
   *
   * @type {Array<BooksGet200ResponseDataInnerCreatedBy>}
   * @memberof UsersLibrarianContactsGet200Response
   */
  data: Array<BooksGet200ResponseDataInnerCreatedBy>;
}
/**
 *
 * @export
 * @interface WishlistsGet200Response
 */
export interface WishlistsGet200Response {
  /**
   *
   * @type {Array<WishlistsGet200ResponseDataInner>}
   * @memberof WishlistsGet200Response
   */
  data: Array<WishlistsGet200ResponseDataInner>;
}
/**
 *
 * @export
 * @interface WishlistsGet200ResponseDataInner
 */
export interface WishlistsGet200ResponseDataInner {
  /**
   *
   * @type {number}
   * @memberof WishlistsGet200ResponseDataInner
   */
  id: number;
  /**
   *
   * @type {BookRegisterGet200ResponseDataInnerBookEdition}
   * @memberof WishlistsGet200ResponseDataInner
   */
  book_edition: BookRegisterGet200ResponseDataInnerBookEdition;
  /**
   *
   * @type {BooksGet200ResponseDataInnerCreatedBy}
   * @memberof WishlistsGet200ResponseDataInner
   */
  user: BooksGet200ResponseDataInnerCreatedBy;
}
/**
 *
 * @export
 * @interface WishlistsGet201Response
 */
export interface WishlistsGet201Response {
  /**
   *
   * @type {WishlistsGet200ResponseDataInner}
   * @memberof WishlistsGet201Response
   */
  data: WishlistsGet200ResponseDataInner;
}
/**
 *
 * @export
 * @interface WishlistsGetRequest
 */
export interface WishlistsGetRequest {
  /**
   * BookEdition id
   * @type {number}
   * @memberof WishlistsGetRequest
   */
  book_edition_id: number;
}
/**
 *
 * @export
 * @interface WishlistsGetUserIdParameter
 */
export interface WishlistsGetUserIdParameter {
  /**
   *
   * @type {number}
   * @memberof WishlistsGetUserIdParameter
   */
  id?: number;
  /**
   *
   * @type {BookRegisterGet200ResponseDataInnerBookEdition}
   * @memberof WishlistsGetUserIdParameter
   */
  book_edition?: BookRegisterGet200ResponseDataInnerBookEdition;
  /**
   *
   * @type {BooksGet200ResponseDataInnerCreatedBy}
   * @memberof WishlistsGetUserIdParameter
   */
  user?: BooksGet200ResponseDataInnerCreatedBy;
}
/**
 *
 * @export
 * @interface WishlistsMultiplePostRequest
 */
export interface WishlistsMultiplePostRequest {
  /**
   *
   * @type {Array<number>}
   * @memberof WishlistsMultiplePostRequest
   */
  book_edition_ids: Array<number>;
}

/**
 * ActivityApi - axios parameter creator
 * @export
 */
export const ActivityApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get list of activities
     * @summary Get list of activities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activityGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/activity`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ActivityApi - functional programming interface
 * @export
 */
export const ActivityApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ActivityApiAxiosParamCreator(configuration);
  return {
    /**
     * Get list of activities
     * @summary Get list of activities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async activityGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.activityGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ActivityApi - factory interface
 * @export
 */
export const ActivityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ActivityApiFp(configuration);
  return {
    /**
     * Get list of activities
     * @summary Get list of activities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activityGet(options?: AxiosRequestConfig): AxiosPromise<ActivityGet200Response> {
      return localVarFp.activityGet(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * ActivityApi - object-oriented interface
 * @export
 * @class ActivityApi
 * @extends {BaseAPI}
 */
export class ActivityApi extends BaseAPI {
  /**
   * Get list of activities
   * @summary Get list of activities
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivityApi
   */
  public activityGet(options?: AxiosRequestConfig) {
    return ActivityApiFp(this.configuration)
      .activityGet(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AuthorApi - axios parameter creator
 * @export
 */
export const AuthorApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get list of authors
     * @summary Get list of authors
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authorsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/authors`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete author by id
     * @summary Delete author by id
     * @param {number} id Author id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authorsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('authorsIdDelete', 'id', id);
      const localVarPath = `/authors/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get author by id
     * @summary Get author by id
     * @param {number} id Author id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authorsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('authorsIdGet', 'id', id);
      const localVarPath = `/authors/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update author by id
     * @summary Update author by id
     * @param {number} id Author id
     * @param {AuthorsGetRequest} authorsGetRequest Update author by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authorsIdPatch: async (
      id: number,
      authorsGetRequest: AuthorsGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('authorsIdPatch', 'id', id);
      // verify required parameter 'authorsGetRequest' is not null or undefined
      assertParamExists('authorsIdPatch', 'authorsGetRequest', authorsGetRequest);
      const localVarPath = `/authors/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(authorsGetRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a new author
     * @summary Create a new author
     * @param {AuthorsGetRequest} authorsGetRequest Create a new author
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authorsPost: async (
      authorsGetRequest: AuthorsGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorsGetRequest' is not null or undefined
      assertParamExists('authorsPost', 'authorsGetRequest', authorsGetRequest);
      const localVarPath = `/authors`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(authorsGetRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuthorApi - functional programming interface
 * @export
 */
export const AuthorApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthorApiAxiosParamCreator(configuration);
  return {
    /**
     * Get list of authors
     * @summary Get list of authors
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authorsGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorsGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authorsGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Delete author by id
     * @summary Delete author by id
     * @param {number} id Author id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authorsIdDelete(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authorsIdDelete(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get author by id
     * @summary Get author by id
     * @param {number} id Author id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authorsIdGet(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorsGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authorsIdGet(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Update author by id
     * @summary Update author by id
     * @param {number} id Author id
     * @param {AuthorsGetRequest} authorsGetRequest Update author by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authorsIdPatch(
      id: number,
      authorsGetRequest: AuthorsGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorsGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authorsIdPatch(id, authorsGetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Create a new author
     * @summary Create a new author
     * @param {AuthorsGetRequest} authorsGetRequest Create a new author
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authorsPost(
      authorsGetRequest: AuthorsGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorsGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authorsPost(authorsGetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AuthorApi - factory interface
 * @export
 */
export const AuthorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AuthorApiFp(configuration);
  return {
    /**
     * Get list of authors
     * @summary Get list of authors
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authorsGet(options?: AxiosRequestConfig): AxiosPromise<AuthorsGet200Response> {
      return localVarFp.authorsGet(options).then((request) => request(axios, basePath));
    },
    /**
     * Delete author by id
     * @summary Delete author by id
     * @param {AuthorApiAuthorsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authorsIdDelete(
      requestParameters: AuthorApiAuthorsIdDeleteRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp.authorsIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
    },
    /**
     * Get author by id
     * @summary Get author by id
     * @param {AuthorApiAuthorsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authorsIdGet(
      requestParameters: AuthorApiAuthorsIdGetRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<AuthorsGet201Response> {
      return localVarFp.authorsIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
    },
    /**
     * Update author by id
     * @summary Update author by id
     * @param {AuthorApiAuthorsIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authorsIdPatch(
      requestParameters: AuthorApiAuthorsIdPatchRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<AuthorsGet201Response> {
      return localVarFp
        .authorsIdPatch(requestParameters.id, requestParameters.authorsGetRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create a new author
     * @summary Create a new author
     * @param {AuthorApiAuthorsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authorsPost(
      requestParameters: AuthorApiAuthorsPostRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<AuthorsGet201Response> {
      return localVarFp
        .authorsPost(requestParameters.authorsGetRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for authorsIdDelete operation in AuthorApi.
 * @export
 * @interface AuthorApiAuthorsIdDeleteRequest
 */
export interface AuthorApiAuthorsIdDeleteRequest {
  /**
   * Author id
   * @type {number}
   * @memberof AuthorApiAuthorsIdDelete
   */
  readonly id: number;
}

/**
 * Request parameters for authorsIdGet operation in AuthorApi.
 * @export
 * @interface AuthorApiAuthorsIdGetRequest
 */
export interface AuthorApiAuthorsIdGetRequest {
  /**
   * Author id
   * @type {number}
   * @memberof AuthorApiAuthorsIdGet
   */
  readonly id: number;
}

/**
 * Request parameters for authorsIdPatch operation in AuthorApi.
 * @export
 * @interface AuthorApiAuthorsIdPatchRequest
 */
export interface AuthorApiAuthorsIdPatchRequest {
  /**
   * Author id
   * @type {number}
   * @memberof AuthorApiAuthorsIdPatch
   */
  readonly id: number;

  /**
   * Update author by id
   * @type {AuthorsGetRequest}
   * @memberof AuthorApiAuthorsIdPatch
   */
  readonly authorsGetRequest: AuthorsGetRequest;
}

/**
 * Request parameters for authorsPost operation in AuthorApi.
 * @export
 * @interface AuthorApiAuthorsPostRequest
 */
export interface AuthorApiAuthorsPostRequest {
  /**
   * Create a new author
   * @type {AuthorsGetRequest}
   * @memberof AuthorApiAuthorsPost
   */
  readonly authorsGetRequest: AuthorsGetRequest;
}

/**
 * AuthorApi - object-oriented interface
 * @export
 * @class AuthorApi
 * @extends {BaseAPI}
 */
export class AuthorApi extends BaseAPI {
  /**
   * Get list of authors
   * @summary Get list of authors
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthorApi
   */
  public authorsGet(options?: AxiosRequestConfig) {
    return AuthorApiFp(this.configuration)
      .authorsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete author by id
   * @summary Delete author by id
   * @param {AuthorApiAuthorsIdDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthorApi
   */
  public authorsIdDelete(requestParameters: AuthorApiAuthorsIdDeleteRequest, options?: AxiosRequestConfig) {
    return AuthorApiFp(this.configuration)
      .authorsIdDelete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get author by id
   * @summary Get author by id
   * @param {AuthorApiAuthorsIdGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthorApi
   */
  public authorsIdGet(requestParameters: AuthorApiAuthorsIdGetRequest, options?: AxiosRequestConfig) {
    return AuthorApiFp(this.configuration)
      .authorsIdGet(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update author by id
   * @summary Update author by id
   * @param {AuthorApiAuthorsIdPatchRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthorApi
   */
  public authorsIdPatch(requestParameters: AuthorApiAuthorsIdPatchRequest, options?: AxiosRequestConfig) {
    return AuthorApiFp(this.configuration)
      .authorsIdPatch(requestParameters.id, requestParameters.authorsGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a new author
   * @summary Create a new author
   * @param {AuthorApiAuthorsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthorApi
   */
  public authorsPost(requestParameters: AuthorApiAuthorsPostRequest, options?: AxiosRequestConfig) {
    return AuthorApiFp(this.configuration)
      .authorsPost(requestParameters.authorsGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * BookApi - axios parameter creator
 * @export
 */
export const BookApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get params for filtering books by existing data in office
     * @summary Get book filter params
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    booksFiltersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/books/filters`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get list of books
     * @summary Get list of books
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    booksGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/books`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get list of books
     * @summary Get list of books
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bookNamesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/books/names`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },

    /**
     * Delete book by id
     * @summary Delete book by id
     * @param {number} id Book id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    booksIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('booksIdDelete', 'id', id);
      const localVarPath = `/books/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get book by id
     * @summary Get book by id
     * @param {number} id Book id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    booksIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('booksIdGet', 'id', id);
      const localVarPath = `/books/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update book by id
     * @summary Update book by id
     * @param {number} id Book id
     * @param {BooksIdDeleteRequest} booksIdDeleteRequest Update book by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    booksIdPatch: async (
      id: number,
      booksIdDeleteRequest: BooksIdDeleteRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('booksIdPatch', 'id', id);
      // verify required parameter 'booksIdDeleteRequest' is not null or undefined
      assertParamExists('booksIdPatch', 'booksIdDeleteRequest', booksIdDeleteRequest);
      const localVarPath = `/books/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(booksIdDeleteRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a new book & book edition
     * @summary Create a new book & book edition
     * @param {BooksGetRequest} booksGetRequest Create a new book &amp; book edition
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    booksPost: async (booksGetRequest: BooksGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'booksGetRequest' is not null or undefined
      assertParamExists('booksPost', 'booksGetRequest', booksGetRequest);
      const localVarPath = `/books`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(booksGetRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BookApi - functional programming interface
 * @export
 */
export const BookApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BookApiAxiosParamCreator(configuration);
  return {
    /**
     * Get params for filtering books by existing data in office
     * @summary Get book filter params
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async booksFiltersGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooksFiltersGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.booksFiltersGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get list of books
     * @summary Get list of books
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async booksGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooksGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.booksGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get list of books
     * @summary Get list of books
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bookNamesGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookNamesGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bookNamesGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Delete book by id
     * @summary Delete book by id
     * @param {number} id Book id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async booksIdDelete(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.booksIdDelete(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get book by id
     * @summary Get book by id
     * @param {number} id Book id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async booksIdGet(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooksGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.booksIdGet(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Update book by id
     * @summary Update book by id
     * @param {number} id Book id
     * @param {BooksIdDeleteRequest} booksIdDeleteRequest Update book by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async booksIdPatch(
      id: number,
      booksIdDeleteRequest: BooksIdDeleteRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooksGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.booksIdPatch(id, booksIdDeleteRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Create a new book & book edition
     * @summary Create a new book & book edition
     * @param {BooksGetRequest} booksGetRequest Create a new book &amp; book edition
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async booksPost(
      booksGetRequest: BooksGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooksGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.booksPost(booksGetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * BookApi - factory interface
 * @export
 */
export const BookApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = BookApiFp(configuration);
  return {
    /**
     * Get params for filtering books by existing data in office
     * @summary Get book filter params
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    booksFiltersGet(options?: AxiosRequestConfig): AxiosPromise<BooksFiltersGet200Response> {
      return localVarFp.booksFiltersGet(options).then((request) => request(axios, basePath));
    },
    /**
     * Get list of books
     * @summary Get list of books
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    booksGet(options?: AxiosRequestConfig): AxiosPromise<BooksGet200Response> {
      return localVarFp.booksGet(options).then((request) => request(axios, basePath));
    },
    /**
     * Get list of book names
     * @summary Get list of book names
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bookNamesGet(options?: AxiosRequestConfig): AxiosPromise<BookNamesGet200Response> {
      return localVarFp.bookNamesGet(options).then((request) => request(axios, basePath));
    },
    /**
     * Delete book by id
     * @summary Delete book by id
     * @param {BookApiBooksIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    booksIdDelete(requestParameters: BookApiBooksIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
      return localVarFp.booksIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
    },
    /**
     * Get book by id
     * @summary Get book by id
     * @param {BookApiBooksIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    booksIdGet(
      requestParameters: BookApiBooksIdGetRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<BooksGet201Response> {
      return localVarFp.booksIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
    },
    /**
     * Update book by id
     * @summary Update book by id
     * @param {BookApiBooksIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    booksIdPatch(
      requestParameters: BookApiBooksIdPatchRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<BooksGet201Response> {
      return localVarFp
        .booksIdPatch(requestParameters.id, requestParameters.booksIdDeleteRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create a new book & book edition
     * @summary Create a new book & book edition
     * @param {BookApiBooksPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    booksPost(
      requestParameters: BookApiBooksPostRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<BooksGet201Response> {
      return localVarFp
        .booksPost(requestParameters.booksGetRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for booksIdDelete operation in BookApi.
 * @export
 * @interface BookApiBooksIdDeleteRequest
 */
export interface BookApiBooksIdDeleteRequest {
  /**
   * Book id
   * @type {number}
   * @memberof BookApiBooksIdDelete
   */
  readonly id: number;
}

/**
 * Request parameters for booksIdGet operation in BookApi.
 * @export
 * @interface BookApiBooksIdGetRequest
 */
export interface BookApiBooksIdGetRequest {
  /**
   * Book id
   * @type {number}
   * @memberof BookApiBooksIdGet
   */
  readonly id: number;
}

/**
 * Request parameters for booksIdPatch operation in BookApi.
 * @export
 * @interface BookApiBooksIdPatchRequest
 */
export interface BookApiBooksIdPatchRequest {
  /**
   * Book id
   * @type {number}
   * @memberof BookApiBooksIdPatch
   */
  readonly id: number;

  /**
   * Update book by id
   * @type {BooksIdDeleteRequest}
   * @memberof BookApiBooksIdPatch
   */
  readonly booksIdDeleteRequest: BooksIdDeleteRequest;
}

/**
 * Request parameters for booksPost operation in BookApi.
 * @export
 * @interface BookApiBooksPostRequest
 */
export interface BookApiBooksPostRequest {
  /**
   * Create a new book &amp; book edition
   * @type {BooksGetRequest}
   * @memberof BookApiBooksPost
   */
  readonly booksGetRequest: BooksGetRequest;
}

/**
 * BookApi - object-oriented interface
 * @export
 * @class BookApi
 * @extends {BaseAPI}
 */
export class BookApi extends BaseAPI {
  /**
   * Get params for filtering books by existing data in office
   * @summary Get book filter params
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BookApi
   */
  public booksFiltersGet(options?: AxiosRequestConfig) {
    return BookApiFp(this.configuration)
      .booksFiltersGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get list of books
   * @summary Get list of books
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BookApi
   */
  public booksGet(options?: AxiosRequestConfig) {
    return BookApiFp(this.configuration)
      .booksGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get params for filtering books by existing data in office
   * @summary Get book filter params
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BookApi
   */
  public bookNamesFiltersGet(options?: AxiosRequestConfig) {
    return BookApiFp(this.configuration)
      .booksFiltersGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get list of books
   * @summary Get list of books
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BookApi
   */
  public bookNamesGet(options?: AxiosRequestConfig) {
    return BookApiFp(this.configuration)
      .bookNamesGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete book by id
   * @summary Delete book by id
   * @param {BookApiBooksIdDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BookApi
   */
  public booksIdDelete(requestParameters: BookApiBooksIdDeleteRequest, options?: AxiosRequestConfig) {
    return BookApiFp(this.configuration)
      .booksIdDelete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get book by id
   * @summary Get book by id
   * @param {BookApiBooksIdGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BookApi
   */
  public booksIdGet(requestParameters: BookApiBooksIdGetRequest, options?: AxiosRequestConfig) {
    return BookApiFp(this.configuration)
      .booksIdGet(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update book by id
   * @summary Update book by id
   * @param {BookApiBooksIdPatchRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BookApi
   */
  public booksIdPatch(requestParameters: BookApiBooksIdPatchRequest, options?: AxiosRequestConfig) {
    return BookApiFp(this.configuration)
      .booksIdPatch(requestParameters.id, requestParameters.booksIdDeleteRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a new book & book edition
   * @summary Create a new book & book edition
   * @param {BookApiBooksPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BookApi
   */
  public booksPost(requestParameters: BookApiBooksPostRequest, options?: AxiosRequestConfig) {
    return BookApiFp(this.configuration)
      .booksPost(requestParameters.booksGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * BookEditionApi - axios parameter creator
 * @export
 */
export const BookEditionApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get list of book editions
     * @summary Get list of book editions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bookEditionsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/book_editions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete book edition by id
     * @summary Delete book edition by id
     * @param {number} id Book edition id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bookEditionsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('bookEditionsIdDelete', 'id', id);
      const localVarPath = `/book_editions/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get book edition by id
     * @summary Get book edition by id
     * @param {number} id Book edition id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bookEditionsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('bookEditionsIdGet', 'id', id);
      const localVarPath = `/book_editions/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update book edition by id
     * @summary Update book edition by id
     * @param {number} id Book edition id
     * @param {BookEditionsGetRequest} bookEditionsGetRequest Update book edition by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bookEditionsIdPatch: async (
      id: number,
      bookEditionsGetRequest: BookEditionsGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('bookEditionsIdPatch', 'id', id);
      // verify required parameter 'bookEditionsGetRequest' is not null or undefined
      assertParamExists('bookEditionsIdPatch', 'bookEditionsGetRequest', bookEditionsGetRequest);
      const localVarPath = `/book_editions/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bookEditionsGetRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a book edition
     * @summary Create a book edition
     * @param {BookEditionsGetRequest} bookEditionsGetRequest Create a book edition
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bookEditionsPost: async (
      bookEditionsGetRequest: BookEditionsGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bookEditionsGetRequest' is not null or undefined
      assertParamExists('bookEditionsPost', 'bookEditionsGetRequest', bookEditionsGetRequest);
      const localVarPath = `/book_editions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bookEditionsGetRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BookEditionApi - functional programming interface
 * @export
 */
export const BookEditionApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BookEditionApiAxiosParamCreator(configuration);
  return {
    /**
     * Get list of book editions
     * @summary Get list of book editions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bookEditionsGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookEditionsGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bookEditionsGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Delete book edition by id
     * @summary Delete book edition by id
     * @param {number} id Book edition id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bookEditionsIdDelete(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bookEditionsIdDelete(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get book edition by id
     * @summary Get book edition by id
     * @param {number} id Book edition id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bookEditionsIdGet(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookEditionsGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bookEditionsIdGet(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Update book edition by id
     * @summary Update book edition by id
     * @param {number} id Book edition id
     * @param {BookEditionsGetRequest} bookEditionsGetRequest Update book edition by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bookEditionsIdPatch(
      id: number,
      bookEditionsGetRequest: BookEditionsGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookEditionsGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bookEditionsIdPatch(
        id,
        bookEditionsGetRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Create a book edition
     * @summary Create a book edition
     * @param {BookEditionsGetRequest} bookEditionsGetRequest Create a book edition
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bookEditionsPost(
      bookEditionsGetRequest: BookEditionsGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookEditionsGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bookEditionsPost(bookEditionsGetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * BookEditionApi - factory interface
 * @export
 */
export const BookEditionApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = BookEditionApiFp(configuration);
  return {
    /**
     * Get list of book editions
     * @summary Get list of book editions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bookEditionsGet(options?: AxiosRequestConfig): AxiosPromise<BookEditionsGet200Response> {
      return localVarFp.bookEditionsGet(options).then((request) => request(axios, basePath));
    },
    /**
     * Delete book edition by id
     * @summary Delete book edition by id
     * @param {BookEditionApiBookEditionsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bookEditionsIdDelete(
      requestParameters: BookEditionApiBookEditionsIdDeleteRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp.bookEditionsIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
    },
    /**
     * Get book edition by id
     * @summary Get book edition by id
     * @param {BookEditionApiBookEditionsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bookEditionsIdGet(
      requestParameters: BookEditionApiBookEditionsIdGetRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<BookEditionsGet201Response> {
      return localVarFp.bookEditionsIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
    },
    /**
     * Update book edition by id
     * @summary Update book edition by id
     * @param {BookEditionApiBookEditionsIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bookEditionsIdPatch(
      requestParameters: BookEditionApiBookEditionsIdPatchRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<BookEditionsGet201Response> {
      return localVarFp
        .bookEditionsIdPatch(requestParameters.id, requestParameters.bookEditionsGetRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create a book edition
     * @summary Create a book edition
     * @param {BookEditionApiBookEditionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bookEditionsPost(
      requestParameters: BookEditionApiBookEditionsPostRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<BookEditionsGet201Response> {
      return localVarFp
        .bookEditionsPost(requestParameters.bookEditionsGetRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for bookEditionsIdDelete operation in BookEditionApi.
 * @export
 * @interface BookEditionApiBookEditionsIdDeleteRequest
 */
export interface BookEditionApiBookEditionsIdDeleteRequest {
  /**
   * Book edition id
   * @type {number}
   * @memberof BookEditionApiBookEditionsIdDelete
   */
  readonly id: number;
}

/**
 * Request parameters for bookEditionsIdGet operation in BookEditionApi.
 * @export
 * @interface BookEditionApiBookEditionsIdGetRequest
 */
export interface BookEditionApiBookEditionsIdGetRequest {
  /**
   * Book edition id
   * @type {number}
   * @memberof BookEditionApiBookEditionsIdGet
   */
  readonly id: number;
}

/**
 * Request parameters for bookEditionsIdPatch operation in BookEditionApi.
 * @export
 * @interface BookEditionApiBookEditionsIdPatchRequest
 */
export interface BookEditionApiBookEditionsIdPatchRequest {
  /**
   * Book edition id
   * @type {number}
   * @memberof BookEditionApiBookEditionsIdPatch
   */
  readonly id: number;

  /**
   * Update book edition by id
   * @type {BookEditionsGetRequest}
   * @memberof BookEditionApiBookEditionsIdPatch
   */
  readonly bookEditionsGetRequest: BookEditionsGetRequest;
}

/**
 * Request parameters for bookEditionsPost operation in BookEditionApi.
 * @export
 * @interface BookEditionApiBookEditionsPostRequest
 */
export interface BookEditionApiBookEditionsPostRequest {
  /**
   * Create a book edition
   * @type {BookEditionsGetRequest}
   * @memberof BookEditionApiBookEditionsPost
   */
  readonly bookEditionsGetRequest: BookEditionsGetRequest;
}

/**
 * BookEditionApi - object-oriented interface
 * @export
 * @class BookEditionApi
 * @extends {BaseAPI}
 */
export class BookEditionApi extends BaseAPI {
  /**
   * Get list of book editions
   * @summary Get list of book editions
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BookEditionApi
   */
  public bookEditionsGet(options?: AxiosRequestConfig) {
    return BookEditionApiFp(this.configuration)
      .bookEditionsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete book edition by id
   * @summary Delete book edition by id
   * @param {BookEditionApiBookEditionsIdDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BookEditionApi
   */
  public bookEditionsIdDelete(
    requestParameters: BookEditionApiBookEditionsIdDeleteRequest,
    options?: AxiosRequestConfig,
  ) {
    return BookEditionApiFp(this.configuration)
      .bookEditionsIdDelete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get book edition by id
   * @summary Get book edition by id
   * @param {BookEditionApiBookEditionsIdGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BookEditionApi
   */
  public bookEditionsIdGet(requestParameters: BookEditionApiBookEditionsIdGetRequest, options?: AxiosRequestConfig) {
    return BookEditionApiFp(this.configuration)
      .bookEditionsIdGet(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update book edition by id
   * @summary Update book edition by id
   * @param {BookEditionApiBookEditionsIdPatchRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BookEditionApi
   */
  public bookEditionsIdPatch(
    requestParameters: BookEditionApiBookEditionsIdPatchRequest,
    options?: AxiosRequestConfig,
  ) {
    return BookEditionApiFp(this.configuration)
      .bookEditionsIdPatch(requestParameters.id, requestParameters.bookEditionsGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a book edition
   * @summary Create a book edition
   * @param {BookEditionApiBookEditionsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BookEditionApi
   */
  public bookEditionsPost(requestParameters: BookEditionApiBookEditionsPostRequest, options?: AxiosRequestConfig) {
    return BookEditionApiFp(this.configuration)
      .bookEditionsPost(requestParameters.bookEditionsGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * BookRegisterApi - axios parameter creator
 * @export
 */
export const BookRegisterApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get list of book registers
     * @summary Get list of book registers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bookRegisterAllGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/book_register/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a new book register
     * @summary Create a new book register
     * @param {BookRegisterGetRequest} bookRegisterGetRequest Create a new book register
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bookRegisterAllPost: async (
      bookRegisterGetRequest: BookRegisterGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bookRegisterGetRequest' is not null or undefined
      assertParamExists('bookRegisterAllPost', 'bookRegisterGetRequest', bookRegisterGetRequest);
      const localVarPath = `/book_register/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bookRegisterGetRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update book register return_confirmed_at by id
     * @summary Update book register return_confirmed_at by id
     * @param {number} id Book register id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bookRegisterConfirmIdPatch: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('bookRegisterConfirmIdPatch', 'id', id);
      const localVarPath = `/book_register/confirm/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get list of book registers
     * @summary Get list of book registers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bookRegisterGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/book_register`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update book register by id
     * @summary Update book register by id
     * @param {number} id Book register id
     * @param {BookRegisterGetRequest} bookRegisterGetRequest Update book register by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bookRegisterIdPatch: async (
      id: number,
      bookRegisterGetRequest: BookRegisterGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('bookRegisterIdPatch', 'id', id);
      // verify required parameter 'bookRegisterGetRequest' is not null or undefined
      assertParamExists('bookRegisterIdPatch', 'bookRegisterGetRequest', bookRegisterGetRequest);
      const localVarPath = `/book_register/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bookRegisterGetRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a new book register
     * @summary Create a new book register
     * @param {BookRegisterGetRequest} bookRegisterGetRequest Create a new book register
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bookRegisterPost: async (
      bookRegisterGetRequest: BookRegisterGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bookRegisterGetRequest' is not null or undefined
      assertParamExists('bookRegisterPost', 'bookRegisterGetRequest', bookRegisterGetRequest);
      const localVarPath = `/book_register`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bookRegisterGetRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get list of top book registers of the month
     * @summary Get list of top books
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bookRegisterTopGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/book_register/top`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BookRegisterApi - functional programming interface
 * @export
 */
export const BookRegisterApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BookRegisterApiAxiosParamCreator(configuration);
  return {
    /**
     * Get list of book registers
     * @summary Get list of book registers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bookRegisterAllGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookRegisterGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bookRegisterAllGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Create a new book register
     * @summary Create a new book register
     * @param {BookRegisterGetRequest} bookRegisterGetRequest Create a new book register
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bookRegisterAllPost(
      bookRegisterGetRequest: BookRegisterGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookRegisterGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bookRegisterAllPost(bookRegisterGetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Update book register return_confirmed_at by id
     * @summary Update book register return_confirmed_at by id
     * @param {number} id Book register id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bookRegisterConfirmIdPatch(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookRegisterGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bookRegisterConfirmIdPatch(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get list of book registers
     * @summary Get list of book registers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bookRegisterGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookRegisterGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bookRegisterGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Update book register by id
     * @summary Update book register by id
     * @param {number} id Book register id
     * @param {BookRegisterGetRequest} bookRegisterGetRequest Update book register by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bookRegisterIdPatch(
      id: number,
      bookRegisterGetRequest: BookRegisterGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookRegisterGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bookRegisterIdPatch(
        id,
        bookRegisterGetRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Create a new book register
     * @summary Create a new book register
     * @param {BookRegisterGetRequest} bookRegisterGetRequest Create a new book register
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bookRegisterPost(
      bookRegisterGetRequest: BookRegisterGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookRegisterGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bookRegisterPost(bookRegisterGetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get list of top book registers of the month
     * @summary Get list of top books
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bookRegisterTopGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookRegisterTopGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bookRegisterTopGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * BookRegisterApi - factory interface
 * @export
 */
export const BookRegisterApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = BookRegisterApiFp(configuration);
  return {
    /**
     * Get list of book registers
     * @summary Get list of book registers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bookRegisterAllGet(options?: AxiosRequestConfig): AxiosPromise<BookRegisterGet200Response> {
      return localVarFp.bookRegisterAllGet(options).then((request) => request(axios, basePath));
    },
    /**
     * Create a new book register
     * @summary Create a new book register
     * @param {BookRegisterApiBookRegisterAllPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bookRegisterAllPost(
      requestParameters: BookRegisterApiBookRegisterAllPostRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<BookRegisterGet201Response> {
      return localVarFp
        .bookRegisterAllPost(requestParameters.bookRegisterGetRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update book register return_confirmed_at by id
     * @summary Update book register return_confirmed_at by id
     * @param {BookRegisterApiBookRegisterConfirmIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bookRegisterConfirmIdPatch(
      requestParameters: BookRegisterApiBookRegisterConfirmIdPatchRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<BookRegisterGet201Response> {
      return localVarFp
        .bookRegisterConfirmIdPatch(requestParameters.id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get list of book registers
     * @summary Get list of book registers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bookRegisterGet(options?: AxiosRequestConfig): AxiosPromise<BookRegisterGet200Response> {
      return localVarFp.bookRegisterGet(options).then((request) => request(axios, basePath));
    },
    /**
     * Update book register by id
     * @summary Update book register by id
     * @param {BookRegisterApiBookRegisterIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bookRegisterIdPatch(
      requestParameters: BookRegisterApiBookRegisterIdPatchRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<BookRegisterGet201Response> {
      return localVarFp
        .bookRegisterIdPatch(requestParameters.id, requestParameters.bookRegisterGetRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create a new book register
     * @summary Create a new book register
     * @param {BookRegisterApiBookRegisterPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bookRegisterPost(
      requestParameters: BookRegisterApiBookRegisterPostRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<BookRegisterGet201Response> {
      return localVarFp
        .bookRegisterPost(requestParameters.bookRegisterGetRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get list of top book registers of the month
     * @summary Get list of top books
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bookRegisterTopGet(options?: AxiosRequestConfig): AxiosPromise<BookRegisterTopGet200Response> {
      return localVarFp.bookRegisterTopGet(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for bookRegisterAllPost operation in BookRegisterApi.
 * @export
 * @interface BookRegisterApiBookRegisterAllPostRequest
 */
export interface BookRegisterApiBookRegisterAllPostRequest {
  /**
   * Create a new book register
   * @type {BookRegisterGetRequest}
   * @memberof BookRegisterApiBookRegisterAllPost
   */
  readonly bookRegisterGetRequest: BookRegisterGetRequest;
}

/**
 * Request parameters for bookRegisterConfirmIdPatch operation in BookRegisterApi.
 * @export
 * @interface BookRegisterApiBookRegisterConfirmIdPatchRequest
 */
export interface BookRegisterApiBookRegisterConfirmIdPatchRequest {
  /**
   * Book register id
   * @type {number}
   * @memberof BookRegisterApiBookRegisterConfirmIdPatch
   */
  readonly id: number;
}

/**
 * Request parameters for bookRegisterIdPatch operation in BookRegisterApi.
 * @export
 * @interface BookRegisterApiBookRegisterIdPatchRequest
 */
export interface BookRegisterApiBookRegisterIdPatchRequest {
  /**
   * Book register id
   * @type {number}
   * @memberof BookRegisterApiBookRegisterIdPatch
   */
  readonly id: number;

  /**
   * Update book register by id
   * @type {BookRegisterGetRequest}
   * @memberof BookRegisterApiBookRegisterIdPatch
   */
  readonly bookRegisterGetRequest: BookRegisterGetRequest;
}

/**
 * Request parameters for bookRegisterPost operation in BookRegisterApi.
 * @export
 * @interface BookRegisterApiBookRegisterPostRequest
 */
export interface BookRegisterApiBookRegisterPostRequest {
  /**
   * Create a new book register
   * @type {BookRegisterGetRequest}
   * @memberof BookRegisterApiBookRegisterPost
   */
  readonly bookRegisterGetRequest: BookRegisterGetRequest;
}

/**
 * BookRegisterApi - object-oriented interface
 * @export
 * @class BookRegisterApi
 * @extends {BaseAPI}
 */
export class BookRegisterApi extends BaseAPI {
  /**
   * Get list of book registers
   * @summary Get list of book registers
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BookRegisterApi
   */
  public bookRegisterAllGet(options?: AxiosRequestConfig) {
    return BookRegisterApiFp(this.configuration)
      .bookRegisterAllGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a new book register
   * @summary Create a new book register
   * @param {BookRegisterApiBookRegisterAllPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BookRegisterApi
   */
  public bookRegisterAllPost(
    requestParameters: BookRegisterApiBookRegisterAllPostRequest,
    options?: AxiosRequestConfig,
  ) {
    return BookRegisterApiFp(this.configuration)
      .bookRegisterAllPost(requestParameters.bookRegisterGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update book register return_confirmed_at by id
   * @summary Update book register return_confirmed_at by id
   * @param {BookRegisterApiBookRegisterConfirmIdPatchRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BookRegisterApi
   */
  public bookRegisterConfirmIdPatch(
    requestParameters: BookRegisterApiBookRegisterConfirmIdPatchRequest,
    options?: AxiosRequestConfig,
  ) {
    return BookRegisterApiFp(this.configuration)
      .bookRegisterConfirmIdPatch(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get list of book registers
   * @summary Get list of book registers
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BookRegisterApi
   */
  public bookRegisterGet(options?: AxiosRequestConfig) {
    return BookRegisterApiFp(this.configuration)
      .bookRegisterGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update book register by id
   * @summary Update book register by id
   * @param {BookRegisterApiBookRegisterIdPatchRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BookRegisterApi
   */
  public bookRegisterIdPatch(
    requestParameters: BookRegisterApiBookRegisterIdPatchRequest,
    options?: AxiosRequestConfig,
  ) {
    return BookRegisterApiFp(this.configuration)
      .bookRegisterIdPatch(requestParameters.id, requestParameters.bookRegisterGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a new book register
   * @summary Create a new book register
   * @param {BookRegisterApiBookRegisterPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BookRegisterApi
   */
  public bookRegisterPost(requestParameters: BookRegisterApiBookRegisterPostRequest, options?: AxiosRequestConfig) {
    return BookRegisterApiFp(this.configuration)
      .bookRegisterPost(requestParameters.bookRegisterGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get list of top book registers of the month
   * @summary Get list of top books
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BookRegisterApi
   */
  public bookRegisterTopGet(options?: AxiosRequestConfig) {
    return BookRegisterApiFp(this.configuration)
      .bookRegisterTopGet(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CategoryApi - axios parameter creator
 * @export
 */
export const CategoryApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get list of categories
     * @summary Get list of categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/categories`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete category by id
     * @summary Delete category by id
     * @param {number} id Category id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('categoriesIdDelete', 'id', id);
      const localVarPath = `/categories/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get category by id
     * @summary Get category by id
     * @param {number} id Category id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('categoriesIdGet', 'id', id);
      const localVarPath = `/categories/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update category by id
     * @summary Update category by id
     * @param {number} id Category id
     * @param {CategoriesGetRequest} categoriesGetRequest Update category by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesIdPatch: async (
      id: number,
      categoriesGetRequest: CategoriesGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('categoriesIdPatch', 'id', id);
      // verify required parameter 'categoriesGetRequest' is not null or undefined
      assertParamExists('categoriesIdPatch', 'categoriesGetRequest', categoriesGetRequest);
      const localVarPath = `/categories/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(categoriesGetRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a new category
     * @summary Create a new category
     * @param {CategoriesGetRequest} categoriesGetRequest Create a new category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesPost: async (
      categoriesGetRequest: CategoriesGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'categoriesGetRequest' is not null or undefined
      assertParamExists('categoriesPost', 'categoriesGetRequest', categoriesGetRequest);
      const localVarPath = `/categories`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(categoriesGetRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CategoryApi - functional programming interface
 * @export
 */
export const CategoryApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CategoryApiAxiosParamCreator(configuration);
  return {
    /**
     * Get list of categories
     * @summary Get list of categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async categoriesGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoriesGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Delete category by id
     * @summary Delete category by id
     * @param {number} id Category id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async categoriesIdDelete(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesIdDelete(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get category by id
     * @summary Get category by id
     * @param {number} id Category id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async categoriesIdGet(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoriesGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesIdGet(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Update category by id
     * @summary Update category by id
     * @param {number} id Category id
     * @param {CategoriesGetRequest} categoriesGetRequest Update category by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async categoriesIdPatch(
      id: number,
      categoriesGetRequest: CategoriesGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoriesGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesIdPatch(id, categoriesGetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Create a new category
     * @summary Create a new category
     * @param {CategoriesGetRequest} categoriesGetRequest Create a new category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async categoriesPost(
      categoriesGetRequest: CategoriesGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoriesGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesPost(categoriesGetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CategoryApi - factory interface
 * @export
 */
export const CategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CategoryApiFp(configuration);
  return {
    /**
     * Get list of categories
     * @summary Get list of categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesGet(options?: AxiosRequestConfig): AxiosPromise<CategoriesGet200Response> {
      return localVarFp.categoriesGet(options).then((request) => request(axios, basePath));
    },
    /**
     * Delete category by id
     * @summary Delete category by id
     * @param {CategoryApiCategoriesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesIdDelete(
      requestParameters: CategoryApiCategoriesIdDeleteRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp.categoriesIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
    },
    /**
     * Get category by id
     * @summary Get category by id
     * @param {CategoryApiCategoriesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesIdGet(
      requestParameters: CategoryApiCategoriesIdGetRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<CategoriesGet201Response> {
      return localVarFp.categoriesIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
    },
    /**
     * Update category by id
     * @summary Update category by id
     * @param {CategoryApiCategoriesIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesIdPatch(
      requestParameters: CategoryApiCategoriesIdPatchRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<CategoriesGet201Response> {
      return localVarFp
        .categoriesIdPatch(requestParameters.id, requestParameters.categoriesGetRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create a new category
     * @summary Create a new category
     * @param {CategoryApiCategoriesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesPost(
      requestParameters: CategoryApiCategoriesPostRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<CategoriesGet201Response> {
      return localVarFp
        .categoriesPost(requestParameters.categoriesGetRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for categoriesIdDelete operation in CategoryApi.
 * @export
 * @interface CategoryApiCategoriesIdDeleteRequest
 */
export interface CategoryApiCategoriesIdDeleteRequest {
  /**
   * Category id
   * @type {number}
   * @memberof CategoryApiCategoriesIdDelete
   */
  readonly id: number;
}

/**
 * Request parameters for categoriesIdGet operation in CategoryApi.
 * @export
 * @interface CategoryApiCategoriesIdGetRequest
 */
export interface CategoryApiCategoriesIdGetRequest {
  /**
   * Category id
   * @type {number}
   * @memberof CategoryApiCategoriesIdGet
   */
  readonly id: number;
}

/**
 * Request parameters for categoriesIdPatch operation in CategoryApi.
 * @export
 * @interface CategoryApiCategoriesIdPatchRequest
 */
export interface CategoryApiCategoriesIdPatchRequest {
  /**
   * Category id
   * @type {number}
   * @memberof CategoryApiCategoriesIdPatch
   */
  readonly id: number;

  /**
   * Update category by id
   * @type {CategoriesGetRequest}
   * @memberof CategoryApiCategoriesIdPatch
   */
  readonly categoriesGetRequest: CategoriesGetRequest;
}

/**
 * Request parameters for categoriesPost operation in CategoryApi.
 * @export
 * @interface CategoryApiCategoriesPostRequest
 */
export interface CategoryApiCategoriesPostRequest {
  /**
   * Create a new category
   * @type {CategoriesGetRequest}
   * @memberof CategoryApiCategoriesPost
   */
  readonly categoriesGetRequest: CategoriesGetRequest;
}

/**
 * CategoryApi - object-oriented interface
 * @export
 * @class CategoryApi
 * @extends {BaseAPI}
 */
export class CategoryApi extends BaseAPI {
  /**
   * Get list of categories
   * @summary Get list of categories
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoryApi
   */
  public categoriesGet(options?: AxiosRequestConfig) {
    return CategoryApiFp(this.configuration)
      .categoriesGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete category by id
   * @summary Delete category by id
   * @param {CategoryApiCategoriesIdDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoryApi
   */
  public categoriesIdDelete(requestParameters: CategoryApiCategoriesIdDeleteRequest, options?: AxiosRequestConfig) {
    return CategoryApiFp(this.configuration)
      .categoriesIdDelete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get category by id
   * @summary Get category by id
   * @param {CategoryApiCategoriesIdGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoryApi
   */
  public categoriesIdGet(requestParameters: CategoryApiCategoriesIdGetRequest, options?: AxiosRequestConfig) {
    return CategoryApiFp(this.configuration)
      .categoriesIdGet(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update category by id
   * @summary Update category by id
   * @param {CategoryApiCategoriesIdPatchRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoryApi
   */
  public categoriesIdPatch(requestParameters: CategoryApiCategoriesIdPatchRequest, options?: AxiosRequestConfig) {
    return CategoryApiFp(this.configuration)
      .categoriesIdPatch(requestParameters.id, requestParameters.categoriesGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a new category
   * @summary Create a new category
   * @param {CategoryApiCategoriesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoryApi
   */
  public categoriesPost(requestParameters: CategoryApiCategoriesPostRequest, options?: AxiosRequestConfig) {
    return CategoryApiFp(this.configuration)
      .categoriesPost(requestParameters.categoriesGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DropDownApi - axios parameter creator
 * @export
 */
export const DropDownApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get list of dropdowns
     * @summary Get list of dropdowns
     * @param {Array<string>} [name] dropdown name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dropdownsGet: async (name?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/dropdowns`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (name) {
        localVarQueryParameter['name[]'] = name;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DropDownApi - functional programming interface
 * @export
 */
export const DropDownApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DropDownApiAxiosParamCreator(configuration);
  return {
    /**
     * Get list of dropdowns
     * @summary Get list of dropdowns
     * @param {Array<string>} [name] dropdown name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dropdownsGet(
      name?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DropdownsGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dropdownsGet(name, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DropDownApi - factory interface
 * @export
 */
export const DropDownApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DropDownApiFp(configuration);
  return {
    /**
     * Get list of dropdowns
     * @summary Get list of dropdowns
     * @param {DropDownApiDropdownsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dropdownsGet(
      requestParameters: DropDownApiDropdownsGetRequest = {},
      options?: AxiosRequestConfig,
    ): AxiosPromise<DropdownsGet200Response> {
      return localVarFp.dropdownsGet(requestParameters.name, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for dropdownsGet operation in DropDownApi.
 * @export
 * @interface DropDownApiDropdownsGetRequest
 */
export interface DropDownApiDropdownsGetRequest {
  /**
   * dropdown name
   * @type {Array<string>}
   * @memberof DropDownApiDropdownsGet
   */
  readonly name?: Array<string>;
}

/**
 * DropDownApi - object-oriented interface
 * @export
 * @class DropDownApi
 * @extends {BaseAPI}
 */
export class DropDownApi extends BaseAPI {
  /**
   * Get list of dropdowns
   * @summary Get list of dropdowns
   * @param {DropDownApiDropdownsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DropDownApi
   */
  public dropdownsGet(requestParameters: DropDownApiDropdownsGetRequest = {}, options?: AxiosRequestConfig) {
    return DropDownApiFp(this.configuration)
      .dropdownsGet(requestParameters.name, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * FeedbackApi - axios parameter creator
 * @export
 */
export const FeedbackApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get list of feedback
     * @summary Get list of feedback
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    feedbacksGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/feedbacks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete feedback by id
     * @summary Delete feedback by id
     * @param {number} id Feedback id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    feedbacksIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('feedbacksIdDelete', 'id', id);
      const localVarPath = `/feedbacks/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get feedback by id
     * @summary Get feedback by id
     * @param {number} id Feedback id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    feedbacksIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('feedbacksIdGet', 'id', id);
      const localVarPath = `/feedbacks/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update feedback by id
     * @summary Update feedback by id
     * @param {number} id Feedback id
     * @param {FeedbacksIdDeleteRequest} feedbacksIdDeleteRequest Update feedback by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    feedbacksIdPatch: async (
      id: number,
      feedbacksIdDeleteRequest: FeedbacksIdDeleteRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('feedbacksIdPatch', 'id', id);
      // verify required parameter 'feedbacksIdDeleteRequest' is not null or undefined
      assertParamExists('feedbacksIdPatch', 'feedbacksIdDeleteRequest', feedbacksIdDeleteRequest);
      const localVarPath = `/feedbacks/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        feedbacksIdDeleteRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a new feedback
     * @summary Create a new feedback
     * @param {FeedbacksGetRequest} feedbacksGetRequest Create a new feedback
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    feedbacksPost: async (
      feedbacksGetRequest: FeedbacksGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'feedbacksGetRequest' is not null or undefined
      assertParamExists('feedbacksPost', 'feedbacksGetRequest', feedbacksGetRequest);
      const localVarPath = `/feedbacks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(feedbacksGetRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FeedbackApi - functional programming interface
 * @export
 */
export const FeedbackApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FeedbackApiAxiosParamCreator(configuration);
  return {
    /**
     * Get list of feedback
     * @summary Get list of feedback
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async feedbacksGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedbacksGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.feedbacksGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Delete feedback by id
     * @summary Delete feedback by id
     * @param {number} id Feedback id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async feedbacksIdDelete(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.feedbacksIdDelete(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get feedback by id
     * @summary Get feedback by id
     * @param {number} id Feedback id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async feedbacksIdGet(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedbacksGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.feedbacksIdGet(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Update feedback by id
     * @summary Update feedback by id
     * @param {number} id Feedback id
     * @param {FeedbacksIdDeleteRequest} feedbacksIdDeleteRequest Update feedback by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async feedbacksIdPatch(
      id: number,
      feedbacksIdDeleteRequest: FeedbacksIdDeleteRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedbacksGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.feedbacksIdPatch(id, feedbacksIdDeleteRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Create a new feedback
     * @summary Create a new feedback
     * @param {FeedbacksGetRequest} feedbacksGetRequest Create a new feedback
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async feedbacksPost(
      feedbacksGetRequest: FeedbacksGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedbacksGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.feedbacksPost(feedbacksGetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * FeedbackApi - factory interface
 * @export
 */
export const FeedbackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = FeedbackApiFp(configuration);
  return {
    /**
     * Get list of feedback
     * @summary Get list of feedback
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    feedbacksGet(options?: AxiosRequestConfig): AxiosPromise<FeedbacksGet200Response> {
      return localVarFp.feedbacksGet(options).then((request) => request(axios, basePath));
    },
    /**
     * Delete feedback by id
     * @summary Delete feedback by id
     * @param {FeedbackApiFeedbacksIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    feedbacksIdDelete(
      requestParameters: FeedbackApiFeedbacksIdDeleteRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp.feedbacksIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
    },
    /**
     * Get feedback by id
     * @summary Get feedback by id
     * @param {FeedbackApiFeedbacksIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    feedbacksIdGet(
      requestParameters: FeedbackApiFeedbacksIdGetRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<FeedbacksGet201Response> {
      return localVarFp.feedbacksIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
    },
    /**
     * Update feedback by id
     * @summary Update feedback by id
     * @param {FeedbackApiFeedbacksIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    feedbacksIdPatch(
      requestParameters: FeedbackApiFeedbacksIdPatchRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<FeedbacksGet201Response> {
      return localVarFp
        .feedbacksIdPatch(requestParameters.id, requestParameters.feedbacksIdDeleteRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create a new feedback
     * @summary Create a new feedback
     * @param {FeedbackApiFeedbacksPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    feedbacksPost(
      requestParameters: FeedbackApiFeedbacksPostRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<FeedbacksGet201Response> {
      return localVarFp
        .feedbacksPost(requestParameters.feedbacksGetRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for feedbacksIdDelete operation in FeedbackApi.
 * @export
 * @interface FeedbackApiFeedbacksIdDeleteRequest
 */
export interface FeedbackApiFeedbacksIdDeleteRequest {
  /**
   * Feedback id
   * @type {number}
   * @memberof FeedbackApiFeedbacksIdDelete
   */
  readonly id: number;
}

/**
 * Request parameters for feedbacksIdGet operation in FeedbackApi.
 * @export
 * @interface FeedbackApiFeedbacksIdGetRequest
 */
export interface FeedbackApiFeedbacksIdGetRequest {
  /**
   * Feedback id
   * @type {number}
   * @memberof FeedbackApiFeedbacksIdGet
   */
  readonly id: number;
}

/**
 * Request parameters for feedbacksIdPatch operation in FeedbackApi.
 * @export
 * @interface FeedbackApiFeedbacksIdPatchRequest
 */
export interface FeedbackApiFeedbacksIdPatchRequest {
  /**
   * Feedback id
   * @type {number}
   * @memberof FeedbackApiFeedbacksIdPatch
   */
  readonly id: number;

  /**
   * Update feedback by id
   * @type {FeedbacksIdDeleteRequest}
   * @memberof FeedbackApiFeedbacksIdPatch
   */
  readonly feedbacksIdDeleteRequest: FeedbacksIdDeleteRequest;
}

/**
 * Request parameters for feedbacksPost operation in FeedbackApi.
 * @export
 * @interface FeedbackApiFeedbacksPostRequest
 */
export interface FeedbackApiFeedbacksPostRequest {
  /**
   * Create a new feedback
   * @type {FeedbacksGetRequest}
   * @memberof FeedbackApiFeedbacksPost
   */
  readonly feedbacksGetRequest: FeedbacksGetRequest;
}

/**
 * FeedbackApi - object-oriented interface
 * @export
 * @class FeedbackApi
 * @extends {BaseAPI}
 */
export class FeedbackApi extends BaseAPI {
  /**
   * Get list of feedback
   * @summary Get list of feedback
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeedbackApi
   */
  public feedbacksGet(options?: AxiosRequestConfig) {
    return FeedbackApiFp(this.configuration)
      .feedbacksGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete feedback by id
   * @summary Delete feedback by id
   * @param {FeedbackApiFeedbacksIdDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeedbackApi
   */
  public feedbacksIdDelete(requestParameters: FeedbackApiFeedbacksIdDeleteRequest, options?: AxiosRequestConfig) {
    return FeedbackApiFp(this.configuration)
      .feedbacksIdDelete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get feedback by id
   * @summary Get feedback by id
   * @param {FeedbackApiFeedbacksIdGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeedbackApi
   */
  public feedbacksIdGet(requestParameters: FeedbackApiFeedbacksIdGetRequest, options?: AxiosRequestConfig) {
    return FeedbackApiFp(this.configuration)
      .feedbacksIdGet(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update feedback by id
   * @summary Update feedback by id
   * @param {FeedbackApiFeedbacksIdPatchRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeedbackApi
   */
  public feedbacksIdPatch(requestParameters: FeedbackApiFeedbacksIdPatchRequest, options?: AxiosRequestConfig) {
    return FeedbackApiFp(this.configuration)
      .feedbacksIdPatch(requestParameters.id, requestParameters.feedbacksIdDeleteRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a new feedback
   * @summary Create a new feedback
   * @param {FeedbackApiFeedbacksPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeedbackApi
   */
  public feedbacksPost(requestParameters: FeedbackApiFeedbacksPostRequest, options?: AxiosRequestConfig) {
    return FeedbackApiFp(this.configuration)
      .feedbacksPost(requestParameters.feedbacksGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * EmailApi - axios parameter creator
 * @export
 */
export const EmailApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Create a new email message
     * @summary Create a new email message
     * @param {EmailGetRequest} emailGetRequest Create a new email message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendLocalLibrarianEmail: async (
      emailGetRequest: EmailGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'emailGetRequest' is not null or undefined
      assertParamExists('emailPost', 'emailGetRequest', emailGetRequest);

      const localVarPath = `users/send-mail-to-librarian`;

      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(emailGetRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EmailApiFp - functional programming interface
 * @export
 */
export const EmailApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = EmailApiAxiosParamCreator(configuration);
  return {
    /**
     * Create a new email message
     * @summary Create a new email message
     * @param {EmailGetRequest} emailGetRequest Create a new email message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendLocalLibrarianEmail(
      emailGetRequest: EmailGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sendLocalLibrarianEmail(emailGetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * FeedbackApi - factory interface
 * @export
 */
export const EmailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = EmailApiFp(configuration);
  return {
    /**
     * Create a new email
     * @summary Create a new email message
     * @param {EmailApiPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendLocalLibrarianEmail(
      requestParameters: EmailApiPostRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<EmailGet201Response> {
      return localVarFp
        .sendLocalLibrarianEmail(requestParameters.emailGetRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};
/**
 * Request parameters for feedbacksPost operation in FeedbackApi.
 * @export
 * @interface EmailApiPostRequest
 */
export interface EmailApiPostRequest {
  /**
   * Create a new email message
   * @type {EmailGetRequest}
   * @memberof EmailApiPost
   */
  readonly emailGetRequest: EmailGetRequest;
}

/**
 * EmailApi - object-oriented interface
 * @export
 * @class EmailkApi
 * @extends {BaseAPI}
 */
export class EmailApi extends BaseAPI {
  /**
   * Create a  new email message
   * @summary Create a new email message
   * @param {EmailApiPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmailApi
   */
  public sendLocalLibrarianEmail(requestParameters: EmailApiPostRequest, options?: AxiosRequestConfig) {
    return EmailApiFp(this.configuration)
      .sendLocalLibrarianEmail(requestParameters.emailGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * KindleApi - axios parameter creator
 * @export
 */
export const KindleApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get list of kindles with borrowed data
     * @summary Get list of kindles with borrowed data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kindlesBorrowedGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/kindles/borrowed`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get list of kindles
     * @summary Get list of kindles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kindlesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/kindles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete kindle by id
     * @summary Delete kindle by id
     * @param {number} id Kindle id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kindlesIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('kindlesIdDelete', 'id', id);
      const localVarPath = `/kindles/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get kindle by id
     * @summary Get kindle by id
     * @param {number} id Kindle id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kindlesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('kindlesIdGet', 'id', id);
      const localVarPath = `/kindles/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update kindle by id
     * @summary Update kindle by id
     * @param {number} id Kindle id
     * @param {KindlesGetRequest} kindlesGetRequest Update kindle by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kindlesIdPatch: async (
      id: number,
      kindlesGetRequest: KindlesGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('kindlesIdPatch', 'id', id);
      // verify required parameter 'kindlesGetRequest' is not null or undefined
      assertParamExists('kindlesIdPatch', 'kindlesGetRequest', kindlesGetRequest);
      const localVarPath = `/kindles/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(kindlesGetRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a new kindles
     * @summary Create a new kindles
     * @param {KindlesGetRequest} kindlesGetRequest Create a new kindles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kindlesPost: async (
      kindlesGetRequest: KindlesGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kindlesGetRequest' is not null or undefined
      assertParamExists('kindlesPost', 'kindlesGetRequest', kindlesGetRequest);
      const localVarPath = `/kindles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(kindlesGetRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * KindleApi - functional programming interface
 * @export
 */
export const KindleApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = KindleApiAxiosParamCreator(configuration);
  return {
    /**
     * Get list of kindles with borrowed data
     * @summary Get list of kindles with borrowed data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async kindlesBorrowedGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KindlesBorrowedGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.kindlesBorrowedGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get list of kindles
     * @summary Get list of kindles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async kindlesGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KindlesGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.kindlesGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Delete kindle by id
     * @summary Delete kindle by id
     * @param {number} id Kindle id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async kindlesIdDelete(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.kindlesIdDelete(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get kindle by id
     * @summary Get kindle by id
     * @param {number} id Kindle id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async kindlesIdGet(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KindlesGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.kindlesIdGet(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Update kindle by id
     * @summary Update kindle by id
     * @param {number} id Kindle id
     * @param {KindlesGetRequest} kindlesGetRequest Update kindle by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async kindlesIdPatch(
      id: number,
      kindlesGetRequest: KindlesGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KindlesGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.kindlesIdPatch(id, kindlesGetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Create a new kindles
     * @summary Create a new kindles
     * @param {KindlesGetRequest} kindlesGetRequest Create a new kindles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async kindlesPost(
      kindlesGetRequest: KindlesGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KindlesGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.kindlesPost(kindlesGetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * KindleApi - factory interface
 * @export
 */
export const KindleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = KindleApiFp(configuration);
  return {
    /**
     * Get list of kindles with borrowed data
     * @summary Get list of kindles with borrowed data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kindlesBorrowedGet(options?: AxiosRequestConfig): AxiosPromise<KindlesBorrowedGet200Response> {
      return localVarFp.kindlesBorrowedGet(options).then((request) => request(axios, basePath));
    },
    /**
     * Get list of kindles
     * @summary Get list of kindles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kindlesGet(options?: AxiosRequestConfig): AxiosPromise<KindlesGet200Response> {
      return localVarFp.kindlesGet(options).then((request) => request(axios, basePath));
    },
    /**
     * Delete kindle by id
     * @summary Delete kindle by id
     * @param {KindleApiKindlesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kindlesIdDelete(
      requestParameters: KindleApiKindlesIdDeleteRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp.kindlesIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
    },
    /**
     * Get kindle by id
     * @summary Get kindle by id
     * @param {KindleApiKindlesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kindlesIdGet(
      requestParameters: KindleApiKindlesIdGetRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<KindlesGet201Response> {
      return localVarFp.kindlesIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
    },
    /**
     * Update kindle by id
     * @summary Update kindle by id
     * @param {KindleApiKindlesIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kindlesIdPatch(
      requestParameters: KindleApiKindlesIdPatchRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<KindlesGet201Response> {
      return localVarFp
        .kindlesIdPatch(requestParameters.id, requestParameters.kindlesGetRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create a new kindles
     * @summary Create a new kindles
     * @param {KindleApiKindlesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kindlesPost(
      requestParameters: KindleApiKindlesPostRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<KindlesGet201Response> {
      return localVarFp
        .kindlesPost(requestParameters.kindlesGetRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for kindlesIdDelete operation in KindleApi.
 * @export
 * @interface KindleApiKindlesIdDeleteRequest
 */
export interface KindleApiKindlesIdDeleteRequest {
  /**
   * Kindle id
   * @type {number}
   * @memberof KindleApiKindlesIdDelete
   */
  readonly id: number;
}

/**
 * Request parameters for kindlesIdGet operation in KindleApi.
 * @export
 * @interface KindleApiKindlesIdGetRequest
 */
export interface KindleApiKindlesIdGetRequest {
  /**
   * Kindle id
   * @type {number}
   * @memberof KindleApiKindlesIdGet
   */
  readonly id: number;
}

/**
 * Request parameters for kindlesIdPatch operation in KindleApi.
 * @export
 * @interface KindleApiKindlesIdPatchRequest
 */
export interface KindleApiKindlesIdPatchRequest {
  /**
   * Kindle id
   * @type {number}
   * @memberof KindleApiKindlesIdPatch
   */
  readonly id: number;

  /**
   * Update kindle by id
   * @type {KindlesGetRequest}
   * @memberof KindleApiKindlesIdPatch
   */
  readonly kindlesGetRequest: KindlesGetRequest;
}

/**
 * Request parameters for kindlesPost operation in KindleApi.
 * @export
 * @interface KindleApiKindlesPostRequest
 */
export interface KindleApiKindlesPostRequest {
  /**
   * Create a new kindles
   * @type {KindlesGetRequest}
   * @memberof KindleApiKindlesPost
   */
  readonly kindlesGetRequest: KindlesGetRequest;
}

/**
 * KindleApi - object-oriented interface
 * @export
 * @class KindleApi
 * @extends {BaseAPI}
 */
export class KindleApi extends BaseAPI {
  /**
   * Get list of kindles with borrowed data
   * @summary Get list of kindles with borrowed data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KindleApi
   */
  public kindlesBorrowedGet(options?: AxiosRequestConfig) {
    return KindleApiFp(this.configuration)
      .kindlesBorrowedGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get list of kindles
   * @summary Get list of kindles
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KindleApi
   */
  public kindlesGet(options?: AxiosRequestConfig) {
    return KindleApiFp(this.configuration)
      .kindlesGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete kindle by id
   * @summary Delete kindle by id
   * @param {KindleApiKindlesIdDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KindleApi
   */
  public kindlesIdDelete(requestParameters: KindleApiKindlesIdDeleteRequest, options?: AxiosRequestConfig) {
    return KindleApiFp(this.configuration)
      .kindlesIdDelete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get kindle by id
   * @summary Get kindle by id
   * @param {KindleApiKindlesIdGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KindleApi
   */
  public kindlesIdGet(requestParameters: KindleApiKindlesIdGetRequest, options?: AxiosRequestConfig) {
    return KindleApiFp(this.configuration)
      .kindlesIdGet(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update kindle by id
   * @summary Update kindle by id
   * @param {KindleApiKindlesIdPatchRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KindleApi
   */
  public kindlesIdPatch(requestParameters: KindleApiKindlesIdPatchRequest, options?: AxiosRequestConfig) {
    return KindleApiFp(this.configuration)
      .kindlesIdPatch(requestParameters.id, requestParameters.kindlesGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a new kindles
   * @summary Create a new kindles
   * @param {KindleApiKindlesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KindleApi
   */
  public kindlesPost(requestParameters: KindleApiKindlesPostRequest, options?: AxiosRequestConfig) {
    return KindleApiFp(this.configuration)
      .kindlesPost(requestParameters.kindlesGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * KindleRegisterApi - axios parameter creator
 * @export
 */
export const KindleRegisterApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get list of kindle registers
     * @summary Get list of kindle registers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kindleRegisterAllGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/kindle_register/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a new kindle register
     * @summary Create a new kindle register
     * @param {KindleRegisterGetRequest} kindleRegisterGetRequest Create a new kindle register
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kindleRegisterAllPost: async (
      kindleRegisterGetRequest: KindleRegisterGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kindleRegisterGetRequest' is not null or undefined
      assertParamExists('kindleRegisterAllPost', 'kindleRegisterGetRequest', kindleRegisterGetRequest);
      const localVarPath = `/kindle_register/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        kindleRegisterGetRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update kindle register return_confirmed_at by confirmation
     * @summary Update kindle register return_confirmed_at column by confirmation
     * @param {number} id Kindle register id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kindleRegisterConfirmIdPatch: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('kindleRegisterConfirmIdPatch', 'id', id);
      const localVarPath = `/kindle_register/confirm/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get list of kindle registers
     * @summary Get list of kindle registers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kindleRegisterGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/kindle_register`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update kindle register by id
     * @summary Update kindle register by id
     * @param {number} id Kindle register id
     * @param {KindleRegisterGetRequest} kindleRegisterGetRequest Update kindle register by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kindleRegisterIdPatch: async (
      id: number,
      kindleRegisterGetRequest: KindleRegisterGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('kindleRegisterIdPatch', 'id', id);
      // verify required parameter 'kindleRegisterGetRequest' is not null or undefined
      assertParamExists('kindleRegisterIdPatch', 'kindleRegisterGetRequest', kindleRegisterGetRequest);
      const localVarPath = `/kindle_register/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        kindleRegisterGetRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a new kindle register
     * @summary Create a new kindle register
     * @param {KindleRegisterGetRequest} kindleRegisterGetRequest Create a new kindle register
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kindleRegisterPost: async (
      kindleRegisterGetRequest: KindleRegisterGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kindleRegisterGetRequest' is not null or undefined
      assertParamExists('kindleRegisterPost', 'kindleRegisterGetRequest', kindleRegisterGetRequest);
      const localVarPath = `/kindle_register`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        kindleRegisterGetRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * KindleRegisterApi - functional programming interface
 * @export
 */
export const KindleRegisterApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = KindleRegisterApiAxiosParamCreator(configuration);
  return {
    /**
     * Get list of kindle registers
     * @summary Get list of kindle registers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async kindleRegisterAllGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KindleRegisterGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.kindleRegisterAllGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Create a new kindle register
     * @summary Create a new kindle register
     * @param {KindleRegisterGetRequest} kindleRegisterGetRequest Create a new kindle register
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async kindleRegisterAllPost(
      kindleRegisterGetRequest: KindleRegisterGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KindleRegisterGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.kindleRegisterAllPost(
        kindleRegisterGetRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Update kindle register return_confirmed_at by confirmation
     * @summary Update kindle register return_confirmed_at column by confirmation
     * @param {number} id Kindle register id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async kindleRegisterConfirmIdPatch(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KindleRegisterGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.kindleRegisterConfirmIdPatch(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get list of kindle registers
     * @summary Get list of kindle registers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async kindleRegisterGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KindleRegisterGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.kindleRegisterGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Update kindle register by id
     * @summary Update kindle register by id
     * @param {number} id Kindle register id
     * @param {KindleRegisterGetRequest} kindleRegisterGetRequest Update kindle register by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async kindleRegisterIdPatch(
      id: number,
      kindleRegisterGetRequest: KindleRegisterGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KindleRegisterGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.kindleRegisterIdPatch(
        id,
        kindleRegisterGetRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Create a new kindle register
     * @summary Create a new kindle register
     * @param {KindleRegisterGetRequest} kindleRegisterGetRequest Create a new kindle register
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async kindleRegisterPost(
      kindleRegisterGetRequest: KindleRegisterGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KindleRegisterGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.kindleRegisterPost(kindleRegisterGetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * KindleRegisterApi - factory interface
 * @export
 */
export const KindleRegisterApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = KindleRegisterApiFp(configuration);
  return {
    /**
     * Get list of kindle registers
     * @summary Get list of kindle registers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kindleRegisterAllGet(options?: AxiosRequestConfig): AxiosPromise<KindleRegisterGet200Response> {
      return localVarFp.kindleRegisterAllGet(options).then((request) => request(axios, basePath));
    },
    /**
     * Create a new kindle register
     * @summary Create a new kindle register
     * @param {KindleRegisterApiKindleRegisterAllPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kindleRegisterAllPost(
      requestParameters: KindleRegisterApiKindleRegisterAllPostRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<KindleRegisterGet201Response> {
      return localVarFp
        .kindleRegisterAllPost(requestParameters.kindleRegisterGetRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update kindle register return_confirmed_at by confirmation
     * @summary Update kindle register return_confirmed_at column by confirmation
     * @param {KindleRegisterApiKindleRegisterConfirmIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kindleRegisterConfirmIdPatch(
      requestParameters: KindleRegisterApiKindleRegisterConfirmIdPatchRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<KindleRegisterGet201Response> {
      return localVarFp
        .kindleRegisterConfirmIdPatch(requestParameters.id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get list of kindle registers
     * @summary Get list of kindle registers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kindleRegisterGet(options?: AxiosRequestConfig): AxiosPromise<KindleRegisterGet200Response> {
      return localVarFp.kindleRegisterGet(options).then((request) => request(axios, basePath));
    },
    /**
     * Update kindle register by id
     * @summary Update kindle register by id
     * @param {KindleRegisterApiKindleRegisterIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kindleRegisterIdPatch(
      requestParameters: KindleRegisterApiKindleRegisterIdPatchRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<KindleRegisterGet201Response> {
      return localVarFp
        .kindleRegisterIdPatch(requestParameters.id, requestParameters.kindleRegisterGetRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create a new kindle register
     * @summary Create a new kindle register
     * @param {KindleRegisterApiKindleRegisterPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kindleRegisterPost(
      requestParameters: KindleRegisterApiKindleRegisterPostRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<KindleRegisterGet201Response> {
      return localVarFp
        .kindleRegisterPost(requestParameters.kindleRegisterGetRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for kindleRegisterAllPost operation in KindleRegisterApi.
 * @export
 * @interface KindleRegisterApiKindleRegisterAllPostRequest
 */
export interface KindleRegisterApiKindleRegisterAllPostRequest {
  /**
   * Create a new kindle register
   * @type {KindleRegisterGetRequest}
   * @memberof KindleRegisterApiKindleRegisterAllPost
   */
  readonly kindleRegisterGetRequest: KindleRegisterGetRequest;
}

/**
 * Request parameters for kindleRegisterConfirmIdPatch operation in KindleRegisterApi.
 * @export
 * @interface KindleRegisterApiKindleRegisterConfirmIdPatchRequest
 */
export interface KindleRegisterApiKindleRegisterConfirmIdPatchRequest {
  /**
   * Kindle register id
   * @type {number}
   * @memberof KindleRegisterApiKindleRegisterConfirmIdPatch
   */
  readonly id: number;
}

/**
 * Request parameters for kindleRegisterIdPatch operation in KindleRegisterApi.
 * @export
 * @interface KindleRegisterApiKindleRegisterIdPatchRequest
 */
export interface KindleRegisterApiKindleRegisterIdPatchRequest {
  /**
   * Kindle register id
   * @type {number}
   * @memberof KindleRegisterApiKindleRegisterIdPatch
   */
  readonly id: number;

  /**
   * Update kindle register by id
   * @type {KindleRegisterGetRequest}
   * @memberof KindleRegisterApiKindleRegisterIdPatch
   */
  readonly kindleRegisterGetRequest: KindleRegisterGetRequest;
}

/**
 * Request parameters for kindleRegisterPost operation in KindleRegisterApi.
 * @export
 * @interface KindleRegisterApiKindleRegisterPostRequest
 */
export interface KindleRegisterApiKindleRegisterPostRequest {
  /**
   * Create a new kindle register
   * @type {KindleRegisterGetRequest}
   * @memberof KindleRegisterApiKindleRegisterPost
   */
  readonly kindleRegisterGetRequest: KindleRegisterGetRequest;
}

/**
 * KindleRegisterApi - object-oriented interface
 * @export
 * @class KindleRegisterApi
 * @extends {BaseAPI}
 */
export class KindleRegisterApi extends BaseAPI {
  /**
   * Get list of kindle registers
   * @summary Get list of kindle registers
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KindleRegisterApi
   */
  public kindleRegisterAllGet(options?: AxiosRequestConfig) {
    return KindleRegisterApiFp(this.configuration)
      .kindleRegisterAllGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a new kindle register
   * @summary Create a new kindle register
   * @param {KindleRegisterApiKindleRegisterAllPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KindleRegisterApi
   */
  public kindleRegisterAllPost(
    requestParameters: KindleRegisterApiKindleRegisterAllPostRequest,
    options?: AxiosRequestConfig,
  ) {
    return KindleRegisterApiFp(this.configuration)
      .kindleRegisterAllPost(requestParameters.kindleRegisterGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update kindle register return_confirmed_at by confirmation
   * @summary Update kindle register return_confirmed_at column by confirmation
   * @param {KindleRegisterApiKindleRegisterConfirmIdPatchRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KindleRegisterApi
   */
  public kindleRegisterConfirmIdPatch(
    requestParameters: KindleRegisterApiKindleRegisterConfirmIdPatchRequest,
    options?: AxiosRequestConfig,
  ) {
    return KindleRegisterApiFp(this.configuration)
      .kindleRegisterConfirmIdPatch(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get list of kindle registers
   * @summary Get list of kindle registers
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KindleRegisterApi
   */
  public kindleRegisterGet(options?: AxiosRequestConfig) {
    return KindleRegisterApiFp(this.configuration)
      .kindleRegisterGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update kindle register by id
   * @summary Update kindle register by id
   * @param {KindleRegisterApiKindleRegisterIdPatchRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KindleRegisterApi
   */
  public kindleRegisterIdPatch(
    requestParameters: KindleRegisterApiKindleRegisterIdPatchRequest,
    options?: AxiosRequestConfig,
  ) {
    return KindleRegisterApiFp(this.configuration)
      .kindleRegisterIdPatch(requestParameters.id, requestParameters.kindleRegisterGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a new kindle register
   * @summary Create a new kindle register
   * @param {KindleRegisterApiKindleRegisterPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KindleRegisterApi
   */
  public kindleRegisterPost(
    requestParameters: KindleRegisterApiKindleRegisterPostRequest,
    options?: AxiosRequestConfig,
  ) {
    return KindleRegisterApiFp(this.configuration)
      .kindleRegisterPost(requestParameters.kindleRegisterGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OfficeApi - axios parameter creator
 * @export
 */
export const OfficeApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get list of offices
     * @summary Get list of offices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    officesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/offices`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete office by id
     * @summary Delete office by id
     * @param {number} id office id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    officesIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('officesIdDelete', 'id', id);
      const localVarPath = `/offices/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get office by id
     * @summary Get office by id
     * @param {number} id Office id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    officesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('officesIdGet', 'id', id);
      const localVarPath = `/offices/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update office by id
     * @summary Update office by id
     * @param {number} id Office id
     * @param {OfficesGetRequest} officesGetRequest Update office by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    officesIdPatch: async (
      id: number,
      officesGetRequest: OfficesGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('officesIdPatch', 'id', id);
      // verify required parameter 'officesGetRequest' is not null or undefined
      assertParamExists('officesIdPatch', 'officesGetRequest', officesGetRequest);
      const localVarPath = `/offices/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(officesGetRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a new office
     * @summary Create a new office
     * @param {OfficesGetRequest} officesGetRequest Create a new office
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    officesPost: async (
      officesGetRequest: OfficesGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'officesGetRequest' is not null or undefined
      assertParamExists('officesPost', 'officesGetRequest', officesGetRequest);
      const localVarPath = `/offices`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(officesGetRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OfficeApi - functional programming interface
 * @export
 */
export const OfficeApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OfficeApiAxiosParamCreator(configuration);
  return {
    /**
     * Get list of offices
     * @summary Get list of offices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async officesGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficesGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.officesGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Delete office by id
     * @summary Delete office by id
     * @param {number} id office id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async officesIdDelete(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.officesIdDelete(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get office by id
     * @summary Get office by id
     * @param {number} id Office id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async officesIdGet(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficesGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.officesIdGet(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Update office by id
     * @summary Update office by id
     * @param {number} id Office id
     * @param {OfficesGetRequest} officesGetRequest Update office by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async officesIdPatch(
      id: number,
      officesGetRequest: OfficesGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficesGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.officesIdPatch(id, officesGetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Create a new office
     * @summary Create a new office
     * @param {OfficesGetRequest} officesGetRequest Create a new office
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async officesPost(
      officesGetRequest: OfficesGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficesGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.officesPost(officesGetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * OfficeApi - factory interface
 * @export
 */
export const OfficeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = OfficeApiFp(configuration);
  return {
    /**
     * Get list of offices
     * @summary Get list of offices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    officesGet(options?: AxiosRequestConfig): AxiosPromise<OfficesGet200Response> {
      return localVarFp.officesGet(options).then((request) => request(axios, basePath));
    },
    /**
     * Delete office by id
     * @summary Delete office by id
     * @param {OfficeApiOfficesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    officesIdDelete(
      requestParameters: OfficeApiOfficesIdDeleteRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp.officesIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
    },
    /**
     * Get office by id
     * @summary Get office by id
     * @param {OfficeApiOfficesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    officesIdGet(
      requestParameters: OfficeApiOfficesIdGetRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<OfficesGet201Response> {
      return localVarFp.officesIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
    },
    /**
     * Update office by id
     * @summary Update office by id
     * @param {OfficeApiOfficesIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    officesIdPatch(
      requestParameters: OfficeApiOfficesIdPatchRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<OfficesGet201Response> {
      return localVarFp
        .officesIdPatch(requestParameters.id, requestParameters.officesGetRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create a new office
     * @summary Create a new office
     * @param {OfficeApiOfficesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    officesPost(
      requestParameters: OfficeApiOfficesPostRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<OfficesGet201Response> {
      return localVarFp
        .officesPost(requestParameters.officesGetRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for officesIdDelete operation in OfficeApi.
 * @export
 * @interface OfficeApiOfficesIdDeleteRequest
 */
export interface OfficeApiOfficesIdDeleteRequest {
  /**
   * office id
   * @type {number}
   * @memberof OfficeApiOfficesIdDelete
   */
  readonly id: number;
}

/**
 * Request parameters for officesIdGet operation in OfficeApi.
 * @export
 * @interface OfficeApiOfficesIdGetRequest
 */
export interface OfficeApiOfficesIdGetRequest {
  /**
   * Office id
   * @type {number}
   * @memberof OfficeApiOfficesIdGet
   */
  readonly id: number;
}

/**
 * Request parameters for officesIdPatch operation in OfficeApi.
 * @export
 * @interface OfficeApiOfficesIdPatchRequest
 */
export interface OfficeApiOfficesIdPatchRequest {
  /**
   * Office id
   * @type {number}
   * @memberof OfficeApiOfficesIdPatch
   */
  readonly id: number;

  /**
   * Update office by id
   * @type {OfficesGetRequest}
   * @memberof OfficeApiOfficesIdPatch
   */
  readonly officesGetRequest: OfficesGetRequest;
}

/**
 * Request parameters for officesPost operation in OfficeApi.
 * @export
 * @interface OfficeApiOfficesPostRequest
 */
export interface OfficeApiOfficesPostRequest {
  /**
   * Create a new office
   * @type {OfficesGetRequest}
   * @memberof OfficeApiOfficesPost
   */
  readonly officesGetRequest: OfficesGetRequest;
}

/**
 * OfficeApi - object-oriented interface
 * @export
 * @class OfficeApi
 * @extends {BaseAPI}
 */
export class OfficeApi extends BaseAPI {
  /**
   * Get list of offices
   * @summary Get list of offices
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfficeApi
   */
  public officesGet(options?: AxiosRequestConfig) {
    return OfficeApiFp(this.configuration)
      .officesGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete office by id
   * @summary Delete office by id
   * @param {OfficeApiOfficesIdDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfficeApi
   */
  public officesIdDelete(requestParameters: OfficeApiOfficesIdDeleteRequest, options?: AxiosRequestConfig) {
    return OfficeApiFp(this.configuration)
      .officesIdDelete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get office by id
   * @summary Get office by id
   * @param {OfficeApiOfficesIdGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfficeApi
   */
  public officesIdGet(requestParameters: OfficeApiOfficesIdGetRequest, options?: AxiosRequestConfig) {
    return OfficeApiFp(this.configuration)
      .officesIdGet(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update office by id
   * @summary Update office by id
   * @param {OfficeApiOfficesIdPatchRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfficeApi
   */
  public officesIdPatch(requestParameters: OfficeApiOfficesIdPatchRequest, options?: AxiosRequestConfig) {
    return OfficeApiFp(this.configuration)
      .officesIdPatch(requestParameters.id, requestParameters.officesGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a new office
   * @summary Create a new office
   * @param {OfficeApiOfficesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfficeApi
   */
  public officesPost(requestParameters: OfficeApiOfficesPostRequest, options?: AxiosRequestConfig) {
    return OfficeApiFp(this.configuration)
      .officesPost(requestParameters.officesGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ReportApi - axios parameter creator
 * @export
 */
export const ReportApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Show the average period (in days) of the books
     * @summary Get list of report for average borrowing period
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reportsAverageBorrowingPeriodGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/reports/average_borrowing_period`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Show how many times a book was borrowed in a given period of time
     * @summary Get list of report frequency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reportsFrequencyGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/reports/frequency`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Show the main indicators per readers in a given period
     * @summary Get list of readers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reportsReadersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/reports/readers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ReportApiAxiosParamCreator(configuration);
  return {
    /**
     * Show the average period (in days) of the books
     * @summary Get list of report for average borrowing period
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reportsAverageBorrowingPeriodGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportsAverageBorrowingPeriodGet200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.reportsAverageBorrowingPeriodGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Show how many times a book was borrowed in a given period of time
     * @summary Get list of report frequency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reportsFrequencyGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportsFrequencyGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.reportsFrequencyGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Show the main indicators per readers in a given period
     * @summary Get list of readers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reportsReadersGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportsReadersGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.reportsReadersGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ReportApiFp(configuration);
  return {
    /**
     * Show the average period (in days) of the books
     * @summary Get list of report for average borrowing period
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reportsAverageBorrowingPeriodGet(
      options?: AxiosRequestConfig,
    ): AxiosPromise<ReportsAverageBorrowingPeriodGet200Response> {
      return localVarFp.reportsAverageBorrowingPeriodGet(options).then((request) => request(axios, basePath));
    },
    /**
     * Show how many times a book was borrowed in a given period of time
     * @summary Get list of report frequency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reportsFrequencyGet(options?: AxiosRequestConfig): AxiosPromise<ReportsFrequencyGet200Response> {
      return localVarFp.reportsFrequencyGet(options).then((request) => request(axios, basePath));
    },
    /**
     * Show the main indicators per readers in a given period
     * @summary Get list of readers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reportsReadersGet(options?: AxiosRequestConfig): AxiosPromise<ReportsReadersGet200Response> {
      return localVarFp.reportsReadersGet(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
  /**
   * Show the average period (in days) of the books
   * @summary Get list of report for average borrowing period
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public reportsAverageBorrowingPeriodGet(options?: AxiosRequestConfig) {
    return ReportApiFp(this.configuration)
      .reportsAverageBorrowingPeriodGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Show how many times a book was borrowed in a given period of time
   * @summary Get list of report frequency
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public reportsFrequencyGet(options?: AxiosRequestConfig) {
    return ReportApiFp(this.configuration)
      .reportsFrequencyGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Show the main indicators per readers in a given period
   * @summary Get list of readers
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public reportsReadersGet(options?: AxiosRequestConfig) {
    return ReportApiFp(this.configuration)
      .reportsReadersGet(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SettingApi - axios parameter creator
 * @export
 */
export const SettingApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get list of settings
     * @summary Get list of settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settingsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/settings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete setting by id
     * @summary Delete setting by id
     * @param {number} id setting id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settingsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('settingsIdDelete', 'id', id);
      const localVarPath = `/settings/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get setting by id
     * @summary Get setting by id
     * @param {number} id Setting id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settingsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('settingsIdGet', 'id', id);
      const localVarPath = `/settings/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update setting by id
     * @summary Update setting by id
     * @param {number} id Setting id
     * @param {SettingsGetRequest} settingsGetRequest Update setting by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settingsIdPatch: async (
      id: number,
      settingsGetRequest: SettingsGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('settingsIdPatch', 'id', id);
      // verify required parameter 'settingsGetRequest' is not null or undefined
      assertParamExists('settingsIdPatch', 'settingsGetRequest', settingsGetRequest);
      const localVarPath = `/settings/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(settingsGetRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a new setting
     * @summary Create a new setting
     * @param {SettingsGetRequest} settingsGetRequest Create a new setting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settingsPost: async (
      settingsGetRequest: SettingsGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'settingsGetRequest' is not null or undefined
      assertParamExists('settingsPost', 'settingsGetRequest', settingsGetRequest);
      const localVarPath = `/settings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(settingsGetRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SettingApi - functional programming interface
 * @export
 */
export const SettingApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SettingApiAxiosParamCreator(configuration);
  return {
    /**
     * Get list of settings
     * @summary Get list of settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async settingsGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.settingsGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Delete setting by id
     * @summary Delete setting by id
     * @param {number} id setting id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async settingsIdDelete(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.settingsIdDelete(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get setting by id
     * @summary Get setting by id
     * @param {number} id Setting id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async settingsIdGet(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.settingsIdGet(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Update setting by id
     * @summary Update setting by id
     * @param {number} id Setting id
     * @param {SettingsGetRequest} settingsGetRequest Update setting by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async settingsIdPatch(
      id: number,
      settingsGetRequest: SettingsGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.settingsIdPatch(id, settingsGetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Create a new setting
     * @summary Create a new setting
     * @param {SettingsGetRequest} settingsGetRequest Create a new setting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async settingsPost(
      settingsGetRequest: SettingsGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.settingsPost(settingsGetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SettingApi - factory interface
 * @export
 */
export const SettingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SettingApiFp(configuration);
  return {
    /**
     * Get list of settings
     * @summary Get list of settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settingsGet(options?: AxiosRequestConfig): AxiosPromise<SettingsGet200Response> {
      return localVarFp.settingsGet(options).then((request) => request(axios, basePath));
    },
    /**
     * Delete setting by id
     * @summary Delete setting by id
     * @param {SettingApiSettingsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settingsIdDelete(
      requestParameters: SettingApiSettingsIdDeleteRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp.settingsIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
    },
    /**
     * Get setting by id
     * @summary Get setting by id
     * @param {SettingApiSettingsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settingsIdGet(
      requestParameters: SettingApiSettingsIdGetRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<SettingsGet201Response> {
      return localVarFp.settingsIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
    },
    /**
     * Update setting by id
     * @summary Update setting by id
     * @param {SettingApiSettingsIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settingsIdPatch(
      requestParameters: SettingApiSettingsIdPatchRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<SettingsGet201Response> {
      return localVarFp
        .settingsIdPatch(requestParameters.id, requestParameters.settingsGetRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create a new setting
     * @summary Create a new setting
     * @param {SettingApiSettingsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settingsPost(
      requestParameters: SettingApiSettingsPostRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<SettingsGet201Response> {
      return localVarFp
        .settingsPost(requestParameters.settingsGetRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for settingsIdDelete operation in SettingApi.
 * @export
 * @interface SettingApiSettingsIdDeleteRequest
 */
export interface SettingApiSettingsIdDeleteRequest {
  /**
   * setting id
   * @type {number}
   * @memberof SettingApiSettingsIdDelete
   */
  readonly id: number;
}

/**
 * Request parameters for settingsIdGet operation in SettingApi.
 * @export
 * @interface SettingApiSettingsIdGetRequest
 */
export interface SettingApiSettingsIdGetRequest {
  /**
   * Setting id
   * @type {number}
   * @memberof SettingApiSettingsIdGet
   */
  readonly id: number;
}

/**
 * Request parameters for settingsIdPatch operation in SettingApi.
 * @export
 * @interface SettingApiSettingsIdPatchRequest
 */
export interface SettingApiSettingsIdPatchRequest {
  /**
   * Setting id
   * @type {number}
   * @memberof SettingApiSettingsIdPatch
   */
  readonly id: number;

  /**
   * Update setting by id
   * @type {SettingsGetRequest}
   * @memberof SettingApiSettingsIdPatch
   */
  readonly settingsGetRequest: SettingsGetRequest;
}

/**
 * Request parameters for settingsPost operation in SettingApi.
 * @export
 * @interface SettingApiSettingsPostRequest
 */
export interface SettingApiSettingsPostRequest {
  /**
   * Create a new setting
   * @type {SettingsGetRequest}
   * @memberof SettingApiSettingsPost
   */
  readonly settingsGetRequest: SettingsGetRequest;
}

/**
 * SettingApi - object-oriented interface
 * @export
 * @class SettingApi
 * @extends {BaseAPI}
 */
export class SettingApi extends BaseAPI {
  /**
   * Get list of settings
   * @summary Get list of settings
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingApi
   */
  public settingsGet(options?: AxiosRequestConfig) {
    return SettingApiFp(this.configuration)
      .settingsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete setting by id
   * @summary Delete setting by id
   * @param {SettingApiSettingsIdDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingApi
   */
  public settingsIdDelete(requestParameters: SettingApiSettingsIdDeleteRequest, options?: AxiosRequestConfig) {
    return SettingApiFp(this.configuration)
      .settingsIdDelete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get setting by id
   * @summary Get setting by id
   * @param {SettingApiSettingsIdGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingApi
   */
  public settingsIdGet(requestParameters: SettingApiSettingsIdGetRequest, options?: AxiosRequestConfig) {
    return SettingApiFp(this.configuration)
      .settingsIdGet(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update setting by id
   * @summary Update setting by id
   * @param {SettingApiSettingsIdPatchRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingApi
   */
  public settingsIdPatch(requestParameters: SettingApiSettingsIdPatchRequest, options?: AxiosRequestConfig) {
    return SettingApiFp(this.configuration)
      .settingsIdPatch(requestParameters.id, requestParameters.settingsGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a new setting
   * @summary Create a new setting
   * @param {SettingApiSettingsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingApi
   */
  public settingsPost(requestParameters: SettingApiSettingsPostRequest, options?: AxiosRequestConfig) {
    return SettingApiFp(this.configuration)
      .settingsPost(requestParameters.settingsGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SubscriptionApi - axios parameter creator
 * @export
 */
export const SubscriptionApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get list of subscriptions
     * @summary Get list of subscriptions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscriptionsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/subscriptions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update subscription by id
     * @summary Update subscription by id
     * @param {number} id subscription id
     * @param {SubscriptionsIdPatchRequest} subscriptionsIdPatchRequest Update subscription by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscriptionsIdPatch: async (
      id: number,
      subscriptionsIdPatchRequest: SubscriptionsIdPatchRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('subscriptionsIdPatch', 'id', id);
      // verify required parameter 'subscriptionsIdPatchRequest' is not null or undefined
      assertParamExists('subscriptionsIdPatch', 'subscriptionsIdPatchRequest', subscriptionsIdPatchRequest);
      const localVarPath = `/subscriptions/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        subscriptionsIdPatchRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a new subscription
     * @summary Create a new subscription
     * @param {SubscriptionsGetRequest} subscriptionsGetRequest Create a new subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscriptionsPost: async (
      subscriptionsGetRequest: SubscriptionsGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'subscriptionsGetRequest' is not null or undefined
      assertParamExists('subscriptionsPost', 'subscriptionsGetRequest', subscriptionsGetRequest);
      const localVarPath = `/subscriptions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        subscriptionsGetRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SubscriptionApi - functional programming interface
 * @export
 */
export const SubscriptionApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SubscriptionApiAxiosParamCreator(configuration);
  return {
    /**
     * Get list of subscriptions
     * @summary Get list of subscriptions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async subscriptionsGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionsGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionsGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Update subscription by id
     * @summary Update subscription by id
     * @param {number} id subscription id
     * @param {SubscriptionsIdPatchRequest} subscriptionsIdPatchRequest Update subscription by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async subscriptionsIdPatch(
      id: number,
      subscriptionsIdPatchRequest: SubscriptionsIdPatchRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionsGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionsIdPatch(
        id,
        subscriptionsIdPatchRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Create a new subscription
     * @summary Create a new subscription
     * @param {SubscriptionsGetRequest} subscriptionsGetRequest Create a new subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async subscriptionsPost(
      subscriptionsGetRequest: SubscriptionsGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionsGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionsPost(subscriptionsGetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SubscriptionApi - factory interface
 * @export
 */
export const SubscriptionApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SubscriptionApiFp(configuration);
  return {
    /**
     * Get list of subscriptions
     * @summary Get list of subscriptions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscriptionsGet(options?: AxiosRequestConfig): AxiosPromise<SubscriptionsGet200Response> {
      return localVarFp.subscriptionsGet(options).then((request) => request(axios, basePath));
    },
    /**
     * Update subscription by id
     * @summary Update subscription by id
     * @param {SubscriptionApiSubscriptionsIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscriptionsIdPatch(
      requestParameters: SubscriptionApiSubscriptionsIdPatchRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<SubscriptionsGet201Response> {
      return localVarFp
        .subscriptionsIdPatch(requestParameters.id, requestParameters.subscriptionsIdPatchRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create a new subscription
     * @summary Create a new subscription
     * @param {SubscriptionApiSubscriptionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscriptionsPost(
      requestParameters: SubscriptionApiSubscriptionsPostRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<SubscriptionsGet201Response> {
      return localVarFp
        .subscriptionsPost(requestParameters.subscriptionsGetRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for subscriptionsIdPatch operation in SubscriptionApi.
 * @export
 * @interface SubscriptionApiSubscriptionsIdPatchRequest
 */
export interface SubscriptionApiSubscriptionsIdPatchRequest {
  /**
   * subscription id
   * @type {number}
   * @memberof SubscriptionApiSubscriptionsIdPatch
   */
  readonly id: number;

  /**
   * Update subscription by id
   * @type {SubscriptionsIdPatchRequest}
   * @memberof SubscriptionApiSubscriptionsIdPatch
   */
  readonly subscriptionsIdPatchRequest: SubscriptionsIdPatchRequest;
}

/**
 * Request parameters for subscriptionsPost operation in SubscriptionApi.
 * @export
 * @interface SubscriptionApiSubscriptionsPostRequest
 */
export interface SubscriptionApiSubscriptionsPostRequest {
  /**
   * Create a new subscription
   * @type {SubscriptionsGetRequest}
   * @memberof SubscriptionApiSubscriptionsPost
   */
  readonly subscriptionsGetRequest: SubscriptionsGetRequest;
}

/**
 * SubscriptionApi - object-oriented interface
 * @export
 * @class SubscriptionApi
 * @extends {BaseAPI}
 */
export class SubscriptionApi extends BaseAPI {
  /**
   * Get list of subscriptions
   * @summary Get list of subscriptions
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionApi
   */
  public subscriptionsGet(options?: AxiosRequestConfig) {
    return SubscriptionApiFp(this.configuration)
      .subscriptionsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update subscription by id
   * @summary Update subscription by id
   * @param {SubscriptionApiSubscriptionsIdPatchRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionApi
   */
  public subscriptionsIdPatch(
    requestParameters: SubscriptionApiSubscriptionsIdPatchRequest,
    options?: AxiosRequestConfig,
  ) {
    return SubscriptionApiFp(this.configuration)
      .subscriptionsIdPatch(requestParameters.id, requestParameters.subscriptionsIdPatchRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a new subscription
   * @summary Create a new subscription
   * @param {SubscriptionApiSubscriptionsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionApi
   */
  public subscriptionsPost(requestParameters: SubscriptionApiSubscriptionsPostRequest, options?: AxiosRequestConfig) {
    return SubscriptionApiFp(this.configuration)
      .subscriptionsPost(requestParameters.subscriptionsGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SuggestedBooksApi - axios parameter creator
 * @export
 */
export const SuggestedBooksApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get list of suggested Books
     * @summary Get list of suggested Books
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    suggestedBooksGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/suggested_books`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete suggested book by id
     * @summary Delete suggested book by id
     * @param {number} id Suggested book id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    suggestedBooksIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('suggestedBooksIdDelete', 'id', id);
      const localVarPath = `/suggested_books/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update suggested book by id
     * @summary Update suggested book by id
     * @param {number} id subscription id
     * @param {SuggestedBooksGetRequest} suggestedBooksGetRequest Update subscription by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    suggestedBooksIdPatch: async (
      id: number,
      suggestedBooksGetRequest: SuggestedBooksGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('suggestedBooksIdPatch', 'id', id);
      // verify required parameter 'suggestedBooksGetRequest' is not null or undefined
      assertParamExists('suggestedBooksIdPatch', 'suggestedBooksGetRequest', suggestedBooksGetRequest);
      const localVarPath = `/suggested_books/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        suggestedBooksGetRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a suggested Books
     * @summary Create a new suggested Books
     * @param {SuggestedBooksGetRequest} suggestedBooksGetRequest Create a suggested Books
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    suggestedBooksPost: async (
      suggestedBooksGetRequest: SuggestedBooksGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'suggestedBooksGetRequest' is not null or undefined
      assertParamExists('suggestedBooksPost', 'suggestedBooksGetRequest', suggestedBooksGetRequest);
      const localVarPath = `/suggested_books`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        suggestedBooksGetRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SuggestedBooksApi - functional programming interface
 * @export
 */
export const SuggestedBooksApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SuggestedBooksApiAxiosParamCreator(configuration);
  return {
    /**
     * Get list of suggested Books
     * @summary Get list of suggested Books
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async suggestedBooksGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuggestedBooksGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.suggestedBooksGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Delete suggested book by id
     * @summary Delete suggested book by id
     * @param {number} id Suggested book id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async suggestedBooksIdDelete(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.suggestedBooksIdDelete(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Update suggested book by id
     * @summary Update suggested book by id
     * @param {number} id subscription id
     * @param {SuggestedBooksGetRequest} suggestedBooksGetRequest Update subscription by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async suggestedBooksIdPatch(
      id: number,
      suggestedBooksGetRequest: SuggestedBooksGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuggestedBooksGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.suggestedBooksIdPatch(
        id,
        suggestedBooksGetRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Create a suggested Books
     * @summary Create a new suggested Books
     * @param {SuggestedBooksGetRequest} suggestedBooksGetRequest Create a suggested Books
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async suggestedBooksPost(
      suggestedBooksGetRequest: SuggestedBooksGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuggestedBooksGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.suggestedBooksPost(suggestedBooksGetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SuggestedBooksApi - factory interface
 * @export
 */
export const SuggestedBooksApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SuggestedBooksApiFp(configuration);
  return {
    /**
     * Get list of suggested Books
     * @summary Get list of suggested Books
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    suggestedBooksGet(options?: AxiosRequestConfig): AxiosPromise<SuggestedBooksGet200Response> {
      return localVarFp.suggestedBooksGet(options).then((request) => request(axios, basePath));
    },
    /**
     * Delete suggested book by id
     * @summary Delete suggested book by id
     * @param {SuggestedBooksApiSuggestedBooksIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    suggestedBooksIdDelete(
      requestParameters: SuggestedBooksApiSuggestedBooksIdDeleteRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .suggestedBooksIdDelete(requestParameters.id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update suggested book by id
     * @summary Update suggested book by id
     * @param {SuggestedBooksApiSuggestedBooksIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    suggestedBooksIdPatch(
      requestParameters: SuggestedBooksApiSuggestedBooksIdPatchRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<SuggestedBooksGet201Response> {
      return localVarFp
        .suggestedBooksIdPatch(requestParameters.id, requestParameters.suggestedBooksGetRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create a suggested Books
     * @summary Create a new suggested Books
     * @param {SuggestedBooksApiSuggestedBooksPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    suggestedBooksPost(
      requestParameters: SuggestedBooksApiSuggestedBooksPostRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<SuggestedBooksGet201Response> {
      return localVarFp
        .suggestedBooksPost(requestParameters.suggestedBooksGetRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for suggestedBooksIdDelete operation in SuggestedBooksApi.
 * @export
 * @interface SuggestedBooksApiSuggestedBooksIdDeleteRequest
 */
export interface SuggestedBooksApiSuggestedBooksIdDeleteRequest {
  /**
   * Suggested book id
   * @type {number}
   * @memberof SuggestedBooksApiSuggestedBooksIdDelete
   */
  readonly id: number;
}

/**
 * Request parameters for suggestedBooksIdPatch operation in SuggestedBooksApi.
 * @export
 * @interface SuggestedBooksApiSuggestedBooksIdPatchRequest
 */
export interface SuggestedBooksApiSuggestedBooksIdPatchRequest {
  /**
   * subscription id
   * @type {number}
   * @memberof SuggestedBooksApiSuggestedBooksIdPatch
   */
  readonly id: number;

  /**
   * Update subscription by id
   * @type {SuggestedBooksGetRequest}
   * @memberof SuggestedBooksApiSuggestedBooksIdPatch
   */
  readonly suggestedBooksGetRequest: SuggestedBooksGetRequest;
}

/**
 * Request parameters for suggestedBooksPost operation in SuggestedBooksApi.
 * @export
 * @interface SuggestedBooksApiSuggestedBooksPostRequest
 */
export interface SuggestedBooksApiSuggestedBooksPostRequest {
  /**
   * Create a suggested Books
   * @type {SuggestedBooksGetRequest}
   * @memberof SuggestedBooksApiSuggestedBooksPost
   */
  readonly suggestedBooksGetRequest: SuggestedBooksGetRequest;
}

/**
 * SuggestedBooksApi - object-oriented interface
 * @export
 * @class SuggestedBooksApi
 * @extends {BaseAPI}
 */
export class SuggestedBooksApi extends BaseAPI {
  /**
   * Get list of suggested Books
   * @summary Get list of suggested Books
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SuggestedBooksApi
   */
  public suggestedBooksGet(options?: AxiosRequestConfig) {
    return SuggestedBooksApiFp(this.configuration)
      .suggestedBooksGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete suggested book by id
   * @summary Delete suggested book by id
   * @param {SuggestedBooksApiSuggestedBooksIdDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SuggestedBooksApi
   */
  public suggestedBooksIdDelete(
    requestParameters: SuggestedBooksApiSuggestedBooksIdDeleteRequest,
    options?: AxiosRequestConfig,
  ) {
    return SuggestedBooksApiFp(this.configuration)
      .suggestedBooksIdDelete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update suggested book by id
   * @summary Update suggested book by id
   * @param {SuggestedBooksApiSuggestedBooksIdPatchRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SuggestedBooksApi
   */
  public suggestedBooksIdPatch(
    requestParameters: SuggestedBooksApiSuggestedBooksIdPatchRequest,
    options?: AxiosRequestConfig,
  ) {
    return SuggestedBooksApiFp(this.configuration)
      .suggestedBooksIdPatch(requestParameters.id, requestParameters.suggestedBooksGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a suggested Books
   * @summary Create a new suggested Books
   * @param {SuggestedBooksApiSuggestedBooksPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SuggestedBooksApi
   */
  public suggestedBooksPost(
    requestParameters: SuggestedBooksApiSuggestedBooksPostRequest,
    options?: AxiosRequestConfig,
  ) {
    return SuggestedBooksApiFp(this.configuration)
      .suggestedBooksPost(requestParameters.suggestedBooksGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TagApi - axios parameter creator
 * @export
 */
export const TagApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get list of tags
     * @summary Get list of tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/tags`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete tag by id
     * @summary Delete tag by id
     * @param {number} id Tag id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('tagsIdDelete', 'id', id);
      const localVarPath = `/tags/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get tag by id
     * @summary Get tag by id
     * @param {number} id Tag id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('tagsIdGet', 'id', id);
      const localVarPath = `/tags/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update tag by id
     * @summary Update tag by id
     * @param {number} id Tag id
     * @param {TagsGetRequest} tagsGetRequest Update tag by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagsIdPatch: async (
      id: number,
      tagsGetRequest: TagsGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('tagsIdPatch', 'id', id);
      // verify required parameter 'tagsGetRequest' is not null or undefined
      assertParamExists('tagsIdPatch', 'tagsGetRequest', tagsGetRequest);
      const localVarPath = `/tags/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(tagsGetRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a new tags
     * @summary Create a new tags
     * @param {TagsGetRequest} tagsGetRequest Create a new tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagsPost: async (tagsGetRequest: TagsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'tagsGetRequest' is not null or undefined
      assertParamExists('tagsPost', 'tagsGetRequest', tagsGetRequest);
      const localVarPath = `/tags`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(tagsGetRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TagApi - functional programming interface
 * @export
 */
export const TagApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TagApiAxiosParamCreator(configuration);
  return {
    /**
     * Get list of tags
     * @summary Get list of tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tagsGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagsGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tagsGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Delete tag by id
     * @summary Delete tag by id
     * @param {number} id Tag id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tagsIdDelete(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tagsIdDelete(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get tag by id
     * @summary Get tag by id
     * @param {number} id Tag id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tagsIdGet(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagsGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tagsIdGet(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Update tag by id
     * @summary Update tag by id
     * @param {number} id Tag id
     * @param {TagsGetRequest} tagsGetRequest Update tag by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tagsIdPatch(
      id: number,
      tagsGetRequest: TagsGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagsGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tagsIdPatch(id, tagsGetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Create a new tags
     * @summary Create a new tags
     * @param {TagsGetRequest} tagsGetRequest Create a new tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tagsPost(
      tagsGetRequest: TagsGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagsGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tagsPost(tagsGetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * TagApi - factory interface
 * @export
 */
export const TagApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = TagApiFp(configuration);
  return {
    /**
     * Get list of tags
     * @summary Get list of tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagsGet(options?: AxiosRequestConfig): AxiosPromise<TagsGet200Response> {
      return localVarFp.tagsGet(options).then((request) => request(axios, basePath));
    },
    /**
     * Delete tag by id
     * @summary Delete tag by id
     * @param {TagApiTagsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagsIdDelete(requestParameters: TagApiTagsIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
      return localVarFp.tagsIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
    },
    /**
     * Get tag by id
     * @summary Get tag by id
     * @param {TagApiTagsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagsIdGet(
      requestParameters: TagApiTagsIdGetRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<TagsGet201Response> {
      return localVarFp.tagsIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
    },
    /**
     * Update tag by id
     * @summary Update tag by id
     * @param {TagApiTagsIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagsIdPatch(
      requestParameters: TagApiTagsIdPatchRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<TagsGet201Response> {
      return localVarFp
        .tagsIdPatch(requestParameters.id, requestParameters.tagsGetRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create a new tags
     * @summary Create a new tags
     * @param {TagApiTagsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagsPost(requestParameters: TagApiTagsPostRequest, options?: AxiosRequestConfig): AxiosPromise<TagsGet201Response> {
      return localVarFp.tagsPost(requestParameters.tagsGetRequest, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for tagsIdDelete operation in TagApi.
 * @export
 * @interface TagApiTagsIdDeleteRequest
 */
export interface TagApiTagsIdDeleteRequest {
  /**
   * Tag id
   * @type {number}
   * @memberof TagApiTagsIdDelete
   */
  readonly id: number;
}

/**
 * Request parameters for tagsIdGet operation in TagApi.
 * @export
 * @interface TagApiTagsIdGetRequest
 */
export interface TagApiTagsIdGetRequest {
  /**
   * Tag id
   * @type {number}
   * @memberof TagApiTagsIdGet
   */
  readonly id: number;
}

/**
 * Request parameters for tagsIdPatch operation in TagApi.
 * @export
 * @interface TagApiTagsIdPatchRequest
 */
export interface TagApiTagsIdPatchRequest {
  /**
   * Tag id
   * @type {number}
   * @memberof TagApiTagsIdPatch
   */
  readonly id: number;

  /**
   * Update tag by id
   * @type {TagsGetRequest}
   * @memberof TagApiTagsIdPatch
   */
  readonly tagsGetRequest: TagsGetRequest;
}

/**
 * Request parameters for tagsPost operation in TagApi.
 * @export
 * @interface TagApiTagsPostRequest
 */
export interface TagApiTagsPostRequest {
  /**
   * Create a new tags
   * @type {TagsGetRequest}
   * @memberof TagApiTagsPost
   */
  readonly tagsGetRequest: TagsGetRequest;
}

/**
 * TagApi - object-oriented interface
 * @export
 * @class TagApi
 * @extends {BaseAPI}
 */
export class TagApi extends BaseAPI {
  /**
   * Get list of tags
   * @summary Get list of tags
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagApi
   */
  public tagsGet(options?: AxiosRequestConfig) {
    return TagApiFp(this.configuration)
      .tagsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete tag by id
   * @summary Delete tag by id
   * @param {TagApiTagsIdDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagApi
   */
  public tagsIdDelete(requestParameters: TagApiTagsIdDeleteRequest, options?: AxiosRequestConfig) {
    return TagApiFp(this.configuration)
      .tagsIdDelete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get tag by id
   * @summary Get tag by id
   * @param {TagApiTagsIdGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagApi
   */
  public tagsIdGet(requestParameters: TagApiTagsIdGetRequest, options?: AxiosRequestConfig) {
    return TagApiFp(this.configuration)
      .tagsIdGet(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update tag by id
   * @summary Update tag by id
   * @param {TagApiTagsIdPatchRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagApi
   */
  public tagsIdPatch(requestParameters: TagApiTagsIdPatchRequest, options?: AxiosRequestConfig) {
    return TagApiFp(this.configuration)
      .tagsIdPatch(requestParameters.id, requestParameters.tagsGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a new tags
   * @summary Create a new tags
   * @param {TagApiTagsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagApi
   */
  public tagsPost(requestParameters: TagApiTagsPostRequest, options?: AxiosRequestConfig) {
    return TagApiFp(this.configuration)
      .tagsPost(requestParameters.tagsGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TopApi - axios parameter creator
 * @export
 */
export const TopApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get list of top book registers of the month
     * @summary Get list of top books
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bookRegisterTopGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/book_register/top`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TopApi - functional programming interface
 * @export
 */
export const TopApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TopApiAxiosParamCreator(configuration);
  return {
    /**
     * Get list of top book registers of the month
     * @summary Get list of top books
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bookRegisterTopGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookRegisterTopGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bookRegisterTopGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * TopApi - factory interface
 * @export
 */
export const TopApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = TopApiFp(configuration);
  return {
    /**
     * Get list of top book registers of the month
     * @summary Get list of top books
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bookRegisterTopGet(options?: AxiosRequestConfig): AxiosPromise<BookRegisterTopGet200Response> {
      return localVarFp.bookRegisterTopGet(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * TopApi - object-oriented interface
 * @export
 * @class TopApi
 * @extends {BaseAPI}
 */
export class TopApi extends BaseAPI {
  /**
   * Get list of top book registers of the month
   * @summary Get list of top books
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TopApi
   */
  public bookRegisterTopGet(options?: AxiosRequestConfig) {
    return TopApiFp(this.configuration)
      .bookRegisterTopGet(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get list of user
     * @summary Get list of user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get user by id
     * @summary Get user by id
     * @param {number} id User id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersIdGet', 'id', id);
      const localVarPath = `/users/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update user by id
     * @summary Update user by id
     * @param {number} id User id
     * @param {UsersIdGetRequest} usersIdGetRequest Update user by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersIdPatch: async (
      id: number,
      usersIdGetRequest: UsersIdGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersIdPatch', 'id', id);
      // verify required parameter 'usersIdGetRequest' is not null or undefined
      assertParamExists('usersIdPatch', 'usersIdGetRequest', usersIdGetRequest);
      const localVarPath = `/users/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(usersIdGetRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get a list of users who have a role to manage the library in the office.
     * @summary Get librarian contacts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersLibrarianContactsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/users/librarian-contacts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get logged user
     * @summary Get user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersMeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/users/me`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration);
  return {
    /**
     * Get list of user
     * @summary Get list of user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get user by id
     * @summary Get user by id
     * @param {number} id User id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersIdGet(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersIdGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersIdGet(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Update user by id
     * @summary Update user by id
     * @param {number} id User id
     * @param {UsersIdGetRequest} usersIdGetRequest Update user by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersIdPatch(
      id: number,
      usersIdGetRequest: UsersIdGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersIdGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersIdPatch(id, usersIdGetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get a list of users who have a role to manage the library in the office.
     * @summary Get librarian contacts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersLibrarianContactsGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersLibrarianContactsGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersLibrarianContactsGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get logged user
     * @summary Get user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersMeGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersIdGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersMeGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = UserApiFp(configuration);
  return {
    /**
     * Get list of user
     * @summary Get list of user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersGet(options?: AxiosRequestConfig): AxiosPromise<UsersGet200Response> {
      return localVarFp.usersGet(options).then((request) => request(axios, basePath));
    },
    /**
     * Get user by id
     * @summary Get user by id
     * @param {UserApiUsersIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersIdGet(
      requestParameters: UserApiUsersIdGetRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<UsersIdGet200Response> {
      return localVarFp.usersIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
    },
    /**
     * Update user by id
     * @summary Update user by id
     * @param {UserApiUsersIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersIdPatch(
      requestParameters: UserApiUsersIdPatchRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<UsersIdGet200Response> {
      return localVarFp
        .usersIdPatch(requestParameters.id, requestParameters.usersIdGetRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get a list of users who have a role to manage the library in the office.
     * @summary Get librarian contacts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersLibrarianContactsGet(options?: AxiosRequestConfig): AxiosPromise<UsersLibrarianContactsGet200Response> {
      return localVarFp.usersLibrarianContactsGet(options).then((request) => request(axios, basePath));
    },
    /**
     * Get logged user
     * @summary Get user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersMeGet(options?: AxiosRequestConfig): AxiosPromise<UsersIdGet200Response> {
      return localVarFp.usersMeGet(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for usersIdGet operation in UserApi.
 * @export
 * @interface UserApiUsersIdGetRequest
 */
export interface UserApiUsersIdGetRequest {
  /**
   * User id
   * @type {number}
   * @memberof UserApiUsersIdGet
   */
  readonly id: number;
}

/**
 * Request parameters for usersIdPatch operation in UserApi.
 * @export
 * @interface UserApiUsersIdPatchRequest
 */
export interface UserApiUsersIdPatchRequest {
  /**
   * User id
   * @type {number}
   * @memberof UserApiUsersIdPatch
   */
  readonly id: number;

  /**
   * Update user by id
   * @type {UsersIdGetRequest}
   * @memberof UserApiUsersIdPatch
   */
  readonly usersIdGetRequest: UsersIdGetRequest;
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
  /**
   * Get list of user
   * @summary Get list of user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public usersGet(options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .usersGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get user by id
   * @summary Get user by id
   * @param {UserApiUsersIdGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public usersIdGet(requestParameters: UserApiUsersIdGetRequest, options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .usersIdGet(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update user by id
   * @summary Update user by id
   * @param {UserApiUsersIdPatchRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public usersIdPatch(requestParameters: UserApiUsersIdPatchRequest, options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .usersIdPatch(requestParameters.id, requestParameters.usersIdGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get a list of users who have a role to manage the library in the office.
   * @summary Get librarian contacts
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public usersLibrarianContactsGet(options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .usersLibrarianContactsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get logged user
   * @summary Get user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public usersMeGet(options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .usersMeGet(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * WishlistApi - axios parameter creator
 * @export
 */
export const WishlistApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Delete a multiple book edition ids to wishlist
     * @summary Delete a multiple book edition ids to wishlist
     * @param {Array<number>} bookEditionIds Book editions ids as array
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wishlistsDeleteMultipleDelete: async (
      bookEditionIds: Array<number>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bookEditionIds' is not null or undefined
      assertParamExists('wishlistsDeleteMultipleDelete', 'bookEditionIds', bookEditionIds);
      const localVarPath = `/wishlists/delete_multiple`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (bookEditionIds) {
        localVarQueryParameter['book_edition_ids[]'] = bookEditionIds;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get list of wishlist
     * @summary Get wishlists
     * @param {WishlistsGetUserIdParameter} [userId] dropdown name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wishlistsGet: async (
      userId?: WishlistsGetUserIdParameter,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/wishlists`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (userId !== undefined) {
        localVarQueryParameter['user_id'] = userId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete wishlist by id
     * @summary Delete wishlist by id
     * @param {number} id Wishlist id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wishlistsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('wishlistsIdDelete', 'id', id);
      const localVarPath = `/wishlists/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a multiple book edition ids to wishlist
     * @summary Create a multiple book edition ids to wishlist
     * @param {WishlistsMultiplePostRequest} wishlistsMultiplePostRequest Create a multiple book edition ids to wishlist
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wishlistsMultiplePost: async (
      wishlistsMultiplePostRequest: WishlistsMultiplePostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'wishlistsMultiplePostRequest' is not null or undefined
      assertParamExists('wishlistsMultiplePost', 'wishlistsMultiplePostRequest', wishlistsMultiplePostRequest);
      const localVarPath = `/wishlists_multiple`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        wishlistsMultiplePostRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a new wishlist
     * @summary Create a new wishlist
     * @param {WishlistsGetRequest} wishlistsGetRequest Create a new wishlist
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wishlistsPost: async (
      wishlistsGetRequest: WishlistsGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'wishlistsGetRequest' is not null or undefined
      assertParamExists('wishlistsPost', 'wishlistsGetRequest', wishlistsGetRequest);
      const localVarPath = `/wishlists`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(wishlistsGetRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * WishlistApi - functional programming interface
 * @export
 */
export const WishlistApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WishlistApiAxiosParamCreator(configuration);
  return {
    /**
     * Delete a multiple book edition ids to wishlist
     * @summary Delete a multiple book edition ids to wishlist
     * @param {Array<number>} bookEditionIds Book editions ids as array
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async wishlistsDeleteMultipleDelete(
      bookEditionIds: Array<number>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.wishlistsDeleteMultipleDelete(bookEditionIds, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get list of wishlist
     * @summary Get wishlists
     * @param {WishlistsGetUserIdParameter} [userId] dropdown name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async wishlistsGet(
      userId?: WishlistsGetUserIdParameter,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WishlistsGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.wishlistsGet(userId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Delete wishlist by id
     * @summary Delete wishlist by id
     * @param {number} id Wishlist id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async wishlistsIdDelete(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.wishlistsIdDelete(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Create a multiple book edition ids to wishlist
     * @summary Create a multiple book edition ids to wishlist
     * @param {WishlistsMultiplePostRequest} wishlistsMultiplePostRequest Create a multiple book edition ids to wishlist
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async wishlistsMultiplePost(
      wishlistsMultiplePostRequest: WishlistsMultiplePostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WishlistsGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.wishlistsMultiplePost(
        wishlistsMultiplePostRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Create a new wishlist
     * @summary Create a new wishlist
     * @param {WishlistsGetRequest} wishlistsGetRequest Create a new wishlist
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async wishlistsPost(
      wishlistsGetRequest: WishlistsGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WishlistsGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.wishlistsPost(wishlistsGetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * WishlistApi - factory interface
 * @export
 */
export const WishlistApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = WishlistApiFp(configuration);
  return {
    /**
     * Delete a multiple book edition ids to wishlist
     * @summary Delete a multiple book edition ids to wishlist
     * @param {WishlistApiWishlistsDeleteMultipleDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wishlistsDeleteMultipleDelete(
      requestParameters: WishlistApiWishlistsDeleteMultipleDeleteRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .wishlistsDeleteMultipleDelete(requestParameters.bookEditionIds, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get list of wishlist
     * @summary Get wishlists
     * @param {WishlistApiWishlistsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wishlistsGet(
      requestParameters: WishlistApiWishlistsGetRequest = {},
      options?: AxiosRequestConfig,
    ): AxiosPromise<WishlistsGet200Response> {
      return localVarFp.wishlistsGet(requestParameters.userId, options).then((request) => request(axios, basePath));
    },
    /**
     * Delete wishlist by id
     * @summary Delete wishlist by id
     * @param {WishlistApiWishlistsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wishlistsIdDelete(
      requestParameters: WishlistApiWishlistsIdDeleteRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp.wishlistsIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
    },
    /**
     * Create a multiple book edition ids to wishlist
     * @summary Create a multiple book edition ids to wishlist
     * @param {WishlistApiWishlistsMultiplePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wishlistsMultiplePost(
      requestParameters: WishlistApiWishlistsMultiplePostRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<WishlistsGet200Response> {
      return localVarFp
        .wishlistsMultiplePost(requestParameters.wishlistsMultiplePostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create a new wishlist
     * @summary Create a new wishlist
     * @param {WishlistApiWishlistsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wishlistsPost(
      requestParameters: WishlistApiWishlistsPostRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<WishlistsGet201Response> {
      return localVarFp
        .wishlistsPost(requestParameters.wishlistsGetRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for wishlistsDeleteMultipleDelete operation in WishlistApi.
 * @export
 * @interface WishlistApiWishlistsDeleteMultipleDeleteRequest
 */
export interface WishlistApiWishlistsDeleteMultipleDeleteRequest {
  /**
   * Book editions ids as array
   * @type {Array<number>}
   * @memberof WishlistApiWishlistsDeleteMultipleDelete
   */
  readonly bookEditionIds: Array<number>;
}

/**
 * Request parameters for wishlistsGet operation in WishlistApi.
 * @export
 * @interface WishlistApiWishlistsGetRequest
 */
export interface WishlistApiWishlistsGetRequest {
  /**
   * dropdown name
   * @type {WishlistsGetUserIdParameter}
   * @memberof WishlistApiWishlistsGet
   */
  readonly userId?: WishlistsGetUserIdParameter;
}

/**
 * Request parameters for wishlistsIdDelete operation in WishlistApi.
 * @export
 * @interface WishlistApiWishlistsIdDeleteRequest
 */
export interface WishlistApiWishlistsIdDeleteRequest {
  /**
   * Wishlist id
   * @type {number}
   * @memberof WishlistApiWishlistsIdDelete
   */
  readonly id: number;
}

/**
 * Request parameters for wishlistsMultiplePost operation in WishlistApi.
 * @export
 * @interface WishlistApiWishlistsMultiplePostRequest
 */
export interface WishlistApiWishlistsMultiplePostRequest {
  /**
   * Create a multiple book edition ids to wishlist
   * @type {WishlistsMultiplePostRequest}
   * @memberof WishlistApiWishlistsMultiplePost
   */
  readonly wishlistsMultiplePostRequest: WishlistsMultiplePostRequest;
}

/**
 * Request parameters for wishlistsPost operation in WishlistApi.
 * @export
 * @interface WishlistApiWishlistsPostRequest
 */
export interface WishlistApiWishlistsPostRequest {
  /**
   * Create a new wishlist
   * @type {WishlistsGetRequest}
   * @memberof WishlistApiWishlistsPost
   */
  readonly wishlistsGetRequest: WishlistsGetRequest;
}

/**
 * WishlistApi - object-oriented interface
 * @export
 * @class WishlistApi
 * @extends {BaseAPI}
 */
export class WishlistApi extends BaseAPI {
  /**
   * Delete a multiple book edition ids to wishlist
   * @summary Delete a multiple book edition ids to wishlist
   * @param {WishlistApiWishlistsDeleteMultipleDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WishlistApi
   */
  public wishlistsDeleteMultipleDelete(
    requestParameters: WishlistApiWishlistsDeleteMultipleDeleteRequest,
    options?: AxiosRequestConfig,
  ) {
    return WishlistApiFp(this.configuration)
      .wishlistsDeleteMultipleDelete(requestParameters.bookEditionIds, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get list of wishlist
   * @summary Get wishlists
   * @param {WishlistApiWishlistsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WishlistApi
   */
  public wishlistsGet(requestParameters: WishlistApiWishlistsGetRequest = {}, options?: AxiosRequestConfig) {
    return WishlistApiFp(this.configuration)
      .wishlistsGet(requestParameters.userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete wishlist by id
   * @summary Delete wishlist by id
   * @param {WishlistApiWishlistsIdDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WishlistApi
   */
  public wishlistsIdDelete(requestParameters: WishlistApiWishlistsIdDeleteRequest, options?: AxiosRequestConfig) {
    return WishlistApiFp(this.configuration)
      .wishlistsIdDelete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a multiple book edition ids to wishlist
   * @summary Create a multiple book edition ids to wishlist
   * @param {WishlistApiWishlistsMultiplePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WishlistApi
   */
  public wishlistsMultiplePost(
    requestParameters: WishlistApiWishlistsMultiplePostRequest,
    options?: AxiosRequestConfig,
  ) {
    return WishlistApiFp(this.configuration)
      .wishlistsMultiplePost(requestParameters.wishlistsMultiplePostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a new wishlist
   * @summary Create a new wishlist
   * @param {WishlistApiWishlistsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WishlistApi
   */
  public wishlistsPost(requestParameters: WishlistApiWishlistsPostRequest, options?: AxiosRequestConfig) {
    return WishlistApiFp(this.configuration)
      .wishlistsPost(requestParameters.wishlistsGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
