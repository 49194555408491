import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Pagination,
  Select,
  Skeleton,
  TextField,
  Tooltip,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { AxiosResponse } from 'axios';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import CurrentUserContext from '../../../contexts/CurrentUserContext';
import { api } from '../../../services/api';
import { BooksFiltersGet200Response, BooksGet200Response, KindlesGet200Response } from '../../../services/api/openapi';
import BookCard from '../../BorrowedBooks/BookCard';
import { CardType } from '../../BorrowedBooks/types';
import { ITagDropdown } from '../Create/types';
import {
  accordionMainBoxSx,
  accordionTextFieldSx,
  bookTitleSx,
  bottomBarSx,
  filterListSx,
  filterOptionTextSx,
  itemSx,
  noBooksBoxSx,
  paginationSx,
} from './styles';

const bookTypes: [string, string][] = [
  ['Paper', 'paper'],
  ['Kindle', 'kindle'],
  ['E-book', 'e-book'],
];

const bookLangs = {
  en: 'English',
  ro: 'Romanian',
  ru: 'Russian',
  bg: 'Bulgarian',
};

const perPageOptions: number[] = [5, 10, 15, 20, 25];

const Books: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const getPageFromQuery = () => Number.parseInt(searchParams.get('page') ?? '1');

  const [booksPerPage, setBooksPerPage] = useState<number>(5);
  const [authorSearch, setAuthorSearch] = useState<string>('');
  const [tagsSearch, setTagsSearch] = useState<string>('');
  const [categorySearch, setCategorySearch] = useState<string>('');

  const [currentPage, setCurrentPage] = useState<number>(getPageFromQuery());
  const { currentUser } = useContext(CurrentUserContext);
  const [typeFilter, setTypeFilter] = useState<[string, string] | null>(null);
  const [authorFilter, setAuthorFilter] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [categoryFilter, setCategoryFilter] = useState<{
    id: number;
    description: string;
  } | null>(null);
  const [tagsFilter, setTagsFilter] = useState<ITagDropdown[]>([]);
  const [languageFilter, setLanguageFilter] = useState<string | null>(null);

  const { data: kindlesData } = useQuery({
    queryKey: 'kindles',
    queryFn: () => api.kindle.kindlesGet(),
    select: useCallback(
      (data: AxiosResponse<KindlesGet200Response, any>) => {
        return data.data.data.filter((k) => k.office?.id === currentUser?.office?.id);
      },
      [currentUser],
    ),
  });

  const { data: bookFilters, isLoading: isLoadingFilters } = useQuery({
    queryKey: ['filters'],
    queryFn: () => api.book.booksFiltersGet(),
    select: useCallback((data: AxiosResponse<BooksFiltersGet200Response, any>) => {
      return data.data.data;
    }, []),
  });

  useEffect(() => {
    const typeString = searchParams.get('type');
    const authorId = Number.parseInt(searchParams.get('author') ?? '');
    const categoryId = Number.parseInt(searchParams.get('category') ?? '');
    const tagsString = searchParams.get('tag_ids');
    const langString = searchParams.get('lang');

    const type = bookTypes.find((t) => t[1] === typeString);
    setTypeFilter(type ?? null);

    const author = bookFilters?.authors.find((a) => a.id === authorId);
    setAuthorFilter(author ?? null);

    const category = bookFilters?.categories.find((c) => c.id === categoryId);
    setCategoryFilter(category ?? null);

    const tagStrings = tagsString?.split(',');

    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/naming-convention
    const tag_ids = tagStrings?.map((t) => bookFilters?.tags.find((tt) => t === tt.id.toString())!);
    if (tag_ids) setTagsFilter(tag_ids.filter((t) => !!t));

    const lang = bookFilters?.book_langs.find((l) => l.lang === langString);
    setLanguageFilter(lang?.lang ?? null);
  }, [bookFilters]);

  const goToFirstPage = () => setCurrentPage(1);

  const handleTypeChange = (type: typeof typeFilter) => {
    setTypeFilter(type);
    setSearchParams((prev) => {
      if (type) searchParams.set('type', type[1]);
      else searchParams.delete('type');
      return prev;
    });
    goToFirstPage();
  };

  const handleAuthorChange = (author: typeof authorFilter) => {
    setAuthorFilter(author);
    setSearchParams((prev) => {
      if (author) searchParams.set('author', author.id.toString());
      else searchParams.delete('author');
      return prev;
    });
    goToFirstPage();
  };

  const handleCategoryChange = (category: typeof categoryFilter) => {
    setCategoryFilter(category);
    setSearchParams((prev) => {
      if (category) searchParams.set('category', category.id.toString());
      else searchParams.delete('category');
      return prev;
    });
    goToFirstPage();
  };

  const handleTagsChange = (tag_ids: typeof tagsFilter) => {
    setTagsFilter(tag_ids);
    setSearchParams((prev) => {
      if (tag_ids && tag_ids.length > 0) searchParams.set('tag_ids', tag_ids.map((t) => t.id).join(','));
      else searchParams.delete('tag_ids');
      return prev;
    });
    goToFirstPage();
  };

  const handleLangChange = (lang: typeof languageFilter) => {
    setLanguageFilter(lang);
    setSearchParams((prev) => {
      if (lang) searchParams.set('lang', lang);
      else searchParams.delete('lang');
      return prev;
    });
    goToFirstPage();
  };

  useEffect(() => {
    setSearchParams((prev) => {
      searchParams.set('page', currentPage.toString());
      return prev;
    });
  }, [currentPage]);

  const { data: booksData, isLoading: isLoadingBooks } = useQuery({
    queryKey: [
      'paginatedBooks',
      typeFilter,
      authorFilter,
      categoryFilter,
      tagsFilter,
      languageFilter,
      currentPage,
      currentUser?.office.id,
      booksPerPage,
    ],
    queryFn: () => {
      const filters = {
        type: typeFilter ? typeFilter[1] : undefined,
        author_name: authorFilter?.name,
        category_id: categoryFilter?.id,
        tag_ids: tagsFilter.map((t) => t.id),
        lang: languageFilter,
      };

      return api.book.booksGet({
        params: {
          office_id: currentUser?.office.id,
          active: true,
          page: currentPage,
          per_page: booksPerPage,
          recently_added: 'desc',
          ...filters,
        },
      });
    },
    select: useCallback((data: AxiosResponse<BooksGet200Response>) => {
      return {
        data: data.data.data,
        links: data.data.links,
        meta: data.data.meta,
      };
    }, []),
    enabled: !!currentUser?.office?.id,
  });

  const handlePageChange = (event: any, value: number) => {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Box>
      <Grid container columns={{ xs: 12, md: 16 }} spacing={1}>
        <Grid item xs={3} md={4}>
          <Box sx={accordionMainBoxSx}>
            <Accordion defaultExpanded={true} sx={itemSx}>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panelTypes-content" id="panelTypes-header">
                <Box sx={bookTitleSx}>Type</Box>
              </AccordionSummary>
              <AccordionDetails>
                <FormControl fullWidth>
                  {bookTypes.map((type) => {
                    return (
                      <FormControlLabel
                        key={type[1]}
                        control={
                          <Checkbox
                            checked={!!(typeFilter && type[1] === typeFilter[1])}
                            onChange={(event, checked) => {
                              if (checked) handleTypeChange(type);
                              else handleTypeChange(null);
                            }}
                          />
                        }
                        label={<Box sx={filterOptionTextSx}>{type[0]}</Box>}
                      />
                    );
                  })}
                </FormControl>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded={true} sx={itemSx}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panelAuthors-content"
                id="panelAuthors-header"
              >
                <Box sx={bookTitleSx}>Author</Box>
              </AccordionSummary>
              <AccordionDetails>
                {isLoadingFilters ? (
                  <Box>Loading...</Box>
                ) : (
                  <FormControl fullWidth>
                    <TextField
                      placeholder="Search"
                      variant="outlined"
                      fullWidth
                      value={authorSearch}
                      onChange={(event) => setAuthorSearch(event.target.value)}
                      sx={accordionTextFieldSx}
                      spellCheck
                    />
                    <List sx={filterListSx}>
                      {bookFilters &&
                        bookFilters.authors
                          .filter((a) => a.name.toLowerCase().includes(authorSearch.trim().toLowerCase()))
                          .map((author) => {
                            return (
                              <ListItem disablePadding key={author.id}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={!!(authorFilter && author.id === authorFilter.id)}
                                      onChange={(event, checked) => {
                                        if (checked) handleAuthorChange(author);
                                        else handleAuthorChange(null);
                                      }}
                                    />
                                  }
                                  label={
                                    <Tooltip title={author.name}>
                                      <Box sx={filterOptionTextSx}>{author.name}</Box>
                                    </Tooltip>
                                  }
                                />
                              </ListItem>
                            );
                          })}
                    </List>
                  </FormControl>
                )}
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded={true} sx={itemSx}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panelCategories-content"
                id="panelCategories-header"
              >
                <Box sx={bookTitleSx}>Category</Box>
              </AccordionSummary>
              <AccordionDetails>
                {isLoadingFilters ? (
                  <Box>Loading...</Box>
                ) : (
                  <FormControl fullWidth>
                    <TextField
                      placeholder="Search"
                      variant="outlined"
                      fullWidth
                      value={categorySearch}
                      onChange={(event) => setCategorySearch(event.target.value)}
                      sx={accordionTextFieldSx}
                      spellCheck
                    />
                    <List sx={filterListSx}>
                      {bookFilters &&
                        bookFilters.categories
                          .filter((c) => c.description.toLowerCase().includes(categorySearch.trim().toLowerCase()))
                          .map((category) => {
                            return (
                              <ListItem disablePadding key={category.id}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={!!(categoryFilter && category.id === categoryFilter.id)}
                                      onChange={(event, checked) => {
                                        if (checked) handleCategoryChange(category);
                                        else handleCategoryChange(null);
                                      }}
                                    />
                                  }
                                  label={
                                    <Tooltip title={category.description}>
                                      <Box sx={filterOptionTextSx}>{category.description}</Box>
                                    </Tooltip>
                                  }
                                />
                              </ListItem>
                            );
                          })}
                    </List>
                  </FormControl>
                )}
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded={true} sx={itemSx}>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panelTags-content" id="panelTags-header">
                <Box sx={bookTitleSx}>Tags</Box>
              </AccordionSummary>
              <AccordionDetails>
                {isLoadingFilters ? (
                  <Box>Loading...</Box>
                ) : (
                  <FormControl fullWidth>
                    <TextField
                      placeholder="Search"
                      variant="outlined"
                      fullWidth
                      value={tagsSearch}
                      onChange={(event) => setTagsSearch(event.target.value)}
                      sx={accordionTextFieldSx}
                      spellCheck
                    />
                    <List sx={filterListSx}>
                      {bookFilters &&
                        bookFilters.tags
                          .filter((t) => t.description.toLowerCase().includes(tagsSearch.trim().toLowerCase()))
                          .map((tag) => {
                            return (
                              <ListItem disablePadding key={tag.id}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={tagsFilter.includes(tag)}
                                      onChange={(event, checked) => {
                                        let newTags = tagsFilter;
                                        if (checked) newTags = [...newTags, tag];
                                        else newTags = newTags.filter((t) => t.id !== tag.id);
                                        handleTagsChange(newTags);
                                      }}
                                    />
                                  }
                                  label={
                                    <Tooltip title={tag.description}>
                                      <Box sx={filterOptionTextSx}>{tag.description}</Box>
                                    </Tooltip>
                                  }
                                />
                              </ListItem>
                            );
                          })}
                    </List>
                  </FormControl>
                )}
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded={true} sx={itemSx}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panelLanguages-content"
                id="panelLanguages-header"
              >
                <Box sx={bookTitleSx}>Language</Box>
              </AccordionSummary>
              <AccordionDetails>
                {isLoadingFilters ? (
                  <Box>Loading...</Box>
                ) : (
                  <FormControl fullWidth>
                    {bookFilters &&
                      bookFilters.book_langs.map((lang) => {
                        return (
                          <FormControlLabel
                            key={lang.lang}
                            control={
                              <Checkbox
                                checked={!!(languageFilter && lang.lang === languageFilter)}
                                onChange={(event, checked) => {
                                  if (checked) handleLangChange(lang.lang);
                                  else handleLangChange(null);
                                }}
                              />
                            }
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            label={<Box sx={filterOptionTextSx}>{bookLangs[lang.lang]}</Box>}
                          />
                        );
                      })}
                  </FormControl>
                )}
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
        <Grid item xs={9} md={12} container columns={{ xs: 12 }}>
          {!isLoadingBooks && booksData ? (
            booksData.data.length === 0 ? (
              <Grid item xs={12} sx={{ width: 1 }}>
                <Box sx={noBooksBoxSx}>
                  <Box sx={{ fontSize: '20px', fontWeight: 500 }}>There are no books in your office!</Box>
                  <Box component="img" src="/NoBooksImg4.png" alt="No books available" style={{ maxWidth: '30%' }} />
                </Box>
              </Grid>
            ) : (
              <Grid item xs={12}>
                {booksData?.data.map((book, index) => (
                  <Box key={book.id} sx={{ mt: index > 0 ? '15px' : 0 }}>
                    <BookCard
                      book={book}
                      cardType={CardType.BOOKITEM}
                      totalKindles={kindlesData?.length}
                      availableKindles={kindlesData?.filter((be) => be.status === 'available').length}
                    />
                  </Box>
                ))}
                <Grid container columns={{ xs: 12 }} sx={bottomBarSx}>
                  <Grid item xs={2} />
                  <Grid item xs={8.5}>
                    <Box sx={paginationSx}>
                      <Pagination
                        color="primary"
                        shape="rounded"
                        count={booksData?.meta.last_page || 1}
                        page={currentPage}
                        onChange={handlePageChange}
                        boundaryCount={1}
                        siblingCount={1}
                        disabled={!booksData?.links.prev && !booksData?.links.next}
                        hidePrevButton={!booksData?.links.prev}
                        hideNextButton={!booksData?.links.next}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={1.5}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="books-per-page-select-label">Per page</InputLabel>
                      <Select
                        labelId="books-per-page-select-label"
                        id="books-per-page-select"
                        value={booksPerPage}
                        label="Per page"
                        onChange={(event) => {
                          handlePageChange(null, 1);
                          setBooksPerPage(event.target.value as number);
                        }}
                      >
                        {perPageOptions.map((itemsCount) => {
                          return (
                            <MenuItem key={itemsCount} value={itemsCount}>
                              {itemsCount}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )
          ) : (
            <Grid item xs={12}>
              {Array.from({ length: 6 }, (_, index) => (
                <Skeleton
                  key={index}
                  variant="rounded"
                  width="100%"
                  height={270}
                  animation="wave"
                  sx={{ mt: index > 0 ? '15px' : 0 }}
                />
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Books;
