import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Grid, Stack, Tooltip, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import * as React from 'react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { libraryTheme } from '../../../LibraryTheme';
import { api } from '../../../services/api';
import { BookRegisterGet200Response, WishlistsGet200Response } from '../../../services/api/openapi';
import BookCover from '../../../shared/BookCover';
import { copyObject } from '../../../utils';
import { filterBookActions, IBookActionDetails, IBookActions, initialBookActions } from '../../Book/Show';
import { wishlistButtonSx } from '../../Book/Show/styles';
import {
  authorsBoxSx,
  authorsListSx,
  authorsSx,
  availableCopiesMainBoxSx,
  availableCopiesTitleSx,
  boldTextSx,
  cardBoxSx,
  categoryChipSx,
  chipsContainerSx,
  daysLeftSx,
  infoBoxSx,
  infoStackSx,
  italicTextSx,
  kindleEditionsContentBoxSx,
  kindleEditionsContentSx,
  numberOfPagesBoxSx,
  paperCopiesContentBoxSx,
  paperCopiesContentSx,
  pendingConfirmationTextSx,
  publishedYearBoxSx,
  returnBeforeDateSx,
  tagChipSx,
  titleSx,
  typeChipSx,
} from '../styles';
import { BookCardProps, CardType, DueDateState } from '../types';

const BookCard: FC<BookCardProps> = ({
  book,
  returnBefore,
  numberOfPages,
  isPendingReturnConfirmation,
  returnBtn,
  handleHeartClick,
  cardType,
  totalKindles,
  availableKindles,
}) => {
  const [bookActions, setBookActions] = useState<IBookActions>(copyObject(initialBookActions));
  const [actionType, setActionType] = useState<IBookActionDetails>(initialBookActions.paper);

  const queryClient = useQueryClient();
  const returnBeforeString = returnBefore?.toLocaleDateString('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  const authorsStrings = book.authors.map((a) => a.name).slice(0, 2);
  const authorsString = Array.isArray(authorsStrings) ? authorsStrings.join(', ') : authorsStrings;

  const paperEditions = book.details.filter((be) => be.type === 'paper');
  const kindleEditions = book.details.filter((be) => be.type === 'kindle');
  const ebookEditions = book.details.filter((be) => be.type === 'e-book');
  if (!numberOfPages)
    numberOfPages = (() => {
      if (paperEditions.length > 0) {
        return paperEditions[paperEditions.length - 1].number_of_pages;
      } else if (kindleEditions.length > 0) {
        return kindleEditions[kindleEditions.length - 1].number_of_pages;
      } else if (ebookEditions.length > 0) {
        return ebookEditions[ebookEditions.length - 1].number_of_pages;
      }
    })();

  const types = book.details.reduce<string[]>((acc, bd) => {
    if (!acc.includes(bd.type)) {
      acc.push(bd.type);
    }
    return acc;
  }, []);
  const tags = book.tags.map((t) => t.description).slice(0, 1);

  const totalPaperCopies = paperEditions.length;
  const availablePaperCopies = paperEditions.filter((be) => be.status === 'available').length;
  const availableKindleEditions = kindleEditions.length;
  const isAvailable = book.details.some((bd) => bd.status === 'available');

  const daysLeft: number = moment(returnBefore).startOf('day').diff(moment().startOf('day'), 'days');
  const daysDue: number = -daysLeft;

  const dueDateState: DueDateState = useMemo(() => {
    if (daysLeft < 0) return DueDateState.PASSED;
    if (daysLeft <= 7) return DueDateState.CLOSE;
    return DueDateState.FAR;
  }, [daysLeft]);

  const dueDateMessage: string = useMemo(() => {
    if (dueDateState === DueDateState.PASSED) return daysDue + (daysDue === 1 ? ' day overdue' : ' days overdue');
    return daysLeft + (daysLeft === 1 ? ' day left' : ' days left');
  }, [daysDue, daysLeft, dueDateState]);

  const dueDateColor: string = useMemo(() => {
    if (dueDateState === DueDateState.PASSED) return libraryTheme.palette.error.main;
    if (dueDateState === DueDateState.CLOSE) return libraryTheme.palette.warning.main;
    return libraryTheme.palette.success.main;
  }, [dueDateState]);

  const { data: booksRegisters } = useQuery({
    queryFn: () => {
      return api.bookRegister.bookRegisterGet();
    },
    queryKey: ['booksRegisters'],
    select: useCallback((data: AxiosResponse<BookRegisterGet200Response, any>) => {
      return data.data.data;
    }, []),
  });

  const { data: wishlist, isLoading: isLoadingWishlist } = useQuery({
    queryFn: () => {
      return api.wishlist.wishlistsGet();
    },
    queryKey: 'wishlist',
    select: useCallback((data: AxiosResponse<WishlistsGet200Response, any>) => {
      return data.data.data;
    }, []),
  });

  const addInWishlistMutation = useMutation(
    (bookEditionIds: number[]) => {
      return api.wishlist.wishlistsMultiplePost(
        {
          wishlistsMultiplePostRequest: {
            book_edition_ids: bookEditionIds,
          },
        },
        { headers: { 'Content-Type': 'multipart/form-data' } },
      );
    },
    {
      onSuccess: () => queryClient.invalidateQueries('wishlist'),
    },
  );

  const removeFromWishlistMutation = useMutation(
    (bookEditionIds: number[]) => {
      return api.wishlist.wishlistsDeleteMultipleDelete({
        bookEditionIds: bookEditionIds,
      });
    },
    {
      onSuccess: () => queryClient.invalidateQueries('wishlist'),
    },
  );

  const toggleWishAction = async (action: IBookActionDetails) => {
    if (action.editions.length) {
      if (!action.wishes.length) {
        await addInWishlistMutation.mutateAsync(action.editions.map((e) => e.id));
      } else {
        await removeFromWishlistMutation.mutateAsync(action.editions.map((e) => e.id));
      }
    }
  };

  useEffect(() => {
    if (book.details && booksRegisters && wishlist) {
      const filteredActions = filterBookActions(book.details, booksRegisters, wishlist);
      setBookActions(filteredActions);

      if (filteredActions.paper.editions.length) {
        setActionType(filteredActions.paper);
      } else if (filteredActions.ebook.editions.length) {
        setActionType(filteredActions.ebook);
      } else if (filteredActions.kindle.editions.length) {
        setActionType(filteredActions.kindle);
      }
    }
  }, [book.details, booksRegisters, wishlist]);

  return (
    <Box sx={{ ...cardBoxSx, display: 'flex', flexDirection: 'row' }}>
      <BookCover
        bookId={book.id}
        title={book.title}
        cover={book.cover}
        isAvailable={isAvailable}
        width="168px"
        height="250px"
      />
      <Grid sx={infoBoxSx} container>
        <Grid item container direction="column">
          <Grid item>
            <Tooltip title={book.title} placement="top-start">
              <Box sx={titleSx}>{book.title}</Box>
            </Tooltip>
          </Grid>
          <Grid item xs>
            <Stack sx={infoStackSx}>
              <Box>
                <Box
                  sx={{
                    ...authorsBoxSx,
                    ...(cardType === CardType.BOOKITEM && {
                      mt: '15px',
                    }),
                  }}
                >
                  <Box component="span" sx={authorsSx}>
                    Authors:
                  </Box>
                  <Tooltip title={authorsString} placement="bottom-start">
                    <Box component="span" sx={authorsListSx}>
                      {authorsString}
                    </Box>
                  </Tooltip>
                </Box>
                <Box
                  sx={{
                    ...publishedYearBoxSx,
                    ...(cardType === CardType.BOOKITEM && {
                      mt: '20px',
                    }),
                  }}
                >
                  <Box sx={boldTextSx}>Published year:</Box>
                  <Box sx={italicTextSx}>{book.published_at}</Box>
                </Box>
                {numberOfPages && (
                  <Box sx={numberOfPagesBoxSx}>
                    <Box sx={boldTextSx}>Number of pages:</Box>
                    <Box sx={italicTextSx} data-testid="numberOfPages">
                      {numberOfPages}
                    </Box>
                  </Box>
                )}
              </Box>
              <Box>
                <Box sx={chipsContainerSx}>
                  <Typography
                    sx={{
                      ...boldTextSx,
                      ...(cardType === CardType.BOOKITEM && {
                        mr: 0,
                      }),
                    }}
                  >
                    Category / Tags:
                  </Typography>
                  <Box sx={categoryChipSx} key={book.category.description}>
                    {book.category.description}
                  </Box>
                  {tags?.map((tag) => (
                    <Box sx={tagChipSx} key={tag}>
                      {tag}
                    </Box>
                  ))}
                </Box>
                <Box sx={chipsContainerSx}>
                  <Box
                    sx={{
                      ...boldTextSx,
                      ...(cardType === CardType.BOOKITEM && {
                        mr: 0,
                      }),
                    }}
                  >
                    Types:
                  </Box>
                  {types?.includes('paper') && <Box sx={typeChipSx}>Paper</Box>}
                  {types?.includes('kindle') && <Box sx={typeChipSx}>Kindle</Box>}
                  {types?.includes('e-book') && <Box sx={typeChipSx}>E-Book</Box>}
                </Box>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      {cardType === CardType.BORROWED ? (
        <Stack sx={{ marginLeft: '20px', marginRight: '10px' }} direction="column" justifyContent="center">
          <Box sx={{ ...pendingConfirmationTextSx, visibility: isPendingReturnConfirmation ? 'initial' : 'hidden' }}>
            Pending return confirmation
          </Box>
          {returnBtn}
          <Box sx={{ ...returnBeforeDateSx, color: dueDateColor }}>Before: {returnBeforeString}</Box>
          <Box sx={daysLeftSx}>{dueDateMessage}</Box>
        </Stack>
      ) : cardType === CardType.BOOKITEM ? (
        <Grid container>
          <Grid item container direction="column" justifyContent="space-between" alignItems="flex-end">
            <Grid item>
              <Box>
                <LoadingButton
                  sx={wishlistButtonSx}
                  color="error"
                  disabled={!actionType.editions.length}
                  loading={addInWishlistMutation.isLoading || isLoadingWishlist || removeFromWishlistMutation.isLoading}
                  loadingPosition="center"
                  onClick={async () => toggleWishAction(actionType)}
                  startIcon={
                    actionType.editions.length && actionType.wishes.length ? (
                      <Tooltip title="Remove from wishlist" arrow>
                        <FavoriteIcon sx={wishlistButtonSx} />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Add to wishlist" arrow>
                        <FavoriteBorderIcon sx={wishlistButtonSx} />
                      </Tooltip>
                    )
                  }
                />
              </Box>
            </Grid>
            <Grid item>
              <Box sx={availableCopiesMainBoxSx}>
                {totalPaperCopies ? (
                  <Box sx={paperCopiesContentBoxSx}>
                    <Typography sx={availableCopiesTitleSx}>Available paper copies:</Typography>
                    <Typography sx={paperCopiesContentSx}>
                      {`${availablePaperCopies} out of ${totalPaperCopies}`}
                    </Typography>
                  </Box>
                ) : (
                  ''
                )}
                {availableKindleEditions ? (
                  <Box sx={kindleEditionsContentBoxSx}>
                    <Typography sx={availableCopiesTitleSx}>Available kindle devices:</Typography>
                    <Typography sx={kindleEditionsContentSx}>{`${availableKindles} out of ${totalKindles}`}</Typography>
                  </Box>
                ) : (
                  ''
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid item container direction="column" justifyContent="space-between" alignItems="flex-end">
            <Grid item>
              <Box data-testid="wishlistBook">
                <Tooltip title="Remove">
                  <IconButton data-testid="wishlistRemoveIcon" aria-label="previous" onClick={handleHeartClick}>
                    <FavoriteIcon color="error" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
export default BookCard;
