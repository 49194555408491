import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Button, CircularProgress, Divider, Grid, IconButton, Pagination, Paper, Skeleton } from '@mui/material';
import { AxiosResponse } from 'axios';
import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import currentUserContext from '../../contexts/CurrentUserContext';
import { api } from '../../services/api';
import {
  BookRegisterGet200Response,
  BookRegisterTopGet200Response,
  BooksGet200Response,
} from '../../services/api/openapi';
import { getProfilePicturesAccessToken, getProfilePictureUrl } from '../../utils';
import BorrowedBooks from './BorrowedBooks';
import IncomingBook from './IncomingBooks/index';
import ReadingNowBook from './ReadingNowBooks';
import { readingNowBooksPaperSx } from './ReadingNowBooks/styles';
import {
  booksSectionTitleSx,
  dashboardCitySx,
  dashboardCoverSx,
  dashboardDateSx,
  dashboardGreetingsSx,
  dividerBoxSx,
  keyboardArrowSignSx,
  keyboardArrowSx,
  loadingSkeletonBoxSx,
  mainContentSx,
  mainCoverOverlaySx,
  myBooksBtnSx,
  paginationBoxSx,
  readingNowTitleSx,
  scrollBoxSx,
  topMostReadBooksPaperSx,
} from './styles/styles';
import SuggestBookForm from './SuggestABook/form';
import TopMostReadBooks from './TopMostReadBooks';

const getCurrentDateLong = () => {
  const date = new Date();
  const dayLong = date.toLocaleDateString('en-US', {
    weekday: 'long',
  });
  const monthLong = date.toLocaleDateString('en-US', {
    month: 'long',
  });
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  return `${dayLong}, ${monthLong} ${day}, ${year}`;
};

const CAROUSEL_BOOKS = 6;

const BOOKS_TO_CHECK_FOR_SUGGESTIONS = 10;
const RELEVANT_SUGGESTIONS = 5;
const RANDOM_SUGGESTIONS = 1;
const BOOKS_PER_PAGE = 5;

const Dashboard: FC = () => {
  const { currentUser } = useContext(currentUserContext);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [carouselPosition, setCarouselPosition] = useState(0);

  const scrollRef = useRef<HTMLDivElement>(null);

  const { data: topBooksMonth, isLoading: isTopBooksLoading } = useQuery({
    queryKey: ['topBooksMonth'],
    queryFn: () =>
      api.bookRegister.bookRegisterTopGet({
        params: {
          office_id: currentUser?.office.id,
        },
      }),
    select: useCallback((data: AxiosResponse<BookRegisterTopGet200Response, any>) => {
      return data.data.data;
    }, []),
    enabled: !!currentUser,
  });

  const { data: bookRegistersData, isLoading: isBookRegistersLoading } = useQuery({
    queryKey: ['recentlyBookRegistersAll'],
    queryFn: () =>
      api.bookRegister.bookRegisterAllGet({
        params: {
          per_page: 10,
          office_id: currentUser?.office.id,
          'sort[return_at]': 'desc',
        },
      }),
    select: useCallback((data: AxiosResponse<BookRegisterGet200Response, any>) => {
      return data.data.data;
    }, []),
    enabled: !!currentUser,
  });

  const totalBooks = bookRegistersData?.slice(0, 25).length;
  const totalPages = totalBooks && Math.ceil(totalBooks / BOOKS_PER_PAGE);

  const { data: recentlyAddedBooks, isLoading: isRecentlyAddedBooksLoading } = useQuery({
    queryKey: ['paginatedBooks', 1, currentUser?.office.id],
    queryFn: () =>
      api.book.booksGet({
        params: {
          office_id: currentUser?.office.id,
          active: true,
          page: 1,
          per_page: 10,
          recently_added: 'desc',
        },
      }),
    select: useCallback((data: AxiosResponse<BooksGet200Response>) => {
      return {
        data: data.data.data,
        links: data.data.links,
        meta: data.data.meta,
      };
    }, []),
    enabled: !!currentUser,
  });

  const { data: userBorrowedBooks, isLoading: isLoadingUserBorrowedBooks } = useQuery({
    queryKey: ['userBorrowedBooks', currentUser?.id],
    queryFn: () =>
      api.bookRegister.bookRegisterGet({
        params: { user_id: currentUser?.id },
      }),
    select: useCallback((data: AxiosResponse<BookRegisterGet200Response>) => {
      return data.data.data;
    }, []),
  });

  const { data: books, isLoading: isBooksLoading } = useQuery({
    queryKey: ['booksForSuggestions'],
    queryFn: () =>
      api.book.booksGet({
        params: {
          office_id: currentUser?.office.id,
          page: 1,
          per_page: BOOKS_TO_CHECK_FOR_SUGGESTIONS,
          recently_added: 'desc',
        },
      }),
    select: useCallback((data: AxiosResponse<BooksGet200Response>) => {
      return data.data.data.filter((b) => b.details.length > 0);
    }, []),
  });

  const suggestedBooks = useMemo(() => {
    const authors = new Set(
      userBorrowedBooks?.reduce((acc: string[], curr) => {
        const authorsNames: string[] = curr.book_edition?.book.authors?.map((a) => a.name) ?? [];
        return acc.concat(authorsNames);
      }, []),
    );

    const tags = new Set(
      userBorrowedBooks?.reduce((acc: number[], curr) => {
        const tagsIds: number[] = curr.book_edition?.book.tags.map((t) => t.id) ?? [];
        return acc.concat(tagsIds);
      }, []),
    );

    const categories = new Set(
      userBorrowedBooks?.reduce((acc: number[], curr) => {
        return acc.concat(curr.book_edition?.book.category.id ?? []);
      }, []),
    );

    const relevantBooks =
      books
        ?.filter((b) => b.details.length > 0)
        ?.filter((b) => {
          if (!userBorrowedBooks) return true;
          return !userBorrowedBooks.some((bb) => bb.book_edition?.book.id === b.id);
        }) // FILTER DOESN'T WORK CORRECTLY BECAUSE NOT ALL BOOK REGISTERS CONTAIN BOOK EDITION
        // WHEN BOOK EDITION IS DELETED, BUT BOOK REGISTER OF THAT EDITION STILL EXISTS
        .map((b) => {
          const authorsPoints: number = b.authors.filter((a) => authors.has(a.name)).length;
          const tagsPoints: number = b.tags.filter((t) => tags.has(t.id)).length;
          const categoryPoints: number = categories.has(b.category.id) ? 1 : 0;
          const bookPoints: number = authorsPoints + tagsPoints + categoryPoints;

          return { ...b, bookPoints };
        }) ?? [];

    relevantBooks.sort(function (a, b) {
      return b.bookPoints - a.bookPoints;
    });

    const randomBooks = relevantBooks.slice(RELEVANT_SUGGESTIONS).sort(() => 0.5 - Math.random());
    const randomSuggestions = randomBooks.slice(0, RANDOM_SUGGESTIONS);

    return relevantBooks.slice(0, RELEVANT_SUGGESTIONS).concat(randomSuggestions);
  }, [userBorrowedBooks, books]);

  const handlePrevClick = () => setCarouselPosition(carouselPosition - 1);

  const handleNextClick = () => setCarouselPosition(carouselPosition + 1);

  const handleChangePage = async (newPage: number) => {
    setCurrentPage(newPage);
    await (() => new Promise((res) => setTimeout(res, 1000)));
    scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const [profilePictures, setProfilePictures] = useState<[string, string][]>([]);

  const getPictureUrl = (email: string | undefined) => {
    if (!email) return undefined;
    const item = profilePictures.find((p) => p[0] === email);
    if (item) return item[1];
    return undefined;
  };

  useEffect(() => {
    getProfilePicturesAccessToken().then((accessToken) => {
      const emails = Array.from(new Set(bookRegistersData?.map((br) => br.user?.email)));
      emails.forEach(async (email) => {
        if (email) {
          const url = await getProfilePictureUrl(email, accessToken);
          if (url) setProfilePictures((prev) => [...prev, [email, url]]);
        }
      });
    });
  }, [bookRegistersData]);

  const noIncomingBooks = !recentlyAddedBooks?.data || recentlyAddedBooks.data.length === 0;
  const noTopBooks = !topBooksMonth || topBooksMonth.length === 0;
  const noSuggestedBooks = !suggestedBooks || suggestedBooks.length === 0;
  const noReadingNowBooks = !bookRegistersData || bookRegistersData.length === 0;

  return (
    <React.Fragment>
      <Grid container sx={dashboardCoverSx}>
        <Box sx={mainCoverOverlaySx}>
          <Grid item container direction="column" justifyContent="center" alignItems="center">
            <Box>
              <Grid item container direction="column" justifyContent="center" alignItems="center">
                <Grid item>
                  <Box sx={dashboardGreetingsSx}>
                    {currentUser ? (
                      `Greetings, ${currentUser?.name.split(' ')[0]}!`
                    ) : (
                      <CircularProgress sx={{ color: '#FFFFFF' }} />
                    )}
                  </Box>
                </Grid>
                <Grid item>
                  <Box sx={dashboardDateSx}>{getCurrentDateLong()}</Box>
                </Grid>
                <Grid item>
                  <Box sx={dashboardCitySx}>{currentUser?.office.city}</Box>
                </Grid>

                <Grid item container justifyContent="center" spacing={0.25}>
                  <Grid item>
                    <Button component={Link} to="/books/borrowed" sx={myBooksBtnSx}>
                      My books
                    </Button>
                  </Grid>
                  <Grid item>
                    <SuggestBookForm />
                  </Grid>
                </Grid>
                <Grid item container direction="row" justifyContent="flex-start" alignItems="flex-start">
                  <Grid item>
                    <BorrowedBooks />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Grid>

      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={mainContentSx}>
          <Grid item>
            <Box sx={booksSectionTitleSx}>Incoming books</Box>
          </Grid>
        </Grid>

        {noIncomingBooks || (recentlyAddedBooks?.data.length === 1 && carouselPosition > 0) ? (
          <Box sx={{ ...booksSectionTitleSx, mb: '25px' }}>There are no incoming books at the moment.</Box>
        ) : (
          <Grid
            wrap="nowrap"
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ ...mainContentSx, mb: '50px' }}
          >
            <Grid container item xs={1} alignItems="end" justifyContent="end" sx={{ minWidth: '45px' }}>
              <Grid item>
                <IconButton disabled={carouselPosition === 0} onClick={handlePrevClick} sx={keyboardArrowSx}>
                  <KeyboardArrowLeft sx={keyboardArrowSignSx} />
                </IconButton>
              </Grid>
            </Grid>

            <Box>
              <Grid
                wrap="nowrap"
                item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                columns={{ xs: 1, sm: 10, md: 12, lg: 10, xl: 12 }}
                sx={{ marginLeft: '-10px' }}
              >
                {
                  /* eslint-disable @typescript-eslint/indent */
                  !isRecentlyAddedBooksLoading && recentlyAddedBooks?.data
                    ? recentlyAddedBooks?.data
                        ?.slice(carouselPosition, carouselPosition + CAROUSEL_BOOKS)
                        .map((book, index) => (
                          <Grid
                            item
                            key={book.id}
                            sx={{
                              display: {
                                xs: index < 1 ? 'initial' : 'none',
                                sm: index < 2 ? 'initial' : 'none',
                                md: index < 3 ? 'initial' : 'none',
                                lg: index < 5 ? 'initial' : 'none',
                                xl: index < 6 ? 'initial' : 'none',
                              },
                            }}
                            xs={1}
                            sm={5}
                            md={4}
                            lg={2}
                            xl={2}
                          >
                            <IncomingBook
                              bookId={book.id}
                              cover={book.cover}
                              title={book.title}
                              author={book.authors
                                .slice(0, 2)
                                .map((a) => a.name)
                                .join(', ')}
                              isAvailable={book.details.some((bd) => bd.status === 'available')}
                            />
                          </Grid>
                        ))
                    : Array.from({ length: CAROUSEL_BOOKS }, (_, index) => (
                        <Box
                          key={index}
                          sx={{
                            ...loadingSkeletonBoxSx,
                            display: {
                              xs: index < 1 ? 'initial' : 'none',
                              sm: index < 2 ? 'initial' : 'none',
                              md: index < 3 ? 'initial' : 'none',
                              lg: index < 5 ? 'initial' : 'none',
                              xl: index < 6 ? 'initial' : 'none',
                            },
                          }}
                        >
                          <Skeleton
                            variant="rounded"
                            width={168}
                            height={250}
                            animation="wave"
                            sx={{ margin: '0 25px' }}
                          />
                        </Box>
                      ))
                }
              </Grid>
            </Box>

            <Grid container item xs={1} alignItems="start" justifyContent="start" sx={{ minWidth: '45px' }}>
              <Grid item>
                <IconButton
                  disabled={carouselPosition === (recentlyAddedBooks?.data?.length ?? 0) - CAROUSEL_BOOKS}
                  onClick={handleNextClick}
                  sx={keyboardArrowSx}
                >
                  <KeyboardArrowRight sx={keyboardArrowSignSx} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Box sx={dividerBoxSx} />

        <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={mainContentSx}>
          <Grid item>
            <Box sx={{ ...booksSectionTitleSx, mb: '25px' }}>Top books of the month</Box>
          </Grid>
        </Grid>

        {noTopBooks ? (
          <Box sx={{ ...booksSectionTitleSx, mb: '25px' }}>There are no top books for this month.</Box>
        ) : (
          <Paper sx={topMostReadBooksPaperSx} elevation={0}>
            <Grid container direction="row" justifyContent="space-around" alignItems="center" sx={mainContentSx}>
              {!isTopBooksLoading && topBooksMonth
                ? /* eslint-disable @typescript-eslint/indent */
                  topBooksMonth.map((bookInfo, index) => (
                    <Grid item key={bookInfo.book.id} xs={12} sm={6} md={4} lg={2}>
                      <TopMostReadBooks index={index} book={bookInfo.book} />
                    </Grid>
                  ))
                : Array.from({ length: CAROUSEL_BOOKS }, (_, index) => (
                    <Box key={index} sx={loadingSkeletonBoxSx}>
                      <Skeleton variant="rounded" width={168} height={250} animation="wave" sx={{ margin: '0 25px' }} />
                    </Box>
                    /* eslint-disable @typescript-eslint/indent */
                  ))}
            </Grid>
          </Paper>
        )}

        <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={mainContentSx}>
          <Grid item>
            <Box sx={booksSectionTitleSx}>
              {currentUser ? (
                `${currentUser?.name.split(' ')[0]}, we have today’s suggestions for you`
              ) : (
                <Skeleton variant="rounded" animation="wave" width={400} height={30} />
              )}
            </Box>
          </Grid>
        </Grid>

        {noSuggestedBooks ? (
          <Box sx={{ ...booksSectionTitleSx, mb: '25px' }}>No books at this moment</Box>
        ) : (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ ...mainContentSx, mb: '50px' }}
          >
            <Grid item />
            <Box sx={{ width: '95%' }}>
              <Grid item container direction="row" justifyContent="center" columns={{ xs: 1, sm: 8, md: 12, lg: 12 }}>
                {!isLoadingUserBorrowedBooks && !isBooksLoading && suggestedBooks ? (
                  /* eslint-disable @typescript-eslint/indent */
                  suggestedBooks.map((book) => (
                    <Grid item key={book.id} xs={1} sm={4} md={4} lg={2}>
                      <IncomingBook
                        bookId={book.id}
                        cover={book.cover}
                        title={book.title}
                        author={book.authors
                          .slice(0, 2)
                          .map((a) => a.name)
                          .join(', ')}
                        isAvailable={book.details.some((bd) => bd.status === 'available')}
                      />
                    </Grid>
                  ))
                ) : (
                  <Box sx={{ padding: '25px 8px', width: '100%' }}>
                    <Skeleton variant="rounded" animation="wave" height={250} />
                  </Box>
                  /* eslint-disable @typescript-eslint/indent */
                )}
              </Grid>
            </Box>
            <Grid item />
          </Grid>
        )}

        <Box sx={dividerBoxSx} />

        <Box sx={{ width: '95%', maxWidth: '1358px' }}>
          <Grid container>
            <Grid item xs={12}>
              <Box visibility="hidden" ref={scrollRef} sx={scrollBoxSx}>
                Scroll box
              </Box>
              <Box sx={readingNowTitleSx}>Reading now</Box>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
              {!isBookRegistersLoading && !noReadingNowBooks ? (
                <Paper sx={readingNowBooksPaperSx} elevation={0}>
                  {bookRegistersData
                    ?.slice((currentPage - 1) * BOOKS_PER_PAGE, currentPage * BOOKS_PER_PAGE)
                    .map((br, index, array) => (
                      <Box key={br.id}>
                        <ReadingNowBook
                          key={br.id}
                          cover={br.book_edition?.book.cover}
                          userPictureUrl={getPictureUrl(br.user?.email)}
                          userName={br.user?.name}
                          title={br.book_edition?.book.title}
                          authors={br.book_edition?.book.authors.map((a) => a.name).join(', ')}
                          return_at={br.return_at}
                          return_confirmed_at={br.return_confirmed_at}
                          bookId={br.book_edition?.book.id}
                          publishedYear={br.book_edition?.book.published_at}
                          numberOfPages={br.book_edition?.number_of_pages}
                          category={br.book_edition?.book.category.description}
                          tag={br.book_edition?.book.tags.map((t) => t.description)[0]}
                          type={br.book_edition?.type}
                          isAvailable={br.book_edition?.book.details.some((bd) => bd.status === 'available')}
                          description={br.book_edition?.book.description}
                          isFirst={index === 0}
                          isLast={index === array.length - 1}
                        />
                        {index < array.length - 1 && <Divider sx={{ background: '#E0E0E0' }} />}
                      </Box>
                    ))}
                </Paper>
              ) : (
                <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ mb: '25px' }}>
                  <Box>There are no books on your reading list.</Box>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} sx={paginationBoxSx}>
              <Pagination
                count={totalPages}
                page={currentPage}
                shape="rounded"
                onChange={(event, page) => handleChangePage(page)}
                color="primary"
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </React.Fragment>
  );
};

export default Dashboard;
