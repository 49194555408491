import { Box, Button, LinearProgress } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { AxiosResponse } from 'axios';
import { addDays, format } from 'date-fns';
import React, { FC, useCallback, useContext, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import KindleImage from '../../../assets/images/KindleImage.jpg';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import feedbackContext from '../../../contexts/FeedbackContext';
import { ConfirmBorrowDialog } from '../../../dialogs/confirmationDialog';
import { api } from '../../../services/api';
import { KindlesGet200Response } from '../../../services/api/openapi';
import { selectFromQuery } from '../../../utils';
import { buttonSx, dataGridBoxSx, imageSx, loadingBoxSx } from '../Index/styles';
import { noItemsBoxSx } from './styles';

const BorrowKindle: FC = () => {
  const { setFeedback } = useContext(feedbackContext);
  const userContext = useContext(CurrentUserContext);
  const currentUser = userContext.currentUser;
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: kindlesData, isLoading: isKindlesLoading } = useQuery({
    queryKey: 'kindles',
    queryFn: () => api.kindle.kindlesGet(),
    select: useCallback(
      (data: AxiosResponse<KindlesGet200Response, any>) => {
        return data.data.data.filter((k) => k.office?.id === currentUser?.office?.id);
      },
      [currentUser],
    ),
  });

  const borrowKindleMutation = useMutation(
    (variables: { kindleId: number }) =>
      api.kindleRegister.kindleRegisterPost({
        kindleRegisterGetRequest: { kindle_id: variables.kindleId },
      }),
    {
      onSuccess: async () => {
        navigate('/books/borrowed');
        await Promise.all([
          queryClient.invalidateQueries(['kindleRegister']),
          queryClient.refetchQueries('kindles'),
          queryClient.refetchQueries('kindleRegisterAll'),
        ]);
      },
      onError: (e) => {
        setFeedback({
          status: 'error',
          message: (e as any).response.data.message,
        });
      },
    },
  );

  const { data: settings } = useQuery({
    queryFn: () => api.setting.settingsGet(),
    queryKey: ['settings'],
    select: useCallback(selectFromQuery, []),
  });

  const handleBorrowKindleClick = (kindleId: number, kindleInventoryNum: string) => {
    const maxTermKindles = settings?.filter((s) => s.office_id.id == currentUser?.office.id)[0].max_term_kindles;
    const returnDate = maxTermKindles ? format(addDays(new Date(), maxTermKindles), 'MMMM d, yyyy') : '';
    ConfirmBorrowDialog('kindle', kindleInventoryNum, () => borrowKindleMutation.mutate({ kindleId: kindleId }), [
      'Please keep in mind the following rules:',
      '1. Please take care of the borrowed kindle and keep it safe.',
      `2. Please return the kindle by ${returnDate}.`,
      "3. Please do not install any additional books or applications on the kindle without the Librarian's permission. Do not switch the account or add a new account on this kindle.",
    ]);
  };

  const columns: GridColDef[] = [
    {
      field: 'image',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      width: 70,
      hideable: false,
      renderCell: () => <Box component="img" src={KindleImage} sx={imageSx} />,
    },
    {
      field: 'inventory_num',
      headerName: 'Inventory number',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      minWidth: 130,
      flex: 0.5,
    },
    {
      field: 'actions',
      headerName: 'Borrow',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      minWidth: 150,
      flex: 0.5,
      renderCell: (row) => (
        <Button sx={buttonSx} onClick={() => handleBorrowKindleClick(row.row.id, row.row.inventory_num)} size="small">
          Borrow
        </Button>
      ),
    },
  ];

  const availableKindles = useMemo(() => {
    if (!kindlesData) return [];
    const avKindles = kindlesData.filter((k) => k.status === 'available');
    if (!avKindles) return [];
    return avKindles?.map((k) => {
      return {
        id: k.id,
        inventory_num: k.inventory_num,
      };
    });
  }, [kindlesData]);

  return (
    <Box data-testid="available_kindles_page">
      {isKindlesLoading ? (
        <Box sx={loadingBoxSx}>
          <LinearProgress />
        </Box>
      ) : (
        <>
          {availableKindles.length === 0 ? (
            <Box sx={noItemsBoxSx}>There are no available Kindles in {currentUser?.office.city} office.</Box>
          ) : (
            <Box sx={dataGridBoxSx}>
              <DataGrid
                rows={availableKindles}
                columns={columns}
                loading={isKindlesLoading}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableColumnSelector
                headerHeight={70}
                rowHeight={70}
                autoHeight
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default BorrowKindle;
