import { AxiosRequestConfig } from 'axios';

import { api, EBookLang, EBookType, IBookEdition } from '../../../services/api';
import { BookApiBooksPostRequest, BookEditionApiBookEditionsPostRequest } from '../../../services/api/openapi';
import { IFormValues } from './types';

export const bookPostFactory = (values: IFormValues): BookApiBooksPostRequest => {
  return {
    booksGetRequest: {
      author_id: values.author_ids,
      category_id: Number(values.category_id),
      cover: values.cover,
      description: values.description,
      lang: values.lang as EBookLang,
      published_at: values.published_at,
      tag_id: values.tag_ids,
      title: values.title,
      type: values.type as EBookType,
      isbn: [values.isbn, values.isbn_2].find((i) => i.length === 10)!,
      isbn_13: [values.isbn, values.isbn_2].find((i) => i.length === 13)!,
      inventory_num: values.inventory_num as string | undefined,
      file_url: values.file_url as string | undefined,
      number_of_pages: values.number_of_pages === 0 ? undefined : values.number_of_pages,
    },
  };
};

export const bookEditionPostFactory = (values: IFormValues): BookEditionApiBookEditionsPostRequest => {
  return {
    bookEditionsGetRequest: {
      book_id: 0, // Only for initializing object
      file_url: values.file_url as string | undefined,
      inventory_num: values.inventory_num as string | undefined,
      isbn: [values.isbn, values.isbn_2].find((i) => i?.length === 10),
      isbn_13: [values.isbn, values.isbn_2].find((i) => i?.length === 13),
      type: values.type,
      number_of_pages: values.number_of_pages === 0 ? undefined : values.number_of_pages,
    },
  };
};

export const postNewCategory = async (value: string) => {
  return api.category.categoriesPost({
    categoriesGetRequest: { description: value },
  });
};

export const postNewTag = async (value: string) => {
  return api.tag.tagsPost({ tagsGetRequest: { description: value } });
};

export const verifyBookInDb = async (isbn: string): Promise<IBookEdition | null> => {
  const config: AxiosRequestConfig = { params: { isbn } };

  if (isbn.length === 13) {
    config.params.isbn = undefined;
    config.params.isbn_13 = isbn;
  }

  try {
    const res = await api.bookEdition.bookEditionsGet(config);
    const bookEditions = res.data.data;

    if (bookEditions.length) return bookEditions[0];
  } catch (e) {
    return null;
  }

  return null;
};
