import { Box, CircularProgress, Grid } from '@mui/material';
import { FC, useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { IUserData } from '../../services/api';

interface Props {
  user: IUserData | null;
  authRoles: number[];
}

const PrivateRoute: FC<Props> = ({ user, authRoles }) => {
  const isAuthorized = useMemo(() => user && authRoles.includes(user.role.id), [user, authRoles]);

  return user !== null ? (
    isAuthorized ? (
      <Outlet />
    ) : (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '50vh' }}
      >
        <Grid item xs={3}>
          <Box>You are not authorized to access this page</Box>
        </Grid>
      </Grid>
    )
  ) : (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '50vh' }}
    >
      <Grid item xs={3} data-testid="circular_progress">
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

export default PrivateRoute;
