import { SxProps } from '@mui/material';

export const UserAccountBoxSx: SxProps = {
  display: 'flex',
  alignItems: 'end',
  textAlign: 'center',
};

export const AvatarSx: SxProps = {
  width: 40,
  height: 40,
};
