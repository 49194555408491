import { SxProps } from '@mui/material';

import { libraryTheme } from '../LibraryTheme';

export const dialogTitleSx: SxProps = {
  color: '#000',
  fontSize: '18px',
  fontWeight: 600,
  mt: 1,
};

export const dialogContentRuleSx: SxProps = {
  fontSize: '12px',
  fontStyle: 'italic',
  fontWeight: 400,
};

export const confirmButtonSx: SxProps = {
  height: '40px',
  width: '124px',
  fontSize: '14px',
  fontWeight: 700,
  color: '#FFF',
  borderRadius: '5px',
  background: 'linear-gradient(180deg, #EC147A 0%, #EC147A 100%)',
  transition: 'background 0.3s ease-in-out',
  '&:hover': {
    background: 'linear-gradient(180deg, rgb(215 14 109) 0%, rgb(215 14 109) 100%)',
  },
};

export const cancelButtonSx: SxProps = {
  height: '40px',
  width: '124px',
  fontSize: '14px',
  fontWeight: 700,
  color: '#000',
  borderColor: libraryTheme.palette.neutral.main,
  border: 1,
  borderRadius: '5px',
  mr: '10px',
};
