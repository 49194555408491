import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Grid, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import feedbackContext from '../../../../../contexts/FeedbackContext';
import { api } from '../../../../../services/api';
import { handleErrorMsg } from '../../../../../utils';
import schema from '../../../../Create/Authors/validations';
import { AddFormSubmitBtnSx, AddFormTextFieldSx } from '../../styles';

interface IFormValues {
  name: string;
}

const initialValues: IFormValues = {
  name: '',
};

interface IFormProps {
  handleOpen: () => void;
}

const AddAuthorForm = ({ handleOpen }: IFormProps) => {
  const queryClient = useQueryClient();
  const { setFeedback } = useContext(feedbackContext);

  const postMutation = useMutation(
    (values: IFormValues) => {
      return api.author.authorsPost({
        authorsGetRequest: { name: values.name },
      });
    },
    {
      onSuccess: () => {
        setFeedback({
          status: 'success',
          message: 'The author was successfully created',
        });
        return queryClient.invalidateQueries(['authors']);
      },
      onError: (e) => {
        handleErrorMsg(e, setFeedback);
      },
    },
  );

  const handleSubmitForm = async (values: IFormValues) => {
    await postMutation.mutateAsync(values);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    resetForm();
    handleOpen();
  };

  const { errors, handleBlur, handleChange, handleSubmit, resetForm, isValid, values, touched } = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmitForm,
    validationSchema: schema,
  });

  return (
    <Box component="form" autoComplete="off" onSubmit={handleSubmit}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          {' '}
          <TextField
            sx={AddFormTextFieldSx}
            InputProps={{ sx: { height: '40px' } }}
            size="small"
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
            id="name"
            name="name"
            label="Name"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.name}
            spellCheck
          />
        </Grid>
        <Grid item container direction="column" justifyContent="flex-end" alignItems="flex-end" spacing={2}>
          <Grid item>
            {' '}
            <LoadingButton
              sx={AddFormSubmitBtnSx}
              disabled={!isValid}
              loading={postMutation.isLoading}
              type="submit"
              variant="contained"
            >
              Submit
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddAuthorForm;
