import moment from 'moment';
import * as Yup from 'yup';

export const FILE_SIZE = 19922944;
export const SUPPORTED_FORMATS = [
  'application/pdf',
  'application/epub+zip',
  'application/x-mobipocket-ebook',
  'application/fb2+xml',
];

export const schema = Yup.object({
  title: Yup.string().trim().max(200, 'Title should have a maximum of 200 characters').required('Title is required'),
  author_id: Yup.array().min(1, 'Add at least one author'),
  cover: Yup.string().trim().max(2048, 'Cover should have a maximum of 2048 characters').required('Cover is required'),
  lang: Yup.string().max(2, 'Language should be 2 characters').required('Please select book language'),
  published_at: Yup.number()
    .required('Published year is required')
    .typeError('Published year must have 4 digits')
    .min(1970, 'Published year starts from 1970')
    .max(moment().year(), 'Published year cannot be in the future'),
  description: Yup.string()
    .trim()
    .max(4000, 'Description should have a maximum of 4000 chars')
    .required('Description is required'),
  category_id: Yup.number().required('Select a category for the book'),
  tag_id: Yup.array().min(1, 'Add at least one tag').max(5, 'A maximum of 5 tags can be added'),
});
