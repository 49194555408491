import { Dialog, DialogTitle } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { createRoot, Root } from 'react-dom/client';

import { dialogTitleSx } from './styles';

interface DialogProps {
  id: string;
  title: string;
  Content: React.ComponentType<any>;
}

interface InternalProps {
  dialogProps: DialogProps;
  parent: HTMLDivElement;
  root: Root;
}

// The AppDialog component is the actual dialog.
// It uses the useState hook to keep track of whether it is open or closed.
// When it is closed, it calls the onClose function which sets the open state to false,
// unmounts the component from the DOM, and removes the div element from the document.body.
const AppDialog = ({ dialogProps, parent, root }: InternalProps) => {
  const { id, title, Content } = dialogProps;
  const [open, setOpen] = useState<boolean>(true);

  const onClose = () => {
    setOpen(false);
    root.unmount();
    document.body.removeChild(parent);
  };

  const closeDialogHandler = (e: any) => {
    if (id === e.detail) onClose();
  };

  // The useEffect hook is used to listen for closeDialog events on the document object.
  // When a closeDialog event is received, the closeDialogHandler function is called.
  // If the id of the event matches the id of the current dialog,
  // the onClose function is called to close the dialog.
  useEffect(() => {
    document.addEventListener('closeDialog', closeDialogHandler);
    return () => document.removeEventListener('closeDialog', closeDialogHandler);
  }, []);

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle sx={dialogTitleSx} align="center">
        {title}
      </DialogTitle>
      <Content />
    </Dialog>
  );
};

// The open function is used to open a new dialog. It creates a new div element and attaches it to the document.body.
// It then creates a new ReactRoot and renders the AppDialog component into it.
export const open = (props: DialogProps) => {
  const parent = document.createElement('div');
  document.body.appendChild(parent);
  const root = createRoot(parent);
  root.render(<AppDialog parent={parent} root={root} dialogProps={props} />);
};

// The close function is used to close a dialog.
export const close = (id: string) => {
  const event = new CustomEvent('closeDialog', {
    detail: id,
  });
  document.dispatchEvent(event);
};
