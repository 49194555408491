import { Box, Skeleton, Tab, Tabs, Typography } from '@mui/material';
import React, { FC, ReactNode, SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import CurrentUserContext from '../../../contexts/CurrentUserContext';
import BorrowKindle from '../../Kindle/Borrow';
import { tabsSx, tabTextSx } from '../../Office/styles';
import Books from './index';
import { mainTitleSx } from './styles';

interface ITabPanelProps {
  children?: ReactNode;
  value: number;
  index: number;
}

const TabsCount = 2;

const TabPanel = (props: ITabPanelProps) => {
  const { children, value, index } = props;
  return <div hidden={value !== index}>{value === index && <Box>{children}</Box>}</div>;
};

const BooksAndKindles: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState<number>(0);
  const { currentUser } = useContext(CurrentUserContext);

  useEffect(() => {
    const queryValue = Number.parseInt(searchParams.get('tab') ?? '0');
    if (isNaN(queryValue) || queryValue < 0 || queryValue >= TabsCount) setSearchParams({ tab: '0' });
    else setValue(queryValue);
  }, [searchParams]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setSearchParams({ tab: newValue.toString() });
  };

  return (
    <Box>
      <Box sx={mainTitleSx}>
        <Box display="flex" alignItems="center">
          Books & Kindles at office{' '}
          {currentUser?.office ? (
            <>
              {currentUser?.office.name}, {currentUser?.office.city}
            </>
          ) : (
            <Skeleton variant="rounded" width={100} height={36} animation="wave" sx={{ ml: '4px' }} />
          )}
        </Box>
      </Box>

      <Tabs indicatorColor="secondary" sx={tabsSx} value={value} onChange={handleChange}>
        <Tab label={<Box sx={tabTextSx}>Books</Box>} />
        <Tab label={<Box sx={tabTextSx}>Kindles</Box>} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Books />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BorrowKindle />
      </TabPanel>
    </Box>
  );
};

export default BooksAndKindles;
