import { Box } from '@mui/material';
import { FC } from 'react';

import UserPicture from '../../../../shared/UserPicture';
import { bottomFlagBoxSx, flagBoxSx, readingStatusTypographySx } from './styles';

interface FlagComponentProps {
  userPictureUrl?: string;
  userName?: string;
  status?: string;
  flagBgColor: string;
  flagBoxHeight?: string;
  bottomFlagBoxTop?: string;
  circleBoxBackground?: string;
}

const FlagComponent: FC<FlagComponentProps> = ({
  userPictureUrl,
  userName,
  status,
  flagBgColor,
  flagBoxHeight,
  bottomFlagBoxTop,
  circleBoxBackground,
}) => {
  return (
    <Box
      sx={{
        ...flagBoxSx,
        backgroundColor: flagBgColor,
        height: flagBoxHeight,
      }}
    >
      <UserPicture userPictureUrl={userPictureUrl} userName={userName} circleBoxBackground={circleBoxBackground} />
      <Box component="span" sx={readingStatusTypographySx}>
        {status}
      </Box>
      <Box sx={{ ...bottomFlagBoxSx, top: bottomFlagBoxTop }} />
    </Box>
  );
};

export default FlagComponent;
