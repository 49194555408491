import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Skeleton,
  TextField,
  Tooltip,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { GridCellParams } from '@mui/x-data-grid';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { FeedBackDialogContentSx } from '../../../components/MainLayout/Feedback/styles';
import FeedbackContext from '../../../contexts/FeedbackContext';
import { libraryTheme } from '../../../LibraryTheme';
import { api } from '../../../services/api';
import { handleErrorMsg, selectFromQuery } from '../../../utils';
import { openEditIconSx } from '../../Book/Edit/styles';
import { actionIconSx } from '../../Kindle/Index/styles';
import { statusTextSx } from '../../Office/styles';
import {
  CancelButtonSx,
  DeleteButtonSx,
  EditDialogContentSx,
  EditDialogTextFieldSx,
  EditDialogTitleSx,
  FeedbackGridBoxSx,
  SelectedFeedbackCloseBtnSx,
  SelectedFeedbackContentSx,
  SelectedFeedbackTitleSx,
} from '../styles';

const STATUS = [
  { id: 1, label: 'new' },
  { id: 2, label: 'implemented' },
];

const FeedbackTab: FC = () => {
  const { setFeedback } = useContext(FeedbackContext);
  const queryClient = useQueryClient();
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [feedbackDialog, setFeedbackDialog] = useState<boolean>(false);
  const [selectedFeedback, setSelectedFeedback] = useState<any>(undefined);

  const editStatusMutation = useMutation(
    (feedback: any) =>
      api.feedback.feedbacksIdPatch({
        id: feedback.id,
        feedbacksIdDeleteRequest: {
          status: feedback.status?.label,
        },
      }),
    {
      onSuccess: () => {
        const inv = queryClient.invalidateQueries(['feedback']);
        setEditOpen(false);
        return inv;
      },
      onError: (e) => handleErrorMsg(e, setFeedback),
    },
  );

  const deleteMessageMutation = useMutation(
    (feedback: any) =>
      api.feedback.feedbacksIdDelete({
        id: feedback.id,
      }),
    {
      onSuccess: () => {
        setFeedback({
          status: 'success',
          message: 'Selected feedback message was deleted successfully',
        });
        setDeleteOpen(false);
        return queryClient.invalidateQueries('feedback');
      },
      onError: (error) => {
        handleErrorMsg(error, setFeedback);
      },
    },
  );
  const { data: feedbackData, isLoading: isLoadingFeedback } = useQuery({
    queryKey: ['feedback'],
    queryFn: () => api.feedback.feedbacksGet(),
    select: useCallback(selectFromQuery, []),
  });

  const feedbackRows = useMemo(() => {
    if (!feedbackData) return [];
    return feedbackData?.map((f) => {
      return {
        id: f.id,
        created_at: f.created_at,
        user: f.user?.name,
        office: {
          name: f.user?.office.name,
          city: f.user?.office.city,
        },
        message: f.message,
        status: f.status,
      };
    });
  }, [feedbackData]);

  const handleOpenEdit = (feedback: any) => {
    setEditOpen(true);
    setSelectedFeedback(feedback);
  };

  const handleCloseEdit = () => {
    setEditOpen(false);
    setFeedbackDialog(false);
  };

  const handleOpenDelete = (feedback: any) => {
    setDeleteOpen(true);
    setSelectedFeedback(feedback);
  };

  const handleCloseDelete = () => {
    setDeleteOpen(false);
    setFeedbackDialog(false);
  };

  const handleCellClick = (params: GridCellParams) => {
    const columnField = params.field;
    if (columnField !== 'edit' && columnField !== 'delete') {
      setFeedbackDialog(true);
      setSelectedFeedback(params.row);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'created_at',
      headerName: 'Time',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      width: 130,
      valueFormatter: (params) => new Date(params.value).toLocaleDateString(),
    },
    {
      field: 'user',
      headerName: 'Name',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      hideable: false,
      width: 280,
      renderCell: (row) => row.value,
    },
    {
      field: 'office',
      headerName: 'Office',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      width: 160,
      renderCell: (row) => {
        return (
          <Box>
            {row.value.name}, {row.value.city}
          </Box>
        );
      },
    },
    {
      field: 'message',
      headerName: 'Feedback',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      sortable: false,
      width: 140,
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      hideable: false,
      sortable: false,
      width: 130,
      renderCell: (row) => {
        return (
          <Box
            sx={{
              ...statusTextSx,
              color: row.value === 'new' ? libraryTheme.palette.warning.main : libraryTheme.palette.success.main,
            }}
            key={row.id}
          >
            {row.value}
          </Box>
        );
      },
    },
    {
      field: 'edit',
      headerName: 'Edit Status',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 150,
      renderCell: (row) => (
        <Tooltip title="Edit Status">
          <IconButton aria-label="edit" onClick={() => handleOpenEdit(row.row)}>
            <EditIcon sx={{ ...openEditIconSx, color: '#000' }} />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: 'delete',
      headerName: 'Delete Feedback',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      width: 150,
      renderCell: (row) => {
        return (
          <Tooltip title="Delete">
            <IconButton
              data-testid="delete-feedback-message-btn"
              aria-label="delete"
              color="primary"
              onClick={() => handleOpenDelete(row.row)}
            >
              <DeleteIcon sx={actionIconSx} />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];
  return (
    <>
      <Box sx={FeedbackGridBoxSx}>
        {isLoadingFeedback ? (
          <>
            <Skeleton variant="rounded" width="100%" height={50} animation="wave" sx={{ mt: 1 }} />
            {Array.from({ length: 10 }, (_, index) => (
              <Skeleton key={index} variant="rounded" width="100%" height={40} animation="wave" sx={{ mt: 0.5 }} />
            ))}
          </>
        ) : (
          <DataGrid
            disableVirtualization
            columns={columns}
            rows={feedbackRows}
            loading={isLoadingFeedback}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableColumnSelector
            onCellClick={handleCellClick}
            autoHeight
          />
        )}
      </Box>
      {/* EDIT DIALOG */}
      <Dialog open={editOpen} onClose={handleCloseEdit}>
        <DialogTitle sx={EditDialogTitleSx}>Edit feedback status</DialogTitle>
        <DialogContent>
          <Box sx={EditDialogContentSx}>Edit status for {selectedFeedback?.user}&apos;s feedback</Box>
          <FormControl fullWidth>
            <Autocomplete
              disableClearable
              options={STATUS}
              value={selectedFeedback?.status}
              isOptionEqualToValue={(option, value) => option.name === value?.name}
              onChange={(event, value) => {
                setSelectedFeedback((prev: any) => {
                  return { ...prev!, status: value };
                });
              }}
              renderInput={(params) => <TextField {...params} variant="outlined" sx={EditDialogTextFieldSx} />}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={editStatusMutation.isLoading}
            onClick={() => editStatusMutation.mutate(selectedFeedback)}
            sx={{ ...DeleteButtonSx, mb: 1, mr: 2 }}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* FEEDBACK DIALOG */}
      <Dialog open={feedbackDialog} onClose={handleCloseEdit}>
        <DialogTitle sx={SelectedFeedbackTitleSx}>Submitted Feedback from {selectedFeedback?.user}</DialogTitle>
        <DialogContent sx={SelectedFeedbackContentSx}>{selectedFeedback?.message}</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEdit} sx={SelectedFeedbackCloseBtnSx}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* DELETE DIALOG */}
      <Dialog open={deleteOpen} onClose={handleCloseDelete}>
        <DialogTitle sx={SelectedFeedbackTitleSx}>Delete feedback message</DialogTitle>
        <DialogContent sx={FeedBackDialogContentSx}>
          Are you sure you want to permanently delete current feedback message?
        </DialogContent>
        <DialogActions>
          <Button sx={CancelButtonSx} onClick={handleCloseDelete}>
            Cancel
          </Button>
          <LoadingButton
            loading={deleteMessageMutation.isLoading}
            onClick={() => deleteMessageMutation.mutate(selectedFeedback)}
            sx={{ ...DeleteButtonSx }}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FeedbackTab;
