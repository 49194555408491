import { Box, Skeleton } from '@mui/material';
import Button from '@mui/material/Button';
import { AxiosResponse } from 'axios';
import React, { FC, useCallback, useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import CurrentUserContext from '../../contexts/CurrentUserContext';
import feedbackContext from '../../contexts/FeedbackContext';
import { api } from '../../services/api';
import {
  BookRegisterApiBookRegisterIdPatchRequest,
  BookRegisterGet200Response,
  KindleRegisterApiKindleRegisterIdPatchRequest,
  KindleRegisterGet200Response,
} from '../../services/api/openapi';
import { handleErrorMsg } from '../../utils';
import BookCard from './BookCard';
import BorrowedKindle from './BorrowedKindle';
import {
  borrowedBooksTitleSx,
  borrowedKindlesTitleSx,
  itemSx,
  noBorrowedBoxSx,
  returnButtonDisabledSx,
  returnButtonSx,
  rulesSx,
} from './styles';
import { CardType } from './types';

const BorrowedBooksAndKindles: FC = () => {
  const { currentUser } = useContext(CurrentUserContext);
  const { setFeedback } = useContext(feedbackContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: booksRegister, isFetching: isBookRegisterFetching } = useQuery({
    queryKey: 'booksRegister',
    queryFn: () =>
      api.bookRegister.bookRegisterGet({
        params: { user_id: currentUser?.id, active: true },
      }),
    select: useCallback((data: AxiosResponse<BookRegisterGet200Response>) => {
      return data.data.data;
    }, []),
  });

  const { data: kindleRegister, isFetching: isKindleRegisterFetching } = useQuery({
    queryKey: 'kindleRegister',
    queryFn: () =>
      api.kindleRegister.kindleRegisterGet({
        params: { user_id: currentUser?.id, active: true },
      }),
    select: useCallback((data: AxiosResponse<KindleRegisterGet200Response>) => {
      return data.data.data;
    }, []),
  });

  const returnBookMutation = useMutation(
    (variables: BookRegisterApiBookRegisterIdPatchRequest) => {
      return api.bookRegister.bookRegisterIdPatch(variables);
    },
    {
      onSuccess: () => {
        setFeedback({
          status: 'info',
          message: 'Contact your librarian to return the book',
        });
        return Promise.all([
          queryClient.invalidateQueries(['booksRegister']),
          queryClient.invalidateQueries(['bookEditions']),
          queryClient.invalidateQueries(['booksRegistersOffice']),
          queryClient.invalidateQueries(['recentlyBookRegistersAll']),
        ]);
      },
      onError: (e) => handleErrorMsg(e, setFeedback),
    },
  );

  const returnKindleMutation = useMutation(
    (variables: KindleRegisterApiKindleRegisterIdPatchRequest) => {
      return api.kindleRegister.kindleRegisterIdPatch(variables);
    },
    {
      onSuccess: () => {
        setFeedback({
          status: 'info',
          message: 'Contact your librarian to return the Kindle',
        });
        return Promise.all([
          queryClient.invalidateQueries(['kindleRegisterAll']),
          queryClient.invalidateQueries(['kindles']),
          queryClient.invalidateQueries(['kindleRegister']),
        ]);
      },
      onError: (e) => handleErrorMsg(e, setFeedback),
    },
  );

  const handleBookClick = (bookId: number | undefined) => {
    navigate(`/books/${bookId}`);
  };

  return (
    <Box data-testid="borrowed_books_page">
      <Box>
        <Box sx={borrowedBooksTitleSx}>Borrowed books</Box>

        {isBookRegisterFetching && !booksRegister ? (
          <>
            {Array.from({ length: 3 }, (_, index) => (
              <Skeleton
                data-testid="bookSkeleton"
                key={index}
                variant="rounded"
                width="100%"
                height={30}
                animation="wave"
                sx={{ m: 1 }}
              />
            ))}
          </>
        ) : (
          <>
            {booksRegister?.length ? (
              booksRegister.map(
                (br) =>
                  br.book_edition && (
                    <Box sx={itemSx} key={br.id}>
                      <BookCard
                        book={br.book_edition.book}
                        returnBefore={new Date(br.return_before)}
                        numberOfPages={br.book_edition.number_of_pages}
                        isPendingReturnConfirmation={br.book_edition.status === 'pending return confirmation'}
                        cardType={CardType.BORROWED}
                        /* eslint-disable @typescript-eslint/indent */
                        returnBtn={
                          <Button
                            sx={
                              br.book_edition.status !== 'pending return confirmation'
                                ? returnButtonSx
                                : returnButtonDisabledSx
                            }
                            onClick={() =>
                              returnBookMutation.mutate({
                                id: br.id,
                                bookRegisterGetRequest: {
                                  book_edition_id: br.book_edition!.id,
                                },
                              })
                            }
                            disabled={br.book_edition.status === 'pending return confirmation'}
                            size="small"
                          >
                            Return
                          </Button>
                        }
                        /* eslint-disable @typescript-eslint/indent */
                      />
                    </Box>
                  ),
              )
            ) : (
              <Box sx={noBorrowedBoxSx}>It looks like you don&apos;t have any borrowed books right now.</Box>
            )}
          </>
        )}

        <Box sx={rulesSx}>
          <Box
            sx={{
              marginTop: '25px',
              fontWeight: 600,
            }}
          >
            Please keep in mind the following rules concerning the borrowed books:
          </Box>
          <Box>1. Please take care of the borrowed book and keep it safe.</Box>
          <Box>2. Please return the book by the indicated return date.</Box>
          <Box>
            3. Please do not forget to use a bookmark to mark the place where you stopped, instead of folding the page.
            This will keep the book in good condition and prevent damage.
          </Box>
        </Box>
      </Box>
      <Box>
        <Box sx={borrowedKindlesTitleSx}>Borrowed Kindles</Box>

        {isKindleRegisterFetching && !kindleRegister ? (
          <>
            {Array.from({ length: 3 }, (_, index) => (
              <Skeleton
                data-testid="kindleSkeleton"
                key={index}
                variant="rounded"
                width="100%"
                height={30}
                animation="wave"
                sx={{ m: 1 }}
              />
            ))}
          </>
        ) : (
          <>
            {kindleRegister?.length ? (
              kindleRegister.map((kr) => (
                <Box sx={itemSx} key={kr.id}>
                  <BorrowedKindle
                    inventoryNum={kr.kindle.inventory_num}
                    returnBefore={new Date(kr.return_before)}
                    isPendingReturnConfirmation={kr.kindle.status === 'pending return confirmation'}
                    returnBtn={
                      <Button
                        sx={
                          kr.kindle.status !== 'pending return confirmation' ? returnButtonSx : returnButtonDisabledSx
                        }
                        onClick={() =>
                          returnKindleMutation.mutate({
                            id: kr.id,
                            kindleRegisterGetRequest: {
                              kindle_id: kr.kindle.id,
                            },
                          })
                        }
                        disabled={kr.kindle.status === 'pending return confirmation'}
                        size="small"
                      >
                        Return
                      </Button>
                    }
                  />
                </Box>
              ))
            ) : (
              <Box sx={noBorrowedBoxSx}>It looks like you don&apos;t have any borrowed Kindles right now.</Box>
            )}
          </>
        )}

        <Box sx={rulesSx}>
          <Box
            data-testid="kindleRules"
            sx={{
              marginTop: '25px',
              fontWeight: 600,
            }}
          >
            Please keep in mind the following rules concerning the borrowed Kindles:
          </Box>
          <Box>1. Please take care of the borrowed Kindle and keep it safe.</Box>
          <Box>2. Please return the Kindle by the indicated return date.</Box>
          <Box>
            3. Please do not install any additional books or applications on the Kindle without the Librarian&apos;s
            permission. Do not switch the account or add a new account on this Kindle.
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BorrowedBooksAndKindles;
