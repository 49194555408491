import { SxProps } from '@mui/material';

export const incomingBookBoxSx: SxProps = {
  width: '218px',
  height: '369px',
  flexShrink: 0,
  padding: '25px 8px',
};

export const incomingBookTitleSx: SxProps = {
  color: '#000',
  textAlign: 'center',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: 'normal',
};

export const incomingBookAuthorsSx: SxProps = {
  color: '#000',
  textAlign: 'center',
  fontSize: '11px',
  fontWeight: 300,
  lineHeight: 'normal',
};
