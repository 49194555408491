import { LoadingButton } from '@mui/lab';
import { Box, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { useContext, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import feedbackContext from '../../../../contexts/FeedbackContext';
import { api, IAuthor } from '../../../../services/api';
import { handleErrorMsg } from '../../../../utils';
import { EditSubmitBtnSx, EditSubmitDisabledBtnSx, EditTextFieldSx } from '../../styles';
import schema from '../validations';

interface IFormValues {
  name: string;
}

interface IUpdateAuthorProps {
  author: IAuthor;
  handleCloseEdit: () => void;
}

const initialValues: IFormValues = {
  name: '',
};

const UpdateAuthorForm = ({ author, handleCloseEdit }: IUpdateAuthorProps) => {
  const queryClient = useQueryClient();
  const { setFeedback } = useContext(feedbackContext);

  useEffect(() => {
    initialValues.name = author.name;
  }, [author]);

  const updateMutation = useMutation(
    (values: IFormValues) => api.author.authorsIdPatch({ id: author.id, authorsGetRequest: values }),
    {
      onSuccess: () => {
        setFeedback({
          status: 'success',
          message: 'The author was successfully edited',
        });
        handleCloseEdit();
        return Promise.all([
          queryClient.invalidateQueries(['authors']),
          queryClient.invalidateQueries(['authors', { id: author.id }]),
        ]);
      },
      onError: (e) => {
        handleErrorMsg(e, setFeedback);
      },
    },
  );

  const handleSubmitForm = (values: IFormValues) => updateMutation.mutate(values);

  const { errors, handleBlur, handleChange, handleSubmit, values, isValid } = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: handleSubmitForm,
    validationSchema: schema,
  });

  return (
    <Box autoComplete="off" component="form" onSubmit={handleSubmit} sx={{ '& .MuiTextField-root': { mb: 1, mt: 1 } }}>
      <TextField
        sx={EditTextFieldSx}
        error={!!errors.name}
        helperText={errors.name}
        id="name"
        label="Name"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.name}
        spellCheck
      />
      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <LoadingButton
          sx={author.name === values.name || !isValid ? EditSubmitDisabledBtnSx : EditSubmitBtnSx}
          disabled={author.name === values.name || !isValid}
          loading={updateMutation.isLoading}
          type="submit"
        >
          Submit
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default UpdateAuthorForm;
