import { ModeEdit } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material';
import { AxiosResponse } from 'axios';
import { useFormik } from 'formik';
import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import FeedbackContext from '../../../../contexts/FeedbackContext';
import { api, IOfficePost, ISettingsPost } from '../../../../services/api';
import { DropdownsGet200Response } from '../../../../services/api/openapi';
import { handleErrorMsg } from '../../../../utils';
import { ActionIconSx, ButtonsBoxSx, CancelButtonSx, Inputs } from '../../styles';
import {
  OfficeDialogCloseBtnSx,
  OfficeDialogSaveBtnSx,
  OfficeDialogSaveDisabledBtnSx,
  OfficeDialogTitleSx,
} from '../styles';
import { IFormValues, IOfficeSettings } from '../types';
import { schema } from '../validation';

interface Props {
  office: IOfficeSettings;
}

const EditOffice: FC<Props> = ({ office }) => {
  const { setFeedback } = useContext(FeedbackContext);
  const queryClient = useQueryClient();
  const [open, setOpen] = useState<boolean>(false);

  const formInitialValues: IFormValues = {
    name: office.name,
    city: office.city,
    country: office.country,
    max_books: office.settings?.max_books ?? 3,
    max_term_books: office.settings?.max_term_books ?? 30,
    max_kindles: office.settings?.max_kindles ?? 1,
    max_term_kindles: office.settings?.max_term_kindles ?? 90,
  };

  const editOfficeMutation = useMutation(
    (variables: { officeId: number; officeData: IOfficePost }) => {
      return api.office.officesIdPatch({
        id: variables.officeId,
        officesGetRequest: variables.officeData,
      });
    },
    {
      onSuccess: () => {
        setOpen(false);
        return queryClient.invalidateQueries(['offices']);
      },
      onError: (e) => handleErrorMsg(e, setFeedback),
    },
  );

  const editSettingsMutation = useMutation(
    (variables: { settingsId: number; settingsData: ISettingsPost }) => {
      return api.setting.settingsIdPatch({
        id: variables.settingsId,
        settingsGetRequest: variables.settingsData,
      });
    },
    {
      onSuccess: () => {
        setOpen(false);
        return queryClient.invalidateQueries(['settings']);
      },
      onError: (e) => handleErrorMsg(e, setFeedback),
    },
  );

  const { handleSubmit, handleChange, handleBlur, values, resetForm, setFieldValue, errors, isValid } = useFormik({
    initialValues: formInitialValues,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: () => {
      const officeData = {
        name: values.name,
        city: values.city,
        country: values.country,
      };
      const settingsData = {
        max_books: values.max_books,
        max_term_books: values.max_term_books,
        max_term_kindles: values.max_term_kindles,
      };
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      if (officeDataChanged) editOfficeMutation.mutate({ officeId: office.id, officeData });
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      if (settingsDataChanged)
        editSettingsMutation.mutate({
          settingsId: office.settings.id,
          settingsData,
        });
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      if (!officeDataChanged && !settingsDataChanged) setOpen(false);
    },
  });

  const officeDataChanged = useMemo(() => {
    return office.name !== values.name || office.city !== values.city || office.country !== values.country;
  }, [values.name, values.city, values.country]);

  const settingsDataChanged = useMemo(() => {
    return (
      office.settings?.max_books !== values.max_books ||
      office.settings?.max_term_books !== values.max_term_books ||
      office.settings?.max_kindles !== values.max_kindles ||
      office.settings?.max_term_kindles !== values.max_term_kindles
    );
  }, [values.max_books, values.max_term_books, values.max_kindles, values.max_term_kindles]);

  const handleOpenDialog = () => {
    resetForm();
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const { data: countriesData, isLoading: isLoadingCountries } = useQuery({
    queryKey: ['countries'],
    queryFn: () => api.dropdown.dropdownsGet({ name: ['office_country'] }),
    select: useCallback((data: AxiosResponse<DropdownsGet200Response, any>) => {
      const countries = Object.entries(data.data.data[0].values) as [string, string][];
      return countries.map((c) => c[1]);
    }, []),
  });

  return (
    <Box>
      <Box sx={ButtonsBoxSx}>
        <Tooltip title="Edit">
          <IconButton data-testid="edit-button" onClick={handleOpenDialog} aria-label="update">
            <ModeEdit sx={ActionIconSx} />
          </IconButton>
        </Tooltip>
      </Box>
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle sx={OfficeDialogTitleSx}>Edit office</DialogTitle>
        <Box component="form" onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container columns={{ xs: 12, md: 12 }} spacing={1}>
              <Grid item xs={12}>
                <TextField
                  spellCheck
                  sx={[Inputs]}
                  id="name"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.name}
                  helperText={errors.name}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  spellCheck
                  sx={[Inputs]}
                  id="city"
                  label="City"
                  variant="outlined"
                  fullWidth
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.city}
                  helperText={errors.city}
                />
              </Grid>

              <Grid item xs={12}>
                {isLoadingCountries ? (
                  <Box>Loading...</Box>
                ) : (
                  <Autocomplete
                    sx={[Inputs]}
                    disableClearable
                    fullWidth
                    id="country"
                    value={values.country}
                    onChange={(event, value) => setFieldValue('country', value)}
                    options={countriesData ?? []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Country"
                        fullWidth
                        onBlur={handleBlur}
                        error={!!errors.country}
                        helperText={errors.country}
                      />
                    )}
                  />
                )}
              </Grid>

              <Grid item xs={3}>
                <TextField
                  sx={[Inputs]}
                  id="max_books"
                  type="number"
                  label="Max books"
                  variant="outlined"
                  value={values.max_books}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.max_books}
                  helperText={errors.max_books}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  sx={[Inputs]}
                  id="max_term_books"
                  type="number"
                  label="Max term books"
                  variant="outlined"
                  value={values.max_term_books}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.max_term_books}
                  helperText={errors.max_term_books}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  sx={[Inputs]}
                  disabled
                  id="max_kindles"
                  type="number"
                  label="Max kindles"
                  variant="outlined"
                  value={values.max_kindles}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.max_kindles}
                  helperText={errors.max_kindles}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  sx={{ m: '6px 0px 6px 2px' }}
                  id="max_term_kindles"
                  type="number"
                  label="Max term kindles"
                  variant="outlined"
                  value={values.max_term_kindles}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.max_term_kindles}
                  helperText={errors.max_term_kindles}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ mb: 1 }}>
            <Button sx={OfficeDialogCloseBtnSx} onClick={handleCloseDialog}>
              Close
            </Button>
            <Button
              type="submit"
              disabled={!isValid}
              sx={!isValid ? OfficeDialogSaveDisabledBtnSx : OfficeDialogSaveBtnSx}
            >
              Save
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
};

export default EditOffice;
