import { Box, Stack } from '@mui/material';
import moment from 'moment';
import React, { FC, useMemo } from 'react';

import KindleImage from '../../../assets/images/KindleImage.jpg';
import { libraryTheme } from '../../../LibraryTheme';
import {
  cardBoxSx,
  daysLeftSx,
  kindleImageSx,
  pendingConfirmationTextSx,
  returnBeforeDateSx,
  titleSx,
} from '../styles';
import { BorrowedKindleProps, DueDateState } from '../types';

const BorrowedKindle: FC<BorrowedKindleProps> = ({
  inventoryNum,
  returnBefore,
  returnBtn,
  isPendingReturnConfirmation,
}) => {
  const returnBeforeString = returnBefore.toLocaleDateString('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  const daysLeft: number = moment(returnBefore).startOf('day').diff(moment().startOf('day'), 'days');
  const daysDue: number = -daysLeft;

  const dueDateState: DueDateState = useMemo(() => {
    if (daysLeft < 0) return DueDateState.PASSED;
    if (daysLeft <= 7) return DueDateState.CLOSE;
    return DueDateState.FAR;
  }, [daysLeft]);

  const dueDateMessage: string = useMemo(() => {
    if (dueDateState === DueDateState.PASSED) return daysDue + (daysDue === 1 ? ' day overdue' : ' days overdue');
    return daysLeft + (daysLeft === 1 ? ' day left' : ' days left');
  }, [daysDue, daysLeft, dueDateState]);

  const dueDateColor: string = useMemo(() => {
    if (dueDateState === DueDateState.PASSED) return libraryTheme.palette.error.main;
    if (dueDateState === DueDateState.CLOSE) return libraryTheme.palette.warning.main;
    return libraryTheme.palette.success.main;
  }, [dueDateState]);

  return (
    <Box sx={{ ...cardBoxSx, display: 'flex', flexDirection: 'row' }}>
      <Box component="img" id="kindle-image" src={KindleImage} alt="Kindle" sx={kindleImageSx} />
      <Stack sx={{ paddingLeft: '20px', flex: 1 }} direction="column" justifyContent="center">
        <Box component="span" sx={titleSx}>
          Kindle -{' '}
          <Box sx={{ fontWeight: 300, fontStyle: 'italic' }} component="span" data-testid="inventory_num">
            {inventoryNum}
          </Box>
        </Box>
      </Stack>
      <Stack sx={{ marginLeft: '20px', marginRight: '10px' }} direction="column" justifyContent="center">
        <Box sx={{ ...pendingConfirmationTextSx, visibility: isPendingReturnConfirmation ? 'initial' : 'hidden' }}>
          Pending return confirmation
        </Box>
        {returnBtn}
        <Box sx={{ ...returnBeforeDateSx, color: dueDateColor }}>Before: {returnBeforeString}</Box>
        <Box sx={daysLeftSx}>{dueDateMessage}</Box>
      </Stack>
    </Box>
  );
};
export default BorrowedKindle;
