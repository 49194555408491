import { Alert, Snackbar, SnackbarOrigin } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import feedbackContext from '../../contexts/FeedbackContext';

const snackBarOrigin: SnackbarOrigin = {
  horizontal: 'center',
  vertical: 'top',
};

const Feedback = () => {
  const { feedback } = useContext(feedbackContext);
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    if (feedback) setShow(true);
  }, [feedback]);

  const handleClose = () => setShow(false);

  return (
    <Snackbar
      anchorOrigin={snackBarOrigin}
      open={show}
      autoHideDuration={feedback?.welcome ? undefined : 5000}
      onClose={handleClose}
    >
      {feedback ? (
        <Alert onClose={handleClose} severity={feedback.status} sx={{ width: '100%' }}>
          {feedback?.welcome ? (
            <Link to="/user/settings" style={{ textDecoration: 'none' }}>
              {feedback.message}
            </Link>
          ) : (
            feedback.message
          )}
        </Alert>
      ) : undefined}
    </Snackbar>
  );
};

export default Feedback;
