import { SxProps } from '@mui/material';

export const suggestABookBtnSx: SxProps = {
  width: '170px',
  height: '40px',
  flexShrink: 0,
  borderRadius: '10px',
  border: '2px solid #FFF',
  color: '#FFF',
  textTransform: 'none',
  fontSize: '14px',
  fontWeight: 600,
};

export const suggestABookDialogTitleSx: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  alignItems: 'center',
  color: '#000',
  fontSize: '18px',
  fontWeight: 600,
  paddingBottom: 0,
};

export const suggestABookCardMediaSx: SxProps = {
  width: '55px',
  height: '40px',
};

export const submitBtnSx: SxProps = {
  width: '124px',
  height: '40px',
  flexShrink: 0,
  background: 'linear-gradient(180deg, #EC147A 0%, #EC147A 100%)',
  color: '#FFFFFF',
  fontSize: '14px',
  fontWeight: 700,
  textTransform: 'uppercase',
  transition: 'background 0.3s ease-in-out',
  '&:hover': {
    background: 'linear-gradient(180deg, rgb(215 14 109) 0%, rgb(215 14 109) 100%)',
  },
};

export const cancelBtnSx: SxProps = {
  width: '124px',
  height: '40px',
  flexShrink: 0,
  background: '#FFFFFF',
  color: '#000',
  fontSize: '14px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  textTransform: 'uppercase',
  border: '1px #464646 solid',
};
