import { SxProps } from '@mui/material';

export const titleSx: SxProps = {
  color: '#ffffff',
  fontSize: '12px',
  fontStyle: 'italic',
  fontFamily: 'Roboto',
  fontWeight: 300,
  lineHeight: 'normal',
  marginTop: '15px',
};
