import { useMsal } from '@azure/msal-react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import background from '../../assets/images/login-background.jpg';
import largeLogo from '../../assets/images/logo.png';
import { loginRequest } from '../../config/authConfig';

const Login: FC = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();

  const handleLogin = () => {
    instance
      .loginPopup(loginRequest)
      .then(() => {
        navigate('/');
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Box
      component="form"
      noValidate
      sx={{
        height: '100vh',
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#fff',
            borderRadius: '12px',
            minWidth: '325px',
            width: '500px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '0px 0px 12px 12px',
              padding: '32px',
            }}
          >
            <Typography
              sx={{
                flexGrow: 1,
                textAlign: 'center',
                fontSize: '60px',
                fontWeight: '50',
                fontFamily: 'LeagueGothic',
                color: 'black',
              }}
            >
              <img src={largeLogo} style={{ paddingRight: '7px' }} alt="" />
              <span
                style={{
                  fontFamily: 'A-Symbol-Underlined',
                }}
              >
                A
              </span>
              mdaris library
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '0px 0px 12px 12px',
              padding: '32px',
              backgroundColor: '#F3F4F6',
            }}
          >
            <Button type="button" onClick={() => handleLogin()} fullWidth variant="contained">
              LOGIN
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
