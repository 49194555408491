import { Box, Button, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { ReactNode } from 'react';

import { close, open } from '.';
import { cancelButtonSx, confirmButtonSx, dialogContentRuleSx } from './styles';

interface Props {
  id: string;
  message?: ReactNode;
  additionalContent?: ReactNode[];
  onOkTitle: string;
  onCancelTitle: string;
  onOk: () => void;
  type?: string;
}

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  width: '104px',
  height: '154px',
  borderRadius: '3px',
  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.3)',
});

// This is the actual confirmation dialog content that gets rendered in the DeleteItem function.
const ConfirmationContent = ({ id, message, additionalContent, onOkTitle, onCancelTitle, onOk }: Props) => {
  const confirmed = () => {
    onOk();
    close(id);
  };

  return (
    <Box data-testid="confirmation_dialog">
      {message && (
        <DialogContent>
          <DialogContentText sx={{ color: '#000' }}>{message}</DialogContentText>
          <br />
          {additionalContent?.map((rule, index) => (
            <DialogContentText key={index} sx={dialogContentRuleSx}>
              {rule}
            </DialogContentText>
          ))}
        </DialogContent>
      )}
      <DialogActions sx={{ mb: 1, mr: 2 }}>
        <Button sx={cancelButtonSx} onClick={() => close(id)}>
          {onCancelTitle}
        </Button>
        <Button sx={confirmButtonSx} onClick={confirmed} data-testid="confirm_btn">
          {onOkTitle}
        </Button>
      </DialogActions>
    </Box>
  );
};

// This function returns a confirmation dialog that requires the user to confirm the deletion of an item
export const DeleteItem = (onConfirmed: () => void) => {
  const id = 'delete';
  return open({
    id: id,
    title: 'Are you sure?',
    Content: () =>
      ConfirmationContent({
        id: id,
        message: 'Are you sure you want to delete this item? This process cannot be undone.',
        onOkTitle: 'Delete',
        onCancelTitle: 'Cancel',
        onOk: onConfirmed,
        type: 'delete',
      }),
  });
};

export const ConfirmReturnDialog = (itemTitle: string, onConfirmed: () => void) => {
  const id = 'confirm_return';
  return open({
    id: id,
    title: 'Are you sure?',
    Content: () =>
      ConfirmationContent({
        id: id,
        message: `Are you sure you want to confirm the return of this ${itemTitle} to the office?`,
        onOkTitle: 'Yes',
        onCancelTitle: 'Cancel',
        onOk: onConfirmed,
      }),
  });
};

export const ConfirmBorrowDialog = (
  item: string,
  itemTitle: string,
  onConfirmed: () => void,
  rulesContent?: ReactNode[],
  coverUrl?: string,
) => {
  const id = 'confirm_borrow';
  return open({
    id: id,
    title: 'Are you sure?',
    Content: () =>
      ConfirmationContent({
        id: id,
        message: (
          <Box sx={{ fontSize: '14px', fontWeight: 400 }}>
            Are you sure you want to borrow the {item}{' '}
            <Box component="span" sx={{ fontWeight: 600 }}>
              &quot;{itemTitle}&quot;
            </Box>{' '}
            ?
            {coverUrl && (
              <Box sx={{ marginTop: '25px' }} component="div">
                <Img alt={itemTitle} src={coverUrl} />
              </Box>
            )}
          </Box>
        ),
        additionalContent: rulesContent,
        onOkTitle: 'Confirm',
        onCancelTitle: 'Cancel',
        onOk: onConfirmed,
      }),
  });
};

export const RemoveFromWishlistDialog = (itemTitle: string, onConfirmed: () => void, coverUrl?: string) => {
  const id = 'remove_wishlist_item';
  return open({
    id,
    title: 'Are you sure?',
    Content: () =>
      ConfirmationContent({
        id,
        message: (
          <Box sx={{ fontSize: '16px', fontWeight: 400 }}>
            Are you sure you want to remove the book{' '}
            <Box component="span" sx={{ fontWeight: 600 }}>
              &quot;{itemTitle}&quot;
            </Box>{' '}
            from your wishlist? This process cannot be undone.
            {coverUrl && (
              <Box sx={{ marginTop: '25px' }} component="div">
                <Img alt={itemTitle} src={coverUrl} />
              </Box>
            )}
          </Box>
        ),
        onOkTitle: 'Confirm',
        onCancelTitle: 'Cancel',
        onOk: onConfirmed,
      }),
  });
};

export const ConfirmOfficeSettings = (onConfirmed: () => void) => {
  const id = 'confirm_save';
  return open({
    id: id,
    title: 'Are you sure you want to save the office settings?',
    Content: () =>
      ConfirmationContent({
        id: id,
        onOkTitle: 'Save',
        onCancelTitle: 'Cancel',
        onOk: onConfirmed,
      }),
  });
};
