import { SxProps } from '@mui/material';

export const CancelButtonSx: SxProps = {
  width: '124px',
  height: '40px',
  flexShrink: 0,
  borderRadius: '5px',
  border: '1px #464646 solid',
  color: '#000',
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  textTransform: 'uppercase',
};

export const SubmitButtonSx: SxProps = {
  width: '124px',
  height: '40px',
  flexShrink: 0,
  borderRadius: '5px',
  background: 'linear-gradient(180deg, #EC147A 0%, #EC147A 100%)',
  color: '#FFF',
  fontSize: '14px',
  fontWeight: 700,
  textTransform: 'uppercase',
  transition: 'background 0.3s ease-in-out',
  '&:hover': {
    background: 'linear-gradient(180deg, rgb(215 14 109) 0%, rgb(215 14 109) 100%)',
  },
};

export const DialogTitleSx: SxProps = {
  color: '#000',
  fontFamily: 'Roboto',
  fontSize: '22px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  textAlign: 'center',
  marginTop: '10px',
};

export const DialogContentSx: SxProps = {
  color: '#000',
  fontFamily: 'Roboto',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  marginBottom: '10px',
  marginTop: '10px',
};

export const DialogActionsSx: SxProps = {
  mt: '60px',
  padding: 0,
};

export const DialogBoxSx: SxProps = {
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
};

export const DialogTextFieldSx: SxProps = {
  width: '550px',
  height: '130px',
};

export const UserBoxSx: SxProps = {
  height: '60px',
  paddingTop: '18px',
  paddingLeft: '10px',
  display: 'flex',
  justifyContent: 'right',
  alignItems: 'center',
  alignText: 'center',
};

export const LibrarianNameSx: SxProps = {
  fontSize: '16px',
  mb: '15px',
  ml: '10px',
  color: '#000',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
};
