import { SxProps } from '@mui/material';

import { libraryTheme } from '../../../LibraryTheme';

export const OfficeDialogTitleSx: SxProps = {
  color: '#000',
  fontSize: '18px',
  fontWeight: 500,
  pb: 0,
};

export const OfficeDialogCloseBtnSx: SxProps = {
  width: '104px',
  height: '40px',
  border: '1px #464646 solid',
  borderRadius: '5px',
  color: '#000',
  fontSize: '14px',
  fontWeight: 700,
  mr: 1,
};

export const OfficeDialogSaveBtnSx: SxProps = {
  width: '104px',
  height: '40px',
  borderRadius: '5px',
  color: '#FFF',
  fontSize: '14px',
  fontWeight: 700,
  background: 'linear-gradient(180deg, #EC147A 0%, #EC147A 100%)',
  mr: 1.5,
  transition: 'background 0.3s ease-in-out',
  '&:hover': {
    background: 'linear-gradient(180deg, rgb(215 14 109) 0%, rgb(215 14 109) 100%)',
  },
};

export const OfficeDialogSaveDisabledBtnSx: SxProps = {
  width: '104px',
  height: '40px',
  borderRadius: '5px',
  color: '#FFF',
  fontSize: '14px',
  fontWeight: 700,
  background: 'lightgrey',
  mr: 1.5,
};
