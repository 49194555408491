import * as Yup from 'yup';

const schema = Yup.object({
  description: Yup.string()
    .matches(
      /^[a-zA-Z\s.'-]+$/,
      'Category description should only contain letters, spaces, dashes, dots and apostrophes',
    )
    .max(50, 'Category description should be less than 50 chars')
    .required('Category description is required'),
});

export default schema;
