import { SxProps } from '@mui/material';

import { libraryTheme } from '../../LibraryTheme';

export const officeTextSx: SxProps = {
  fontSize: '20px',
  fontWeight: 400,
  marginBottom: '20px',
};

export const tabsSx: SxProps = {
  marginBottom: '25px',
  borderBottom: 1,
  borderColor: '#e0e0e0',
  height: '40px',
};

export const tabTextSx: SxProps = {
  color: '#000000 !important',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: 'normal',
  textTransform: 'none',
};

export const buttonSx: SxProps = {
  height: '40px',
  width: '170px',
  color: '#FFF',
  background: 'linear-gradient(180deg, #EC147A 0%, #EC147A 100%)',
  transition: 'background 0.3s ease-in-out',
  '&:hover': {
    background: 'linear-gradient(180deg, rgb(215 14 109) 0%, rgb(215 14 109) 100%)',
  },
  whiteSpace: 'normal',
};

export const buttonDisabledSx: SxProps = {
  height: '40px',
  width: '170px',
  color: '#FFF',
  background: 'lightgrey',
  whiteSpace: 'normal',
};

export const dateTextSx: SxProps = {
  fontStyle: 'italic',
};

export const settingsGridSx: SxProps = {
  margin: '40px 0',
  padding: '50px 0',
  border: '1px solid #e3e3e3',
  borderRadius: '5px',
};

export const addPaddingSx: SxProps = {
  padding: '10px 10px',
};

export const fieldSx: SxProps = {
  height: '65px',
};

export const settingsTextSx: SxProps = {
  minWidth: '200px',
  textAlign: 'end',
  fontSize: '16px',
  fontWeight: 500,
  paddingTop: '10px',
  paddingRight: '15px',
};

export const settingsTextFieldSx: SxProps = {
  width: '500px',
};

export const saveSettingsButtonSx: SxProps = {
  background: 'linear-gradient(180deg, #EC147A 0%, #EC147A 100%)',
  height: '40px',
  width: '120px',
  color: '#FFF',
  transition: 'background 0.3s ease-in-out',
  '&:hover': {
    background: 'linear-gradient(180deg, rgb(215 14 109) 0%, rgb(215 14 109) 100%)',
  },
};

export const saveSettingsDisabledButtonSx: SxProps = {
  background: 'lightgrey',
  height: '40px',
  width: '120px',
  color: '#FFF',
};

export const multiLineSx: SxProps = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'pre-wrap',
};

export const typeChipSx: SxProps = {
  backgroundColor: libraryTheme.palette.third.main,
  height: '30px',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  borderRadius: '40px',
  padding: '6px 20px',
  textTransform: 'capitalize',
  color: 'white',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};

export const dataGridBoxSx: SxProps = {
  marginTop: 1,
  minHeight: 650,
  width: '100%',
};

export const TypeChipSx: SxProps = {
  margin: 0.2,
  padding: 0.5,
  fontSize: '15px',
  textTransform: 'capitalize',
  backgroundColor: libraryTheme.palette.third.main,
  color: 'white',
};

export const statusTextSx: SxProps = {
  fontSize: '15px',
  fontWeight: 500,
  textTransform: 'capitalize',
};

export const reportsDataGridBoxSx: SxProps = {
  height: 1130,
  width: '100%',
};

export const cancelButtonSx: SxProps = {
  color: libraryTheme.palette.neutral.main,
  borderColor: libraryTheme.palette.neutral.main,
  border: 1,
};
