import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { ReactNode, SetStateAction } from 'react';

import { PopupContentSx, PopupTitleSx } from './styles';

interface IPopupProps {
  children: ReactNode | ReactNode[];
  content?: string;
  openState: [boolean, (value: SetStateAction<boolean>) => void];
  title?: string;
}
const Popup = ({ children, content, openState, title }: IPopupProps) => {
  const [open, setOpen] = openState;

  const handleClose = () => setOpen(false);

  return (
    <Dialog open={open} onClose={handleClose}>
      {title && <DialogTitle sx={PopupTitleSx}>{title}</DialogTitle>}

      <DialogContent>
        {content && <DialogContentText sx={PopupContentSx}>{content}</DialogContentText>}
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default Popup;
