import axiosInstance from './AxiosInstance';
import GoogleBooks from './googleBooks';
import {
  ActivityApi,
  AuthorApi,
  BookApi,
  BookEditionApi,
  BookRegisterApi,
  CategoryApi,
  Configuration,
  DropDownApi,
  EmailApi,
  FeedbackApi,
  KindleApi,
  KindleRegisterApi,
  OfficeApi,
  ReportApi,
  SettingApi,
  SubscriptionApi,
  SuggestedBooksApi,
  TagApi,
  UserApi,
  WishlistApi,
} from './openapi';

const BASE_PATH = process.env.REACT_APP_AL_API_URL;

class Api {
  activity: ActivityApi;

  author: AuthorApi;

  book: BookApi;

  bookEdition: BookEditionApi;

  bookRegister: BookRegisterApi;

  category: CategoryApi;

  dropdown: DropDownApi;

  email: EmailApi;

  kindle: KindleApi;

  kindleRegister: KindleRegisterApi;

  office: OfficeApi;

  report: ReportApi;

  setting: SettingApi;

  subscription: SubscriptionApi;

  tag: TagApi;

  user: UserApi;

  wishlist: WishlistApi;

  googleBooks: GoogleBooks;

  suggestedBooks: SuggestedBooksApi;

  feedback: FeedbackApi;

  constructor() {
    this.activity = new ActivityApi(new Configuration({ basePath: BASE_PATH }), BASE_PATH, axiosInstance);
    this.author = new AuthorApi(new Configuration({ basePath: BASE_PATH }), BASE_PATH, axiosInstance);
    this.book = new BookApi(new Configuration({ basePath: BASE_PATH }), BASE_PATH, axiosInstance);
    this.bookEdition = new BookEditionApi(new Configuration({ basePath: BASE_PATH }), BASE_PATH, axiosInstance);
    this.bookRegister = new BookRegisterApi(new Configuration({ basePath: BASE_PATH }), BASE_PATH, axiosInstance);
    this.category = new CategoryApi(new Configuration({ basePath: BASE_PATH }), BASE_PATH, axiosInstance);
    this.dropdown = new DropDownApi(new Configuration({ basePath: BASE_PATH }), BASE_PATH, axiosInstance);
    this.email = new EmailApi(new Configuration({ basePath: BASE_PATH }), BASE_PATH, axiosInstance);
    this.kindle = new KindleApi(new Configuration({ basePath: BASE_PATH }), BASE_PATH, axiosInstance);
    this.kindleRegister = new KindleRegisterApi(new Configuration({ basePath: BASE_PATH }), BASE_PATH, axiosInstance);
    this.office = new OfficeApi(new Configuration({ basePath: BASE_PATH }), BASE_PATH, axiosInstance);
    this.report = new ReportApi(new Configuration({ basePath: BASE_PATH }), BASE_PATH, axiosInstance);
    this.setting = new SettingApi(new Configuration({ basePath: BASE_PATH }), BASE_PATH, axiosInstance);
    this.subscription = new SubscriptionApi(new Configuration({ basePath: BASE_PATH }), BASE_PATH, axiosInstance);
    this.tag = new TagApi(new Configuration({ basePath: BASE_PATH }), BASE_PATH, axiosInstance);
    this.user = new UserApi(new Configuration({ basePath: BASE_PATH }), BASE_PATH, axiosInstance);
    this.wishlist = new WishlistApi(new Configuration({ basePath: BASE_PATH }), BASE_PATH, axiosInstance);

    this.suggestedBooks = new SuggestedBooksApi(new Configuration({ basePath: BASE_PATH }), BASE_PATH, axiosInstance);

    this.feedback = new FeedbackApi(new Configuration({ basePath: BASE_PATH }), BASE_PATH, axiosInstance);

    this.googleBooks = new GoogleBooks();
  }
}

export const api = new Api();

export type {
  BooksGetRequestLangEnum as EBookLang,
  BooksGetRequestTypeEnum as EBookType,
  AuthorsGet200ResponseDataInner as IAuthor,
  BooksGet200ResponseDataInner as IBook,
  BooksGet200ResponseDataInnerCategory as IBookCategory,
  BooksGet200ResponseDataInnerDetailsInner as IBookDetails,
  BookRegisterGet200ResponseDataInnerBookEdition as IBookEdition,
  BookEditionsGet200Response as IBookEditions,
  BookRegisterGet200ResponseDataInner as IBookRegister,
  BookApiBooksIdGetRequest as IBookRequest,
  DropdownsGet200ResponseDataInner as IDropDown,
  EmailGet201Response as IEmailRequest,
  FeedbacksGet200ResponseDataInner as IFeedback,
  KindlesGet200ResponseDataInner as IKindle,
  KindleRegisterGet200ResponseDataInner as IKindleRegister,
  BooksGet200ResponseDataInnerLangEnum as ILang,
  BooksGet200ResponseDataInnerCreatedByOffice as IOffice,
  OfficesGetRequest as IOfficePost,
  SettingsGetRequest as ISettingsPost,
  SubscriptionsGet200ResponseDataInner as ISubscription,
  SuggestedBooksGet200ResponseDataInner as ISuggestedBook,
  BooksGet200ResponseDataInnerCreatedBy as IUser,
  BooksGet200ResponseDataInnerCreatedBy as IUserData,
  WishlistsGet200ResponseDataInner as IWish,
} from './openapi';
