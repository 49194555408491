import { Box, Button, Skeleton } from '@mui/material';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import moment from 'moment/moment';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import CurrentUserContext from '../../../contexts/CurrentUserContext';
import FeedbackContext from '../../../contexts/FeedbackContext';
import { ConfirmReturnDialog } from '../../../dialogs/confirmationDialog';
import { api } from '../../../services/api';
import BookCover from '../../../shared/BookCover';
import { handleErrorMsg } from '../../../utils';
import { buttonDisabledSx, buttonSx, dataGridBoxSx, dateTextSx, multiLineSx, typeChipSx } from '../styles';

const OfficeBorrowedBooksTab: FC = () => {
  const { currentUser } = useContext(CurrentUserContext);
  const { setFeedback } = useContext(FeedbackContext);
  const queryClient = useQueryClient();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);

  const { data: bookRegistersData, isLoading: isBookRegistersLoading } = useQuery({
    queryKey: ['booksRegistersOffice', currentPage],
    queryFn: () =>
      api.bookRegister.bookRegisterAllGet({
        params: {
          active: true,
          'sort[return_at]': 'desc',
          office_id: currentUser?.office.id,
          per_page: 10,
          page: currentPage + 1,
        },
      }),
    enabled: !!currentUser,
  });

  const [rowCountState, setRowCountState] = useState(bookRegistersData?.data.meta.total || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      bookRegistersData?.data.meta.total !== undefined ? bookRegistersData?.data.meta.total : prevRowCountState,
    );
  }, [bookRegistersData?.data.meta.total, rowCountState]);

  const borrowedBooksInfo = useMemo(() => {
    if (!bookRegistersData) return [];
    return bookRegistersData.data.data.map((br) => {
      return {
        id: br.id,
        book_id: br.book_edition?.book.id,
        cover: br.book_edition?.book.cover,
        title: br.book_edition?.book.title,
        type: br.book_edition?.type,
        inventory_num: br.book_edition?.inventory_num,
        borrowed_by: br.user?.name,
        borrowed_at: br.borrowed_at,
        return_before: br.return_before,
        book_status: br.book_edition?.status,
        isAvailable: br.book_edition?.book.details.some((bd) => bd.status === 'available'),
      };
    });
  }, [bookRegistersData]);

  const confirmReturnedMutation = useMutation(
    (variables: { bookRegisterId: number }) =>
      api.bookRegister.bookRegisterConfirmIdPatch({
        id: variables.bookRegisterId,
      }),
    {
      onSuccess: () => {
        return Promise.all([
          queryClient.refetchQueries('booksRegister'),
          queryClient.refetchQueries('bookEditions'),
          queryClient.invalidateQueries(['booksRegistersOffice']),
        ]);
      },
      onError: (e) => handleErrorMsg(e, setFeedback),
    },
  );

  const handleConfirmClick = (bookRegisterId: number) =>
    ConfirmReturnDialog('book', () => confirmReturnedMutation.mutate({ bookRegisterId }));

  const columns: GridColDef[] = [
    {
      field: 'cover',
      headerName: 'Cover',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      width: 100,
      renderCell: (row) => {
        return (
          <BookCover
            bookId={row.row.book_id}
            title={row.row.title}
            cover={row.value}
            isAvailable={row.row.isAvailable}
            width="68px"
            height="100px"
            small
          />
        );
      },
    },
    {
      field: 'title',
      headerName: 'Title',
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      flex: 1,
      renderCell: (row) => {
        return <Box sx={{ ...multiLineSx, fontWeight: 400 }}>{row.value}</Box>;
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      width: 110,
      renderCell: (row) => {
        return (
          <Box sx={typeChipSx} key={row.id}>
            {row.value}
          </Box>
        );
      },
    },
    {
      field: 'inventory_num',
      headerName: 'Inventory number',
      headerAlign: 'center',
      align: 'center',
      width: 130,
    },
    {
      field: 'borrowed_by',
      headerName: 'Borrowed by',
      headerAlign: 'center',
      align: 'center',
      width: 200,
      renderCell: (row) => {
        return <Box sx={{ fontWeight: 300 }}>{row.value}</Box>;
      },
    },
    {
      field: 'borrowed_at',
      headerName: 'Borrowed at',
      headerAlign: 'center',
      align: 'center',
      width: 100,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleDateString('en-GB', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });
      },
      renderCell: (row) => {
        return <Box sx={{ ...dateTextSx, fontWeight: 300 }}>{row.formattedValue}</Box>;
      },
    },
    {
      field: 'return_before',
      headerName: 'Return date',
      headerAlign: 'center',
      align: 'center',
      width: 100,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleDateString('en-GB', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });
      },
      renderCell: (row) => {
        const past = moment(row.value) < moment();
        return <Box sx={{ ...dateTextSx, fontWeight: 300, color: past ? 'red' : undefined }}>{row.formattedValue}</Box>;
      },
    },
    {
      field: 'confirm_returned',
      headerName: 'Returned',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      width: 180,
      renderCell: (row) => (
        <Button
          data-testid="confirm_return_btn"
          sx={row.row.book_status !== 'pending return confirmation' ? buttonDisabledSx : buttonSx}
          disabled={row.row.book_status !== 'pending return confirmation'}
          onClick={() => handleConfirmClick(row.row.id)}
          size="small"
        >
          Confirm
        </Button>
      ),
    },
  ];

  const CustomToolbar: FC = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter sx={{ p: '5px' }} />
      </GridToolbarContainer>
    );
  };

  return (
    <Box>
      {isBookRegistersLoading ? (
        <>
          <Skeleton
            variant="rounded"
            width="100%"
            height={70}
            animation="wave"
            sx={{ mt: 1 }}
            data-testid="bookRegistersAll_skeleton"
          />
          {Array.from({ length: 4 }, (_, index) => (
            <Skeleton key={index} variant="rounded" width="100%" height={100} animation="wave" sx={{ mt: 0.5 }} />
          ))}
        </>
      ) : (
        <Box sx={dataGridBoxSx}>
          <DataGrid
            disableVirtualization
            headerHeight={70}
            rowHeight={120}
            rows={borrowedBooksInfo}
            columns={columns}
            loading={isBookRegistersLoading}
            pageSize={pageSize}
            rowsPerPageOptions={[10]}
            disableColumnSelector
            disableDensitySelector
            disableColumnFilter
            disableColumnMenu
            autoHeight
            components={{ Toolbar: CustomToolbar }}
            rowCount={rowCountState}
            pagination
            page={currentPage}
            paginationMode="server"
            onPageChange={(newPage) => setCurrentPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          />
        </Box>
      )}
    </Box>
  );
};

export default OfficeBorrowedBooksTab;
