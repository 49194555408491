import { SxProps } from '@mui/material';

export const itemSx: SxProps = {
  marginBottom: '15px',
};

export const sectionTextSx: SxProps = {
  fontSize: '24px',
  fontWeight: 400,
  marginBottom: '25px',
};

export const noItemsBoxSx: SxProps = {
  height: '200px',
  lineHeight: '200px',
  border: '1px solid #E0E0E0',
  borderRadius: '10px',
  textAlign: 'center',
};
