import { SxProps } from '@mui/material';

export const GridBoxSx: SxProps = {
  height: 750,
  maxWidth: 500,
};

export const SkeletonBtnSx: SxProps = {
  display: 'flex',
  justifyContent: 'flex-start',
  mb: '25px',
  mt: '25px',
};
