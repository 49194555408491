import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useContext, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import FeedbackContext from '../../../contexts/FeedbackContext';
import { api } from '../../../services/api';
import UserPicture from '../../../shared/UserPicture';
import { handleErrorMsg } from '../../../utils';
import {
  CancelButtonSx,
  DialogActionsSx,
  DialogBoxSx,
  DialogContentSx,
  DialogTextFieldSx,
  DialogTitleSx,
  LibrarianNameSx,
  SubmitButtonSx,
  UserBoxSx,
} from './styles';

interface IEmailDialogProps {
  name: string;
  url?: string;
  email: string;
  open: boolean;
  setOpen: (arg: boolean) => void;
  handleClose: () => void;
}

const EmailDialog = ({ name, url, email, open, setOpen, handleClose }: IEmailDialogProps) => {
  const { setFeedback } = useContext(FeedbackContext);
  const [message, setMessage] = useState<string>('');
  const queryClient = useQueryClient();

  const handleMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  const sendEmailMutation = useMutation(
    () =>
      api.email.sendLocalLibrarianEmail({
        emailGetRequest: { librarian_email: email, message },
      }),
    {
      onSuccess: () => {
        setFeedback({
          status: 'success',
          message: 'Email message was sent successfully',
        });
        queryClient.invalidateQueries('feedback');
      },
      onError: (e) => handleErrorMsg(e, setFeedback),
    },
  );

  const handleSubmit = async () => {
    if (message.trim() !== '' && message.trim().length > 2) {
      sendEmailMutation.mutateAsync();
      setMessage('');
      setOpen(false);
    } else {
      setFeedback({
        status: 'error',
        message: 'Email cannot be empty',
      });
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={DialogTitleSx}>Contact your local librarian</DialogTitle>
        <DialogContent>
          <Box sx={DialogBoxSx}>
            <DialogContentText sx={DialogContentSx}>Send Email to: </DialogContentText>
            <Box sx={UserBoxSx}>
              <UserPicture userPictureUrl={url} userName={name} />
              <Box sx={LibrarianNameSx}>{name}</Box>
            </Box>
          </Box>

          <TextField
            sx={DialogTextFieldSx}
            placeholder="Enter your message here..."
            multiline
            rows={5}
            value={message}
            inputProps={{ maxLength: 5000 }}
            onChange={handleMessageChange}
          />

          <DialogActions sx={DialogActionsSx}>
            <Button sx={CancelButtonSx} onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton
              sx={SubmitButtonSx}
              onClick={handleSubmit}
              loading={sendEmailMutation.isLoading}
              type="submit"
            >
              Submit
            </LoadingButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EmailDialog;
