import LoadingButton from '@mui/lab/LoadingButton';
import { Box, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import feedbackContext from '../../../../contexts/FeedbackContext';
import { api } from '../../../../services/api';
import { handleErrorMsg } from '../../../../utils';
import { AddNewBtnSx, AddNewDisabledBtnSx, FormBoxSx, FormTextFieldSx } from '../../styles';
import schema from '../validations';

interface IFormValues {
  description: string;
}

const initialValues: IFormValues = {
  description: '',
};

const CreateCategoryForm = () => {
  const queryClient = useQueryClient();
  const { setFeedback } = useContext(feedbackContext);

  const postMutation = useMutation(
    (values: IFormValues) => {
      return api.category.categoriesPost({ categoriesGetRequest: values });
    },
    {
      onSuccess: () => {
        setFeedback({
          status: 'success',
          message: 'The category was successfully created',
        });
        return queryClient.invalidateQueries(['categories']);
      },
      onError: (e) => {
        handleErrorMsg(e, setFeedback);
      },
    },
  );

  const handleSubmitForm = async (values: IFormValues) => {
    await postMutation.mutateAsync(values);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    resetForm();
  };

  const { errors, handleBlur, handleChange, handleSubmit, resetForm, isValid, values, touched } = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmitForm,
    validationSchema: schema,
  });

  return (
    <Box component="form" autoComplete="off" onSubmit={handleSubmit} sx={FormBoxSx}>
      <TextField
        sx={FormTextFieldSx}
        InputProps={{ sx: { height: '40px' } }}
        size="small"
        error={touched.description && Boolean(errors.description)}
        helperText={touched.description && errors.description}
        id="description"
        name="description"
        label="Category"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.description}
        spellCheck
      />
      <LoadingButton
        sx={touched.description && Boolean(errors.description) ? AddNewDisabledBtnSx : AddNewBtnSx}
        disabled={!isValid}
        loading={postMutation.isLoading}
        type="submit"
      >
        ADD NEW
      </LoadingButton>
    </Box>
  );
};

export default CreateCategoryForm;
