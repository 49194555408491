import { AlertColor } from '@mui/material';
import React, { createContext } from 'react';

export interface IFeedback {
  message: string;
  status?: AlertColor;
  welcome?: boolean;
}
const FeedbackContext = createContext<{
  feedback: IFeedback | null;
  setFeedback: React.Dispatch<React.SetStateAction<IFeedback | null>>;
}>({ feedback: null, setFeedback: () => null });

export type SetFeedbackType = React.Dispatch<React.SetStateAction<IFeedback | null>>;

export default FeedbackContext;
