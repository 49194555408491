import {
  Box,
  Button,
  DialogActions,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material';
import { AxiosRequestConfig } from 'axios';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import FeedbackContext from '../../../../contexts/FeedbackContext';
import { api, IBook } from '../../../../services/api';
import {
  BookEditionsGetRequest,
  BooksGet200ResponseDataInnerDetailsInner as BookEdition,
} from '../../../../services/api/openapi';
import { handleErrorMsg } from '../../../../utils';
import {
  cancelButtonSx,
  chooseFileButtonSx,
  ebookEditionBoxSx,
  editionTextSx,
  paperEditionBoxSx,
  saveButtonSx,
} from '../styles';
import { FILE_SIZE, SUPPORTED_FORMATS } from '../validation';

const ALPHA_NUMERIC_REGEX = /[^0-9a-zA-Z]/;

interface Props {
  book: IBook;
  setOpen: (value: ((prevState: boolean) => boolean) | boolean) => void;
}

const EditBookEdition: FC<Props> = ({ book, setOpen }) => {
  const { setFeedback } = useContext(FeedbackContext);
  const queryClient = useQueryClient();

  const [inventoryNumberSelect, setInventoryNumberSelect] = useState<string | undefined>(
    book.details.find((bd) => bd.type === 'paper')?.inventory_num,
  );
  const [inventoryNumberInput, setInventoryNumberInput] = useState<string | undefined>(inventoryNumberSelect);
  const [numberOfPages, setNumberOfPages] = useState<number | undefined>(1);
  const [paperBookEdition, setPaperBookEdition] = useState<BookEdition>();
  const [eBookEdition, setEBookEdition] = useState<BookEdition>();
  const [ebookUrl, setEbookUrl] = useState<string>('');
  const [ebookFile, setEbookFile] = useState<File>();
  const [ebookFileName, setEbookFileName] = useState<string>('');
  const [hasSelectedFile, setHasSelectedFile] = useState<boolean>(false);

  const handleClose = () => setOpen(false);

  useEffect(() => {
    const paperBooks = book.details.filter((be) => be.type === 'paper');
    if (paperBooks.length > 0) {
      const paperBook = paperBooks[0];
      setInventoryNumberSelect(paperBook.inventory_num);
      setInventoryNumberInput(paperBook.inventory_num);
    }

    setEBookEdition(book.details.find((be) => be.type === 'e-book'));
  }, []);

  useEffect(() => {
    if (eBookEdition && eBookEdition.file_url) setEbookUrl(eBookEdition.file_url);
  }, [eBookEdition]);

  useEffect(() => {
    setPaperBookEdition(book.details.find((be) => be.inventory_num === inventoryNumberSelect));
  }, [inventoryNumberSelect, book]);

  useEffect(() => {
    if (paperBookEdition?.number_of_pages) setNumberOfPages(paperBookEdition?.number_of_pages);
    else setNumberOfPages(1);
  }, [paperBookEdition]);

  const editBookEditionMutation = useMutation(
    (variables: { id: number; newBookEdition: BookEditionsGetRequest }) => {
      return api.bookEdition.bookEditionsIdPatch({
        id: variables.id,
        bookEditionsGetRequest: variables.newBookEdition,
      });
    },
    {
      onSuccess: () => {
        setInventoryNumberSelect(inventoryNumberInput);
        setFeedback({ status: 'success', message: 'Book edition updated successfully' });
        handleClose();
        return queryClient.invalidateQueries(['book', book.id]);
      },
      onError: (e) => handleErrorMsg(e, setFeedback),
    },
  );

  const editEbookUrlMutation = useMutation(
    (variables: { newBookEdition: BookEditionsGetRequest }) => {
      const options: AxiosRequestConfig = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };

      // currently deleting then re-creating the e-book with the new url,
      // because patch method doesn't work
      return api.bookEdition.bookEditionsIdDelete({ id: eBookEdition!.id }).then(() => {
        return api.bookEdition.bookEditionsPost({ bookEditionsGetRequest: variables.newBookEdition }, options);
      });
    },
    {
      onSuccess: () => {
        setFeedback({ status: 'success', message: 'New E-book file uploaded successfully' });
        handleClose();
        return queryClient.invalidateQueries(['book', book.id]);
      },
      onError: (e) => handleErrorMsg(e, setFeedback),
    },
  );

  const savePaperBookInventoryNumber = () => {
    if (!inventoryNumberInput || Number.parseInt(inventoryNumberInput) < 1) {
      setFeedback({
        status: 'error',
        message: 'Inventory number should be >= 1',
      });
      return;
    }
    const newBookEdition = {
      book_id: book.id,
      type: paperBookEdition!.type,
      number_of_pages: numberOfPages,
      inventory_num: inventoryNumberInput !== inventoryNumberSelect ? inventoryNumberInput : undefined,
    };
    editBookEditionMutation.mutate({
      id: paperBookEdition!.id,
      newBookEdition,
    });
  };

  const saveEbookUrl = () => {
    const newBookEdition = {
      book_id: book.id,
      isbn: eBookEdition!.isbn,
      isbn_13: eBookEdition!.isbn_13,
      type: eBookEdition!.type,
      file_url: ebookFile as string | undefined,
    };
    editEbookUrlMutation.mutate({ newBookEdition });
  };

  const handleSave = () => {
    if (inventoryNumberInput) savePaperBookInventoryNumber();
    if (hasSelectedFile) saveEbookUrl();
  };

  const handleInvNumberSelectChange = (event: SelectChangeEvent<typeof inventoryNumberSelect>) => {
    setInventoryNumberSelect(event.target.value);
    setInventoryNumberInput(event.target.value);
  };

  const isValidInvNumber = useMemo(() => {
    if (!inventoryNumberInput) return true;
    return inventoryNumberInput && inventoryNumberInput.length > 0 && inventoryNumberInput.length <= 20;
  }, [inventoryNumberInput]);

  const isValidEbookFile = useMemo(() => {
    return ebookFile && SUPPORTED_FORMATS.includes(ebookFile.type) && ebookFile.size <= FILE_SIZE;
  }, [ebookFile]);

  return (
    <Box>
      {book.details.some((be) => be.type === 'paper') && (
        <>
          <Box sx={editionTextSx}>Paper</Box>
          <Box sx={{ ...paperEditionBoxSx, marginBottom: '25px' }}>
            <FormControl sx={{ marginBottom: '12px' }} fullWidth>
              <InputLabel id="inv-number-select-label">Select current inventory number</InputLabel>
              <Select
                labelId="inv-number-select-label"
                label="Select current inventory number"
                size="small"
                onChange={handleInvNumberSelectChange}
                value={inventoryNumberSelect}
              >
                {book.details
                  .filter((ed) => ed.type === 'paper')
                  .map((ed) => (
                    <MenuItem value={ed.inventory_num} key={ed.id}>
                      {ed.inventory_num}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Grid container sx={{ marginBottom: '10px' }}>
              <Grid item xs={6} sx={{ paddingRight: '10px' }}>
                <TextField
                  label="Change inventory number"
                  fullWidth
                  value={inventoryNumberInput}
                  onChange={(event) => {
                    if (!ALPHA_NUMERIC_REGEX.test(event.target.value)) setInventoryNumberInput(event.target.value);
                  }}
                  size="small"
                  error={!isValidInvNumber}
                  helperText={
                    !isValidInvNumber &&
                    inventoryNumberInput &&
                    (inventoryNumberInput.length === 0
                      ? 'Inventory number cannot be empty'
                      : 'Inventory number should have a maximum of 20 characters')
                  }
                />
              </Grid>
              <Grid item xs={6} sx={{ paddingLeft: '10px' }}>
                <TextField
                  label="Change number of pages"
                  fullWidth
                  value={numberOfPages}
                  type="number"
                  onChange={(event) => {
                    const nr = parseInt(event.target.value);
                    if (nr > 0) setNumberOfPages(nr);
                  }}
                  size="small"
                />
              </Grid>
            </Grid>
          </Box>
        </>
      )}

      {book.types.some((be) => be === 'e-book') && (
        <>
          <Box sx={editionTextSx}>E-Book</Box>
          <Box sx={ebookEditionBoxSx}>
            <TextField
              sx={{ marginBottom: '12px' }}
              size="small"
              label="E-book url"
              disabled
              fullWidth
              value={ebookUrl}
            />
            <TextField
              id="file-input"
              sx={{ display: 'none' }}
              size="small"
              fullWidth
              type="file"
              onChange={(event) => {
                const target = event.target as HTMLInputElement;
                if (!!target.files && !!target.files[0]) {
                  setHasSelectedFile(true);
                  if (target && target.files) {
                    setEbookFile(target.files[0]);
                    setEbookFileName(target.files[0].name);
                  }
                }
              }}
            />
            <Box sx={{ height: '70px' }}>
              <TextField
                id="file-name"
                size="small"
                label="File name"
                fullWidth
                InputProps={{ readOnly: true }}
                value={ebookFileName}
                error={hasSelectedFile && !isValidEbookFile}
                helperText={
                  hasSelectedFile &&
                  !isValidEbookFile &&
                  'File type should be pdf, fb2, epub or mobi, and size should be maximum 19 MB'
                }
              />
            </Box>
            <Stack direction="row" gap={2} justifyContent="end">
              <Button sx={chooseFileButtonSx} onClick={() => document.getElementById('file-input')?.click()}>
                CHOOSE FILE
              </Button>
            </Stack>
          </Box>
        </>
      )}
      <DialogActions sx={{ paddingRight: 0 }}>
        <Button sx={cancelButtonSx} onClick={handleClose}>
          Close
        </Button>
        <Button
          sx={saveButtonSx}
          disabled={!isValidInvNumber || (hasSelectedFile && !isValidEbookFile)}
          variant="contained"
          onClick={handleSave}
        >
          Save
        </Button>
      </DialogActions>
    </Box>
  );
};

export default EditBookEdition;
